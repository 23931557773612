import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Crud } from '@shared/models/crud.decoration';
import { CrudService } from '@shared/services/crud.service';
import { Observable } from 'rxjs';
import { delay, map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
@Crud({
  apiUrl: `${environment.apiUrl}/templates/`,
  hasIdPathUpdate: true,
})
export class TemplateService extends CrudService<any> {
  constructor(http: HttpClient) {
    super(http);
  }

  headers = new HttpHeaders().set('Content-Type', 'application/vnd.ms-excel');

  getTemplate(type: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/templates/` + type, {
      headers: this.headers,
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  download(type: string, file: string): Observable<any> {
    return this.getTemplate(type).pipe(
      map((res) => {
        const data = window.URL.createObjectURL(res['body']);
        const link = document.createElement('a');

        link.href = data;
        link.download = file;
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
          })
        );

        return { data, link };
      }),
      delay(100),
      tap(({ data, link }) => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      })
    );
  }
}
