import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthService } from '@app/auth/auth.service';
import { CitadelOauthService } from '@app/auth/oauth/citadel-oauth.service';
import { OauthLoginComponent } from '@app/auth/oauth/oauth-login/oauth-login.component';
import { AppConfigService } from '@shared/services/app-config.services';
import {
  AuthConfig,
  OAuthStorage,
  provideOAuthClient,
} from 'angular-oauth2-oidc';
import { OauthRoutingModule } from './oauth-routing.module';

export function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  imports: [OauthLoginComponent, OauthRoutingModule],
  providers: [
    provideOAuthClient(),
    CitadelOauthService,
    { provide: AuthService, useExisting: CitadelOauthService },
    {
      provide: AuthConfig,
      useFactory: (config: AppConfigService) => config.get('oauthConfig'),
      deps: [AppConfigService],
    },
    //{ provide: OAuthModuleConfig, useValue: authModuleConfig },
    { provide: OAuthStorage, useFactory: storageFactory },
  ],
})
export class CitadelOauthModule {
  static forRoot(): ModuleWithProviders<CitadelOauthModule> {
    return {
      ngModule: CitadelOauthModule,
      providers: [
        CitadelOauthService,
        { provide: AuthService, useExisting: CitadelOauthService },
        {
          provide: AuthConfig,
          useFactory: (config: AppConfigService) => config.get('oauthConfig'),
          deps: [AppConfigService],
        },
        //{ provide: OAuthModuleConfig, useValue: authModuleConfig },
        { provide: OAuthStorage, useFactory: storageFactory },
      ],
    };
  }
}
