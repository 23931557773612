import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Injector,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Organization } from '@shared/models/organization.model';
import { Observable } from 'rxjs';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  FloatLabelType,
  MatFormFieldAppearance,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UserAvatarComponent } from '@shared/components/user-avatar/user-avatar.component';
import { FilterParams } from '@shared/models/filter-params.model';
import { PageableCollection } from '@shared/models/pageable-collection.model';
import { User } from '@shared/models/user.model';
import { UserService } from '@shared/services/user.service';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { take } from 'rxjs/operators';
import { BaseMatSelectComponent } from '../base-mat-select.component';
import { HighlightDirective } from '@shared/directives/highlight.directive';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { FullnamePipe } from '@shared/pipes/fullname.pipe';

@Component({
  selector: 'app-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    NgxMatSelectSearchModule,
    MatIconModule,
    MatButtonModule,
    UserAvatarComponent,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    FullnamePipe,
    DefaultPipe,
    HighlightDirective,
  ],
})
export class UserSelectComponent extends BaseMatSelectComponent<User> {
  @Input() placeholder = 'User';
  @ViewChild('matSelect', { static: true })
  matSelect: MatSelect;
  @Input() floatLabel: FloatLabelType = 'auto';
  @Input() label: string = 'User';
  @Input() filterLabel: string = 'Search';
  @Input()
  appearance: MatFormFieldAppearance = 'outline';
  @Input() isLoading: boolean = false;
  @Output() isLoadingChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @Output() userIdChange: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  set userId(_userId: string) {
    this.loadUserId(_userId);
  }

  get userId(): string {
    return this.value?.id!;
  }

  baseFilterParams: FilterParams = new FilterParams({
    sort: {
      active: 'givenName',
      direction: 'asc',
    },
  });

  constructor(public injector: Injector, private userService: UserService) {
    super(injector);
  }

  loadUserId(userId: string) {
    if ((!userId && !this.value) || userId === this.value?.id) return;

    if (!userId) {
      // @ts-ignore
      this.value = null;
      super.emitChangeEvent();
      return;
    }

    this.value = { id: userId };
    this.userService
      .get(userId)
      .pipe(take(1))
      .subscribe((data) => {
        this.value = data;
        super.emitChangeEvent();
      });
  }

  emitChangeEvent() {
    super.emitChangeEvent();
    this.userIdChange.emit(this.userId);
  }

  searchItems(
    params: FilterParams
  ): Observable<PageableCollection<Organization>> {
    return this.userService.find(params);
  }
}
