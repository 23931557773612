import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthService } from '@app/auth/auth.service';
import { OrgAdvancedSearch } from '@app/organizations/collection/org-advanced-search';
import { OrganizationCollectionTableComponent } from '@app/organizations/collection/organization-collection-table/organization-collection-table.component';
import { NoResultFoundComponent } from '@shared/components/no-result-found/no-result-found.component';
import { PaginatorComponent } from '@shared/components/paginator/paginator.component';
import { SearchToolbarComponent } from '@shared/components/search-toolbar/search-toolbar.component';
import { ActionButton } from '@shared/models/actionbutton.model';
import { FilterParams } from '@shared/models/filter-params.model';
import { Organization } from '@shared/models/organization.model';
import { PageableCollection } from '@shared/models/pageable-collection.model';
import { AdminOnlyRoles } from '@shared/models/role-permissions';
import { AppConfigService } from '@shared/services/app-config.services';

@Component({
  selector: 'app-organizations-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    SearchToolbarComponent,
    OrganizationCollectionTableComponent,
    NoResultFoundComponent,
    PaginatorComponent,
  ],
})
export class OrganizationsCollectionComponent {
  @Input() loading: boolean = false;
  @Input() position: 'fixed' | 'sticky' = 'sticky';
  @Input() pageableCollection: PageableCollection<Organization> =
    {} as PageableCollection<Organization>;
  @Input() placeholder: string = 'Name | Short Name';
  @Input() workspaceView: boolean = false;
  @Input() filterParams: FilterParams = new FilterParams({});
  @Output() filterParamsChange = new EventEmitter<FilterParams>();

  orgActionButton: ActionButton = {
    label: 'New Organization',
    menuIcon: 'note_add',
    roles: AdminOnlyRoles,
    orgEdit: true,
    color: 'primary',
  };

  actionButtons: ActionButton[] = [];

  constructor(user: AuthService, config: AppConfigService) {
    const lowToggle = config.get('lowEnvFeatures', false);
    if (lowToggle && user.isAdmin) {
      this.actionButtons.push(this.orgActionButton);
    }
  }

  onFilterParamsChange(filterParams: FilterParams) {
    this.filterParamsChange.emit(filterParams);
  }

  protected readonly OrgAdvancedSearch = OrgAdvancedSearch;
}
