import { BaseModel } from '@shared/models/base.model';
import { ForeignVisitorId } from '@shared/shared.types';

export interface Passport extends BaseModel {
  id?: any;
  number?: string; // (20)
  type?: string; // (10)
  issuingCountryCode?: string; // (10)
  expirationDate?: string | number; // (10)
  issueDate?: string | number; // (10)
  personalNumber?: string; // (45)
  issueAuthority?: string; // (30)
  endorsements?: string; // (25)
  foreignVisitor?: ForeignVisitorId; //stripped down foreignVisitor object - usually will only contain the foreignVisitor id for mapping
}

export const PassportTypes = [
  'Regular',
  'Official',
  'Diplomatic',
  'Emergency',
  'Collective',
  'Family',
];
