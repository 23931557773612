import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
} from '@angular/forms';

@Directive({
  selector: '[minDateTime]',
  providers: [
    { provide: NG_VALIDATORS, multi: true, useExisting: MinDateDirective },
  ],
  standalone: true,
})
export class MinDateDirective implements Validator, OnChanges {
  @Input('minDateTime') minDate: Date | null;
  onValidatorChange = () => {};

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.minDate) {
      this.onValidatorChange();
    }
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    if (!control.value || !this.minDate) return null;
    const valid = control.value.valueOf() - this.minDate.valueOf() >= 0;

    return valid
      ? null
      : { minDate: { min: this.minDate, actal: control.value } };
  }
}
