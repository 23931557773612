import { CommonModule, Location } from '@angular/common';
import {
  Component,
  Directive,
  EventEmitter,
  HostListener,
  Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { PasswordRulesComponent } from '@shared/components/password-rules/password-rules.component';
import { AlertService } from '@shared/services/alert.service';
import { ConfirmMatchDirective } from '@shared/validators/confirm-match.validator';
import { ValidPasswordDirective } from '@shared/validators/password.validator';

@Directive({
  selector: '[appChangePassword]',
  standalone: true,
})
export class ChangePasswordDirective {
  @Output() appPasswordChanged = new EventEmitter<void>();

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private location: Location
  ) {
    this.route.fragment.subscribe((fragment: string | null) => {
      if (fragment === 'change-password') {
        this.onClick();
        this.location.replaceState(this.location.path(false));
      }
    });
  }

  @HostListener('click', ['$event'])
  onClick() {
    const dialog = ChangePasswordDialogComponent.openDialog(this.dialog);
    dialog.afterClosed().subscribe((result) => {
      if (result) return this.appPasswordChanged.emit();
    });
  }
}

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatSnackBarModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatProgressSpinnerModule,
    PasswordRulesComponent,
    ConfirmMatchDirective,
    ValidPasswordDirective,
  ],
})
export class ChangePasswordDialogComponent {
  busy: boolean = false;
  hide = {
    oldPassword: true,
    newPassword: true,
    confirmPassword: true,
  };

  oldPassword: string;
  newPassword: string;
  confirmPassword: string;

  constructor(
    public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    private auth: AuthService,
    private alert: AlertService
  ) {}

  async submit() {
    this.busy = true;
    try {
      await this.auth.changePassword(this.oldPassword, this.newPassword);
      this.alert.successAlert(`Password Change Successful!`);
      this.dialogRef.close(true);
    } catch (err) {
      if (err?.code == 'NotAuthorizedException') {
        err.message = 'Incorrect Password.';
      }
      this.busy = false;
      this.alert.errorAlert(err?.message ?? 'Password change failed');
    }
  }

  static openDialog(
    dialog: MatDialog
  ): MatDialogRef<ChangePasswordDialogComponent> {
    return dialog.open<ChangePasswordDialogComponent>(
      ChangePasswordDialogComponent,
      {
        width: '530px',
      }
    );
  }
}
