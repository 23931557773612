import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { BaseControlComponent } from '@shared/controls/base-control.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import {
  FAR,
  FarPurposeType,
  FarPurposeTypeTooltip,
} from '@shared/models/far.model';
import { ItineraryControlObservable } from '@shared/services/itinerary-control-observable.service';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@Component({
  selector: 'app-quick-far-control',
  templateUrl: './quick-far-control.component.html',
  styleUrls: ['./quick-far-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: QuickFarControlComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: QuickFarControlComponent,
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AuthEventsDialogComponent,
    AuthEventsDialogDirective,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatTooltipModule,
  ],
})
export class QuickFarControlComponent
  extends BaseControlComponent<FAR>
  implements Validator
{
  FarPurposeType = FarPurposeType;
  FarPurposeTypeTooltip = FarPurposeTypeTooltip;
  constructor(private itineraryControl: ItineraryControlObservable) {
    super();
  }
  validate(control: AbstractControl<FAR, any>): ValidationErrors | null {
    if (!control?.value?.eventTitle) return { eventTitleRequired: true };
    return null;
  }

  checkLocationRequirement() {
    if (
      this.value.purposeType === FarPurposeType.FACILITY_ACCESS ||
      this.value.purposeType === FarPurposeType.IN_PERSON_MEETING
    ) {
      this.itineraryControl.locationRequired();
    } else if (
      this.value.purposeType === FarPurposeType.OTHER ||
      this.value.purposeType === FarPurposeType.SYSTEM_ACCESS ||
      this.value.purposeType === FarPurposeType.VIRTUAL_MEETING
    ) {
      this.itineraryControl.locationOptional();
    }
  }
}
