import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { DeleteFvDirective } from '@app/foreign-visitors/delete-fv.directive';
import { AvatarComponent } from '@shared/components/avatar/avatar.component';
import { ResourceUserDetailsComponent } from '@shared/components/resource-user-details/resource-user-details.component';

import { ClassificationBannerComponent } from '@shared/cmt/classification-banner.component';
import { ClassifyShowDirective } from '@shared/cmt/classify-show.directive';
import { ClassifyDirective } from '@shared/cmt/classify.directive';
import { ScreeningUpdateDirective } from '@shared/components/screening/screening-dialog/screening-update.directive';
import { ScreeningHistoryDirective } from '@shared/components/screening/screening-history-dialog/screening-history.directive';
import { RolesDirective } from '@shared/directives/roles.directive';
import { IDialogData } from '@shared/models/dialog-data.model';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { Role } from '@shared/models/role';
import { AgePipe } from '@shared/pipes/age.pipe';
import { CountryPipe } from '@shared/pipes/country.pipe';
import { DaysUntilPipe } from '@shared/pipes/days-until.pipe';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { FullnamePipe } from '@shared/pipes/fullname.pipe';
import { MilitaryDatePipe } from '@shared/pipes/military-date.pipe';
import { ScreeningLabelPipe } from '@shared/pipes/screening-display.pipe';
import { SecureImagePipe } from '@shared/pipes/secure-image.pipe';
import { NgPipesModule } from 'ngx-pipes';
import { BiographicDialogComponent } from '../biographic-dialog/biographic-dialog.component';
import { ScreeningInsightCardComponent } from '../screening-insight-card/screening-insight-card.component';

import { Router } from '@angular/router';
import { ClassificationModel } from '@app/shared/models/classification.model';
import {
  FVEditRoles,
  FVRescreenRoles,
} from '@app/shared/models/role-permissions';
import { ConfirmDialogComponent } from '@shared/components/confirm/confirmdialog.component';
import { FilterParams } from '@shared/models/filter-params.model';
import { Group } from '@shared/models/group.model';
import { PageableCollection } from '@shared/models/pageable-collection.model';
import { AlertService } from '@shared/services/alert.service';
import { ForeignVisitorGroupService } from '@shared/services/foreign-visitor-group.service';
import { FvService } from '@shared/services/fv.service';
import { ScreeningService } from '@shared/services/screening.service';
import { Subject, takeUntil } from 'rxjs';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';

@Component({
  selector: 'fvview-info',
  templateUrl: 'fvinfo.component.html',
  styleUrls: ['fvinfo.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatBadgeModule,
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatTooltipModule,
    RouterLink,
    ClassificationBannerComponent,
    ClassifyDirective,
    ClassifyShowDirective,
    DaysUntilPipe,

    NgPipesModule,
    AvatarComponent,
    FullnamePipe,
    AgePipe,
    CountryPipe,
    DefaultPipe,
    MilitaryDatePipe,
    ScreeningHistoryDirective,
    ClassificationBannerComponent,
    CountryPipe,
    ResourceUserDetailsComponent,
    RolesDirective,
    DeleteFvDirective,
    ScreeningLabelPipe,
    ScreeningInsightCardComponent,
    ScreeningUpdateDirective,
    SecureImagePipe,
  ],
  providers: [SecureImagePipe],
})
export class FvInfoComponent implements OnInit, OnDestroy {
  @Input() fv: ForeignVisitor;
  FVRescreenRoles = FVRescreenRoles;
  Role = Role;
  FVEditRoles = FVEditRoles;
  private ngUnsubscribe = new Subject<void>();
  groups: PageableCollection<Group>;

  constructor(
    public dialog: MatDialog,
    public alert: AlertService,
    private fvService: FvService,
    private screeningService: ScreeningService,
    private groupService: ForeignVisitorGroupService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.groupService
      .find(
        new FilterParams({
          sortBy: 'name',
          direction: 'DESC',
          pageNum: 0,
          pageSize: 100,
          includeFvId: this.fv.id,
        })
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.groups = res;
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onEdit(): void {
    this.openDialog({ mode: 'update', model: this.fv });
  }

  openDialog(dialogData: IDialogData<ForeignVisitor>): void {
    const dialogConfig: MatDialogConfig = {
      data: dialogData,
      disableClose: true,
      width: '600px',
      autoFocus: '#submit-button',
    };
    this.dialog.open(BiographicDialogComponent, dialogConfig);
  }

  onClassify(model: ClassificationModel) {
    this.fvService
      .save(model as ForeignVisitor)
      .subscribe((fv: ForeignVisitor) => {
        this.alert.successAlert(
          `Updated Classification for ${fv.givenName} ${fv.surname}`
        );
      });
  }

  openConfirmationDialog(
    fv: ForeignVisitor
  ): MatDialogRef<ConfirmDialogComponent> {
    let data = {
      icon: 'alert-octagon',
      color: 'warn',
      title: `ARE YOU SURE?`,
      message: `This will create a new screening request for ${fv.givenName} ${fv.surname}.`,
      performAction: () => this.rescreen(fv),
      confirmText: 'Confirm',
    };

    return this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data,
    });
  }

  navigateToGroup(item: Group) {
    let filter: any = {};
    let destination = '../fvs';
    let queryField = 'foreignVisitorGroupId';
    let queryParam = item.id!;

    if (queryField) {
      filter[queryField] = [queryParam];
      this.router.navigate([destination], {
        queryParams: filter,
      });
    }
  }

  rescreen(fv: ForeignVisitor) {
    this.screeningService
      .rescreen({ id: fv.id })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe();
  }
}
