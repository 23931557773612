import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ActiveSearchDisplayComponent } from '@shared/components/advanced-search/active-search-display.component';
import { ColumnChooserComponent } from '@shared/components/column-chooser/column-chooser.component';
import { FilterComponent } from '@shared/components/filter/filter.component';
import { NoResultFoundComponent } from '@shared/components/no-result-found/no-result-found.component';
import { PaginatorComponent } from '@shared/components/paginator/paginator.component';
import { SearchToolbarComponent } from '@shared/components/search-toolbar/search-toolbar.component';
import { AdvancedSearchDirective } from '@shared/directives/advanced-search.directive';
import { Approval } from '@shared/models/approval';
import { FilterParams } from '@shared/models/filter-params.model';
import {
  ApprovalService,
  ApprovalSortField,
} from '@shared/services/approval.service';
import { CrudService } from '@shared/services/crud.service';
import { Repository } from '@shared/services/repository.service';
import { tap } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { ApprovalCollectionComponent } from './approval-collection/approval-collection.component';
import { ApprovalTableComponent } from './approval-table/approval-table.component';
import { AsyncPipe } from '@angular/common';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';

@Component({
  selector: 'app-approvals',
  templateUrl: './approvals.component.html',
  styleUrls: ['./approvals.component.scss'],
  providers: [
    Repository,
    { provide: CrudService, useExisting: ApprovalService },
  ],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatMenuModule,
    MatCheckboxModule,
    MatRadioModule,
    MatProgressBarModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    ApprovalsComponent,
    ApprovalCollectionComponent,
    ColumnChooserComponent,
    SearchToolbarComponent,
    ActiveSearchDisplayComponent,
    ApprovalTableComponent,
    NoResultFoundComponent,
    PaginatorComponent,
    FilterComponent,
    AdvancedSearchDirective,
    AsyncPipe,
    AuthEventsDialogDirective,
    AuthEventsDialogComponent,
  ],
})
export class ApprovalsComponent {
  loading: boolean = false;
  refresh$ = this.repo.refresh$.pipe(startWith({}));
  collection$ = this.refresh$.pipe(
    switchMap(() => {
      return this.repo.collection$;
    }),
    tap((_) => {
      this.loading = false;
    })
  );

  filterParams: FilterParams = new FilterParams({
    pageNum: 0,
    pageSize: 20,
    acctApprovalIsValid: true,
    sortBy: ApprovalSortField.EXPIRATION_DATE,
    direction: 'ASC',
  });
  constructor(private repo: Repository<Approval>) {
    const mergedFilterParams = Object.assign(
      this.filterParams,
      this.repo.getQueryParamsSnapshot()
    );
    this.onFilterParamChange(mergedFilterParams);
  }

  onFilterParamChange(filterParams: FilterParams) {
    this.loading = true;
    this.filterParams = filterParams;
    this.repo.navigateWithParams(filterParams);
  }
}
