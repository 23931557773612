import { Routes } from '@angular/router';
import { AuthGuard } from '@shared/helpers/auth.guard';
import { Roles } from '@shared/models/role';
import { ProfileComponent } from './profile/profile.component';

export const USER_PROFILE_ROUTES: Routes = [
  {
    path: 'profile',
    title: 'Profile',
    canActivate: [AuthGuard],
    component: ProfileComponent,
    data: {
      roles: Roles,
    },
  },
];
