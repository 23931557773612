import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { UserAcctComponent } from './user-account/useracct.component';
import { MatCardModule } from '@angular/material/card';
@Component({
  selector: 'app-profile',
  templateUrl: 'profile.component.html',
  styleUrls: ['profile.component.scss'],
  standalone: true,
  imports: [CommonModule, MatCardModule, UserAcctComponent],
})
export class ProfileComponent {}
