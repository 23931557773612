import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { EmptyStateComponent } from '@shared/components/empty-state/empty-state.component';
import { FvDetailsComponent } from '@shared/components/fv-details/fv-details.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { FlagIconDirective } from '@shared/directives/flag-icon.directive';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-fvview-contact',
  templateUrl: './fvview-contact.component.html',
  styleUrls: ['./fvview-contact.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FvDetailsComponent,
    EmptyStateComponent,
    AuthEventsDialogComponent,
    AuthEventsDialogDirective,
    MatDialogModule,
    FlagIconDirective,
    MatSnackBarModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatTooltipModule,
    MatMenuModule,
    MatExpansionModule,
    MatDividerModule,
  ],
})
export class FvviewContactComponent {
  @Input() deletable?: boolean = false;
  @Input() editable?: boolean = true;
  @Input() fv: ForeignVisitor;

  constructor(public dialog: MatDialog) {}

  openVehicleDialog() {
    const dialogConfig: MatDialogConfig = {
      data: cloneDeep(this.fv),
      width: '800px',
    };
    //this.dialog.open(VehicleIDDialogComponent, dialogConfig);
  }

  isEmpty() {
    if (
      !this.fv.driversLicenseId &&
      !this.fv.vehicleLicensePlate &&
      !this.fv.vehicleMake &&
      !this.fv.vehicleModel
    )
      return true;
    return false;
  }
}
