import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  EmailDialogComponent,
  EmailDialogData,
} from '@shared/components/email-dialog/email-dialog.component';
import { ContactEmail } from '@shared/models/contact-email.model';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { cloneDeep } from 'lodash';

@Directive({
  selector: '[appEmailDialog]',
  standalone: true,
})
export class EmailDialogDirective {
  @Input() appEmailDialog?: ContactEmail;
  @Input() fvId: string;
  @Output() fvEmailUpdated = new EventEmitter<ForeignVisitor>();
  constructor(private dialog: MatDialog) {}

  @HostListener('click', ['$event'])
  onClick() {
    if (!this.appEmailDialog) this.appEmailDialog = {};
    const dialogData: EmailDialogData = {
      email: cloneDeep(this.appEmailDialog),
      fvId: this.fvId,
    };
    const dialog = EmailDialogComponent.openDialog(this.dialog, dialogData);
    dialog.afterClosed().subscribe((result) => {
      if (result) return this.fvEmailUpdated.emit(result);
    });
  }
}
