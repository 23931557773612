import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Input,
  ViewChild,
  forwardRef,
} from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgForm,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { hasValues } from '@shared/helpers/has-values';
import { NationalId } from '@shared/models/national-id';
import { BaseControlComponent } from '../base-control.component';
import { CountrySelectComponent } from '../country-select/country-select.component';

@Component({
  selector: 'app-nationalId-control',
  templateUrl: './national-id-control.component.html',
  styleUrls: ['./national-id-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: NationalIdControlComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => NationalIdControlComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    CountrySelectComponent,
  ],
})
export class NationalIdControlComponent
  extends BaseControlComponent<NationalId>
  implements AfterViewInit, Validator
{
  @Input() nationalIdRequired: boolean = true;
  value: NationalId = {};
  onValidationChange: any = () => {};
  @ViewChild('nationalIdForm') nationalIdForm: NgForm;

  constructor() {
    super();
  }

  ngAfterViewInit() {
    this.nationalIdForm.valueChanges?.subscribe(() => {
      this.onValidationChange();
      this.emitChangeEvent();
    });
  }

  hasData(): boolean {
    return hasValues(this.value);
  }

  writeValue(_value: NationalId): void {
    super.writeValue(_value);
    this.value = _value || {};
  }

  validate(_control: AbstractControl<any, any>): ValidationErrors | null {
    return this.nationalIdForm?.valid ? null : { nationalId: true };
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }
}
