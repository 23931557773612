import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { Education } from '@shared/models/education.model';
import { EducationService } from '@shared/services/education.service';
import { take } from 'rxjs/operators';
import { FvEducationFormComponent } from './fv-education.component';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';

@Component({
  selector: 'app-fv-education-dialog',
  templateUrl: './fv-education-dialog.component.html',
  styleUrls: ['./fv-education.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDatepickerModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    FvEducationFormComponent,
  ],
})
export class FvEducationDialogComponent implements OnInit, AfterContentChecked {
  education: Education;
  @ViewChild('educationForm') educationForm: FvEducationFormComponent;
  fvId: string;
  busy: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<FvEducationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fvEducationService: EducationService,
    private changeDetect: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.education = this.data;
    this.fvId = this.data.foreignVisitor?.id;
  }

  ngAfterContentChecked(): void {
    this.changeDetect.detectChanges();
  }

  save() {
    this.busy = true;
    this.education = this.educationForm.education;
    this.fvEducationService
      .save(this.education)
      .pipe(take(1))
      .subscribe(
        (result: Education) => {
          this.onClose({
            update: result,
          });
        },
        (err) => (this.busy = false)
      );
  }

  /**
   *
   * @param data Object that is sent back when the dialog is closed.
   */
  onClose(data: any) {
    this.dialogRef.close(data);
  }
}
