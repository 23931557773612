<mat-toolbar>
  <mat-toolbar-row>
    <span class="flex-grow-1">{{ title }}</span>
    <span>
      <button
        class="ml-2"
        mat-raised-button
        *ngFor="let button of actionButtons"
        [appBulkUpload]="button.bulkUpload ? button.bulkUpload : undefined"
        routerLink="button.routerLink?.length ? [button.routerLink] : []"
      >
        <mat-icon *ngIf="button.menuIcon">{{ button.menuIcon }}</mat-icon>
        <span *ngIf="button.label"> {{ button.label }}</span>
      </button>
    </span>
  </mat-toolbar-row>
</mat-toolbar>
