import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ScreeningRollupDetail } from '@shared/models/screening-rollup-details.model';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { StatusMapperPipe } from '@shared/pipes/status-mapper.pipe';

@Component({
  selector: 'app-screening-fab-button',
  templateUrl: './screening-fab-button.component.html',
  styleUrls: ['./screening-fab-button.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatBadgeModule,
    MatButtonModule,
    MatIconModule,
    MatRippleModule,
    MatTooltipModule,
    DefaultPipe,
    StatusMapperPipe,
  ],
})
export class ScreeningFabButtonComponent {
  @Input() item?: ScreeningRollupDetail;
  @Input() customTooltip: string = '';
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
}
