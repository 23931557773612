import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { StatusPipe } from '@app/system-alerts/status.pipe';
import { ScreeningResult } from '@shared/enums/screening-result.enum';
import { ForeignVisitorScreeningRollup } from '@shared/models/foreignVisitorScreeningRollup';
import {
  Screening,
  ScreeningStatusLabels,
} from '@shared/models/screening.model';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import {
  ScreeningLabelPipe,
  ScreeningIconPipe,
  ScreeningClassPipe,
} from '@shared/pipes/screening-display.pipe';
import { StatusMapperPipe } from '@shared/pipes/status-mapper.pipe';
import { NgPipesModule } from 'ngx-pipes';

@Component({
  selector: 'app-screening-indicator',
  templateUrl: './screening-indicator.component.html',
  styleUrls: ['./screening-indicator.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatButtonModule,
    MatDialogModule,
    MatMenuModule,
    MatTooltipModule,
    NgPipesModule,
    DefaultPipe,
    ScreeningLabelPipe,
    ScreeningIconPipe,
    StatusMapperPipe,
    ScreeningLabelPipe,
    ScreeningClassPipe,
  ],
})
export class ScreeningIndicatorComponent {
  ScreeningStatusLabels = ScreeningStatusLabels;

  @Input() rollup?: ForeignVisitorScreeningRollup | null = null;
  @Input() screening?: Screening | null = null;
  @Input() display: 'rollup' | 'status' | 'result';
  @Input() showStatusIcon: boolean = true;
  @Input() hasMenu: boolean = true;
  @Input() showTooltip: boolean = true;
  @Input() showResultText: boolean = true;
  @Input() showStatusText: boolean = true;
  @Input() iconSize: 'normal' | 'large' = 'normal';

  getResult(result: ScreeningResult | undefined) {
    if (result === 'MACHINE_GREEN' || result === 'REVIEWED_GREEN') {
      return 'GREEN';
    }

    if (result === 'MACHINE_RED') {
      return 'MACHINE RED';
    }

    if (result === 'REVIEWED_RED_WITH_DEROG') {
      return 'RED';
    }

    if (result === 'REVIEWED_RESTRICTED') {
      return 'USP';
    }
    return result;
  }
}
