import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { LookupService } from '@shared/services/lookup.service';
import { map } from 'rxjs/operators';
import { LookupData } from '../models/lookupdata';

@Pipe({
  name: 'country',
  pure: false,
  standalone: true,
})
export class CountryPipe implements PipeTransform, OnDestroy {
  private asyncPipe: AsyncPipe;

  constructor(
    private cdr: ChangeDetectorRef,
    private lookupService: LookupService
  ) {
    this.asyncPipe = new AsyncPipe(this.cdr);
  }

  transform(
    value?: string,
    format: 'name' | 'alpha2' | 'alpha3' | 'nationality' = 'name'
  ): string | null {
    let lookup = (value || '').toUpperCase();
    let countryNameObs$ = this.lookupService.getCountries().pipe(
      map((data) => {
        let country = data.find(
          (c) =>
            c.lookup_cd === lookup ||
            c.lookup_short_name === lookup ||
            c.lookup_data === lookup
        ) as LookupData;

        if (lookup === 'EUR') {
          country = {
            lookup_type: 'country',
            lookup_short_name: 'EU',
            lookup_data: 'European Union',
            lookup_alt_name: 'European Union',
            lookup_cd: 'EUR',
          };
        } else if (lookup === 'UNN') {
          country = {
            lookup_type: 'country',
            lookup_short_name: 'UN',
            lookup_data: 'United Nations',
            lookup_alt_name: 'United Nations',
            lookup_cd: 'UNN',
          };
        } else if (lookup === 'ATO') {
          lookup = 'NATO';
        }

        if (!country) return lookup;

        switch (format) {
          case 'alpha2':
            return country.lookup_short_name;
          case 'alpha3':
            return country.lookup_cd;
          case 'nationality':
            return country.lookup_alt_name;
          default:
            return country.lookup_data;
        }
      })
    );

    return this.asyncPipe.transform(countryNameObs$) as string | null;
  }

  ngOnDestroy() {
    this.asyncPipe.ngOnDestroy();
  }
}
