import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Input,
  ViewChild,
  forwardRef,
} from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgForm,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ScreeningCodeDirective } from '@shared/directives/screening-code.directive';
import { hasValues } from '@shared/helpers/has-values';
import { ContactEmail, EmailType } from '@shared/models/contact-email.model';
import { NgPipesModule } from 'ngx-pipes';
import { BaseControlComponent } from '../base-control.component';

@Component({
  selector: 'app-email-control',
  templateUrl: './email-control.component.html',
  styleUrls: ['./email-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: EmailControlComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EmailControlComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    CommonModule,
    NgPipesModule,
    FormsModule,
    MatIconModule,
    MatSelectModule,
    MatTooltipModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ScreeningCodeDirective,
  ],
})
export class EmailControlComponent
  extends BaseControlComponent<ContactEmail>
  implements AfterViewInit, Validator
{
  @Input() addressRequired: boolean = true;
  value: ContactEmail = {};
  onValidationChange: any = () => {};
  emailTypes = EmailType;
  @ViewChild('emailForm') emailForm: NgForm;

  constructor() {
    super();
  }

  ngAfterViewInit() {
    this.emailForm.valueChanges?.subscribe(() => {
      this.onValidationChange();
      this.emitChangeEvent();
    });
  }

  hasData(): boolean {
    return hasValues(this.value);
  }

  writeValue(_value: ContactEmail): void {
    super.writeValue(_value);
    this.value = _value || {};
  }

  validate(_control: AbstractControl<any, any>): ValidationErrors | null {
    if (this.addressRequired || this.hasData()) {
      return this.emailForm?.valid ? null : { email: true };
    } else {
      return null;
    }
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }
}
