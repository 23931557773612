import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import * as i18nIsoCountries from 'i18n-iso-countries';

@NgModule({
  declarations: [],
  imports: [CommonModule, MatIconModule],
  exports: [MatIconModule],
})
export class CitadelIconModule {
  countries = 'us';
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    // registers the citadel logo svg files to use with material icons
    // <mat-icon [svgIcon]="logo | vertical-logo | horizontal-logo"
    const LOGO_ICONS = ['vertical-logo', 'horizontal-logo', 'logo'];
    LOGO_ICONS.forEach((logo) => {
      matIconRegistry.addSvgIcon(
        logo.toLocaleLowerCase(),
        domSanitizer.bypassSecurityTrustResourceUrl(
          `./assets/icons/CITADEL/CITADEL.${logo}.svg`
        )
      );
    });

    matIconRegistry.registerFontClassAlias('flag', 'fi');
    matIconRegistry.addSvgIconSet(
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg')
    );
    i18nIsoCountries.registerLocale(
      require('i18n-iso-countries/langs/en.json')
    );

    Object.keys(i18nIsoCountries.getAlpha2Codes())
      .concat(['xx'])
      .forEach((code) => {
        matIconRegistry.addSvgIcon(
          `flag-${code.toLocaleUpperCase()}`,
          domSanitizer.bypassSecurityTrustResourceUrl(
            `./assets/flags/4x3/${code.toLocaleLowerCase()}.svg`
          )
        );
        matIconRegistry.addSvgIcon(
          `flag-${code.toLocaleLowerCase()}`,
          domSanitizer.bypassSecurityTrustResourceUrl(
            `./assets/flags/4x3/${code.toLocaleLowerCase()}.svg`
          )
        );
        matIconRegistry.addSvgIcon(
          `flag-sq-${code.toLocaleUpperCase()}`,
          domSanitizer.bypassSecurityTrustResourceUrl(
            `./assets/flags/1x1/${code.toLocaleLowerCase()}.svg`
          )
        );
        matIconRegistry.addSvgIcon(
          `flag-sq-${code.toLocaleLowerCase()}`,
          domSanitizer.bypassSecurityTrustResourceUrl(
            `./assets/flags/1x1/${code.toLocaleLowerCase()}.svg`
          )
        );
      });
  }
}
