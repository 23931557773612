import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Crud } from '@shared/models/crud.decoration';
import { AttachmentService } from './attachment.service';
import { HttpClient } from '@angular/common/http';
import { AttachmentData } from '@shared/models/attachment-data.model';
import { Observable, tap } from 'rxjs';
import { CrudChangeType } from '@shared/models/crud-change-type.enum';

@Injectable({ providedIn: 'root' })
@Crud({ apiUrl: `${environment.apiUrl}/attachment/fvs` })
export class FvAttachmentService extends AttachmentService {
  constructor(http: HttpClient) {
    super(http);
  }

  public addProfilePic(model: AttachmentData): Observable<AttachmentData> {
    return this.http
      .post<AttachmentData>(`${this.apiUrl}/profile_image`, model)
      .pipe(
        tap((m) =>
          this.changeEvent$.emit({
            type: CrudChangeType.create,
            model: m,
            modelId: m.id,
          })
        )
      );
  }
}
