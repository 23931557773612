import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule, Sort } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule, TooltipPosition } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { FilterParams } from '@app/shared/models';
import {
  FarViewRoles,
  LocViewGeoCodeRoles,
} from '@app/shared/models/role-permissions';
import {
  LOCATION_COLUMNS,
  LocationColumnDefs,
  LOCATIONS_TAB,
} from '@app/user-preference/config/location.config.const';
import { UserPrefSaved } from '@app/user-preference/model/config-view-definitions.model';
import { UserPreferenceFormService } from '@app/user-preference/service/user-preference-form.service';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { ConfirmDialogComponent } from '@shared/components/confirm/confirmdialog.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { LocationEditDirective } from '@shared/directives/location-edit.directive';
import { RolesDirective } from '@shared/directives/roles.directive';
import { OrgLocation } from '@shared/models/orglocation.model';
import { AddressPipe } from '@shared/pipes/address.pipe';
import { CountryPipe } from '@shared/pipes/country.pipe';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { AlertService } from '@shared/services/alert.service';
import { AppConfigService } from '@shared/services/app-config.services';
import { OrgLocationService } from '@shared/services/orglocation.service';
import { Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-location-collection-table',
  templateUrl: './location-collection-table.component.html',
  styleUrls: ['./location-collection-table.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    MatIconModule,
    MatMenuModule,
    MatSnackBarModule,
    MatButtonModule,
    AuthEventsDialogComponent,
    AuthEventsDialogDirective,
    DefaultPipe,
    CountryPipe,
    LocationEditDirective,
    AddressPipe,
    RolesDirective,
  ],
})
export class LocationCollectionTableComponent implements OnInit, OnDestroy {
  @Input() locations: OrgLocation[] = [];
  @Input() filterParams: FilterParams;
  @Output() filterParamsChange = new EventEmitter<FilterParams>();
  position: TooltipPosition = 'right';
  deletionMessageCheckEnabled?: boolean = true;
  canViewFAR: boolean = false;
  user = inject(AuthService);
  private ngUnsubscribe = new Subject<void>();

  LocViewGeoCodeRoles = LocViewGeoCodeRoles;

  savedPrefs: UserPrefSaved;
  userPrefFormGroup$: Subscription;
  LOCATION_COLUMNS: LocationColumnDefs = LOCATION_COLUMNS;
  dataColumns: string[] = inject(UserPreferenceFormService).generateDataColumns(
    LOCATIONS_TAB
  );

  constructor(
    public dialog: MatDialog,
    public locationService: OrgLocationService,
    public alert: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private config: AppConfigService
  ) {
    let lowToggle = this.config.get('lowEnvFeatures', true);
    this.deletionMessageCheckEnabled = lowToggle;
  }

  ngOnInit(): void {
    this.savedPrefs = this.route.snapshot.data.userPrefFormGroup?.value;
    const formGroup: FormGroup = this.route.snapshot.data
      .userPrefFormGroup as FormGroup;
    this.userPrefFormGroup$ = formGroup.valueChanges.subscribe(
      (savedPrefs: UserPrefSaved) => {
        this.savedPrefs = savedPrefs;
      }
    );

    const userRoles = this.user.getRoles();
    let roleFound = userRoles.find((r) => FarViewRoles.includes(r)) || false;
    if (roleFound) this.canViewFAR = true;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.userPrefFormGroup$?.unsubscribe();
  }

  onEdited(result: OrgLocation) {
    const location = this.locations.find((l) => l.id === result.id);

    if (location) {
      Object.assign(location, result);
    }
  }

  onSortChange(sort: Sort) {
    this.filterParams.sortBy = sort.active;
    this.filterParams.direction = sort.direction.toUpperCase();
    this.filterParamsChange.emit(this.filterParams);
  }

  deleteLocation(id?: string) {
    if (this.deletionMessageCheckEnabled) {
      let data = {
        icon: 'alert-octagon',
        color: 'warn',
        title: `STOP`,
        message: `Before confirming the deletion of the record, make sure this record still exists on the high side.  If the record does not exist on the high side, cancel this action.  Deletion of this record without it existing on the high side will cause replication to fail.`,
        performAction: () => this.confirmLocationDelete(id),
        confirmText: 'Confirm',
      };

      return this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '600px',
        data,
      });
    } else {
      this.confirmLocationDelete(id);
    }
  }

  confirmLocationDelete(id?: string) {
    this.alert
      .confirmDelete({
        title: `Confirm Delete Location`,
        message: `Are you sure you want to delete this location?`,
        performAction: () => this.locationService.delete(id),
      })
      .pipe(take(1))
      .subscribe(() => {
        this.filterParamsChange.emit(this.filterParams);
      });
  }

  navigateToFARs(
    count: any,
    destination: string,
    queryField?: string,
    queryParam?: string | boolean
  ) {
    if (count) {
      let filter: any = {};

      if (this.router.url === '/') destination = 'fars';

      if (queryField) {
        filter[queryField] = [queryParam];
        this.router.navigate([destination], {
          relativeTo: this.route,
          queryParams: filter,
        });
      } else {
        this.router.navigate([destination], {
          relativeTo: this.route,
        });
      }
    }
  }
}
