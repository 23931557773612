import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgForm,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MatFormFieldAppearance,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CitadelIconModule } from '@app/citadel-icon/citadel-icon.module';
import { DocumentId } from '@app/shared/models/document-id.model';
import { BaseControlComponent } from '@shared/controls/base-control.component';
import { hasValues } from '@shared/helpers/has-values';

@Component({
  selector: 'app-document-id',
  templateUrl: './document-id.component.html',
  styleUrls: ['./document-id.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    CitadelIconModule,
    MatDatepickerModule,
    MatTooltipModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DocumentIdComponent,
      multi: true,
    },
  ],
})
export class DocumentIdComponent
  extends BaseControlComponent<DocumentId>
  implements OnInit
{
  @Input() editable: boolean = true;
  @Input() deletable?: boolean = false;
  isDiplomaticPassport: boolean = false;
  @Input() showClearButton: boolean = true;
  @Input() minIssueDate?: string;
  @ViewChild('documentForm')
  documentForm: NgForm;
  appearance: MatFormFieldAppearance = 'outline';
  today = new Date();
  value: DocumentId = {};
  @Input() parentId: string;
  @Input() type: 'DIPLOMAT' | 'DACA' = 'DIPLOMAT';
  typeLabel = DocumentTypeLabel;

  constructor() {
    super();
  }

  addDocumentData() {
    this.emitChangeEvent();
  }

  checkDocumentData() {
    // Setting isPassport for diplomatic passport component
    this.isDiplomaticPassport = this.hasData() ? false : true;
    this.emitChangeEvent();
  }

  getEmitValue(): DocumentId | null {
    return this.hasData() ? this.value : null;
  }

  hasData() {
    return hasValues(this.value);
  }

  ngOnInit() {
    this.value = {};
  }

  writeValue(_value: DocumentId): void {
    super.writeValue(_value);
    this.value = this.value || {};
  }
}

export enum DocumentTypeLabel {
  DIPLOMAT = 'Diplomatic',
  DACA = 'DACA',
}
