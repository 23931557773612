import {
  ChangeDetectorRef,
  Directive,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { AuthService } from '@app/auth/auth.service';
import { Role } from '@shared/models/role';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appIfRoles]',
  standalone: true,
})
export class RolesDirective implements OnInit, OnDestroy, OnChanges {
  @Input() appIfRoles: Role | Role[] = [];

  @Input() appIfRolesThen: TemplateRef<any>;
  @Input() appIfRolesElse: TemplateRef<any>;

  userRoles: Role[] = [];

  userSubscription: Subscription = Subscription.EMPTY;

  constructor(
    private authService: AuthService,
    private viewContainer: ViewContainerRef,
    private changeDetector: ChangeDetectorRef,
    private templateRef: TemplateRef<any>
  ) {}

  ngOnInit(): void {
    this.viewContainer.clear();
    this.userSubscription = this.authService.user$.subscribe((user) => {
      this.userRoles = user ? user.roles || [] : [];
      this.validatePermission();
    });
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.appIfRoles) {
      this.validatePermission();
    }
  }

  validatePermission() {
    if (!this.userRoles.length) return;

    if (this.hasPermission()) this.handleAuthorized();
    else this.handleUnauthorized();
  }

  hasPermission(): boolean {
    const roles = !this.appIfRoles
      ? []
      : this.appIfRoles instanceof Array
      ? this.appIfRoles
      : [this.appIfRoles];
    return !roles.length || !!this.userRoles.find((r) => roles.includes(r));
  }

  handleAuthorized() {
    this.showTemplateBlockInView(this.appIfRolesThen || this.templateRef);
  }

  handleUnauthorized() {
    this.showTemplateBlockInView(this.appIfRolesElse);
  }

  private showTemplateBlockInView(template?: TemplateRef<any>): void {
    this.viewContainer.clear();
    if (!template) {
      return;
    }

    this.viewContainer.createEmbeddedView(template);
    this.changeDetector.markForCheck();
  }
}
