import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CognitoAuthLoginComponent } from '@app/auth/cognito-auth/cognito-auth-login/cognito-auth-login.component';
import { AuthGuard, IsNotAuthenticated } from '@shared/helpers/auth.guard';
import { LoginDashboardComponent } from '../login-dashboard/login-dashboard.component';

export const COGNITO_AUTH_ROUTES: Routes = [
  {
    path: 'login',
    title: 'Login',
    component: LoginDashboardComponent,
    canDeactivate: [AuthGuard],
    canActivate: [IsNotAuthenticated],
    children: [{ path: '', component: CognitoAuthLoginComponent }],
  },
];

@NgModule({
  imports: [RouterModule.forChild(COGNITO_AUTH_ROUTES)],
  exports: [RouterModule],
})
export class CognitoAuthRoutingModule {}
