import { Directive, NgZone, OnInit } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle, IdleExpiry } from '@ng-idle/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '@app/auth/auth.service';
import { AppConfigService } from './shared/services/app-config.services';
import { Subscription } from 'rxjs';
import { TimeoutDialogComponent } from '@shared/components/timeoutdialog/timeoutdialog.component';

@Directive({
  selector: '[appAutoLogout]',
  standalone: true,
})
export class AutoLogoutDirective implements OnInit {
  private dialogRef: MatDialogRef<TimeoutDialogComponent>;
  private dialogSub = Subscription.EMPTY;

  private DEFAULT_AUTO_LOGOUT_IDLE_TIME: number = 15 * 60; //seconds - Set to 15 minutes
  private DEFAULT_AUTO_LOGOUT_TIMEOUT: number = 30; //seconds

  private AUTO_LOGOUT_IDLE_TIME: number;
  private AUTO_LOGOUT_TIMEOUT: number;

  constructor(
    private expiry: IdleExpiry,
    private idle: Idle,
    private dialog: MatDialog,
    private auth: AuthService,
    private config: AppConfigService
  ) {
    this.AUTO_LOGOUT_IDLE_TIME = this.config.get(
      'autoLogoutIdleTime',
      this.DEFAULT_AUTO_LOGOUT_IDLE_TIME
    ) as number;

    this.AUTO_LOGOUT_TIMEOUT = this.config.get(
      'autoLogoutIdleTimeout',
      this.DEFAULT_AUTO_LOGOUT_TIMEOUT
    ) as number;

    // sets an idle time in seconds
    this.idle.setIdle(this.AUTO_LOGOUT_IDLE_TIME);

    // disable ng-idle idle timeout
    this.idle.setTimeout(false);

    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleStart.subscribe(() => {
      this.onIdleStart();
    });
  }

  ngOnInit(): void {
    if (this.config.get('disableAutoLogout', false)) return;

    //only watch if user is Authenticated
    this.auth.authenticated$?.subscribe((authenticated) => {
      if (authenticated) {
        this.reset();
      } else {
        this.idle.stop();
      }
    });
  }

  onIdleStart() {
    if (this.isExpired()) {
      this.logout();
      return;
    }
    this.openDialog();
  }

  isExpired(): boolean {
    const now: Date = this.expiry.now();
    const last: Date = this.expiry.last() || now;
    const diff = now.getTime() - last.getTime();
    const isExpired = diff > this.AUTO_LOGOUT_TIMEOUT * 1000;
    return isExpired;
  }

  openDialog() {
    if (this.dialogRef?.componentInstance) return;

    const seconds = this.AUTO_LOGOUT_TIMEOUT;
    this.dialogRef = TimeoutDialogComponent.openDialog(this.dialog, {
      timeout: seconds,
    });

    this.dialogSub = this.dialogRef.afterClosed().subscribe((result) => {
      if (result) this.logout();
      if (!result) this.reset();
    });
  }

  reset() {
    this.idle.watch();
    this.dialogSub.unsubscribe();
  }

  logout() {
    this.dialog.closeAll();
    this.idle.stop();
    this.auth.logout();
  }
}
