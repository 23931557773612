import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { AdditionalInfo } from '@shared/models/additional-info.model';
import { Crud } from '@shared/models/crud.decoration';
import { CrudService } from '@shared/services/crud.service';

@Injectable({ providedIn: 'root' })
@Crud({ apiUrl: `${environment.apiUrl}/otherNames`, hasIdPathUpdate: true })
export class OtherNamesService extends CrudService<AdditionalInfo> {
  constructor(http: HttpClient) {
    super(http);
  }
}
