import { Component, Inject, ViewChild } from '@angular/core';
import { FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { AddressComponent } from '@shared/controls/address/address.component';
import { Address } from '@shared/models/address.model';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { AddressService } from '@shared/services/address.service';
import { DialogCloseMessage } from '@shared/shared.types';
import { cloneDeep } from 'lodash';
import { AuthEventsDialogComponent } from '../auth-events-dialog/auth-events-dialog.component';

@Component({
  selector: 'app-address-dialog',
  templateUrl: './address-dialog.component.html',
  styleUrls: ['./address-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    AuthEventsDialogComponent,
    AuthEventsDialogComponent,
    AddressComponent,
  ],
})
export class AddressDialogComponent {
  @ViewChild('addressForm') addressForm: NgForm;
  busy: boolean = false;
  fv: ForeignVisitor;
  constructor(
    public addressDialog: MatDialogRef<AddressDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public addressService: AddressService
  ) {}

  static openDialog(
    dialog: MatDialog,
    item: ForeignVisitor
  ): MatDialogRef<AddressDialogComponent> {
    return dialog.open<AddressDialogComponent>(AddressDialogComponent, {
      data: cloneDeep(item),
      width: '800px',
    });
  }

  save(address: Address) {
    this.busy = true;
    address.fvId = this?.data?.id;
    this.addressService.save(address).subscribe(
      (result: Address) => {
        this.onClose({
          update: result,
          message: `Updated Address Information!`,
        });
      },
      (error) => {
        this.busy = false;
      }
    );
  }

  onClose(message: DialogCloseMessage<ForeignVisitor>) {
    this.addressDialog.close(message);
  }
}
