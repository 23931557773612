import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import {
  AttachmentData,
  AttachmentStatus,
} from '@shared/models/attachment-data.model';
import { CrudChangeType } from '@shared/models/crud-change-type.enum';
import { Crud } from '@shared/models/crud.decoration';
import { FilterParams } from '@shared/models/filter-params.model';
import { CrudService } from '@shared/services/crud.service';
import { intersection, uniq } from 'lodash';
import { interval, Observable, ObservableInput, of, Subscription } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

export const S3_HEADER_SSE = 'x-amz-server-side-encryption';
export const S3_HEADER_SSE_ID = 'x-amz-server-side-encryption-aws-kms-key-id';

export class AttachmentService extends CrudService<AttachmentData> {
  fileUpdateSubscription = Subscription.EMPTY;

  statusChecks: (string | undefined)[];

  constructor(http: HttpClient) {
    super(http);

    this.fileUpdateSubscription = interval(10000)
      .pipe(switchMap(() => this.checkFileStatuses() as ObservableInput<any>))
      .subscribe((cleanAttachments) => {
        cleanAttachments?.forEach((a: AttachmentData) => {
          this.changeEvent$.emit({
            type: CrudChangeType.update,
            model: a,
            modelId: a.id,
          });
          this.statusChecks = this.statusChecks?.filter((id) => id != a.id);
        });
      });
  }

  watchStatus(...id: string[]) {
    this.statusChecks?.push(...id);
    this.statusChecks = uniq(this.statusChecks);
  }

  checkFileStatuses(): Observable<AttachmentData[]> | undefined {
    if (!this.statusChecks?.length) return of([]);

    const params = new FilterParams({
      id: this.statusChecks,
      pageSize: this.statusChecks.length,
    });

    return this.statusChecks
      ? this.find(params).pipe(
          map((data) => {
            this.statusChecks = intersection(
              this.statusChecks,
              data.content.map((a) => a.id)
            );
            return data.content.filter(
              (a) => a.status !== AttachmentStatus.SCAN_PENDING
            );
          })
        )
      : undefined;
  }
}
