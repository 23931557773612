import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Group } from '@shared/models/group.model';
import { GroupDialogComponent } from '../../groups/collection/group-dialog/group-dialog.component';

@Directive({
  selector: '[appGroupEdit]',
  standalone: true,
})
export class GroupEditDirective {
  @Input() appGroupEdit: Group | undefined;
  @Output() appGroupDialogOpen = new EventEmitter<
    MatDialogRef<GroupDialogComponent, Group>
  >();

  @Output() appGroupEdited = new EventEmitter<Group>();

  constructor(private dialog: MatDialog) {}

  @HostListener('click', ['$event'])
  onClick(_e: Event) {
    const dialog = GroupDialogComponent.openDialog(
      this.dialog,
      this.appGroupEdit
    );
    dialog.afterClosed().subscribe((result: Group) => {
      if (result) {
        this.appGroupEdited.emit(result);
      }
    });

    this.appGroupDialogOpen.emit(dialog);
  }
}
