import { Directive, HostListener, Input, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from '@shared/components/confirm/confirmdialog.component';
import { FAR } from '@shared/models/far.model';
import { AlertService } from '@shared/services/alert.service';
import { AppConfigService } from '@shared/services/app-config.services';
import { FarService } from '@shared/services/far.service';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { FAR_ROUTES } from './foreign-access-request-routing.module';

@Directive({
  selector: '[appDeleteFar]',
  standalone: true,
})
export class DeleteFarDirective implements OnDestroy {
  /** Emits when the component is destroyed. */
  private readonly _destroyed = new Subject<void>();
  @Input() appDeleteFar: FAR;
  @Input() farDeleteArchive = false;
  @Input() farDeletedRoute: any[];

  deletionMessageCheckEnabled?: boolean = true;

  constructor(
    private farService: FarService,
    private alert: AlertService,
    private router: Router,
    private dialog: MatDialog,
    private config: AppConfigService
  ) {
    let lowToggle = this.config.get('lowEnvFeatures', true);
    this.deletionMessageCheckEnabled = lowToggle;
  }

  @HostListener('click', ['$event'])
  onClick() {
    if (!this.appDeleteFar) return;
    if (this.deletionMessageCheckEnabled) this.openConfirmationDialog();
    else this.confirmDelete(this.appDeleteFar.id, this.appDeleteFar.eventTitle);
  }

  confirmDelete(farId: string, refTitle?: string) {
    this.alert
      .confirmDelete({
        title: `Confirm Delete ${refTitle}`,
        message: `Are you sure you want to delete ${refTitle}?  This action cannot be undone.`,
        performAction: () =>
          this.farService.delete(farId, this.farDeleteArchive).pipe(
            map((deletedFar: FAR) => {
              this.farDeletedRoute = [FAR_ROUTES[0].path];
              return deletedFar;
            })
          ),
        successMsg: `Foreign Access Request ${refTitle} deleted`,
      })
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => {
        if (this.farDeletedRoute) this.router.navigate(this.farDeletedRoute);
      });
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  openConfirmationDialog(): MatDialogRef<ConfirmDialogComponent> {
    let data = {
      icon: 'alert-octagon',
      color: 'warn',
      title: `STOP`,
      message: `Before confirming the deletion of the record, make sure this record still exists on the high side.  If the record does not exist on the high side, cancel this action.  Deletion of this record without it existing on the high side will cause replication to fail.`,
      performAction: () =>
        this.confirmDelete(this.appDeleteFar.id, this.appDeleteFar.eventTitle),
      confirmText: 'Confirm',
    };

    return this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data,
    });
  }
}
