import { AsyncPipe, CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthService } from '@app/auth/auth.service';
import { CitadelIconModule } from '@app/citadel-icon/citadel-icon.module';
import { BaseMatSelectComponent } from '@shared/controls/base-mat-select.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { HighlightDirective } from '@shared/directives/highlight.directive';
import { LocationEditDirective } from '@shared/directives/location-edit.directive';
import { StopPropagationDirective } from '@shared/directives/stop-propagation.directive';
import { FilterParams } from '@shared/models/filter-params.model';
import { OrgLocation } from '@shared/models/orglocation.model';
import { PageableCollection } from '@shared/models/pageable-collection.model';
import { AddressPipe } from '@shared/pipes/address.pipe';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { OrgLocationService } from '@shared/services/orglocation.service';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { Observable, take } from 'rxjs';
import { AuthEventsDialogComponent } from '../auth-events-dialog/auth-events-dialog.component';

@Component({
  selector: 'app-org-location-select',
  standalone: true,
  imports: [
    CommonModule,
    AsyncPipe,
    MatToolbarModule,
    NgxMatSelectSearchModule,
    CitadelIconModule,
    MatTooltipModule,
    MatButtonModule,
    MatSnackBarModule,
    MatDialogModule,
    FormsModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatListModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    AuthEventsDialogComponent,
    AuthEventsDialogDirective,
    HighlightDirective,
    DefaultPipe,
    AddressPipe,
    StopPropagationDirective,
    LocationEditDirective,
  ],
  templateUrl: './org-location-select.component.html',
  styleUrls: ['./org-location-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrgLocationSelectComponent
  extends BaseMatSelectComponent<OrgLocation>
  implements OnDestroy, OnInit
{
  constructor(
    public injector: Injector,
    private auth: AuthService,
    private locationService: OrgLocationService
  ) {
    super(injector);
  }

  @Input() errorMessage: string = '';
  @Input() organizationId: string = '';
  @Input() multiple: boolean = false;
  @Input()
  set locationId(_locationId: string) {
    this.loadLocationId(_locationId);
  }

  get locationId(): string {
    return this.value?.id!;
  }

  @Input() showCreate: boolean = true;
  @Output() locationIdChange: EventEmitter<string> = new EventEmitter<string>();

  baseFilterParams: FilterParams = new FilterParams({
    currentNetworkDomain: true,
    sort: {
      active: 'name',
      direction: 'asc',
    },
  });

  ngOnInit(): void {
    if (this.baseFilterParams && this.organizationId)
      this.baseFilterParams.organizationId = this.organizationId;
    this.search();
    let authOrg = this.auth.getOrganization();
    if (this.organizationId && authOrg && authOrg.id !== this.organizationId)
      this.showCreate = false;
  }

  searchItems(
    params?: FilterParams
  ): Observable<PageableCollection<OrgLocation>> {
    return this.locationService.find(params);
  }

  loadLocationId(locId: string) {
    if ((!locId && !this.value) || locId === this.value?.id) return;

    if (!locId) {
      // @ts-ignore
      this.value = null;
      super.emitChangeEvent();
      return;
    }

    this.value = { id: locId };
    this.locationService
      .get(locId)
      .pipe(take(1))
      .subscribe((data) => {
        this.value = data;
        super.emitChangeEvent();
      });
  }

  emitChangeEvent() {
    super.emitChangeEvent();
    this.locationIdChange.emit(this.locationId);
  }

  onCreateLocation(location: OrgLocation) {
    this.value = location;
    this.searchCtrl.setValue(location.name);
    this.matSelect.selectionChange.emit();
  }

  trackBy(idx: number, location: OrgLocation): string {
    return location?.id;
  }
}
