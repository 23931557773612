import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule, Sort } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule, TooltipPosition } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { AddFvsDialogComponent } from '@app/foreign-access-requests/farview/far-view-associated-fvs/add-fvs-dialog/add-fvs-dialog.component';
import {
  GROUP_COLUMNS,
  GROUP_TAB,
  GroupColumnDefs,
} from '@app/user-preference/config/group.config.const';
import { UserPrefSaved } from '@app/user-preference/model/config-view-definitions.model';
import { UserPreferenceFormService } from '@app/user-preference/service/user-preference-form.service';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { ConfirmDialogComponent } from '@shared/components/confirm/confirmdialog.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { GroupEditDirective } from '@shared/directives/group-edit.directive';
import { RolesDirective } from '@shared/directives/roles.directive';
import { FilterParams } from '@shared/models/filter-params.model';
import { Group } from '@shared/models/group.model';
import { FVRescreenRoles, FVViewRoles } from '@shared/models/role-permissions';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { AlertService } from '@shared/services/alert.service';
import { GroupService } from '@shared/services/groups.service';
import { ScreeningService } from '@shared/services/screening.service';
import { Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-group-collection-table',
  templateUrl: './group-collection-table.component.html',
  styleUrls: ['./group-collection-table.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatSortModule,
    MatTableModule,
    MatIconModule,
    MatMenuModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatButtonModule,
    MatSortModule,
    AuthEventsDialogComponent,
    AuthEventsDialogDirective,
    GroupEditDirective,
    DefaultPipe,
    RolesDirective,
  ],
})
export class GroupCollectionTableComponent implements OnInit, OnDestroy {
  @Input() groups: Group[] = [];
  @Input() filterParams: FilterParams;
  @Output() filterParamsChange = new EventEmitter<FilterParams>();
  position: TooltipPosition = 'right';
  canViewFN: boolean = false;
  user = inject(AuthService);

  private ngUnsubscribe = new Subject<void>();

  FVRescreenRoles = FVRescreenRoles;

  savedPrefs: UserPrefSaved;
  userPrefFormGroup$: Subscription;
  GROUP_COLUMNS: GroupColumnDefs = GROUP_COLUMNS;
  dataColumns: string[] = inject(UserPreferenceFormService).generateDataColumns(
    GROUP_TAB
  );

  constructor(
    public dialog: MatDialog,
    public groupService: GroupService,
    public alert: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private screening: ScreeningService
  ) {}

  ngOnInit(): void {
    const userRoles = this.user.getRoles();
    let roleFound = userRoles.find((r) => FVViewRoles.includes(r)) || false;
    if (roleFound) this.canViewFN = true;
    this.savedPrefs = this.route.snapshot.data.userPrefFormGroup?.value;
    const formGroup: FormGroup = this.route.snapshot.data
      .userPrefFormGroup as FormGroup;
    this.userPrefFormGroup$ = formGroup.valueChanges.subscribe(
      (savedPrefs: UserPrefSaved) => {
        this.savedPrefs = savedPrefs;
      }
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onEdited(result: Group) {
    const group = this.groups.find((g) => g.id === result.id);

    if (group) {
      Object.assign(group, result);
    }
  }

  onSortChange(sort: Sort) {
    this.filterParams.sortBy = sort.active;
    this.filterParams.direction = sort.direction.toUpperCase();
    this.filterParamsChange.emit(this.filterParams);
  }

  addFnToGroup(group?: Group) {
    let dialogRef = this.dialog.open(AddFvsDialogComponent, {
      data: group,
      width: '600px',
    });

    dialogRef
      .beforeClosed()
      .pipe(take(1))
      .subscribe((data) => {
        let ok = dialogRef.componentInstance.ok;
        if (ok) this.filterParamsChange.emit(this.filterParams);
      });
  }

  removeFnFromGroup(group?: Group) {
    let temp: any = group;
    temp.remove = true;
    let dialogRef = this.dialog.open(AddFvsDialogComponent, {
      data: group,
      width: '600px',
    });

    dialogRef
      .beforeClosed()
      .pipe(take(1))
      .subscribe((data) => {
        let ok = dialogRef.componentInstance.ok;
        if (ok) this.filterParamsChange.emit(this.filterParams);
      });
  }

  deleteGroup(id?: string) {
    this.alert
      .confirmDelete({
        title: `Confirm Delete Group`,
        message: `Are you sure you want to delete this group?`,
        performAction: () => this.groupService.delete(id),
      })
      .pipe(take(1))
      .subscribe(() => {
        this.filterParamsChange.emit(this.filterParams);
      });
  }

  navigateToFNs(
    count: any,
    destination: string,
    queryField?: string,
    queryParam?: string | boolean
  ) {
    if (count) {
      let filter: any = {};

      if (this.router.url === '/') destination = 'fvs';

      if (queryField) {
        filter[queryField] = [queryParam];
        this.router.navigate([destination], {
          relativeTo: this.route,
          queryParams: filter,
        });
      } else {
        this.router.navigate([destination], {
          relativeTo: this.route,
        });
      }
    }
  }

  openConfirmationDialog(
    groupId: string
  ): MatDialogRef<ConfirmDialogComponent> {
    let data = {
      icon: 'alert-octagon',
      color: 'warn',
      title: `ARE YOU SURE?`,
      message: `This will create a new screening request for all completed Foreign Nationals that are part of this group.`,
      performAction: () => this.rescreen(groupId),
      confirmText: 'Confirm',
    };

    return this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data,
    });
  }

  rescreen(groupId: string) {
    this.screening
      .rescreenFnByGroup(groupId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.alert.successAlert(
          res + ' Foreign National(s) have been submitted for rescreen'
        );
        this.filterParamsChange.emit(this.filterParams);
      });
  }
}
