import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink, RouterModule } from '@angular/router';
import {
  FAR,
  FarPurposeType,
  FarPurposeTypeIcons,
} from '@shared/models/far.model';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { FarLocationPipe } from '@shared/pipes/far-location.pipe';
import { NgPipesModule } from 'ngx-pipes';

@Component({
  selector: 'app-far-list',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatListModule,
    MatIconModule,
    MatTooltipModule,
    RouterLink,
    NgPipesModule,
    FarLocationPipe,
    DefaultPipe,
  ],
  templateUrl: './far-list.component.html',
  styleUrls: ['./far-list.component.scss'],
})
export class FarListComponent {
  @Input() fars: FAR[] = [];
  @Input() routerNavEnabled: boolean = true;
  FarPurposeType = FarPurposeType;
  FarPurposeTypeIcons = FarPurposeTypeIcons;
}
