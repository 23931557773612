import { Pipe, PipeTransform } from '@angular/core';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { Passport } from '@shared/models/passport.model';

@Pipe({
  name: 'currentPassport',
  standalone: true,
})
export class CurrentPassportPipe implements PipeTransform {
  transform(fv: ForeignVisitor): Passport | null {
    if (fv?.passports && fv.passports.length > 0) {
      let passports: Passport[] = fv.passports.slice().sort((a, b) => {
        if (a.issueDate && b.issueDate) {
          const dateA = new Date(a.issueDate).valueOf();
          const dateB = new Date(b.issueDate).valueOf();
          return (dateA - dateB) * -1;
        } else {
          return 0;
        }
      });
      return passports[0];
    } else {
      return null;
    }
  }
}
