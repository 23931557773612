import { ApplicationRef } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from '@app/app.component';
import { appConfig } from '@app/app.config';
import { environment } from '@environments/environment';
import { APP_CONFIG } from '@environments/ienvironment';

async function bootstrap(): Promise<ApplicationRef> {
  let config = { ...environment };
  if (environment.configJson) {
    const configResp = await fetch(environment.configJson);
    const configJson = await configResp.json();
    config = { ...environment, ...configJson };
  }

  return bootstrapApplication(AppComponent, {
    providers: [
      {
        provide: APP_CONFIG,
        useValue: config,
      },
      ...appConfig.providers,
    ],
  });
}

bootstrap();
