import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ApprovalAdvancedSearch } from '@app/admin/approvals/approval-advanced-search';
import { ApprovalTableComponent } from '@app/admin/approvals/approval-table/approval-table.component';
import { ActiveSearchDisplayComponent } from '@shared/components/advanced-search/active-search-display.component';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { ColumnChooserComponent } from '@shared/components/column-chooser/column-chooser.component';
import { FilterComponent } from '@shared/components/filter/filter.component';
import { NoResultFoundComponent } from '@shared/components/no-result-found/no-result-found.component';
import { PaginatorComponent } from '@shared/components/paginator/paginator.component';
import { SearchToolbarComponent } from '@shared/components/search-toolbar/search-toolbar.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { ActionButton } from '@shared/models/actionbutton.model';
import { Approval } from '@shared/models/approval';
import { FilterParams } from '@shared/models/filter-params.model';
import { PageableCollection } from '@shared/models/pageable-collection.model';

@Component({
  selector: 'app-approval-collection',
  templateUrl: './approval-collection.component.html',
  styleUrls: ['./approval-collection.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    SearchToolbarComponent,
    FilterComponent,
    ActiveSearchDisplayComponent,
    AuthEventsDialogComponent,
    AuthEventsDialogDirective,
    ApprovalTableComponent,
    ColumnChooserComponent,
    PaginatorComponent,
    NoResultFoundComponent,
  ],
})
export class ApprovalCollectionComponent {
  @Input() loading: boolean = false;
  @Input() position: 'fixed' | 'sticky' = 'sticky';
  @Input() pageableCollection: PageableCollection<Approval> | null = null;
  @Input() filterParams: FilterParams = new FilterParams({});
  @Output() filterParamsChange = new EventEmitter<FilterParams>();
  @Input() workspaceView: boolean = false;
  actionButtons: ActionButton[] = [];

  onFilterParamChange(filterParams: FilterParams) {
    this.filterParamsChange.emit(filterParams);
  }

  protected readonly ApprovalAdvancedSearch = ApprovalAdvancedSearch;
}
