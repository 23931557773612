import { Pipe, PipeTransform } from '@angular/core';
import { ScreeningStatus } from '@shared/enums/screening-status.enum';
import { Screening, ScreeningDisplayMap } from '@shared/models/screening.model';

@Pipe({
  name: 'screeningColor',
  standalone: true,
})
export class ScreeningColorPipe implements PipeTransform {
  transform(value: Screening): string | null {
    if (value.result && value.status !== ScreeningStatus.Error) {
      return ScreeningDisplayMap.get(value.result).color;
    }

    if (value.status) {
      return ScreeningDisplayMap.get(value.status).color;
    }

    return null;
  }
}

@Pipe({
  name: 'screeningIcon',
  standalone: true,
})
export class ScreeningIconPipe implements PipeTransform {
  transform(value: Screening): string {
    if (value) {
      if (value.result && value.status !== ScreeningStatus.Error) {
        return ScreeningDisplayMap.get(value.result).icon;
      }

      if (isIneligible(value)) {
        return ScreeningDisplayMap.get(ScreeningStatus.Ineligible).icon;
      }
      if (screeningDelayed(value)) {
        return ScreeningDisplayMap.get(ScreeningStatus.Delayed).icon;
      }

      if (value.status) {
        return ScreeningDisplayMap.get(value.status).icon;
      }
    }
    return '';
  }
}

@Pipe({
  name: 'screeningLabel',
  standalone: true,
})
export class ScreeningLabelPipe implements PipeTransform {
  transform(value: Screening, show: 'status' | 'result' = 'status'): string {
    if (value) {
      if (
        show === 'result' &&
        value.result &&
        value.status !== ScreeningStatus.Error
      ) {
        return ScreeningDisplayMap.get(value.result).statusLabel;
      }

      if (screeningDelayed(value)) {
        return ScreeningDisplayMap.get(ScreeningStatus.Delayed).statusLabel;
      }

      if (isIneligible(value)) {
        return ScreeningDisplayMap.get(ScreeningStatus.Ineligible).statusLabel;
      }

      if (value.status) {
        return ScreeningDisplayMap.get(value.status).statusLabel;
      }
    }
    return '';
  }
}

@Pipe({
  name: 'screeningClassList',
  standalone: true,
})
export class ScreeningClassPipe implements PipeTransform {
  transform(value: Screening): string {
    if (value) {
      if (value.result && value.status !== ScreeningStatus.Error) {
        return ScreeningDisplayMap.get(value.result).classList;
      }

      if (screeningDelayed(value)) {
        return ScreeningDisplayMap.get(ScreeningStatus.Delayed).classList;
      }

      if (value.status) {
        return ScreeningDisplayMap.get(value.status).classList;
      }
    }
    return '';
  }
}

@Pipe({
  name: 'screeningDate',
  standalone: true,
})
export class ScreeningDatePipe implements PipeTransform {
  transform(value: Screening) {
    if (value) {
      if (value.status === ScreeningStatus.New) {
        return value.createdDate;
      }

      if (value.status === ScreeningStatus.InProcess) {
        return value.requestedDate;
      }

      if (value.status === ScreeningStatus.Completed) {
        return value.completedDate;
      }

      return value.lastModifiedDate;
    }
  }
}

function screeningDelayed(screening: Screening): boolean {
  const delayTime = 1000 * 60 * 45; // delay time is 45 mintues
  const now = new Date().getTime();
  const lastModified = new Date(screening.lastModifiedDate!).getTime();

  const isDelayed = lastModified + delayTime < now;
  const isInProcess = screening.status === ScreeningStatus.InProcess;
  const isNullResult = !screening.result;
  return isInProcess && isNullResult && isDelayed;
}

function isIneligible(screening: Screening): boolean {
  return (
    !screening.fvCurrentlyScreeningEligible &&
    screening.status == ScreeningStatus.New
  );
}
