import { Component, Input, ViewChild } from '@angular/core';
import {
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgForm,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MatFormFieldAppearance,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { ItineraryDialogComponent } from '@shared/components/itinerary-control/itinerary-dialog.component';
import { BaseControlComponent } from '@shared/controls/base-control.component';
import { OrganizationSelectComponent } from '@shared/controls/organization-select/organization-select.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { Organization } from '@shared/models/organization.model';
import { Role } from '@shared/models/role';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@Component({
  selector: 'app-organization-form',
  templateUrl: './organization-form.component.html',
  styleUrls: ['./organization-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: OrganizationFormComponent,
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    FormsModule,
    OrganizationSelectComponent,
    AuthEventsDialogComponent,
    AuthEventsDialogDirective,
    ItineraryDialogComponent,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatIconModule,
    MatProgressSpinnerModule,
    NgxMatSelectSearchModule,
    MatButtonModule,
    MatTooltipModule,
  ],
})
export class OrganizationFormComponent extends BaseControlComponent<Organization> {
  @ViewChild('organizationForm') organizationForm: NgForm;
  @Input() appearance: MatFormFieldAppearance = 'outline';
  Role = Role;
  invalid: boolean = true;

  constructor() {
    super();
  }

  writeValue(value: Organization): void {
    this.value = value || {};
  }

  get valid(): boolean {
    return this.organizationForm?.valid ?? true;
  }
}
