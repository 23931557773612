import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FarTableComponent } from '@app/foreign-access-requests/dashboard/collections/far-table/far-table.component';
import { FarAdvancedSearch } from '@app/foreign-access-requests/dashboard/far-advanced-search';
import { FAR_TAB } from '@app/user-preference/config/far.config.const';
import { ViewType } from '@app/user-preference/model/config-view-definitions.model';
import { FarCardComponent } from '@shared/components/far-card/far-card.component';
import { NoResultFoundComponent } from '@shared/components/no-result-found/no-result-found.component';
import { PaginatorComponent } from '@shared/components/paginator/paginator.component';
import { SearchToolbarComponent } from '@shared/components/search-toolbar/search-toolbar.component';
import { ActionButton } from '@shared/models/actionbutton.model';
import { FAR } from '@shared/models/far.model';
import { FilterParams } from '@shared/models/filter-params.model';
import { PageableCollection } from '@shared/models/pageable-collection.model';
import { FarEditRoles } from '@shared/models/role-permissions';

const actionButtons: ActionButton[] = [
  {
    label: 'New FAR',
    routerLink: '/far',
    menuIcon: 'note_add',
    roles: FarEditRoles,
  },
];

@Component({
  selector: 'app-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    SearchToolbarComponent,
    PaginatorComponent,
    FarTableComponent,
    FarCardComponent,
    NoResultFoundComponent,
  ],
})
export class CollectionsComponent {
  @Input() position: 'fixed' | 'sticky' = 'sticky';
  @Input() pageableCollection: PageableCollection<FAR>;
  @Input() filterParams: FilterParams = new FilterParams({});
  @Output() filterParamsChange = new EventEmitter<FilterParams>();
  @Input() loading: boolean = false;
  @Input() workspaceView: boolean = false;
  private readonly route: ActivatedRoute = inject(ActivatedRoute);

  view: ViewType = this.route.snapshot.data?.userPrefFormGroup?.value?.far
    ?.viewType as ViewType;
  headerActionButtons: ActionButton[] = actionButtons;
  label: string = FAR_TAB.viewName;

  constructor() {}

  onViewChange(view: ViewType) {
    this.view = view;
  }

  onFilterParamChange(filterParams: FilterParams) {
    this.filterParams = filterParams;
    this.filterParamsChange.emit(filterParams);
  }

  protected readonly FarAdvancedSearch = FarAdvancedSearch;
}
