import { Directive, HostListener, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { ScreeningHistoryDialogComponent } from './screening-history-dialog.component';

@Directive({
  selector: '[screeningHistory]',
  standalone: true,
})
export class ScreeningHistoryDirective {
  @Input() screeningHistory: ForeignVisitor = {};
  constructor(private dialog: MatDialog) {}

  @HostListener('click', ['$event'])
  onClick() {
    this.openDialog(this.screeningHistory);
  }

  openDialog(fv: ForeignVisitor) {
    this.dialog.open(ScreeningHistoryDialogComponent, {
      data: {
        history: fv.allScreenings,
        fvName: fv.givenName + ' ' + fv.surname,
      },
      autoFocus: false,
      width: '800px',
    });
  }
}
