import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CountrySelectComponent } from '@shared/controls/country-select/country-select.component';

import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { IDialogData } from '@shared/models/dialog-data.model';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { FvService } from '@shared/services/fv.service';
import { CrudChange, DialogCloseMessage } from '@shared/shared.types';
import { take } from 'rxjs/operators';
import { BiographicFormComponent } from './biographic-form/biographic-form.component';
@Component({
  selector: 'app-biographic-dialog',
  templateUrl: './biographic-dialog.component.html',
  styleUrls: ['./biographic-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BiographicFormComponent,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,

    AuthEventsDialogComponent,
    AuthEventsDialogDirective,
  ],
})
export class BiographicDialogComponent implements OnInit {
  @ViewChild('bioForm') bioForm: BiographicFormComponent;
  fv: ForeignVisitor;
  mode: CrudChange;
  busy: boolean = false;
  vipTouched: boolean = false;
  @ViewChild('countrySelect') countrySelect: CountrySelectComponent;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IDialogData<ForeignVisitor>,
    private fvService: FvService,
    public dialogRef: MatDialogRef<BiographicDialogComponent>
  ) {}

  ngOnInit(): void {
    this.vipTouched = false;
    if (this.data.model.id) this.getLatest(this.data.model.id);
    this.mode = this.data.mode;
  }

  getLatest(id: string) {
    this.fvService.get(id).subscribe((data) => {
      this.fv = data;
    });
  }

  onSubmit() {
    if (!this.bioForm.bioForm.pristine || this.vipTouched) {
      this.busy = true;
      if (this.fv) {
        this.fvService
          .save(this.fv)
          .pipe(take(1))
          .subscribe(
            (fv: ForeignVisitor) => {
              this.onClose({
                update: fv,
                message: `Updated Biographic Information for ${fv.givenName} ${fv.surname} `,
              });
            },
            (err) => (this.busy = false)
          );
      }
    } else {
      this.dialogRef.close();
    }
  }

  onClose(message: DialogCloseMessage<ForeignVisitor>) {
    this.dialogRef.close(message);
  }
}
