import { CommonModule } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import {
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import {
  MatFormFieldAppearance,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { CitadelIconModule } from '@app/citadel-icon/citadel-icon.module';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { ItineraryControlComponent } from '@shared/components/itinerary-control/itinerary-control.component';
import { AddressComponent } from '@shared/controls/address/address.component';
import { BaseControlComponent } from '@shared/controls/base-control.component';
import { CountrySelectComponent } from '@shared/controls/country-select/country-select.component';
import { OrganizationSelectComponent } from '@shared/controls/organization-select/organization-select.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { FlagIconDirective } from '@shared/directives/flag-icon.directive';
import { RolesDirective } from '@shared/directives/roles.directive';
import { OrgLocation } from '@shared/models/orglocation.model';
import { Role } from '@shared/models/role';
import { CountryPipe } from '@shared/pipes/country.pipe';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { isEmpty } from 'lodash';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
@Component({
  selector: 'app-location-form',
  templateUrl: './location-form.component.html',
  styleUrls: ['./location-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: LocationFormComponent,
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    CommonModule,
    CountryPipe,
    DefaultPipe,
    OrganizationSelectComponent,
    CountrySelectComponent,
    RolesDirective,
    AddressComponent,
    FlagIconDirective,
    AuthEventsDialogComponent,
    AuthEventsDialogDirective,
    ItineraryControlComponent,
    FormsModule,
    ReactiveFormsModule,
    CitadelIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    NgxMatSelectSearchModule,
    MatButtonModule,
  ],
})
export class LocationFormComponent extends BaseControlComponent<OrgLocation> {
  @Input() appearance: MatFormFieldAppearance = 'outline';
  Role = Role;
  invalid: boolean = true;
  @ViewChild('address') address: AddressComponent;

  constructor() {
    super();
  }

  writeValue(value: OrgLocation): void {
    this.value = value || {};
  }

  hasValidValues() {
    if (
      this.value.name &&
      this.value.organization &&
      !isEmpty(this.value.organization.name)
    ) {
      return true;
    }
    return false;
  }
}
