import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'daysAgo',
  standalone: true,
})
export class DaysAgoPipe implements PipeTransform {
  transform(inputDate: string | number | Date): string {
    if (inputDate === null) {
      return '';
    }
    let today: Date = new Date();
    const startDate: Date =
      typeof inputDate == 'string' || typeof inputDate == 'number'
        ? new Date(inputDate)
        : inputDate;
    var Time = today.getTime() - startDate.getTime();
    var daysFloat = Time / (1000 * 3600 * 24);
    var days = Math.trunc(daysFloat);
    if (days == 0 && startDate.getDay() != today.getDay()) {
      days = 1;
    }
    return days.toString();
  }
}
