<div
  id="search-toolbar"
  class="
    search-toolbar
    container-fluid
    d-flex
    align-items-center
    bg-white
    px-3
    py-2
  "
  style="height: 68px"
>
  <div class="mat-headline-5 my-0 mr-3"><ng-content></ng-content></div>

  <app-filter
    [hidden]="groupSelectionMode"
    #appFilter
    class="mr-3 flex-grow-1"
    [placeholder]="filterPlaceholder"
    [filterParams]="filterParams"
    (filterParamsChange)="onFilterParamChange($event)"
    [filterFields]="filterFields"
  ></app-filter>
  <ng-container *ngIf="!compactCard">
    <app-sort
      [hidden]="groupSelectionMode"
      class="mr-3"
      *ngIf="sortFields.length && view === 'card'"
      [sortFields]="sortFields"
      [filterParams]="filterParams"
      (filterParamsChange)="onFilterParamChange($event)"
    ></app-sort>
  </ng-container>
  <ng-container *ngIf="!hideViewToggle">
    <app-view
      [hidden]="groupSelectionMode"
      *ngIf="!compactCard"
      [view]="view"
      (viewChange)="onViewChange($event)"
    ></app-view>
  </ng-container>
  <div class="flex-fill"></div>
  <ng-container
    *ngIf="viewDef.tableName === VIEW_DEF_DEFINITIONS.location.tableName"
  >
    <button
      mat-button
      *appIfRoles="Role.sv_admin"
      (click)="openConfirmationDialog()"
    >
      <div class="d-flex align-items-center gap-1">
        <mat-icon svgIcon="location-enter"></mat-icon>
        <span class="button-group-text"> Normalize</span>
      </div>
    </button>
  </ng-container>
  <app-column-chooser
    *ngIf="view === 'table'"
    [hidden]="groupSelectionMode"
    matTooltip="Column Selection"
    [viewDefKey]="viewDefKey"
    class="mb-1"
  ></app-column-chooser>
  <div *ngIf="!compactCard">
    <ng-container
      *ngIf="viewDef.tableName === VIEW_DEF_DEFINITIONS.fv.tableName"
    >
      <button
        [hidden]="groupSelectionMode"
        id="create-group"
        class="ml-3"
        mat-flat-button
        (click)="selectFvGroup()"
      >
        <div class="d-flex align-items-center gap-1">
          <mat-icon [svgIcon]="'group'"></mat-icon>
          <span class="button-group-text"> New Group</span>
        </div>
      </button>
    </ng-container>
    <ng-container *ngIf="!groupSelectionMode">
      <ng-container *ngFor="let button of actionButtons; let i = index">
        <ng-container *appIfRoles="button.roles">
          <button
            *ngIf="button.bulkUpload"
            id="{{ button.label }}-bulk-upload-button-{{ i }}"
            class="ml-3"
            mat-flat-button
            [appBulkUpload]="button.bulkUpload"
            (appBulkUploaded)="refresh()"
            [routerLink]="button.routerLink?.length ? [button.routerLink] : []"
          >
            <div class="d-flex align-items-center gap-1">
              <mat-icon *ngIf="button.menuIcon">{{ button.menuIcon }}</mat-icon>
              <span class="button-group-text" *ngIf="button.label">
                {{ button.label }}</span
              >
            </div>
          </button>
          <button
            *ngIf="button?.locationEdit"
            id="{{ button.label }}-loc-edit-button-{{ i }}"
            class="ml-3"
            mat-flat-button
            [appLocationEdit]
            (appLocationEdited)="refresh()"
          >
            <div class="d-flex align-items-center gap-1">
              <mat-icon *ngIf="button.menuIcon">{{ button.menuIcon }}</mat-icon>
              <span class="button-group-text" *ngIf="button.label">
                {{ button.label }}</span
              >
            </div>
          </button>
          <button
            *ngIf="button?.groupCreate"
            id="{{ button.label }}-group-create-button-{{ i }}"
            class="ml-3"
            mat-flat-button
            (click)="createEmptyGroup()"
          >
            <div class="d-flex align-items-center gap-1">
              <mat-icon
                *ngIf="button.menuIcon"
                [svgIcon]="button.menuIcon"
              ></mat-icon>
              <span class="button-group-text" *ngIf="button.label">
                {{ button.label }}</span
              >
            </div>
          </button>
          <button
            *ngIf="button?.orgEdit"
            id="{{ button.label }}-org-edit-button-{{ i }}"
            class="ml-3"
            mat-flat-button
            [appOrganizationEdit]
            (appOrganizationEdited)="refresh()"
          >
            <div class="d-flex align-items-center gap-1">
              <mat-icon *ngIf="button.menuIcon">{{ button.menuIcon }}</mat-icon>
              <span class="button-group-text" *ngIf="button.label">
                {{ button.label }}</span
              >
            </div>
          </button>
          <button
            *ngIf="button?.userDialog"
            id="new-user-acct-btn"
            class="ml-3"
            mat-flat-button
            appUserAccountDialog
          >
            <div class="d-flex align-items-center gap-1">
              <mat-icon *ngIf="button.menuIcon">{{ button.menuIcon }}</mat-icon>
              <span class="button-group-text" *ngIf="button.label">
                {{ button.label }}</span
              >
            </div>
          </button>
          <button
            *ngIf="button?.routerLink"
            id="{{ button.label }}-action-button-{{ i }}"
            class="ml-3"
            mat-flat-button
            [routerLink]="button.routerLink?.length ? [button.routerLink] : []"
          >
            <div class="d-flex align-items-center gap-1">
              <mat-icon *ngIf="button.menuIcon">{{ button.menuIcon }}</mat-icon>
              <span class="button-group-text" *ngIf="button.label">
                {{ button.label }}</span
              >
            </div>
          </button>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
  <ng-container *ngIf="groupSelectionMode">
    <div class="flex-fill"></div>
    <button
      id="cancel-group"
      class="ml-3"
      mat-flat-button
      (click)="cancelSelectFvGroup()"
    >
      <div class="d-flex align-items-center gap-1">
        <mat-icon [svgIcon]="'close'"></mat-icon>
        <span class="button-group-text">Cancel</span>
      </div>
    </button>
    <button
      id="create-group"
      class="ml-3"
      mat-flat-button
      (click)="createFvGroup()"
    >
      <div class="d-flex align-items-center gap-1">
        <mat-icon [svgIcon]="'check-circle-outline'"></mat-icon>
        <span class="button-group-text">Confirm</span>
      </div>
    </button>
  </ng-container>
</div>
<app-active-search-display
  [filterFields]="filterFields"
  [filterParams]="filterParams"
  (filterParamsChange)="onFilterParamChange($event)"
></app-active-search-display>
<mat-progress-bar
  color="accent"
  [mode]="loading ? 'indeterminate' : 'determinate'"
></mat-progress-bar>
