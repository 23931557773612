import { Directive, Input } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { isEmpty } from 'lodash';

export interface PasswordValidationErrors extends ValidationErrors {
  hasNumber?: boolean;
  hasCapitalCase?: boolean;
  hasSmallCase?: boolean;
  hasSpecialCharacter?: boolean;
  hasMinLength?: boolean;
}

export interface PasswordPolicy {
  minLength?: number;
  requireCapitalCase?: boolean;
  requireSmallCase?: boolean;
  requireNumber?: boolean;
  requireSpecialCharacter?: boolean;
}

export const defaultPasswordPolicy: PasswordPolicy = {
  minLength: 12,
  requireCapitalCase: true,
  requireSmallCase: true,
  requireNumber: true,
  requireSpecialCharacter: true,
};

@Directive({
  selector: '[validPassword]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: ValidPasswordDirective,
      multi: true,
    },
  ],
  standalone: true,
})
export class ValidPasswordDirective implements Validator {
  @Input() validPassword?: PasswordPolicy;

  validate(selfControl: AbstractControl): ValidationErrors | null {
    const errors: PasswordValidationErrors = {};

    const policy = { ...defaultPasswordPolicy, ...this.validPassword };

    const value = selfControl.value || '';

    if (policy.requireNumber && !value.match(/\d/)) {
      errors.hasNumber = true;
    }

    if (policy.requireCapitalCase && !value.match(/[A-Z]/)) {
      errors.hasCapitalCase = true;
    }

    if (policy.requireSmallCase && !value.match(/[a-z]/)) {
      errors.hasSmallCase = true;
    }

    if (
      policy.requireSpecialCharacter &&
      !value.match(/[\^$*.\[\]{}\(\)?\-"!@#%&\/,><\’:;|_~`]/)
    ) {
      errors.hasSpecialCharacter = true;
    }

    if (policy.minLength && value.length < policy.minLength) {
      errors.hasMinLength = true;
    }

    return isEmpty(errors) ? null : errors;
  }
}
