import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Contact } from '@shared/models/contact.model';
import { Crud } from '@shared/models/crud.decoration';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root',
})
@Crud({
  apiUrl: `${environment.apiUrl}/contacts`,
  hasIdPathUpdate: true,
  entity: 'Contacts',
})
export class ContactService extends CrudService<Contact> {
  constructor(http: HttpClient) {
    super(http);
  }
}
