import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ScreeningDialogComponent } from '@shared/components/screening/screening-dialog/screening-dialog.component';
import { ScreeningTransitionComponent } from '@shared/components/screening/screening-transition/screening-transition.component';
import { ScreeningStatus } from '@shared/enums/screening-status.enum';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import {
  Screening,
  ScreeningDateFormat,
  ScreeningUtils,
} from '@shared/models/screening.model';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import {
  ScreeningDatePipe,
  ScreeningLabelPipe,
  ScreeningIconPipe,
  ScreeningClassPipe,
} from '@shared/pipes/screening-display.pipe';
import { cloneDeep } from 'lodash';
@Component({
  selector: 'fvview-screening-events',
  templateUrl: 'fvscreening-events.component.html',
  styleUrls: ['fvscreening-events.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDividerModule,
    MatCardModule,
    MatIconModule,
    MatMenuModule,
    MatPaginatorModule,
    MatToolbarModule,
    MatTooltipModule,
    DefaultPipe,
    ScreeningDatePipe,
    ScreeningTransitionComponent,
    ScreeningLabelPipe,
    ScreeningIconPipe,
    ScreeningClassPipe,
  ],
})
export class FvScreeningEventsComponent {
  @Input() fv: ForeignVisitor;
  pageEvent: PageEvent;
  ScreeningDateFormat = ScreeningDateFormat;
  ScreeningUtils = ScreeningUtils;
  ScreeningStatus = ScreeningStatus;

  constructor(private dialog: MatDialog) {}

  getLegacyMessage() {
    return `Result contains a Legacy Value`;
  }

  viewScreening(screening: Screening) {
    let fv = cloneDeep(this.fv);
    fv.latestScreening = screening;
    const dialogRef = this.dialog.open(ScreeningDialogComponent, {
      autoFocus: false,
      data: fv,
      width: '900px',
    });
  }
}
