import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ContactDialogComponent } from '@app/organizations/collection/contact-dialog/contact-dialog.component';
import { Contact } from '@shared/models/contact.model';

@Directive({
  selector: '[appContactEdit]',
  standalone: true,
})
export class ContactEditDirective {
  @Input() appContactEdit: Contact | undefined;
  @Output() appContactDialogOpen = new EventEmitter<
    MatDialogRef<ContactDialogComponent, Contact>
  >();

  @Output() appContactEdited = new EventEmitter<Contact>();

  constructor(private dialog: MatDialog) {}

  @HostListener('click', ['$event'])
  onClick(_e: Event) {
    const dialog = ContactDialogComponent.openDialog(
      this.dialog,
      this.appContactEdit
    );
    dialog.afterClosed().subscribe((result: Contact) => {
      if (result) {
        this.appContactEdited.emit(result);
      }
    });

    this.appContactDialogOpen.emit(dialog);
  }
}
