import { CommonModule } from '@angular/common';
import {
  Component,
  OnInit,
  Inject,
  AfterContentChecked,
  ChangeDetectorRef,
  EventEmitter,
  Output,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { VisaComponent } from '@app/foreign-visitors/fv/visa/visa.component';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { Visa } from '@shared/models/visa.model';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { VisaService } from '@shared/services/visa.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-visa-dialog',
  templateUrl: './visa-dialog.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    VisaComponent,
    DefaultPipe,
    AuthEventsDialogComponent,
    MatDialogModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class VisaDialogComponent implements OnInit, AfterContentChecked {
  visa: Visa = {};
  busy: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<VisaDialogComponent>,
    public visaService: VisaService,
    private changeDetect: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: Visa
  ) {}

  ngOnInit() {
    this.visa = this.data;
  }

  //Supressing "ExpressionChangedAfterItHasBeenCheckedError" occurring due to visaForm in VisaForm Component values changing
  ngAfterContentChecked(): void {
    this.changeDetect.detectChanges();
  }

  submitVisa() {
    this.busy = true;
    this.visaService
      .save(this.visa)
      .pipe(take(1))
      .subscribe(
        (result: Visa) => {
          this.onClose(result);
        },
        (err) => (this.busy = false)
      );
  }

  onClose(visa: Visa) {
    this.dialogRef.close(visa);
  }
}
