import { CommonModule, Location } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { ScreeningControlComponent } from '@shared/controls/screening-control/screening-control.component';
import { HighOnlyEnabledDirective } from '@shared/directives';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { RolesDirective } from '@shared/directives/roles.directive';
import { ScreeningResult } from '@shared/enums/screening-result.enum';
import { ScreeningStatus } from '@shared/enums/screening-status.enum';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { ScreeningRoles } from '@shared/models/role-permissions';
import { Screening } from '@shared/models/screening.model';
import { FullnamePipe } from '@shared/pipes/fullname.pipe';
import {
  ScreeningClassPipe,
  ScreeningDatePipe,
  ScreeningIconPipe,
  ScreeningLabelPipe,
} from '@shared/pipes/screening-display.pipe';
import { ScreeningService } from '@shared/services/screening.service';
import { cloneDeep } from 'lodash';
import { NgPipesModule } from 'ngx-pipes';
import { take } from 'rxjs';
import { ConfirmDialogComponent } from '../../confirm/confirmdialog.component';
import { ScreeningTransitionComponent } from '../screening-transition/screening-transition.component';

@Component({
  selector: 'app-screening-dialog',
  templateUrl: './screening-dialog.component.html',
  styleUrls: ['./screening-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatBadgeModule,
    MatDialogModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    NgPipesModule,
    AuthEventsDialogComponent,
    AuthEventsDialogDirective,
    ScreeningClassPipe,
    ScreeningIconPipe,
    ScreeningLabelPipe,
    FullnamePipe,
    ScreeningTransitionComponent,
    RolesDirective,
    ScreeningControlComponent,
    ScreeningDatePipe,
    HighOnlyEnabledDirective,
  ],
})
export class ScreeningDialogComponent implements OnInit {
  busy: boolean = false;
  cacheScreening: Partial<Screening> = {};
  confirmUsp: boolean = false;
  latestScreening!: Screening;
  overrideResults: boolean = false;
  ScreeningRoles = ScreeningRoles;
  status = ScreeningStatus;
  ScreeningResult = ScreeningResult;

  constructor(
    @Inject(MAT_DIALOG_DATA) public fv: ForeignVisitor,
    private dialog: MatDialog,
    private location: Location,
    private router: Router,
    private dialogRef: MatDialogRef<ScreeningDialogComponent>,
    private screeningService: ScreeningService
  ) {}

  ngOnInit(): void {
    this.latestScreening = cloneDeep(this.fv.latestScreening) as Screening;
    this.confirmUsp = false;
  }

  onOverrideResults() {
    this.cacheScreening = cloneDeep(this.latestScreening);
    this.overrideResults = true;
  }

  onCancel() {
    this.latestScreening = this.cacheScreening;
    this.cacheScreening = {};
    this.overrideResults = false;
  }

  onSubmit() {
    if (this.latestScreening.result === ScreeningResult.ReviewedRestricted) {
      this.openConfirmationDialog();
    } else this.save();
  }

  save(sendBack?: boolean) {
    this.busy = true;
    this.screeningService
      .save(this.latestScreening)
      .pipe(take(1))
      .subscribe({
        next: (_) => {
          if (sendBack && location.pathname !== '/screening')
            this.location.back();
          this.dialogRef.close();
        },
        error: (err) => (this.busy = false),
      });
  }

  openConfirmationDialog(): MatDialogRef<ConfirmDialogComponent> {
    let data = {
      icon: 'alert-octagon',
      color: 'warn',
      title: `ARE YOU SURE?`,
      message: `This will cause the Foreign National to be deleted`,
      performAction: () => this.save(true),
      confirmText: 'Confirm',
    };

    return this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data,
    });
  }

  getErrorMessage(error: any) {
    if (error.legacyValue) {
      return `Result contains a Legacy Value`;
    }
  }
}
