import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Crud } from '@shared/models/crud.decoration';
import { FilterParams } from '@shared/models/filter-params.model';
import { PageableCollection } from '@shared/models/pageable-collection.model';
import { SystemAlert } from '@shared/models/system-alert';
import { CrudService } from '@shared/services/crud.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
@Crud({ apiUrl: `${environment.apiUrl}/alerts`, entity: 'System Alert' })
export class SystemAlertsCrudService extends CrudService<SystemAlert> {
  private _defaultFilterParameters: FilterParams = new FilterParams({
    sort: { active: 'startDate', direction: 'desc' },
  });

  private filterParameters_ = new BehaviorSubject<FilterParams>(
    this._defaultFilterParameters
  );
  filterParameters$ = this.filterParameters_.asObservable();

  systemAlerts$ = this.filterParameters$.pipe(
    switchMap((filterParams) => this.find(filterParams))
  );

  constructor(http: HttpClient) {
    super(http);
  }

  onFilterParamChange(filterParms: FilterParams) {
    this.filterParameters_.next(filterParms);
  }

  activeAlerts(): Observable<PageableCollection<SystemAlert>> {
    return this.http.get<PageableCollection<SystemAlert>>(
      `${this.apiUrl}/current`
    );
  }
}
