import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { FnCardComponent } from '@app/foreign-nationals/fn-card/fn-card.component';
import { FvAdvancedSearch } from '@app/foreign-visitors/dashboard/fv-advanced-search';
import { FnGroupDialogComponent } from '@app/foreign-visitors/fn-group-dialog/fn-group-dialog.component';
import { NetworkDomain } from '@app/shared/models/network-domain';
import { FV_TAB } from '@app/user-preference/config/fv.config.const';
import {
  UserPrefSaved,
  ViewType,
} from '@app/user-preference/model/config-view-definitions.model';
import { AvatarComponent } from '@shared/components/avatar/avatar.component';
import { NoResultFoundComponent } from '@shared/components/no-result-found/no-result-found.component';
import { PaginatorComponent } from '@shared/components/paginator/paginator.component';
import { ScreeningIndicatorComponent } from '@shared/components/screening/screening-indicator/screening-indicator.component';
import { SearchToolbarComponent } from '@shared/components/search-toolbar/search-toolbar.component';
import { ScreeningResult } from '@shared/enums/screening-result.enum';
import { ActionButton } from '@shared/models/actionbutton.model';
import { FilterParams } from '@shared/models/filter-params.model';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { PageableCollection } from '@shared/models/pageable-collection.model';
import { FVEditRoles } from '@shared/models/role-permissions';
import { AgePipe } from '@shared/pipes/age.pipe';
import { CurrentPassportPipe } from '@shared/pipes/current-passport.pipe';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { FullnamePipe } from '@shared/pipes/fullname.pipe';
import { AlertService } from '@shared/services/alert.service';
import { AppConfigService } from '@shared/services/app-config.services';
import { FvTableComponent } from '../fv-table/fv-table.component';

const actionButtons: ActionButton[] = [
  {
    label: 'Bulk Upload',
    bulkUpload: 'FV',
    menuIcon: 'input',
    roles: FVEditRoles,
    color: 'primary',
  },
  {
    label: 'New FN',
    routerLink: '/fv',
    menuIcon: 'note_add',
    roles: FVEditRoles,
    color: 'primary',
  },
];

@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatListModule,
    MatTooltipModule,
    RouterLink,
    SearchToolbarComponent,
    FvTableComponent,
    FnCardComponent,
    AvatarComponent,
    ScreeningIndicatorComponent,
    PaginatorComponent,
    NoResultFoundComponent,
    DefaultPipe,
    CurrentPassportPipe,
    AgePipe,
    FullnamePipe,
  ],
})
export class CollectionComponent implements OnInit {
  @ViewChild('trigger') trigger: MatMenuTrigger;
  @ViewChild('searchToolbar') searchToolbar: SearchToolbarComponent;
  @Input() pageableCollection: PageableCollection<ForeignVisitor>;
  @Input() compactCard: boolean = false;
  @Input() loading: boolean = false;
  @Output() emitAdd = new EventEmitter<ForeignVisitor>();
  @Output() emitRemove = new EventEmitter<ForeignVisitor>();
  @Input() position: 'fixed' | 'sticky' = 'sticky';
  @Input() dashboardType: 'baseline';
  @Input() filterParams: FilterParams = new FilterParams({});
  @Output() filterParamsChange = new EventEmitter<FilterParams>();
  @Input() workspaceView: boolean = false;
  private readonly route: ActivatedRoute = inject(ActivatedRoute);
  view: ViewType = this.route.snapshot.data?.userPrefFormGroup?.value?.fv
    ?.viewType as ViewType;
  userPreference: UserPrefSaved | undefined;
  headerActionButtons: ActionButton[] = actionButtons;
  selectDisplayed: boolean = false;
  ScreeningResult = ScreeningResult;
  farScreeningEligibility: boolean = false;
  groupSelectionMode: boolean = false;
  filterFields = FvAdvancedSearch;
  currentNetwork: NetworkDomain = NetworkDomain.U;
  label: string = FV_TAB.viewName;
  additionalDocumentsScreeningEligibility: boolean = false;

  constructor(
    private config: AppConfigService,
    private dialog: MatDialog,
    private alert: AlertService
  ) {}

  ngOnInit(): void {
    this.farScreeningEligibility =
      this.config.get('farScreeningEligibility') || false;

    this.additionalDocumentsScreeningEligibility =
      this.config.get('additionalDocumentsScreeningEligibility') || false;

    if (this.config.get('highEnvFeatures'))
      this.currentNetwork = NetworkDomain.J;
  }

  onViewChange(view: ViewType) {
    this.view = view;
  }

  onFilterParamChange(filterParams: FilterParams) {
    this.filterParamsChange.emit(filterParams);
  }

  groupModeChange(change: boolean) {
    if (change !== null) this.groupSelectionMode = change;
    if (!this.groupSelectionMode) this.deselectAllFns();

    if (change === null) {
      if (this.isFnSelected()) {
        let dialogRef: MatDialogRef<FnGroupDialogComponent> =
          this.dialog.open<FnGroupDialogComponent>(FnGroupDialogComponent, {
            data: this.pageableCollection.content,
          });
        dialogRef.afterClosed().subscribe((result) => {
          this.groupSelectionMode = false;
          this.searchToolbar.groupSelectionMode = false;
          this.deselectAllFns();
        });
      } else {
        this.alert.infoAlert(
          'At least one Foreign National must be selected to create a group.'
        );
      }
    }
  }

  isFnSelected() {
    for (let fn of this.pageableCollection.content)
      if (fn.selected) return true;
  }

  deselectAllFns() {
    for (let fn of this.pageableCollection.content) fn.selected = false;
  }

  hideFilter() {
    this.selectDisplayed = !this.selectDisplayed;
  }

  emitAddEvent(fv: ForeignVisitor) {
    this.emitAdd.emit(fv);
    this.trigger.closeMenu();
  }
}
