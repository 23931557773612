import { CommonModule } from '@angular/common';
import { Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';

import {
  AbstractControl,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgForm,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MatFormFieldAppearance,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { VisaTypes } from '@shared/constants/VISA_TYPES.const';
import { BaseControlComponent } from '@shared/controls/base-control.component';
import { hasValues } from '@shared/helpers/has-values';
import { Visa } from '@shared/models/visa.model';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@Component({
  selector: 'app-visa',
  templateUrl: './visa.component.html',
  styleUrls: ['visa.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: VisaComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => VisaComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class VisaComponent
  extends BaseControlComponent<Visa>
  implements Validator, OnInit
{
  @Input() showClearButton: boolean = true;
  @ViewChild('visaForm')
  visaForm: NgForm;
  visaTypes = VisaTypes;
  appearance: MatFormFieldAppearance = 'outline';
  isVisa: boolean = false;
  isRequiredVisa: boolean;
  visaEntries: any = ['Single', 'Multiple'];
  gender: any = ['Male', 'Female'];
  visaPattern = /[0-9]{8}|[a-zA-Z]{1}[0-9]{7}/;
  onValidationChange: any = () => {};
  value: Visa = {};

  constructor() {
    super();
  }

  addVisa() {
    this.emitChangeEvent();
  }

  getEmitValue(): Visa | null {
    return this.hasVisaData() ? this.value : null;
  }

  hasVisaData() {
    return hasValues(this.value);
  }

  ngOnInit() {
    this.value = {};
  }

  writeValue(_value: Visa) {
    super.writeValue(_value);
    this.value = this.value || {};
  }

  validate(_control: AbstractControl): ValidationErrors | null {
    return this.visaForm?.valid ? null : { visa: true };
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }
}
