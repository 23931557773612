import { IEnvironment } from '@shared/models/ienvironment.model';

export const environment: IEnvironment = {
  production: true,
  apiUrl: '/api/v2',
  configJson: '/config.json',
  countries: '../assets/countryData.json', //Dev Note: this may need a master application folder depending on production settings
  //For Example: '../citadel/assets/countryData.json'
  helpLink: '../assets/help.txt',
  screeningCodeLink: '../assets/screeningCode.pdf',
  supportPhone: '(301) 243-9095',
  restrictDelete: true,
};
