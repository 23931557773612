import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[appHighlight]',
  standalone: true,
})
export class HighlightDirective implements OnChanges, AfterViewInit {
  @Input('appHighlight') term: string;

  private element: HTMLElement;
  private label: string;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
    this.element = this.elementRef.nativeElement;
  }

  ngOnChanges() {
    this.highlightLabel();
  }

  ngAfterViewInit() {
    this.label = this.element.innerHTML;
    this.highlightLabel();
  }

  private escapeRegExp(str: string): string {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  private highlightLabel() {
    if (!this?.term?.length || !this?.label?.length) return;

    const label = this.label;
    if (!this.term) {
      this.setInnerHtml(label);
      return;
    }

    const alternationString = this.escapeRegExp(this.term).replace(/\s+/, '|');
    const termRegex = new RegExp(alternationString, 'gi');
    this.setInnerHtml(
      label.replace(termRegex, `<span class=\"highlighted\">$&</span>`)
    );
  }

  private setInnerHtml(html: string) {
    this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', html);
  }
}
