import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ActionButton } from '@shared/models/actionbutton.model';
import { BulkUploadComponent } from '../bulk-upload/bulk-upload.component';
import { AuthEventsDialogComponent } from '../auth-events-dialog/auth-events-dialog.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { BulkUploadDirective } from '@shared/directives/bulk-upload.directive';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    BulkUploadComponent,
    BulkUploadDirective,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    AuthEventsDialogComponent,
    AuthEventsDialogDirective,
  ],
})
export class PageHeaderComponent {
  @Input() title = '';
  @Input() actionButtons: ActionButton[];
}
