import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ScreeningDrawerObservable {
  private _ScreeningDrawerObservable$ = new Subject<any>();
  ScreeningDrawerObservable$: Observable<any> =
    this._ScreeningDrawerObservable$.asObservable();

  send(data: any) {
    this._ScreeningDrawerObservable$.next(data);
  }
}
