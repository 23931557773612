import { CommonModule } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTable, MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ScreeningCodeDirective } from '@shared/directives/screening-code.directive';
import { DerogatoryInformation } from '@shared/models/derogatory-information.model';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import {
  CommunicationCodeDescriptionPipe,
  DerogDescriptionPipe,
  OffenseCodeDescriptionPipe,
  RelationalCodeDescriptionPipe,
  TimeframeCodeDescriptionPipe,
  VerificationCodeDescriptionPipe,
} from '@shared/pipes/derog-description.pipe';
import { AppConfigService } from '@shared/services/app-config.services';

@Component({
  selector: 'app-screening-code-display',
  templateUrl: './screening-code-display.component.html',
  styleUrls: ['./screening-code-display.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    CommunicationCodeDescriptionPipe,
    ScreeningCodeDirective,
    DefaultPipe,
    VerificationCodeDescriptionPipe,
    TimeframeCodeDescriptionPipe,
    RelationalCodeDescriptionPipe,
    OffenseCodeDescriptionPipe,
    DerogDescriptionPipe,
  ],
})
export class ScreeningCodeDisplayComponent {
  @ViewChild('derogTable') derogTable: MatTable<DerogatoryInformation>;
  @Input() derogItems: DerogatoryInformation[] = [];
  @Input() overrideCode: boolean = false;

  screeningCodeUrl = this.config.get('screeningCodeLink');
  screeningCodeColumns: string[] = [
    'classification',
    'program',
    'code',
    'rationale',
  ];
  overrideCodeCol: string[] = [...this.screeningCodeColumns, 'actions'];

  constructor(private config: AppConfigService) {}

  onAdd(code: DerogatoryInformation) {
    this.derogItems.push(code);
    this.refreshCodes();
  }

  onEdit(derogCode: DerogatoryInformation, index: number) {
    this.derogItems[index] = derogCode;
    this.refreshCodes();
  }

  refreshCodes() {
    this.derogTable.renderRows();
  }

  removeCode(index: number) {
    this.derogItems.splice(index, 1);
    this.refreshCodes();
  }
}
