import { CommonModule } from '@angular/common';
import { Component, Inject, Input, ViewChild } from '@angular/core';
import { FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import {
  MatFormFieldAppearance,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { FvService } from '@shared/services/fv.service';
import { DialogCloseMessage } from '@shared/shared.types';

@Component({
  selector: 'app-physical-id-vehicle-dialog',
  templateUrl: './physical-id-vehicle-dialog.component.html',
  styleUrls: ['./physical-id-vehicle-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDividerModule,
    MatInputModule,
    MatButtonModule,
    AuthEventsDialogComponent,
    AuthEventsDialogDirective,
  ],
})
export class PhysicalIdVehicleDialogComponent {
  @ViewChild('physicalIdForm', { static: true }) physicalIdForm: NgForm;
  @Input() appearance: MatFormFieldAppearance = 'outline';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ForeignVisitor,
    public dialogRef: MatDialogRef<PhysicalIdVehicleDialogComponent>,
    private fvService: FvService
  ) {}

  saveChanges(data: ForeignVisitor) {
    this.fvService.save(data).subscribe((fv: ForeignVisitor) => {
      this.onClose({
        update: fv,
        message: `Updated Identifying and Vehicle Information for ${fv.givenName} ${fv.surname}`,
      });
    });
  }

  onClose(message: DialogCloseMessage<ForeignVisitor>) {
    this.dialogRef.close(message);
  }
}
