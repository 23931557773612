import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'militaryDateTime',
  standalone: true,
})

/* military date format day month year  */
export class MilitaryDateTimePipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return super.transform(value, 'd MMM y HH:mm:ss');
  }
}
