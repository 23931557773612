import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { Address } from '@shared/models/address.model';
import { FarLocation } from '@shared/models/farlocation.model';
import { AddressPipe } from '@shared/pipes/address.pipe';
import { CountryPipe } from '@shared/pipes/country.pipe';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { FarLocationPipe } from '@shared/pipes/far-location.pipe';

@Component({
  selector: 'app-itinerary-table',
  templateUrl: './itinerary-table.component.html',
  styleUrls: ['./itinerary-table.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    AddressPipe,
    DefaultPipe,
    CountryPipe,
  ],
})
export class ItineraryTableComponent {
  @Input() foreignAccessRequestLocations: FarLocation[];
  private pipe: FarLocationPipe = new FarLocationPipe();

  innerDisplayedColumns: string[] = [
    'startDate',
    'endDate',
    'locationName',
    'locationAddress',
    'orgShortName',
  ];

  addressFieldsHaveData(address: Address): boolean {
    if (
      address.line1 ||
      address.line2 ||
      address.city ||
      address.countryCode ||
      address.postalCode
    )
      return true;
    return false;
  }

  buildCityStateDisplay(city: string, state: string) {
    return this.pipe.buildCityStateDisplay({
      city: city,
      stateProvince: state,
    });
  }

  constructor() {}
}
