import {
  ChangeDetectorRef,
  Directive,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { ClassificationService } from './classification.service';
@Directive({
  selector: '[appClassifyShow]',
  standalone: true,
})
export class ClassifyShowDirective {
  constructor(
    private classificationService: ClassificationService,
    private viewContainer: ViewContainerRef,
    private changeDetector: ChangeDetectorRef,
    private templateRef: TemplateRef<any>
  ) {
    this.viewContainer.clear();
    if (this.classificationService.enabled) {
      this.viewContainer.createEmbeddedView(templateRef);
      this.changeDetector.markForCheck();
    }
  }
}
