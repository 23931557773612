import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserPrefSaved } from '@app/user-preference/model/config-view-definitions.model';
import { ActiveSearchDisplayComponent } from '@shared/components/advanced-search/active-search-display.component';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { ColumnChooserComponent } from '@shared/components/column-chooser/column-chooser.component';
import { FilterComponent } from '@shared/components/filter/filter.component';
import { NoResultFoundComponent } from '@shared/components/no-result-found/no-result-found.component';
import { PaginatorComponent } from '@shared/components/paginator/paginator.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { FilterParams } from '@shared/models/filter-params.model';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { PageableCollection } from '@shared/models/pageable-collection.model';
import { SortSelect } from '@shared/models/sort-select.model';
import { AgePipe } from '@shared/pipes/age.pipe';
import { CurrentPassportPipe } from '@shared/pipes/current-passport.pipe';
import { FullnamePipe } from '@shared/pipes/fullname.pipe';
import { ScreeningTableComponent } from './screening-table/screening-table.component';
import { ScreeningToolbarComponent } from './screening-toolbar/screening-toolbar.component';
import { FvSorts } from '@app/foreign-visitors/constants/foreign-visitors.const';

@Component({
  selector: 'app-screening-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    AuthEventsDialogDirective,
    AuthEventsDialogComponent,
    ScreeningToolbarComponent,
    FullnamePipe,
    CurrentPassportPipe,
    AgePipe,
    FilterComponent,
    ColumnChooserComponent,
    ActiveSearchDisplayComponent,
    NoResultFoundComponent,
    PaginatorComponent,
    ScreeningTableComponent,
  ],
})
export class ScreeningCollectionComponent implements OnInit {
  @Input() loading: boolean = false;
  @Input() pageableCollection: PageableCollection<ForeignVisitor>;
  @Input() filterParams: FilterParams = new FilterParams({});
  @Output() filterParamsChange = new EventEmitter<FilterParams>();

  private readonly route: ActivatedRoute = inject(ActivatedRoute);

  FvSorts: SortSelect[] = FvSorts;

  onFilterParamChange(filterParams: FilterParams) {
    this.filterParamsChange.emit(filterParams);
  }

  ngOnInit(): void {
    const savedPref: UserPrefSaved =
      this.route.snapshot.data.userPrefFormGroup.value;
    const params: FilterParams = new FilterParams({
      ...this.route.snapshot.params,
    });

    if (savedPref.screening?.defaultMineOnly === true) {
      params.currentUserCreatedBy = true;
      this.onFilterParamChange(params);
    }
  }
}
