import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SelectFarComponent } from '@shared/controls/select-far/select-far.component';
import { FAR } from '@shared/models/far.model';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { FvService } from '@shared/services/fv.service';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { take } from 'rxjs';
import { FarListComponent } from '../../far-list/far-list.component';

@Component({
  selector: 'app-add-far-dialog',
  templateUrl: './add-far-dialog.component.html',
  styleUrls: ['./add-far-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatSnackBarModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatListModule,
    MatFormFieldModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    SelectFarComponent,
    FarListComponent,
  ],
})
export class AddFarDialogComponent implements OnInit {
  farsToAssociate: FAR[] = [];
  fvId: string = '';
  fvName: string = '';
  fvOrganizationId: string = '';
  busy: boolean = false;
  ok: boolean = false;

  constructor(
    private fvService: FvService,
    @Inject(MAT_DIALOG_DATA) private fv: ForeignVisitor,
    private dialog: MatDialogRef<AddFarDialogComponent>
  ) {}

  ngOnInit(): void {
    this.ok = false;
    this.fvName = this.fv.givenName + ' ' + this.fv.surname;
    this.fvId = this.fv.id;
    if (this.fv && this.fv.ownerOrganizationId)
      this.fvOrganizationId = this.fv.ownerOrganizationId;
  }

  onSubmit() {
    this.ok = true;
    this.busy = true;
    this.fvService
      .addFarToFv(this.fv.id, this.farsToAssociate)
      .pipe(take(1))
      .subscribe((fv) => {
        this.onClose();
      });
  }
  onClose() {
    this.dialog.close();
  }
  onRemove(fv: ForeignVisitor) {
    const filtered = this.farsToAssociate.filter((x) => {
      return fv.id != x.id;
    });
    this.farsToAssociate = filtered;
  }
}
