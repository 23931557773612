import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { StopPropagationDirective } from '@shared/directives/stop-propagation.directive';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { AgePipe } from '@shared/pipes/age.pipe';
import { CurrentPassportPipe } from '@shared/pipes/current-passport.pipe';
import { DaysUntilPipe } from '@shared/pipes/days-until.pipe';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { FullnamePipe } from '@shared/pipes/fullname.pipe';
import { NgPipesModule } from 'ngx-pipes';
import { AvatarComponent } from '../avatar/avatar.component';
import { ScreeningIndicatorComponent } from '../screening/screening-indicator/screening-indicator.component';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-fv-list-item',
  templateUrl: './fv-list-item.component.html',
  styleUrls: ['./fv-list-item.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatListModule,
    NgPipesModule,
    MatIconModule,
    MatTooltipModule,
    MatMenuModule,
    AvatarComponent,
    ScreeningIndicatorComponent,
    FullnamePipe,
    DefaultPipe,
    CurrentPassportPipe,
    StopPropagationDirective,
    RouterLink,
    DaysUntilPipe,
    AgePipe,
  ],
})
export class FvListItemComponent {
  @Input() deletable: boolean = false;
  @Input() item: ForeignVisitor;
  @Input() size: 'xsmall' | 'small' | 'medium' | 'large' = 'medium';
  @Input() showResults: boolean = false;
  @Input() showRemove: boolean = false;
  @Input() showGoTo: boolean = false;
  @Input() showOwningOrg: boolean = true;
  @Input() openNewWindow: boolean = false;
  @Input() highlighted: boolean = false;
  @Input() additionalDocumentsScreeningEligibility: boolean = false;
  @Output() removed = new EventEmitter<ForeignVisitor>();

  openLink(id: string) {
    window.open('/fvs' + id);
  }

  hasDocumentData() {
    if (this.item?.passports && this.item.passports[0]) return true;
    if (this.item?.visas && this.item.visas[0]) return true;
    if (this.item?.diplomaticIds && this.item?.diplomaticIds[0]) return true;
    if (this.item?.dacaIds && this.item.dacaIds[0]) return true;
    if (this.item?.nationalIds && this.item.nationalIds[0]) return true;
    return false;
  }
}
