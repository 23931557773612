import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { CitadelLogoDirective } from '@shared/directives/citadel-logo.directive';
import { AppConfigService } from '@shared/services/app-config.services';

@Component({
  selector: 'app-contact-support-dialog',
  templateUrl: './contact-support-dialog.component.html',
  styleUrls: ['./contact-support-dialog.component.scss'],
  standalone: true,
  imports: [
    CitadelLogoDirective,
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatListModule,
    MatIconModule,
  ],
})
export class ContactSupportDialogComponent {
  supportEmail?: string;
  supportPhone?: string;
  _startTime?: number;
  _endTime?: number;

  get startTime() {
    return new Date().setHours(this._startTime!, 0, 0, 0);
  }
  get endTime() {
    return new Date().setHours(this._endTime!, 0, 0, 0);
  }

  constructor(config: AppConfigService) {
    this.supportEmail = config.get('supportEmail');
    this.supportPhone = config.get('supportPhone');
    this._startTime = config.get('supportStartTime', 7);
    this._endTime = config.get('supportEndTime', 15);
  }

  static openDialog(
    dialog: MatDialog
  ): MatDialogRef<ContactSupportDialogComponent> {
    return dialog.open<ContactSupportDialogComponent>(
      ContactSupportDialogComponent,
      {
        maxWidth: 500,
        autoFocus: false,
      }
    );
  }
}
