import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { AuthService } from '@app/auth/auth.service';
import { AppConfigValue } from '@shared/constants/shared.const';
import { User } from '@shared/models/user.model';
import { AlertService } from '@shared/services/alert.service';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'app-edit-contact-info',
  templateUrl: './edit-contact-info.component.html',
  styleUrls: ['./edit-contact-info.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatProgressSpinnerModule,
  ],
})
export class EditContactInfoComponent {
  profileInfo: User = {};
  busy: boolean = false;

  @AppConfigValue('limitedUserAdmin')
  limitedUserAdmin = false;

  constructor(
    public dialogRef: MatDialogRef<EditContactInfoComponent>,
    private auth: AuthService,
    private userService: UserService,
    private alert: AlertService
  ) {
    const user = this.auth.getUser();
    if (user) {
      this.profileInfo = {
        phone: user.phone,
        mobilePhone: user.mobilePhone,
      };
    }
  }

  async submit() {
    this.busy = true;
    this.userService.updateProfile(this.profileInfo).subscribe(
      (result) => {
        this.auth.userSubject$.next(result);
        this.alert.successAlert(`Contact Info Updated!`);
        this.dialogRef.close(result);
      },
      (err) => (this.busy = false)
    );
  }

  static openDialog(dialog: MatDialog): MatDialogRef<EditContactInfoComponent> {
    return dialog.open<EditContactInfoComponent>(EditContactInfoComponent, {
      width: '530px',
    });
  }
}
