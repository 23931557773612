import { CommonModule } from '@angular/common';
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  Inject,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { ClassificationBannerComponent } from '@shared/cmt/classification-banner.component';
import { ClassifyShowDirective } from '@shared/cmt/classify-show.directive';
import { ClassifyDirective } from '@shared/cmt/classify.directive';
import { ScreeningCodeControlComponent } from '@shared/controls/screening-code-control/screening-code-control.component';
import { ScreeningCodeDirective } from '@shared/directives/screening-code.directive';
import { DerogatoryInformation } from '@shared/models/derogatory-information.model';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-screening-code-dialog',
  templateUrl: './screening-code-dialog.component.html',
  styleUrls: ['./screening-code-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ClassificationBannerComponent,
    ClassifyDirective,
    ClassifyShowDirective,
    ScreeningCodeControlComponent,
    ScreeningCodeDirective,
    MatDialogModule,
    MatButtonModule,
    FormsModule,
  ],
})
export class ScreeningCodeDialogComponent implements AfterContentChecked {
  constructor(
    @Inject(MAT_DIALOG_DATA) public derogCode: DerogatoryInformation,
    private dialogRef: MatDialogRef<ScreeningCodeDialogComponent>,
    private changeDetect: ChangeDetectorRef
  ) {}

  onAddCode(derogCode: DerogatoryInformation) {
    this.dialogRef.close(derogCode);
  }

  ngAfterContentChecked(): void {
    this.changeDetect.detectChanges();
  }

  static openDialog(
    dialog: MatDialog,
    item?: DerogatoryInformation
  ): MatDialogRef<ScreeningCodeDialogComponent, DerogatoryInformation> {
    return dialog.open(ScreeningCodeDialogComponent, { data: cloneDeep(item) });
  }
}
