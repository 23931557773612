import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { SelectGroupComponent } from '@shared/controls/select-group/select-group.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { FAR } from '@shared/models/far.model';
import { Group } from '@shared/models/group.model';
import { FarService } from '@shared/services/far.service';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { take } from 'rxjs';

@Component({
  selector: 'app-add-group-dialog',
  templateUrl: './add-group-dialog.component.html',
  styleUrls: ['./add-group-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    SelectGroupComponent,
    AuthEventsDialogComponent,
    AuthEventsDialogDirective,
    MatDialogModule,
    MatSnackBarModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    FormsModule,
    MatSelectModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
  ],
})
export class AddGroupDialogComponent implements OnInit {
  groupToAssociate: Group = {};
  id: string = '';
  organizationId: string = '';
  busy: boolean = false;
  ok: boolean = false;

  constructor(
    private farService: FarService,
    @Inject(MAT_DIALOG_DATA) private data: FAR,
    private dialog: MatDialogRef<AddGroupDialogComponent>
  ) {}

  ngOnInit(): void {
    this.groupToAssociate = {};
    this.ok = false;
    this.id = this.data.id;

    if (this.data && this.data.ownerOrganizationId)
      this.organizationId = this.data.ownerOrganizationId;
  }

  onSubmit() {
    this.ok = true;
    this.busy = true;

    this.farService
      .addGroupToFar(this.data.id, [this.groupToAssociate.id!])
      .pipe(take(1))
      .subscribe((far) => {
        this.onClose();
      });
  }
  onClose() {
    this.dialog.close();
  }
}
