import { Pipe, PipeTransform } from '@angular/core';
import { ContactPhoneNumber } from '@shared/models/contact-phone-number.model';
import { ForeignVisitorPhoneService } from '../services/foreign-visitor-phone.service';

@Pipe({
  name: 'sortPhones',
  standalone: true,
})
export class SortPhoneNumbersPipe implements PipeTransform {
  constructor(private phoneService: ForeignVisitorPhoneService) {}
  transform(phones: ContactPhoneNumber[]) {
    return this.phoneService.organizePhoneNumbers(phones);
  }
}
