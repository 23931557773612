import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { RolesDirective } from '@shared/directives/roles.directive';
import { Role } from '@shared/models/role';
import { DerogratoryGraphComponent } from './derogratory-graph/derogratory-graph.component';
import { LineGraphComponent } from './line-graph/line-graph.component';
import { OrganizationGraphComponent } from './organizations-graph/organization-graph.component';
import { ScreeningGraphComponent } from './screening-graph/screening-graph.component';
import { HighOnlyEnabledDirective } from '@shared/directives/high-only-enabled.directive';
@Component({
  selector: 'app-graph-container',
  templateUrl: './graph-container.component.html',
  styleUrls: ['./graph-container.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    AuthEventsDialogComponent,
    AuthEventsDialogDirective,
    ScreeningGraphComponent,
    LineGraphComponent,
    DerogratoryGraphComponent,
    OrganizationGraphComponent,
    RolesDirective,
    HighOnlyEnabledDirective,
  ],
})
export class GraphContainerComponent {
  showScreeningGraph: boolean = true;
  showOrgGraph: boolean = true;
  showStatusGraph: boolean = true;
  showDerogGraph: boolean = true;
  Role = Role;
}
