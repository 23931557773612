import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRippleModule } from '@angular/material/core';
import {
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { FnGroupDialogComponent } from '@app/foreign-visitors/fn-group-dialog/fn-group-dialog.component';
import {
  VIEW_DEF_DEFINITIONS,
  VIEW_DEF_KEYS,
} from '@app/user-preference/constants/view-properties.const';
import {
  BaseViewDef,
  ViewType,
} from '@app/user-preference/model/config-view-definitions.model';
import { UserPreferenceFormService } from '@app/user-preference/service/user-preference-form.service';
import { AccountDialogDirective } from '@shared/directives/account-dialog.directive';
import { BulkUploadDirective } from '@shared/directives/bulk-upload.directive';
import { LocationEditDirective } from '@shared/directives/location-edit.directive';
import { OrganizationEditDirective } from '@shared/directives/organization-edit.directive';
import { RolesDirective } from '@shared/directives/roles.directive';
import { ActionButton } from '@shared/models/actionbutton.model';
import { FilterConfig } from '@shared/models/filter-config/filter-config.type';
import { FilterParams } from '@shared/models/filter-params.model';
import { Role } from '@shared/models/role';
import { SortSelect } from '@shared/models/sort-select.model';
import { View } from '@shared/models/view.model';
import { AddressService } from '@shared/services/address.service';
import { AlertService } from '@shared/services/alert.service';
import { AsyncBulkUploadObservable } from '@shared/services/async-bulk-upload.observable';
import { MetricsObservable } from '@shared/services/metrics-observable.service';
import { FileUploadModule } from 'ng2-file-upload';
import { take } from 'rxjs';
import { ActiveSearchDisplayComponent } from '../advanced-search/active-search-display.component';
import { ColumnChooserComponent } from '../column-chooser/column-chooser.component';
import { ConfirmDialogComponent } from '../confirm/confirmdialog.component';
import { FilterComponent } from '../filter/filter.component';
import { SortComponent } from '../sort/sort.component';
import { CollectionView, ViewComponent } from '../view/view.component';

@Component({
  selector: 'app-search-toolbar',
  templateUrl: './search-toolbar.component.html',
  styleUrls: ['./search-toolbar.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatDialogModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    FileUploadModule,
    MatIconModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSortModule,
    MatTooltipModule,
    RouterLink,
    MatRippleModule,
    AccountDialogDirective,
    ActiveSearchDisplayComponent,
    BulkUploadDirective,
    ColumnChooserComponent,
    FilterComponent,
    LocationEditDirective,
    OrganizationEditDirective,
    RolesDirective,
    SortComponent,
    ViewComponent,
  ],
})
export class SearchToolbarComponent implements OnInit, AfterViewInit {
  private readonly route: ActivatedRoute = inject(ActivatedRoute);
  private readonly userPrefFormService: UserPreferenceFormService = inject(
    UserPreferenceFormService
  );
  @ViewChild('appFilter') appFilter: FilterComponent;
  @ViewChild('slideToggle', { read: ElementRef }) element:
    | ElementRef
    | undefined;
  @Input() filterPlaceholder: string = 'Given Name, Surname';
  @Input() filterParams: FilterParams;
  @Input() filterFields: FilterConfig[] = [];
  sortFields: SortSelect[] = [];
  @Input() actionButtons: ActionButton[];
  @Input() collectionViews: View[];
  @Input() compactCard: boolean = false;
  @Input() view: ViewType = 'table';
  @Input() loading: boolean = false;
  @Input() viewDefKey: VIEW_DEF_KEYS;
  @Output() viewChange = new EventEmitter<CollectionView>();
  @Output() filterParamsChange = new EventEmitter<FilterParams>();
  @Output() groupSelectChange = new EventEmitter<any>();
  viewDef: BaseViewDef;
  _hideViewToggle: boolean = false;
  showAllGroups: boolean = false;
  groupSelectionMode: boolean = false;
  VIEW_DEF_DEFINITIONS = VIEW_DEF_DEFINITIONS;
  Role = Role;
  userId: string = '';
  metricsObservable$: MetricsObservable;

  @Input() set hideViewToggle(value: any) {
    this._hideViewToggle = coerceBooleanProperty(value);
  }
  get hideViewToggle(): boolean {
    return this._hideViewToggle;
  }

  constructor(
    private _MetricsObservable: MetricsObservable,
    private authService: AuthService,
    private dialog: MatDialog,
    private address: AddressService,
    private poll: AsyncBulkUploadObservable,
    private alert: AlertService
  ) {}
  ngOnInit(): void {
    this.viewDefKey = this.route.snapshot?.data?.viewDefKey
      ? this.route.snapshot?.data?.viewDefKey
      : this.viewDefKey;
    if (!this.viewDefKey)
      throw `viewDefKey was not provided as an input or through the route data for ${SearchToolbarComponent.name}`;
    const view: ViewType = this.route.snapshot.data.userPrefFormGroup?.get(
      `${this.viewDefKey}.viewType`
    )?.value as ViewType;
    this.viewDef = VIEW_DEF_DEFINITIONS[this.viewDefKey];
    this.sortFields = this.userPrefFormService.generateSortFields(this.viewDef);
    if (view) {
      this.viewChange.emit(view);
    }
  }

  ngAfterViewInit() {
    //Remove Slide Toggle Icons
    if (this.element) {
      this.element.nativeElement
        .querySelector('.mdc-switch__icon--on')
        ?.firstChild.setAttribute('d', '');
      this.element.nativeElement
        .querySelector('.mdc-switch__icon--off')
        ?.firstChild.setAttribute('d', '');
    }
  }

  onViewChange(view: CollectionView) {
    this.viewChange.emit(view);
  }

  onFilterParamChange(filterParams: FilterParams) {
    this.filterParams = filterParams;
    this.refresh();

    //  this.metricsObservable$.send('update');
  }

  refresh() {
    this.filterParamsChange.emit(this.filterParams);
  }

  selectFvGroup() {
    this.groupSelectionMode = true;
    this.groupSelectChange.emit(this.groupSelectionMode);
  }

  cancelSelectFvGroup() {
    this.groupSelectionMode = false;
    this.groupSelectChange.emit(this.groupSelectionMode);
  }

  createFvGroup() {
    this.groupSelectChange.emit(null);
  }

  createEmptyGroup() {
    let dialogRef: MatDialogRef<FnGroupDialogComponent> =
      this.dialog.open<FnGroupDialogComponent>(FnGroupDialogComponent, {
        data: null,
      });
    dialogRef.afterClosed().subscribe(() => {
      this.refresh();
    });
  }

  openConfirmationDialog(): MatDialogRef<ConfirmDialogComponent> {
    let data = {
      icon: 'alert-octagon',
      color: 'warn',
      title: `ARE YOU SURE?`,
      message: `This will attempt to normalize all locations and assign them latitude and longitude coordinates.`,
      performAction: () => this.normalizeLocations(),
      confirmText: 'Confirm',
    };

    return this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data,
    });
  }

  normalizeLocations() {
    this.address
      .resolveLocations()
      .pipe(take(1))
      .subscribe(() => {
        this.alert.infoAlert(
          'Location Normalization starting - Check Notifications for Status.'
        );
        this.poll.startPolling(true);
      });
  }
}
