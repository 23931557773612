import { CommonModule } from '@angular/common';
import { Component, inject, Inject, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthService } from '@app/auth/auth.service';
import { CitadelIconModule } from '@app/citadel-icon/citadel-icon.module';
import { ClassificationBannerComponent } from '@shared/cmt/classification-banner.component';
import { ClassifyShowDirective } from '@shared/cmt/classify-show.directive';
import { ClassifyDirective } from '@shared/cmt/classify.directive';
import { ConfirmDialogComponent } from '@shared/components/confirm/confirmdialog.component';
import { ResourceUserDetailsComponent } from '@shared/components/resource-user-details/resource-user-details.component';
import { DebounceClickDirective } from '@shared/directives/debounce-click.directive';
import { RolesDirective } from '@shared/directives/roles.directive';
import { FilterParams } from '@shared/models/filter-params.model';
import { NetworkDomain } from '@shared/models/network-domain';
import { OrgLocation } from '@shared/models/orglocation.model';
import { Role } from '@shared/models/role';
import { User } from '@shared/models/user.model';
import { AppConfigService } from '@shared/services/app-config.services';
import { OrgLocationService } from '@shared/services/orglocation.service';
import { cloneDeep } from 'lodash';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { take } from 'rxjs/operators';
import { LocationFormComponent } from '../location-form/location-form.component';
@Component({
  selector: 'app-location-dialog',
  templateUrl: './location-dialog.component.html',
  styleUrls: ['./location-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ClassificationBannerComponent,
    LocationFormComponent,
    ResourceUserDetailsComponent,
    DebounceClickDirective,
    CitadelIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatTooltipModule,
    RolesDirective,
    FormsModule,
    ReactiveFormsModule,
    ClassifyShowDirective,
    ClassifyDirective,
    NgxMatSelectSearchModule,
  ],
})
export class LocationDialogComponent implements OnInit {
  private readonly configService = inject(AppConfigService);
  public Role = Role;
  location: OrgLocation = {};
  user: User | null = this.authService.getUser();
  @ViewChild('locationForm') locationForm: LocationFormComponent;

  currentNetWork = this.configService.get('highEnvFeatures')
    ? NetworkDomain.J
    : NetworkDomain.U;

  constructor(
    @Inject(MAT_DIALOG_DATA) public id: string | null,
    public locationDialog: MatDialogRef<LocationDialogComponent>,
    private locationService: OrgLocationService,
    public authService: AuthService,
    public dialog: MatDialog
  ) {}

  public disabledSubmit: boolean = false;

  ngOnInit(): void {
    this.disabledSubmit = false;
    if (this.id) {
      this.getLocation(this.id);
    } else {
      this.initNewLocation();
    }
  }

  displayResourceDetails(): boolean {
    if (this.user?.roles) {
      if (this.user?.roles.includes(Role.sv_admin) && this.id) {
        return true;
      }
      return false;
    }
    return false;
  }

  initNewLocation() {
    this.location.organization = this.user?.organization;
    this.location.networkDomain = this.currentNetWork;
  }

  getLocation(id: string) {
    this.locationService
      .get(id)
      .pipe(take(1))
      .subscribe((data) => {
        this.location = cloneDeep(data);
      });
  }

  // Handles location changes emitted from from component
  onLocationChange(location: OrgLocation) {
    this.location = location;
  }

  hasRequiredData() {
    if (
      this.location &&
      this.location.address &&
      this.location.address.line1 &&
      this.location.address.city &&
      this.location.address.stateProvince &&
      this.location.address.countryCode &&
      this.location.address.postalCode
    ) {
      return true;
    }

    if (
      this.location &&
      this.location.address &&
      (this.location.address.line1 ||
        this.location.address.line2 ||
        this.location.address.city ||
        this.location.address.stateProvince ||
        this.location.address.countryCode ||
        this.location.address.postalCode)
    ) {
      return false;
    }
    return false;
  }

  onSubmit() {
    if (
      !this.id &&
      this.location.address?.longitude &&
      this.location.address?.latitude
    ) {
      let filterParams: FilterParams = new FilterParams({
        pageNum: 0,
        pageSize: 20,
        sortBy: 'name',
        direction: 'ASC',
        longitude: this.location.address?.longitude,
        latitude: this.location.address?.latitude,
      });
      this.locationService
        .find(filterParams)
        .pipe(take(1))
        .subscribe((res) => {
          if (res.content.length > 0)
            this.openConfirmationDialog(res.content.length);
          else this.createLocation();
        });
    } else this.createLocation();
  }

  createLocation() {
    this.disabledSubmit = true;
    this.locationService
      .save(this.location)
      .pipe(take(1))
      .subscribe((data) => {
        this.locationDialog.close(data);
      });
  }

  openConfirmationDialog(count: number): MatDialogRef<ConfirmDialogComponent> {
    let data = {
      icon: 'alert-octagon',
      color: 'warn',
      title: `ARE YOU SURE?`,
      message:
        'There are ' +
        count +
        ` locations that already exist with this address.`,
      performAction: () => this.createLocation(),
      confirmText: 'Confirm',
    };

    return this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data,
    });
  }

  static openDialog(
    dialog: MatDialog,
    location?: OrgLocation
  ): MatDialogRef<LocationDialogComponent> {
    return dialog.open<LocationDialogComponent>(LocationDialogComponent, {
      data: location,
      width: '750px',
    });
  }
}
