import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { FnCardComponent } from '@app/foreign-nationals/fn-card/fn-card.component';
import { FvAdvancedSearch } from '@app/foreign-visitors/dashboard/fv-advanced-search';
import { FvTableComponent } from '@app/foreign-visitors/dashboard/fv-table/fv-table.component';
import { FV_TAB } from '@app/user-preference/config/fv.config.const';
import { ViewType } from '@app/user-preference/model/config-view-definitions.model';
import { ActiveSearchDisplayComponent } from '@shared/components/advanced-search/active-search-display.component';
import { FilterComponent } from '@shared/components/filter/filter.component';
import { NoResultFoundComponent } from '@shared/components/no-result-found/no-result-found.component';
import { PaginatorComponent } from '@shared/components/paginator/paginator.component';
import { SearchToolbarComponent } from '@shared/components/search-toolbar/search-toolbar.component';
import { RolesDirective } from '@shared/directives/roles.directive';
import { ScreeningResult } from '@shared/enums/screening-result.enum';
import { ActionButton } from '@shared/models/actionbutton.model';
import { FilterParams } from '@shared/models/filter-params.model';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { NetworkDomain } from '@shared/models/network-domain';
import { PageableCollection } from '@shared/models/pageable-collection.model';
import { FVEditRoles } from '@shared/models/role-permissions';
import { AgePipe } from '@shared/pipes/age.pipe';
import { CurrentPassportPipe } from '@shared/pipes/current-passport.pipe';
import { FullnamePipe } from '@shared/pipes/fullname.pipe';
import { AlertService } from '@shared/services/alert.service';
import { AppConfigService } from '@shared/services/app-config.services';
import { FnGroupDialogComponent } from '../../fn-group-dialog/fn-group-dialog.component';

const actionButtons: ActionButton[] = [
  {
    label: 'Bulk Upload',
    bulkUpload: 'FV',
    menuIcon: 'input',
    roles: FVEditRoles,
    color: 'primary',
  },
  {
    label: 'New FN',
    routerLink: '/fv',
    menuIcon: 'note_add',
    roles: FVEditRoles,
    color: 'primary',
  },
];

@Component({
  selector: 'app-grid-collection',
  templateUrl: './grid-collection.component.html',
  styleUrls: ['./grid-collection.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RolesDirective,
    FullnamePipe,
    CurrentPassportPipe,
    AgePipe,
    SearchToolbarComponent,
    FilterComponent,
    ActiveSearchDisplayComponent,
    MatDividerModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    MatSnackBarModule,
    MatProgressBarModule,
    FnCardComponent,
    PaginatorComponent,
    NoResultFoundComponent,
    FvTableComponent,
  ],
})
export class GridCollectionComponent implements OnInit {
  @ViewChild('trigger') trigger: MatMenuTrigger;
  @ViewChild('searchToolbar') searchToolbar: SearchToolbarComponent;
  @Input() pageableCollection: PageableCollection<ForeignVisitor>;
  @Input() loading: boolean = false;
  @Output() emitAdd = new EventEmitter<ForeignVisitor>();
  @Output() emitRemove = new EventEmitter<ForeignVisitor>();
  @Input() position: 'fixed' | 'sticky' = 'sticky';
  @Input() filterParams: FilterParams = new FilterParams({});
  @Output() filterParamsChange = new EventEmitter<FilterParams>();
  private readonly route: ActivatedRoute = inject(ActivatedRoute);
  view: ViewType = this.route.snapshot.data.userPreference?.get(
    `${FV_TAB.viewDefKey}.viewType`
  )?.value as ViewType;
  headerActionButtons: ActionButton[] = actionButtons;
  selectDisplayed: boolean = false;
  ScreeningResult = ScreeningResult;
  farScreeningEligibility: boolean = false;
  filterFields = FvAdvancedSearch;
  groupSelectionMode: boolean = false;
  currentNetwork: NetworkDomain = NetworkDomain.U;
  label: string = FV_TAB.viewName;
  additionalDocumentsScreeningEligibility: boolean = false;

  constructor(
    private config: AppConfigService,
    private dialog: MatDialog,
    private alert: AlertService
  ) {}

  ngOnInit(): void {
    this.farScreeningEligibility =
      this.config.get('farScreeningEligibility') || false;

    this.additionalDocumentsScreeningEligibility =
      this.config.get('additionalDocumentsScreeningEligibility') || false;

    if (this.config.get('highEnvFeatures'))
      this.currentNetwork = NetworkDomain.J;
    this.route.queryParams.subscribe((params) => {
      if (JSON.stringify(this.filterParams) !== JSON.stringify(params)) {
        let newParams = new FilterParams({ ...this.filterParams, ...params });
        this.onFilterParamChange(newParams);
      }
    });
  }

  onViewChange(view: ViewType) {
    this.view = view;
  }

  onFilterParamChange(filterParams: FilterParams) {
    this.filterParamsChange.emit(filterParams);
  }

  hideFilter() {
    this.selectDisplayed = !this.selectDisplayed;
  }

  emitAddEvent(fv: ForeignVisitor) {
    this.emitAdd.emit(fv);
    this.trigger.closeMenu();
  }

  groupModeChange(change: boolean) {
    if (change !== null) this.groupSelectionMode = change;
    if (!this.groupSelectionMode) this.deselectAllFns();

    if (change === null) {
      if (this.isFnSelected()) {
        let dialogRef: MatDialogRef<FnGroupDialogComponent> =
          this.dialog.open<FnGroupDialogComponent>(FnGroupDialogComponent, {
            data: this.pageableCollection.content,
          });
        dialogRef.afterClosed().subscribe((result) => {
          this.groupSelectionMode = false;
          this.searchToolbar.groupSelectionMode = false;
          this.deselectAllFns();
        });
      } else {
        this.alert.infoAlert(
          'At least one Foreign National must be selected to create a group.'
        );
      }
    }
  }

  deselectAllFns() {
    for (let fn of this.pageableCollection.content) fn.selected = false;
  }

  isFnSelected() {
    for (let fn of this.pageableCollection.content)
      if (fn.selected) return true;
  }
}
