import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EmailDialogDirective } from '@shared/directives/email-dialog.directive';
import { ContactEmail, emailIcons } from '@shared/models/contact-email.model';
import { NgPipesModule } from 'ngx-pipes';
import { FvPhoneDisplayComponent } from '../fv-phone-display/fv-phone-display.component';
import { DefaultPipe } from '@shared/pipes/default.pipe';

@Component({
  selector: 'app-fv-email-display',
  templateUrl: './fv-email-display.component.html',
  styleUrls: ['./fv-email-display.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    NgPipesModule,
    DefaultPipe,
    MatListModule,
    MatTooltipModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatMenuModule,
    MatListModule,
    MatSidenavModule,
    EmailDialogDirective,
    FvPhoneDisplayComponent,
  ],
})
export class FvEmailDisplayComponent {
  @Input() editable?: boolean = false;
  @Input() emails: ContactEmail[];
  @Input() fvId: string;
  emailIcons = emailIcons;
}
