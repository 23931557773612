import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EmptyStateComponent } from '@shared/components/empty-state/empty-state.component';
import { FlagIconDirective } from '@shared/directives/flag-icon.directive';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { cloneDeep } from 'lodash';
import { NatlIdDialogComponent } from '../fvidentification/natl-id-dialog/natl-id-dialog/natl-id-dialog.component';
import { CountryPipe } from '@shared/pipes/country.pipe';
import { DefaultPipe } from '@shared/pipes/default.pipe';

@Component({
  selector: 'app-fvview-national-id',
  templateUrl: './fvview-national-id.component.html',
  styleUrls: ['./fvview-national-id.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatBadgeModule,
    MatExpansionModule,
    MatDividerModule,
    MatDialogModule,
    MatSnackBarModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatTooltipModule,
    MatMenuModule,
    EmptyStateComponent,
    DefaultPipe,
    CountryPipe,
    FlagIconDirective,
  ],
})
export class FvviewNationalIdComponent {
  @Input() deletable?: boolean = false;
  @Input() editable?: boolean = true;
  @Input() hasDocument: boolean = false;
  @Input() fv: ForeignVisitor;
  @Input() additionalDocumentsScreeningEligibility: boolean = false;

  constructor(public dialog: MatDialog) {}

  openNatlIdDialog(id?: string) {
    const dialogConfig: MatDialogConfig = {
      data: { fv: cloneDeep(this.fv), id: id },
      width: '800px',
    };
    this.dialog.open(NatlIdDialogComponent, dialogConfig);
  }
}
