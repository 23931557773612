import { CommonModule } from '@angular/common';
import { Component, forwardRef, Input, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgForm,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { BaseControlComponent } from '@shared/controls/base-control.component';
import { EmailControlComponent } from '@shared/controls/email-control/email-control.component';
import { PhoneNumberControlComponent } from '@shared/controls/phone-number-component/phone-number-control.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { ContactEmail } from '@shared/models/contact-email.model';
import { ContactPhoneNumber } from '@shared/models/contact-phone-number.model';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['contact.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ContactComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ContactComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AuthEventsDialogComponent,
    AuthEventsDialogDirective,
    EmailControlComponent,
    PhoneNumberControlComponent,
  ],
})
export class ContactComponent
  extends BaseControlComponent<ForeignVisitor>
  implements Validator
{
  @ViewChild('contactForm')
  contactForm: NgForm;
  appearance: MatFormFieldAppearance = 'outline';
  onValidationChange: any = () => {};
  @Input() fvEmail: ContactEmail = {};
  @Input() fvPhone: ContactPhoneNumber;

  constructor() {
    super();
  }

  writeValue(_value: ForeignVisitor) {
    super.writeValue(_value);
    this.value = _value || {};
  }

  validate(_control: AbstractControl): ValidationErrors | null {
    return this.contactForm?.valid ? null : { contact: true };
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }
}
