import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { AlertService } from '@shared/services/alert.service';
import { EMPTY, catchError } from 'rxjs';
import { UserPreference } from '../model/user-preference.model';
import { UserPreferenceService } from '../service/user-preference.service';

export const userPreferenceResolver: ResolveFn<UserPreference | null> = (
  route,
  state
) => {
  const userPreferenceService: UserPreferenceService = inject(
    UserPreferenceService
  );
  const alertService: AlertService = inject(AlertService);
  return userPreferenceService.getUserPrefs(true).pipe(
    catchError((err: Error) => {
      alertService.errorAlert('Unable to load user preference ');
      return EMPTY;
    })
  );
};
