import { CommonModule } from '@angular/common';
import { Component, forwardRef, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgForm,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BaseControlComponent } from '@shared/controls/base-control.component';
import { Sponsor } from '@shared/models/sponsor.model';
import { PhoneValidator } from '@shared/validators/phone-number.validator';

@Component({
  selector: 'app-sponsor',
  templateUrl: './sponsor.component.html',
  styleUrls: ['./sponsor.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SponsorComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SponsorComponent),
      multi: true,
    },
  ],
})
export class SponsorComponent
  extends BaseControlComponent<Sponsor>
  implements Validator
{
  value: Sponsor = {};
  @ViewChild('sponsorForm')
  sponsorForm: NgForm;

  onValidationChange: any = () => {};
  PhoneValidation = PhoneValidator.getPattern();

  constructor() {
    super();
  }

  writeValue(_value: Sponsor) {
    super.writeValue(_value);
    this.value = this.value || {};
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.sponsorForm?.valid ? null : { sponsor: true };
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }

  isRequired() {
    if (this.value.givenName && this.value.surname && this.value.email) {
      this.sponsorForm?.form?.setErrors(null);
      return true;
    }

    if (
      this.value.givenName ||
      this.value.surname ||
      this.value.phone ||
      this.value.email
    ) {
      this.sponsorForm?.form?.setErrors({ incorrect: true });
      return true;
    }
    this.sponsorForm?.form?.setErrors(null);
    return false;
  }
}
