import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BulkUploadComponent } from '@shared/components/bulk-upload/bulk-upload.component';
import { BulkUploadType } from '@shared/models/bulk-upload-type';

@Directive({
  selector: '[appBulkUpload]',
  standalone: true,
})
export class BulkUploadDirective {
  @Input() appBulkUpload?: BulkUploadType;
  @Input() farId: any[] = [];

  @Output() appBulkUploaded = new EventEmitter<any>();

  constructor(private dialog: MatDialog) {}

  @HostListener('click', ['$event'])
  onClick() {
    if (!this.appBulkUpload) return;
    const dialog = BulkUploadComponent.openDialog(this.dialog, {
      type: this.appBulkUpload,
      farId: this.farId,
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) this.appBulkUploaded.emit(result);
    });
  }
}
