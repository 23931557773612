import { CommonModule } from '@angular/common';
import {
  Component,
  Directive,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { EmailControlComponent } from '@shared/controls/email-control/email-control.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { ForeignVisitorGroup } from '@shared/models/foreign-visitor-group.model';
import { GroupService } from '@shared/services/groups.service';
import { NgPipesModule } from 'ngx-pipes';
import { take } from 'rxjs';

@Component({
  selector: 'app-fn-group-dialog',
  templateUrl: './fn-group-dialog.component.html',
  styleUrls: ['./fn-group-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgPipesModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    AuthEventsDialogComponent,
    AuthEventsDialogDirective,
    EmailControlComponent,
  ],
})
export class FnGroupDialogComponent {
  value: ForeignVisitorGroup = {};
  busy: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public groupService: GroupService,
    public groupDialog: MatDialogRef<FnGroupDialogComponent>
  ) {}

  save() {
    this.busy = true;
    this.value.foreignVisitors = this.getSelectedFNs();
    this.groupService
      .save(this.value)
      .pipe(take(1))
      .subscribe((res) => {
        this.busy = false;
        this.groupDialog.close();
      });
  }

  getSelectedFNs() {
    let selected: any = [];
    if (this.data) {
      for (let fn of this.data) {
        if (fn.selected) selected.push({ id: fn.id });
      }
    }
    return selected;
  }

  static openDialog(
    dialog: MatDialog,
    item: any
  ): MatDialogRef<FnGroupDialogComponent> {
    return dialog.open<FnGroupDialogComponent>(FnGroupDialogComponent, {
      data: item,
      width: '800px',
    });
  }
}
