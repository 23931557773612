import { CommonModule, formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ScreeningService } from '@shared/services/screening.service';
import { Color, NgxChartsModule, ScaleType } from '@swimlane/ngx-charts';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-line-graph',
  templateUrl: './line-graph.component.html',
  styleUrls: ['./line-graph.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatDatepickerModule,
    NgxChartsModule,
    MatProgressSpinnerModule,
    FormsModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
  ],
})
export class LineGraphComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject<void>();
  multiline: any[];
  loading: boolean = false;
  startDate: string = '';
  endDate: string = '';
  today: Date = new Date();
  datepickerUnit: 'DAY' | 'WEEK' | 'MONTH' = 'DAY';
  datepickerUnitRange: any = {
    DAY: 31,
    WEEK: 180,
    MONTH: 365,
  };
  dateRangeExceeded: boolean = false;
  display: boolean = true;

  screeningStatusDataPoints: any = [
    'totalCount',
    'newStatusCount',
    'inProcessStatusCount',
    'completedStatusCount',
    'errorStatusCount',
  ];

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = true;
  showLegend: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Date';
  showYAxisLabel: boolean = true;
  yAxisLabel: string = 'Count';
  legendTitle: string = 'Screening';
  orgLegendTitle: string = 'Organizations';
  timeline: boolean = true;

  lineColorScheme: Color = {
    name: 'lineScheme',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#00315a', '#6c757d', '#00acc1', '#4caf50', '#b71c1c'],
  };

  constructor(private screenings: ScreeningService) {}

  ngOnInit() {
    this.endDate = formatDate(new Date(), 'yyyy-MM-dd', 'en-US', 'GMT');
    let calcStart = new Date();
    calcStart.setDate(calcStart.getDate() - 7);
    this.startDate = formatDate(calcStart, 'yyyy-MM-dd', 'en-US', 'GMT');

    this.getScreeningResultsMetrics();
  }

  getScreeningResultsMetrics() {
    this.startDate = formatDate(
      new Date(this.startDate),
      'yyyy-MM-dd',
      'en-US',
      'GMT'
    );
    this.endDate = formatDate(
      new Date(this.endDate),
      'yyyy-MM-dd',
      'en-US',
      'GMT'
    );
    if (this.dateRangeValidCheck()) {
      this.loading = true;
      this.screenings
        .findFvsMetrics(this.startDate, this.endDate, this.datepickerUnit)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res) => {
          this.multiline = this.translatetoLineGraph(res);
          this.loading = false;
        });
    }
  }

  dateRangeValidCheck() {
    this.dateRangeExceeded = false;
    let maxRange = this.datepickerUnitRange[this.datepickerUnit];
    let futureDate = new Date(this.startDate);
    futureDate.setDate(futureDate.getDate() + maxRange);

    if (new Date(this.endDate) < futureDate) return true;

    this.dateRangeExceeded = true;
    return false;
  }

  translatetoLineGraph(metrics: any) {
    let arrayData = Object.keys(metrics);
    arrayData.sort((a, b) => (a > b ? 1 : -1));

    let totalFvs: LineGraphItem = {
      name: 'Total FNs',
      series: [],
    };
    let newCount: LineGraphItem = {
      name: 'New',
      series: [],
    };
    let inProcess: LineGraphItem = {
      name: 'In Process',
      series: [],
    };
    let completed: LineGraphItem = {
      name: 'Completed',
      series: [],
    };
    let errors: LineGraphItem = {
      name: 'Errors',
      series: [],
    };

    for (let data of arrayData) {
      totalFvs['series'].push({
        name: data,
        value: metrics[data].totalCount,
      });

      newCount['series'].push({
        name: data,
        value: metrics[data].newStatusCount,
      });

      inProcess['series'].push({
        name: data,
        value: metrics[data].inProcessStatusCount,
      });

      completed['series'].push({
        name: data,
        value: metrics[data].completedStatusCount,
      });

      errors['series'].push({
        name: data,
        value: metrics[data].errorStatusCount,
      });
    }

    return [totalFvs, newCount, inProcess, completed, errors];
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

interface LineGraphItem {
  name: string;
  series: any[];
}
