import { Routes } from '@angular/router';
import { GROUP_TAB } from '@app/user-preference/config/group.config.const';
import { userPreferenceFormResolver } from '@app/user-preference/resolvers/user-preference-form.resolver';
import { AuthGuard } from '@shared/helpers/auth.guard';
import { GroupViewRoles } from '@shared/models/role-permissions';
import { GroupsComponent } from './groups/groups.component';

export const GROUP_ROUTES: Routes = [
  {
    path: 'groups',
    title: 'Groups',
    component: GroupsComponent,
    canActivate: [AuthGuard],
    data: {
      roles: GroupViewRoles,
      viewDefKey: GROUP_TAB.viewDefKey,
    },
    resolve: {
      userPrefFormGroup: userPreferenceFormResolver,
    },
  },
];
