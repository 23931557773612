import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { ClassifyShowDirective } from '@shared/cmt/classify-show.directive';
import { ClassifyDirective } from '@shared/cmt/classify.directive';
import { ClassificationModel } from '@shared/models/classification.model';

@Component({
  selector: 'app-stepper-footer',
  templateUrl: './stepper-footer.component.html',
  styleUrls: ['./stepper-footer.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    MatToolbarModule,
    MatTooltipModule,
    MatCardModule,
    MatButtonModule,
    MatStepperModule,
    MatIconModule,
    MatProgressSpinnerModule,
    ClassifyDirective,
    ClassifyShowDirective,
  ],
})
export class StepperFooterComponent {
  @Input() stepper: MatStepper;
  @Input() form: NgForm;
  @Input() parentRoute: string;
  @Input() classificationModel: ClassificationModel;
  @Output() classificationModelChange = new EventEmitter<ClassificationModel>();

  @Output() emitSubmit = new EventEmitter();
  public busy: boolean = false;
}
