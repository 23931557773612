import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FieldComponent } from '@shared/components/field/field.component';
import { FAR } from '@shared/models/far.model';
import { DaysUntilPipe } from '@shared/pipes/days-until.pipe';

@Component({
  selector: 'app-far-metadata',
  templateUrl: './far-metadata.component.html',
  styleUrls: ['./far-metadata.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FieldComponent,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    FieldComponent,
    MatTooltipModule,
    MatBadgeModule,
    DaysUntilPipe,
  ],
})
export class FarMetadataComponent {
  @Input() far: FAR;
  @Input() tableDisplay: boolean = false;

  get createdByFullName() {
    const givenName = this.far.foreignAccessRequestMetadata?.createdByGivenName;
    const surName = this.far.foreignAccessRequestMetadata?.createdBySurname;
    return `${givenName} ${surName}`;
  }

  get owningOrgShortName() {
    return this.far.foreignAccessRequestMetadata?.owningOrganizationShortName!;
  }

  get owningOrgName() {
    return this.far.foreignAccessRequestMetadata?.owningOrganizationName!;
  }

  get networkDomain() {
    return this.far.networkDomain!;
  }

  get comments() {
    return this.far.commentCount!;
  }

  get attachmentCount() {
    return this.far.attachmentCount!;
  }

  get deletionDate() {
    return this.far.deletionDate!;
  }
}
