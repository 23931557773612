import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[timeline-content-tmp]',
  standalone: true,
})
export class TimelineContentTemplateDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({
  selector: '[timeline-badge]',
  standalone: true,
})
export class TimelineBadgeDirective {
  constructor(public tempRef: TemplateRef<any>) {}
}
