import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Crud } from '@shared/models/crud.decoration';
import { OrgLocation } from '@shared/models/orglocation.model';
import { CrudService } from '@shared/services/crud.service';
import { pick } from 'lodash';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AddressService } from './address.service';

@Injectable({ providedIn: 'root' })
@Crud({
  apiUrl: `${environment.apiUrl}/organizationLocations`,
  hasIdPathUpdate: false,
  entity: 'Location',
})
export class OrgLocationService extends CrudService<OrgLocation> {
  constructor(http: HttpClient, public addressService: AddressService) {
    super(http);
  }

  /**
   * Pre-existing locations require their address be saved separately, then the
   * location be saved. This picks out the location, calls the Address Service
   * then saves the location. If for some reason there is no address, the location
   * is then saved via the Crud Service like normal.
   *
   * @param location OrgLocation - Location that's being saved
   * @returns a new OrgLocation, potentially with an address
   */
  saveWithAddress(location: OrgLocation): Observable<OrgLocation> {
    const newAddress = pick(location, 'address').address;
    return newAddress
      ? this.addressService.save(newAddress).pipe(
          switchMap((address) => {
            const assocLocation: OrgLocation = {
              ...location,
              address: { ...address },
            };
            return super.save(assocLocation);
          })
        )
      : super.save(location);
  }
}
