import { CommonModule } from '@angular/common';
import {
  Component,
  DoCheck,
  EventEmitter,
  Input,
  IterableDiffer,
  IterableDiffers,
  Output,
} from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PassportDialogComponent } from '@app/foreign-visitors/fv/passport/passport-dialog.component';
import { FlagIconDirective } from '@shared/directives/flag-icon.directive';
import { ConfirmDialogData } from '@shared/models/confirm-dialog-data.model';
import { CrudChangeEvent } from '@shared/models/crud-change-event';
import { CrudChangeType } from '@shared/models/crud-change-type.enum';
import { Passport } from '@shared/models/passport.model';
import { CountryPipe } from '@shared/pipes/country.pipe';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { MilitaryDatePipe } from '@shared/pipes/military-date.pipe';
import { AlertService } from '@shared/services/alert.service';
import { PassportService } from '@shared/services/passport.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
@Component({
  selector: 'app-fvview-passports',
  templateUrl: './fvview-passports.component.html',
  styleUrls: ['./fvview-passports.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatBadgeModule,
    MatButtonModule,
    MatDividerModule,
    MatExpansionModule,
    MatIconModule,
    MatTooltipModule,
    MilitaryDatePipe,
    CountryPipe,
    DefaultPipe,
    FlagIconDirective,
  ],
})
export class FvviewPassportsComponent implements DoCheck {
  private _iterableDiffer: IterableDiffer<Passport>;
  @Input() deletable?: boolean = false;
  @Input() editable?: boolean = true;
  @Input() showActions = false;
  @Input() header = 'Passport Details';
  @Input() public parentId: string;
  @Input() hasDocument: boolean = false;
  @Input() additionalDocumentsScreeningEligibility: boolean = false;
  private _data: Passport[];
  @Input()
  set passports(data: Passport[]) {
    this._data = data;
  }

  get passports(): Passport[] {
    return this._data;
  }

  @Output()
  passportChange = new EventEmitter<Passport[]>();
  refresh$: Observable<CrudChangeEvent<Passport>> =
    this.passportService.changeEvent$.pipe(
      startWith({} as CrudChangeEvent<Passport>)
    );

  passports$ = this.refresh$.pipe(
    map((refresh: CrudChangeEvent<Passport>) => {
      if (refresh.type === CrudChangeType.delete)
        this.removePassportRecord(refresh.modelId as string);
      if (refresh.type === CrudChangeType.create)
        this.addPassportRecord(refresh.model as Passport);
      if (refresh.type === CrudChangeType.update)
        this.updatePassportRecord(refresh.model as Passport);
      this.passportChange.emit(this.passports);
      return this.passports;
    })
  );

  constructor(
    private iterableDiffers: IterableDiffers,
    private alertService: AlertService,
    private dialog: MatDialog,
    private passportService: PassportService
  ) {
    this._iterableDiffer = this.iterableDiffers.find([]).create();
  }

  ngDoCheck() {
    let changes = this._iterableDiffer.diff(this._data);
    if (changes) {
      this.passports = this._data;
    }
  }

  /**
   *
   * @param passport Passport to be removed
   */
  removePassport(passport: Passport) {
    let deleteData: ConfirmDialogData = {
      title: `Remove Passport #${passport.number}`,
      message: `Are you sure? This action cannot be undone.`,
      performAction: () => {
        return this.passportService.delete(passport.id);
      },
    };
    this.alertService.confirmDelete(deleteData);
  }

  removePassportRecord(id: string) {
    if (this.passports) {
      this.passports = this.passports.filter((x) => x.id !== id);
    }
  }

  createPassport() {
    const linkedPassport: Passport = { foreignVisitor: { id: this.parentId } };
    this.openPassportDialog(linkedPassport);
  }

  addPassportRecord(passportRecord: Passport) {
    if (this.passports) {
      this.passports = [...this.passports, passportRecord];
    }
  }

  updatePassport(passport: Passport) {
    const linkedPassport: Passport = {
      ...passport,
      foreignVisitor: { id: this.parentId },
    };
    this.openPassportDialog(linkedPassport);
  }

  updatePassportRecord(passportRecord: Passport) {
    if (this.passports) {
      const index = this.passports.findIndex((passport) => {
        return passport.id === passportRecord.id;
      });
      this.passports[index] = passportRecord;
      this.passports = [...this.passports];
    }
  }

  /**
   *
   * @param passport Passport to be modified
   */
  openPassportDialog(passport: Passport) {
    this.dialog.open(PassportDialogComponent, {
      autoFocus: false,
      data: { data: passport, editable: this.editable },
      width: '1000px',
    });
  }
}
