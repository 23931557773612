import { CommonModule } from '@angular/common';
import { Component, Injector, Input, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ContactEditDirective } from '@shared/directives/contact-edit.directive';
import { ContactType } from '@shared/enums/contact-type.enum';
import { Contact } from '@shared/models/contact.model';
import { FilterParams } from '@shared/models/filter-params.model';
import { PageableCollection } from '@shared/models/pageable-collection.model';
import { CountryPipe } from '@shared/pipes/country.pipe';
import { FullnamePipe } from '@shared/pipes/fullname.pipe';
import { ContactService } from '@shared/services/contact.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseControlComponent } from '../base-control.component';

@Component({
  selector: 'contact-select',
  templateUrl: './contact-select.component.html',
  styleUrls: ['./contact-select.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    ContactEditDirective,
    CountryPipe,
    FullnamePipe,
  ],
})
export class ContactSelectComponent
  extends BaseControlComponent<string>
  implements OnInit
{
  @Input() orgId: string | undefined = '';

  baseFilterParams: FilterParams;

  filteredValues: Observable<Contact[]>;

  constructor(
    private contactService: ContactService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.baseFilterParams = new FilterParams({
      sortBy: 'fullName',
      direction: 'ASC',
      type: ContactType.ORG_ADMIN_APPROVER,
      organizationId: this.orgId,
    });
    this.refresh();
  }

  refresh() {
    this.filteredValues = this.searchItems(this.baseFilterParams).pipe(
      map((resp) => resp.content)
    );
  }

  searchItems(params: FilterParams): Observable<PageableCollection<Contact>> {
    return this.contactService.find(params);
  }

  newContactCreated(contact: Contact) {
    this.value = contact?.id!;
    this.refresh();
    this.emitChangeEvent();
  }

  protected readonly ContactType = ContactType;
}
