import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EditContactInfoComponent } from '@app/user-profile/edit-contact-info/edit-contact-info.component';
import { User } from '@shared/models/user.model';

@Directive({
  selector: '[appEditUserContactInfo]',
  standalone: true,
})
export class EditUserContactInfoDirective {
  @Output() appContactInfoChanged = new EventEmitter<User>();

  constructor(private dialog: MatDialog) {}

  @HostListener('click', ['$event'])
  onClick() {
    const dialog = EditContactInfoComponent.openDialog(this.dialog);
    dialog.afterClosed().subscribe((result) => {
      if (result) return this.appContactInfoChanged.emit(result);
    });
  }
}
