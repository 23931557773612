import { CommonModule } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { UserPreferenceColumnCheckboxesComponent } from '@app/user-preference/components/user-preference-column-checkboxes/user-preference-column-checkboxes.component';
import { VIEW_DEF_KEYS } from '@app/user-preference/constants/view-properties.const';
import { UserPrefColumnSortType } from '@app/user-preference/model/user-preference.model';
import { AuthEventsDialogComponent } from '../auth-events-dialog/auth-events-dialog.component';

@Component({
  selector: 'app-column-chooser',
  templateUrl: './column-chooser.component.html',
  styleUrls: ['./column-chooser.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatMenuModule,
    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
    AuthEventsDialogComponent,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,

    UserPreferenceColumnCheckboxesComponent,
  ],
})
export class ColumnChooserComponent {
  @Input() viewDefKey: VIEW_DEF_KEYS;
  @ViewChild('columnCheckboxes')
  readonly columnCheckboxes: UserPreferenceColumnCheckboxesComponent;

  columnDisplayOrder: UserPrefColumnSortType = 'ColumnOrderProperty';
  filter: string = '';

  constructor() {}

  changeDisplayOrder(type: UserPrefColumnSortType) {
    this.columnCheckboxes.changeDisplayOrder(type);
  }

  filterList() {
    this.columnCheckboxes.filterValue = this.filter;
  }

  clear() {
    this.filter = '';
    this.filterList();
  }

  toggleAll(state: boolean) {
    this.columnCheckboxes.toggleAll(state);
  }
}
