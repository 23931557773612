import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FieldComponent } from '@shared/components/field/field.component';
import { FAR } from '@shared/models/far.model';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { NgPipesModule } from 'ngx-pipes';
import { FarMetadataComponent } from '../far-metadata/far-metadata.component';

@Component({
  selector: 'app-far-header',
  templateUrl: './far-header.component.html',
  styleUrls: ['./far-header.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    DefaultPipe,
    FieldComponent,
    FarMetadataComponent,
    NgPipesModule,
    MatCardModule,
    MatTooltipModule,
    MatIconModule,
  ],
})
export class FarHeaderComponent {
  @Input() far: FAR;
  @Input() listView: boolean = false;

  getVisitIcon(): string {
    if (this.far && this.far.locationCount) {
      if (this.far.locationCount < 10) {
        return 'numeric-' + this.far.locationCount + '-box';
      } else if (this.far.locationCount > 9) {
        return 'numeric-9-plus-box';
      } else return 'numeric-0-box';
    } else return 'numeric-0-box';
  }
}
