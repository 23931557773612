import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { FAR } from '@shared/models/far.model';
import { FarService } from '@shared/services/far.service';
@Component({
  selector: 'app-far-description-dialog',
  templateUrl: './far-description-dialog.component.html',
  styleUrls: ['./far-description-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,

    MatIconModule,
    MatProgressSpinnerModule,
    AuthEventsDialogComponent,
    AuthEventsDialogDirective,
  ],
})
export class FarDescriptionDialogComponent {
  busy: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public far: FAR,
    private farService: FarService,
    private dialogRef: MatDialogRef<FarDescriptionDialogComponent>
  ) {}

  onSubmit() {
    this.busy = true;
    this.farService.save(this.far).subscribe((_far) => {
      this.dialogRef.close();
    });
  }
}
