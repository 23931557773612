import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Crud } from '@shared/models/crud.decoration';
import { CrudService } from '@shared/services/crud.service';

@Injectable({ providedIn: 'root' })
@Crud({ apiUrl: `${environment.apiUrl}/socialMedia`, entity: 'Social Media' })
export class SocialMediaService extends CrudService<any> {
  constructor(http: HttpClient) {
    super(http);
  }
}
