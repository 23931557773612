import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { FvEducationDialogComponent } from '@shared/components/fv-education/fv-education-dialog.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { Address } from '@shared/models/address.model';
import { Education } from '@shared/models/education.model';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { AddressPipe } from '@shared/pipes/address.pipe';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { MilitaryDatePipe } from '@shared/pipes/military-date.pipe';
import { AlertService } from '@shared/services/alert.service';
import { EducationService } from '@shared/services/education.service';
import { DialogCloseMessage } from '@shared/shared.types';
import { cloneDeep, without } from 'lodash';
import { NgPipesModule } from 'ngx-pipes';
import { take } from 'rxjs/operators';

@Component({
  selector: 'fvview-education',
  templateUrl: './fvview-education.component.html',
  styleUrls: ['./fvview-education.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatBadgeModule,
    MatButtonModule,
    MatDividerModule,
    MatExpansionModule,
    MatIconModule,
    MatTooltipModule,

    AuthEventsDialogComponent,
    AuthEventsDialogDirective,
    NgPipesModule,
    AddressPipe,
    DefaultPipe,
    MilitaryDatePipe,
  ],
})
export class FvViewEducationComponent implements OnInit {
  @Input() fv: ForeignVisitor = {};

  constructor(
    private dialog: MatDialog,
    private alert: AlertService,
    private fvEducationService: EducationService
  ) {}

  ngOnInit(): void {
    this.fv.education = this.sortByDate(this.fv.education as Education[]);
  }

  sortByDate(education: Education[]): Education[] {
    education = education.sort((a, b) =>
      `${b.educationDates[0].startDate}`.localeCompare(
        `${a.educationDates[0].startDate}`
      )
    );
    return education;
  }

  initEducation(education?: Education): Education {
    if (!education)
      education = {
        educationDates: [{ startDate: '', endDate: '' }],
        schoolName: '',
        degree: '',
        campusLocation: {},
      };
    if (!education.foreignVisitor)
      education.foreignVisitor = { ...{ id: this.fv.id } };
    return cloneDeep(education);
  }

  openEducationDialog(education?: Education): void {
    let holdEducation = this.initEducation(education);
    const dialogRef = this.dialog.open(FvEducationDialogComponent, {
      data: holdEducation,
      width: '600px',
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((data: DialogCloseMessage<Education>) => {
        if (data) this.updateEducation(data);
      });
  }

  updateEducation(data: DialogCloseMessage<Education>): void {
    if (data.update && this.fv.education) {
      let idx: number = this.fv.education.findIndex(
        (education) => education.id == data.update?.id
      );
      if (idx > -1) this.fv.education[idx] = data.update;
      else {
        this.fv.education.push(data.update);
        this.fv.education = this.sortByDate(this.fv.education);
      }
    }
  }

  deleteEducation(education: Education): void {
    this.alert
      .confirmDelete({
        title: `Delete Education Record`,
        message: 'Are you sure you want to delete this record?',
        performAction: () => this.fvEducationService.delete(education.id),
      })
      .subscribe(() => {
        this.fv.education = without(this.fv.education, education);
      });
  }

  isAddress(location?: Address) {
    if (!location) return false;
    if (
      !location.line1 &&
      !location.line2 &&
      !location.city &&
      !location.stateProvince &&
      !location.countryCode &&
      !location.postalCode
    )
      return false;
    return true;
  }
}
