import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SafeUrl } from '@angular/platform-browser';
import { environment } from '@environments/environment';
import {
  AttachmentData,
  AttachmentStatus,
} from '@shared/models/attachment-data.model';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { SecureImagePipe } from '@shared/pipes/secure-image.pipe';
import { Subscription } from 'rxjs';
import { throwError as observableThrowError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/operators';
import {
  ProfilePicSelectComponent,
  ProfilePicSelectDirective,
} from './profile-pic-select.component';
import { FvAttachmentService } from '@shared/services/fv-attachment.service';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-profile-pic',
  templateUrl: './profile-pic.component.html',
  styleUrls: ['./profile-pic.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    SecureImagePipe,
    ProfilePicSelectComponent,
    ProfilePicSelectDirective,
    MatTooltipModule,
    MatIconModule,
  ],
  providers: [SecureImagePipe],
})
export class ProfilePicComponent implements OnDestroy {
  fileUpdateSubscription = Subscription.EMPTY;

  profilePicSrc: SafeUrl;

  imageUnavailable = false;

  constructor(
    private attachmentService: FvAttachmentService,
    private ref: ChangeDetectorRef,
    private secureImagePipe: SecureImagePipe
  ) {
    this.fileUpdateSubscription = this.attachmentService.changeEvent$.subscribe(
      (evt: any) => {
        if (evt.modelId === this.fv?.profilePhotoAttachment?.id) {
          this.fv.profilePhotoAttachment = evt.model;
        }

        if (this.fv?.profilePhotoAttachment && !this.photoProcessing) {
          return this.loadImage();
        }
      }
    );
  }

  _fv: ForeignVisitor;
  @Input()
  set fv(_fv: ForeignVisitor) {
    this._fv = _fv;
    this.watchStatus();
  }

  get fv(): ForeignVisitor {
    return this._fv;
  }

  @Input()
  @HostBinding('class.profile-pic-editable')
  editable: boolean = false;

  get photoProcessing(): boolean | undefined {
    return (
      this.fv?.profilePhotoAttachment?.status &&
      this.fv?.profilePhotoAttachment?.status !== AttachmentStatus.CLEAN
    );
  }

  get attachmentIcon(): string {
    if (this.photoProcessing) {
      return 'account-clock';
    }

    if (this.imageUnavailable) {
      return 'image-off-outline';
    }

    if (this.fv?.gender?.toLowerCase() === 'female') {
      return 'face-woman';
    }

    return 'face-man';
  }

  loadImage() {
    this.secureImagePipe
      .getImageObs(
        `${environment.apiUrl}/attachment/fvs/${this.fv?.profilePhotoAttachment?.id}/download`
      )
      .pipe(
        catchError((err, caught) => {
          this.imageUnavailable = true;
          return observableThrowError(err);
        })
      )
      .subscribe((url) => (this.profilePicSrc = url));
  }

  profilePicUpdate(profilePic: AttachmentData | null) {
    if (profilePic) {
      this.fv.profilePhotoAttachment = profilePic;
      this.watchStatus();
    }
  }

  watchStatus() {
    if (this.fv?.profilePhotoAttachment && !this.photoProcessing) {
      return this.loadImage();
    }

    if (this.fv?.profilePhotoAttachment) {
      this.attachmentService.watchStatus(
        this.fv.profilePhotoAttachment.id as string
      );
    }
  }

  ngOnDestroy() {
    this.fileUpdateSubscription.unsubscribe();
  }
}
