import { isString, result } from 'lodash';
import { FilterConfig } from './models/filter-config/filter-config.type';
import { PropResolver } from './shared.types';

function isArrayVal(config: FilterConfig) {
  return !!(<any>config).multiple;
}

export function resolveProp(
  opt: any,
  resolve?: PropResolver,
  defaultVal = opt
) {
  if (!resolve || !opt) return defaultVal;
  if (isString(resolve)) return result(opt, resolve);
  return resolve(opt);
}

export function getArrayKeys(configs: FilterConfig[]) {
  return (configs || [])
    .filter((config) => isArrayVal(config))
    .map((config) => config.name);
}
