import { Routes } from '@angular/router';
import { SCREENING_TAB } from '@app/user-preference/config/screening.config.const';
import { userPreferenceFormResolver } from '@app/user-preference/resolvers/user-preference-form.resolver';
import { AuthGuard } from '@shared/helpers/auth.guard';
import { ScreeningRoles } from '@shared/models/role-permissions';
import { ScreeningComponent } from './screening.component';

export const SCREENING_ROUTES: Routes = [
  {
    path: 'screening',
    title: 'Screening Review',
    component: ScreeningComponent,
    canActivate: [AuthGuard],
    data: { roles: ScreeningRoles, viewDefKey: SCREENING_TAB.viewDefKey },
    resolve: {
      userPrefFormGroup: userPreferenceFormResolver,
    },
  },
];
