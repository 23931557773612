import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule, Sort } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import {
  SCREENING_COLUMNS,
  SCREENING_TAB,
  ScreeningColumnDefs,
} from '@app/user-preference/config/screening.config.const';
import { UserPrefSaved } from '@app/user-preference/model/config-view-definitions.model';
import { UserPreferenceFormService } from '@app/user-preference/service/user-preference-form.service';
import { AvatarComponent } from '@shared/components/avatar/avatar.component';
import { ConfirmDialogComponent } from '@shared/components/confirm/confirmdialog.component';
import { NoResultFoundComponent } from '@shared/components/no-result-found/no-result-found.component';
import { ScreeningUpdateDirective } from '@shared/components/screening/screening-dialog/screening-update.directive';
import { ScreeningHistoryDirective } from '@shared/components/screening/screening-history-dialog/screening-history.directive';
import { ScreeningIndicatorComponent } from '@shared/components/screening/screening-indicator/screening-indicator.component';
import { AppConfigValue } from '@shared/constants/shared.const';
import { ScreeningResult } from '@shared/enums/screening-result.enum';
import { User } from '@shared/models';
import { FilterParams } from '@shared/models/filter-params.model';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { NetworkDomain } from '@shared/models/network-domain';
import { Role } from '@shared/models/role';
import { ScreeningDateFormat } from '@shared/models/screening.model';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { FullnamePipe } from '@shared/pipes/fullname.pipe';
import { ScreeningService } from '@shared/services/screening.service';
import { NgPipesModule } from 'ngx-pipes';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-screening-table',
  templateUrl: './screening-table.component.html',
  styleUrls: ['./screening-table.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatDialogModule,
    AvatarComponent,
    ScreeningIndicatorComponent,
    NoResultFoundComponent,
    DefaultPipe,
    FullnamePipe,
    NgPipesModule,
    ScreeningHistoryDirective,
    ScreeningUpdateDirective,
  ],
})
export class ScreeningTableComponent implements OnInit, OnDestroy {
  @Input() fvs: ForeignVisitor[];
  @Input() filterParams: FilterParams;
  @Output() filterParamsChange = new EventEmitter<FilterParams>();
  @AppConfigValue('highEnvFeatures')
  private highEnvFeatures: boolean = false;
  ScreeningDateFormat = ScreeningDateFormat;
  private readonly authService: AuthService = inject(AuthService);
  private currentUser: User = this.authService.getUser() as User;

  Role = Role;
  ScreeningResult = ScreeningResult;
  private ngUnsubscribe = new Subject<void>();

  currentNetWork = this.highEnvFeatures ? NetworkDomain.J : NetworkDomain.U;
  savedPrefs: UserPrefSaved;
  userPrefFormGroup$: Subscription;
  SCREENING_COLUMNS: ScreeningColumnDefs = SCREENING_COLUMNS;
  dataColumns: string[] = inject(UserPreferenceFormService).generateDataColumns(
    SCREENING_TAB
  );

  constructor(
    private screeningService: ScreeningService,
    private dialog: MatDialog,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.savedPrefs = this.route.snapshot.data.userPrefFormGroup?.value;
    const formGroup: FormGroup = this.route.snapshot.data
      .userPrefFormGroup as FormGroup;
    this.userPrefFormGroup$ = formGroup.valueChanges.subscribe(
      (savedPrefs: UserPrefSaved) => {
        this.savedPrefs = savedPrefs;
      }
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.userPrefFormGroup$?.unsubscribe();
  }

  onSortChange(sort: Sort | null) {
    if (sort !== null) {
      this.filterParams.sortBy = sort.active;
      this.filterParams.direction = sort.direction.toUpperCase();
    }
    this.filterParamsChange.emit(this.filterParams);
  }

  rescreen(fv: ForeignVisitor) {
    this.screeningService
      .rescreen({ id: fv.id })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.filterParamsChange.emit(this.filterParams);
      });
  }

  openConfirmationDialog(
    fv: ForeignVisitor
  ): MatDialogRef<ConfirmDialogComponent> {
    let data = {
      icon: 'alert-octagon',
      color: 'warn',
      title: `ARE YOU SURE?`,
      message: `This will create a new screening request for ${fv.givenName} ${fv.surname}.`,
      performAction: () => this.rescreen(fv),
      confirmText: 'Confirm',
    };

    return this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data,
    });
  }
}
