import { BaseModel } from '@shared/models/base.model';
import { PermissionModel } from './permission.model';
export interface ContactEmail extends BaseModel, PermissionModel {
  id?: any;
  type?: EmailType | string;
  emailAddress?: string;
}

export enum EmailType {
  Home = 'Home',
  Work = 'Work',
  School = 'School',
  Other = 'Other',
}

export const emailIcons: { [key: string]: string } = {
  [EmailType.Home]: 'home',
  [EmailType.Work]: 'briefcase',
  [EmailType.Other]: 'email',
  [EmailType.School]: 'school',
};
