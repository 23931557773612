import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';
import { LookupData } from '@shared/models/lookupdata';
import { Observable, of } from 'rxjs';

import * as i18nIsoCountries from 'i18n-iso-countries';
import * as nationalities from 'i18n-nationality';
@Injectable({ providedIn: 'root' })
export class LookupService {
  constructor(private http: HttpClient) {
    i18nIsoCountries.registerLocale(
      require('i18n-iso-countries/langs/en.json')
    );
    nationalities.registerLocale(require('i18n-nationality/langs/en.json'));
  }

  public getObservedBehaviorCodes(): Observable<LookupData[]> {
    return this.http.get<LookupData[]>(
      `${environment.apiUrl}/lookup/observed_behavior_topics`
    );
  }

  public getGeneralEncounterCodes(): Observable<LookupData[]> {
    return this.http.get<LookupData[]>(
      `${environment.apiUrl}/lookup/general_encounter_topics`
    );
  }

  public getCountries(): Observable<LookupData[]> {
    return of(
      Object.keys(i18nIsoCountries.getAlpha2Codes())
        .map((alpha2Code) => {
          const alpha3Code = i18nIsoCountries.alpha2ToAlpha3(alpha2Code);
          const name = i18nIsoCountries.getName(alpha2Code, 'en');
          const nationality = nationalities.getName(alpha2Code, 'en');

          return {
            lookup_short_name: alpha2Code,
            lookup_cd: alpha3Code,
            lookup_data: name,
            lookup_alt_name: nationality,
          } as LookupData;
        })
        .sort((a, b) => a.lookup_data.localeCompare(b.lookup_data))
    );
  }
}
