import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatCardMdImage, MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { StatusMapperPipe } from '@shared/pipes/status-mapper.pipe';
@Component({
  selector: 'insight-card',
  templateUrl: 'insight-card.component.html',
  styleUrls: ['insight-card.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    RouterLink,
    StatusMapperPipe,
    DefaultPipe,
  ],
})
export class InsightCardComponent {
  @Input() public value: any;
  @Input() public icon?: string;
  @Input() public svgIcon?: string;
  @Input() public label: string;
  @Input() public clickable? = false;
  @Input() public showClickable? = false;
  @Input() public filterQueryParams?: any;
  @Input() public filterRouterLink?: string;
  @Input() public result?: string;
  @Input() public filterMode?: boolean = false;
  @Input() public isToggled?: boolean = false;
}
