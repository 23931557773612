import { CommonModule } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { IDialogData } from '@shared/models/dialog-data.model';
import { FilterParams } from '@shared/models/filter-params.model';
import { PageableCollection } from '@shared/models/pageable-collection.model';
import { SystemAlert } from '@shared/models/system-alert';
import { AlertService } from '@shared/services/alert.service';
import { Observable, Subject } from 'rxjs';
import { startWith, switchMap, take, tap } from 'rxjs/operators';
import { SystemAlertDialogComponent } from '../system-alert-dialog/system-alert-dialog.component';
import { SystemAlertsCollectionComponent } from '../system-alerts-collection/system-alerts-collection.component';
import { SystemAlertsCrudService } from '../system-alerts-crud.service';

@Component({
  selector: 'app-system-alerts',
  templateUrl: './system-alerts.component.html',
  styleUrls: ['./system-alerts.component.scss'],
  standalone: true,
  imports: [CommonModule, SystemAlertsCollectionComponent],
})
export class SystemAlertsComponent implements OnDestroy {
  /** Emits when the component is destroyed. */
  private readonly _destroyed = new Subject<void>();
  refresh$ = this.systemAlertService.changeEvent$.pipe(startWith(0));
  filterParams$: Observable<FilterParams> =
    this.systemAlertService.filterParameters$;
  loading: boolean = false;

  systemAlerts$: Observable<PageableCollection<SystemAlert>> =
    this.refresh$.pipe(
      switchMap((_) => this.systemAlertService.systemAlerts$),
      tap((_) => {
        this.loading = false;
      })
    );

  constructor(
    private systemAlertService: SystemAlertsCrudService,
    private dialog: MatDialog,
    private alert: AlertService
  ) {}

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  onFilterParamChange(filterParam: FilterParams) {
    this.loading = true;
    this.systemAlertService.onFilterParamChange(filterParam);
  }

  onCreate() {
    this.openDialog({ mode: 'create', model: {} });
  }

  onEdit(systemAlert: SystemAlert) {
    this.openDialog({ mode: 'update', model: systemAlert });
  }

  onDelete(systemAlert: SystemAlert) {
    this.alert
      .confirmDelete({
        title: `Delete a System Alert`,
        message: `Are you sure you want to delete this System Alert?`,
        performAction: () => this.systemAlertService.delete(systemAlert.id),
      })
      .pipe(take(1))
      .subscribe(() => {});
  }

  openDialog(dialogData: IDialogData<Partial<SystemAlert>>) {
    const dialogConfig: MatDialogConfig = {
      data: dialogData,
    };

    this.dialog.open(SystemAlertDialogComponent, dialogConfig);
  }
}
