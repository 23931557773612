import { Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { EmailControlComponent } from '@shared/controls/email-control/email-control.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { ContactEmail } from '@shared/models/contact-email.model';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { ForeignVisitorEmailService } from '@shared/services/foreign-visitor-email.service';
import { DialogCloseMessage } from '@shared/shared.types';
import { NgPipesModule } from 'ngx-pipes';
import { AuthEventsDialogComponent } from '../auth-events-dialog/auth-events-dialog.component';

export interface EmailDialogData {
  email: ContactEmail;
  fvId: string;
}

@Component({
  templateUrl: './email-dialog.component.html',
  styleUrls: ['./email-dialog.component.scss'],
  standalone: true,
  imports: [
    AuthEventsDialogComponent,
    AuthEventsDialogDirective,
    EmailControlComponent,
    MatDialogModule,
    FormsModule,
    MatSelectModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    NgPipesModule,
  ],
})
export class EmailDialogComponent {
  busy: boolean = false;
  constructor(
    public emailDialog: MatDialogRef<EmailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fvEmailService: ForeignVisitorEmailService
  ) {}

  static openDialog(
    dialog: MatDialog,
    item: EmailDialogData
  ): MatDialogRef<EmailDialogComponent> {
    return dialog.open<EmailDialogComponent>(EmailDialogComponent, {
      data: item,
      width: '800px',
    });
  }

  save(email: ContactEmail) {
    this.busy = true;
    email = {
      ...email,
      ...{
        foreignVisitor: {
          id: this.data.fvId,
        },
      },
    };
    this.fvEmailService.save(email).subscribe(
      (result: ForeignVisitor) => {
        this.onClose({
          update: result,
          message: `Updated Email Information for ${result.givenName} ${result.surname} `,
        });
      },
      (err) => {
        this.busy = false;
      }
    );
  }
  onClose(message: DialogCloseMessage<ForeignVisitor>) {
    this.emailDialog.close(message);
  }
}
