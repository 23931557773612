import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { NationalIdControlComponent } from '@shared/controls/national-id-control/national-id-control.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { ConfirmDialogData } from '@shared/models/confirm-dialog-data.model';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { NationalId } from '@shared/models/national-id';
import { AlertService } from '@shared/services/alert.service';
import { NationalIdService } from '@shared/services/national-id.service';
import { DialogCloseMessage } from '@shared/shared.types';
import { without } from 'lodash';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

interface NatlIDData {
  fv: ForeignVisitor;
  id?: string;
}

@Component({
  selector: 'app-natl-id-dialog',
  templateUrl: './natl-id-dialog.component.html',
  styleUrls: ['./natl-id-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    NationalIdControlComponent,
    AuthEventsDialogDirective,
  ],
})
export class NatlIdDialogComponent implements OnInit {
  @ViewChild('nationalIdCtl') nationalIdCtl: NgForm;
  value: NationalId;
  onValidationChange: any = () => {};
  busy: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: NatlIDData,
    private alertService: AlertService,
    private nationalIdService: NationalIdService,
    private dialogRef: MatDialogRef<NatlIdDialogComponent>
  ) {}

  ngOnInit() {
    this.value = this.data.id ? this.findID(this.data.id) : {};
  }

  findID(id: string): NationalId {
    let item: any;
    if (this.data.fv.nationalIds) {
      item = this.data.fv.nationalIds.find((element: NationalId) => {
        if (element.id == id) {
          item = element;
          return item;
        }
      });
    }
    return item;
  }

  getNationalIds(): NationalId[] | null {
    if (this.data.fv.nationalIds) {
      return this.data.fv.nationalIds;
    } else {
      return null;
    }
  }

  /**
   * NOTE: Since the fallout of 90-day deletes and restricted
   * Delete privileges, deleting national IDs is not available
   * functionality.
   *
   * Opens dialog that appears when deleting a National ID
   *
   * @param item Item that is to be removed.
   */
  deleteNationalIdDialog(item: NationalId) {
    if (item?.id) {
      let deleteData: ConfirmDialogData = {
        title: `Delete National ID Record`,
        message: `Are you sure? This action cannot be undone.`,
        performAction: () => {
          this.deleteNationalIdRecord(item);
        },
        successMsg: `Record successfully deleted.`,
      };
      this.alertService.confirmDelete(deleteData).pipe(take(1)).subscribe();
    } else {
      this.data.fv.nationalIds = without(this.data.fv.nationalIds, item);
    }
  }

  /**
   * See note in deleteNationalIdDialog() above.
   *
   * @param item id being delete
   */
  deleteNationalIdRecord(item: NationalId) {
    if (item.id) {
      this.nationalIdService
        .delete(item.id)
        .pipe(take(1))
        .subscribe(() => {
          this.data.fv.nationalIds = without(this.data.fv.nationalIds, item);
        });
    }
  }

  onSubmit() {
    this.busy = true;
    this.saveNationalIds(this.value);
  }

  /**
   * Bc we're basically saving amdist a for-loop, we don't wan't success
   * messages for each ID. Comparing the id of the last national ID with the
   * item we're saving, we can control whether or not to send the success
   * message.
   *
   * @param item National ID we're trying to save on the FV
   * @returns a subscription of the save request for the API.
   */
  saveNationalIds(item: NationalId): Subscription {
    item.foreignVisitor = {};
    item.foreignVisitor.id = this.data.fv.id;
    return this.saveWithMessage(item);
  }

  /**
   * Save the id with the national ID service, then close the dialog and
   * pop up a snackbar.
   *
   * @param item National ID we're saving on the FV
   * @returns Subscription of the save request.
   */
  saveWithMessage(item: NationalId): Subscription {
    return this.nationalIdService.save(item).subscribe(
      (item: NationalId) => {
        this.onClose({
          update: item,
          message: `Updated the Identifying Information ${this.data.fv.givenName} ${this.data.fv.surname}`,
        });
      },
      (err) => (this.busy = false)
    );
  }

  onClose(message: DialogCloseMessage<NationalId>) {
    this.dialogRef.close(message);
  }
}
