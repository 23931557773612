import { environment } from '@environments/environment';

export type BulkUploadType = 'FarFV' | 'FV' | 'Org' | 'Screening';

export interface BulkTemplate {
  label: string;
  file: string;
  type: string;
}

export interface BulkSetting {
  label: string;
  templates: BulkTemplate[] | null;
  path: string;
  submitButtonLabel: string;
  errorTableColumns: any[];
  adminOwnerOrgSelect: boolean;
  showCountrySelect: boolean;
}

export const BulkUploadSettings: { [key in BulkUploadType]: BulkSetting } = {
  FV: {
    label: 'Foreign National Bulk Upload',
    templates: [
      {
        label: 'FN Template',
        file: `ForeignVisitor-Template.xlsx`,
        type: 'foreignVisitor',
      },
    ],
    path: `${environment.apiUrl}/fvs/upload`,
    submitButtonLabel: 'Upload',
    errorTableColumns: ['row', 'messages'],
    adminOwnerOrgSelect: true,
    showCountrySelect: true,
  },
  FarFV: {
    label: 'Foreign National Bulk Upload to FAR',
    templates: [
      {
        label: 'FN Template',
        file: `ForeignVisitor-Template.xlsx`,
        type: 'foreignVisitor',
      },
    ],
    path: `${environment.apiUrl}/fvs/far/upload`,
    submitButtonLabel: 'Upload',
    errorTableColumns: ['row', 'messages'],
    adminOwnerOrgSelect: false,
    showCountrySelect: true,
  },
  Org: {
    label: 'Location Bulk Upload',
    templates: [
      {
        label: 'Location Template',
        file: 'OrgLocTemplate.xlsx',
        type: 'orgLocation',
      },
    ],
    path: `${environment.apiUrl}/org/upload`,
    submitButtonLabel: 'Upload',
    errorTableColumns: ['row', 'messages'],
    adminOwnerOrgSelect: true,
    showCountrySelect: true,
  },
  Screening: {
    label: 'Screening Results Import',
    templates: null,
    path: `${environment.apiUrl}/screeningDecision/upload`,
    submitButtonLabel: 'Import',
    errorTableColumns: ['row', 'messages'],
    adminOwnerOrgSelect: false,
    showCountrySelect: false,
  },
};

export interface BulkUploadData {
  type: BulkUploadType;
  farId: any[];
}
