import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ForeignVisitor } from '@app/shared/models/foreign-visitor.model';
import { environment } from '@environments/environment';

import { ScreeningResult } from '@shared/enums/screening-result.enum';
import { ScreeningStatus } from '@shared/enums/screening-status.enum';
import {
  DocumentId,
  FAR,
  FilterParams,
  FvCollectionMetrics,
  FvMetrics,
  PageableCollection,
} from '@shared/models';
import { CrudChangeType } from '@shared/models/crud-change-type.enum';
import { Crud } from '@shared/models/crud.decoration';
import { SUPPRESS_MESSAGE } from '@shared/shared.const';
import { pick } from 'lodash';
import { merge, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AddressService } from './address.service';
import { AppConfigService } from './app-config.services';
import { CrudService } from './crud.service';
import { EducationService } from './education.service';
import { EmploymentService } from './employment.service';
import { FarService } from './far.service';
import { ForeignVisitorEmailService } from './foreign-visitor-email.service';
import { ForeignVisitorPhoneService } from './foreign-visitor-phone.service';
import { NationalIdService } from './national-id.service';
import { OtherNamesService } from './other-names.service';
import { PassportService } from './passport.service';
import { ScreeningService } from './screening.service';
import { VisaService } from './visa.service';
@Injectable({ providedIn: 'root' })
@Crud({
  apiUrl: `${environment.apiUrl}/fvs`,
  entity: 'Foreign National',
  hasIdPathUpdate: true,
})
export class FvService extends CrudService<ForeignVisitor> {
  // When the foreign visitor or any of its child objects emit a crud change, change event will be emitted.
  averageScreeningResponseTime =
    this.configService.get('averageScreeningResponseTime') || 60;
  modelChanges$ = merge(
    this.changeEvent$,
    this.educationService.changeEvent$,
    this.employmentService.changeEvent$,
    this.otherNameService.changeEvent$,
    this.passportService.changeEvent$,
    this.passportService.changeEvent$,
    this.screeningService.changeEvent$,
    this.visaService.changeEvent$,
    this.nationalIdService.changeEvent$,
    this.fvEmailService.changeEvent$,
    this.fvPhoneService.changeEvent$,
    this.addressService.changeEvent$,
    this.farService.changeEvent$
  );

  ScreeningStatus = ScreeningStatus;
  ScreeningResult = ScreeningResult;

  constructor(
    http: HttpClient,
    private addressService: AddressService,
    private educationService: EducationService,
    private employmentService: EmploymentService,
    private otherNameService: OtherNamesService,
    private passportService: PassportService,
    private screeningService: ScreeningService,
    private visaService: VisaService,
    private nationalIdService: NationalIdService,
    private configService: AppConfigService,
    private fvEmailService: ForeignVisitorEmailService,
    private fvPhoneService: ForeignVisitorPhoneService,
    private farService: FarService
  ) {
    super(http);
  }

  getFarFvs(filterParams: FilterParams) {
    return this.http.get<PageableCollection<ForeignVisitor>>(
      `${this.apiUrl}/far`,
      {
        params: filterParams?.httpParams,
      }
    );
  }

  findLinked(fvId: string, filterParams?: FilterParams) {
    return this.http.get<PageableCollection<ForeignVisitor>>(
      `${this.apiUrl}/linked/${fvId}`,
      {
        params: filterParams?.httpParams,
      }
    );
  }

  public find(
    filterParams?: FilterParams
  ): Observable<PageableCollection<ForeignVisitor>> {
    return this.http
      .get<PageableCollection<ForeignVisitor>>(`${this.apiUrl}`, {
        params: filterParams?.httpParams,
      })
      .pipe(
        map((data) => {
          if (!data.content || data.content.length === 0) return data;
          for (let fv of data.content) {
            if (
              fv.latestScreening?.status === ScreeningStatus.InProcess &&
              fv.latestScreening?.result !== ScreeningResult.MachineRed
            ) {
              fv.isScreeningTimeOutstanding = this.setTimeOutstanding(fv);
              fv.latestScreening.delayed = fv.isScreeningTimeOutstanding;
            }
            if (fv.allScreenings && fv.allScreenings.length > 1) {
              fv.allScreenings = this.orderScreeningHistory(fv.allScreenings);
            }
            this.sortPassports(fv);
            fv.selected = false;
          }
          return data;
        })
      );
  }

  public addDocIdToFv(
    fvId: string,
    document: DocumentId[]
  ): Observable<ForeignVisitor> {
    return this.http
      .put<DocumentId>(
        `${this.apiUrl}/${fvId}/docId/add`,
        {
          documentIdDtos: document,
        },
        { context: this.context }
      )
      .pipe(
        tap((m) =>
          this.changeEvent$.emit({
            type: 'create',
            model: m,
            modelId: fvId,
          })
        )
      );
  }

  public deleteFvDocId(fvId: string, id: string): Observable<ForeignVisitor> {
    return this.http.put<ForeignVisitor>(
      `${this.apiUrl}/${fvId}/docId/delete`,
      { id: id },
      {
        context: this.context,
      }
    );
  }

  public restoreDocId(fvId: string, id: string): Observable<ForeignVisitor> {
    return this.http.put<ForeignVisitor>(
      `${this.apiUrl}/${fvId}/docId/restore`,
      { id: id },
      {
        context: this.context,
      }
    );
  }

  public updateDocId(
    fvId: string,
    diplomaticPassport: DocumentId
  ): Observable<ForeignVisitor> {
    return this.http
      .put<ForeignVisitor>(
        `${this.apiUrl}/${fvId}/docId/update`,
        diplomaticPassport,
        {
          context: this.context,
        }
      )
      .pipe(
        tap((m) =>
          this.changeEvent$.emit({
            type: 'update',
            model: m,
            modelId: fvId,
          })
        )
      );
  }

  public findAllDipId(fvId: string): Observable<ForeignVisitor> {
    return this.http.put<ForeignVisitor>(
      `${this.apiUrl}/${fvId}/dipId/showAll`,
      {
        context: this.context,
      }
    );
  }

  public addFarToFv(fvId: string, fars: FAR[]): Observable<ForeignVisitor> {
    return this.http
      .put<ForeignVisitor>(
        `${this.apiUrl}/${fvId}/fars/add`,
        { foreignAccessRequests: fars },
        { context: this.context }
      )
      .pipe(
        tap((m) =>
          this.changeEvent$.emit({
            type: 'update',
            model: m,
            modelId: fvId,
            component: 'far',
          })
        )
      );
  }

  public get(id: any): Observable<ForeignVisitor> {
    return this.http
      .get<ForeignVisitor>(`${this.apiUrl}/${id}`, {
        context: this.context,
      })
      .pipe(
        map((data) => {
          if (
            data.latestScreening?.status === ScreeningStatus.InProcess &&
            data.latestScreening?.result !== ScreeningResult.MachineRed
          ) {
            data.isScreeningTimeOutstanding = this.setTimeOutstanding(data);
            data.latestScreening.delayed = data.isScreeningTimeOutstanding;
          }
          if (data.allScreenings && data.allScreenings.length > 1) {
            data.allScreenings = this.orderScreeningHistory(data.allScreenings);
          }
          this.sortPassports(data);
          this.initializeAdditionalInfo(data);
          return data;
        })
      );
  }

  sortPassports(data: ForeignVisitor) {
    if (data && data.passports && data.passports.length > 1) {
      data.passports = data.passports.sort((a: any, b: any) =>
        a.issuingCountryCode > b.issuingCountryCode
          ? 1
          : a.issuingCountryCode < b.issuingCountryCode
          ? -1
          : 0
      );
    }
  }

  orderScreeningHistory(screenings: any): any {
    screenings = screenings.sort((a: any, b: any) =>
      a.lastModifiedDate < b.lastModifiedDate
        ? 1
        : a.lastModifiedDate > b.lastModifiedDate
        ? -1
        : 0
    );
    return screenings;
  }

  initializeAdditionalInfo(fv: ForeignVisitor) {
    //Find Additional Information Info Type and set editMode to false
    if (fv && fv.otherNames) {
      for (let info of fv.otherNames) {
        info.editMode = false;
        info.foreignVisitor = { ...{ id: fv.id } };
        if (info.surname || info.givenName) info.type = 'name';
        else if (info.birthCountry) info.type = 'birthCountry';
        else if (info.dateOfBirth) info.type = 'dob';
      }
      fv.otherNames = fv.otherNames.sort((a, b) => this.myComparer(a, b));
    }
  }

  compareProperty(a: any, b: any) {
    return a || b ? (!a ? -1 : !b ? 1 : a.localeCompare(b)) : 0;
  }

  myComparer(a: any, b: any) {
    return (
      this.compareProperty(b.type, a.type) ||
      this.compareProperty(b.lastModifiedDate, a.lastModifiedDate)
    );
  }

  setTimeOutstanding(fv: ForeignVisitor): boolean {
    let now = Date.now();
    if (fv.latestScreening && fv.latestScreening.requestedDate) {
      let convertedDate = new Date(fv.latestScreening.requestedDate).getTime();
      let timeOutstanding = now - convertedDate;
      return timeOutstanding / 60000 > this.averageScreeningResponseTime;
    }
    return false;
  }

  getLinked(linkedById: string, fvId: string): Observable<ForeignVisitor> {
    return this.http
      .get<ForeignVisitor>(`${this.apiUrl}/linked/${linkedById}/view/${fvId}`)
      .pipe(
        map((data) => {
          let fv = data;
          if (
            fv.latestScreening?.status === ScreeningStatus.InProcess &&
            fv.latestScreening?.result !== ScreeningResult.MachineRed
          ) {
            fv.isScreeningTimeOutstanding = this.setTimeOutstanding(fv);
            fv.latestScreening.delayed = fv.isScreeningTimeOutstanding;
          }
          if (fv.allScreenings && fv.allScreenings.length > 1) {
            fv.allScreenings = this.orderScreeningHistory(fv.allScreenings);
          }
          this.sortPassports(fv);
          this.initializeAdditionalInfo(data);
          return data;
        })
      );
  }

  override save(
    model: ForeignVisitor,
    context?: HttpContext
  ): Observable<ForeignVisitor> {
    return super.save(model, context).pipe(
      map((data) => {
        if (
          data.latestScreening?.status === ScreeningStatus.InProcess &&
          data.latestScreening?.result !== ScreeningResult.MachineRed
        ) {
          data.isScreeningTimeOutstanding = this.setTimeOutstanding(data);
          data.latestScreening.delayed = data.isScreeningTimeOutstanding;
        }
        if (data.allScreenings && data.allScreenings.length > 1) {
          data.allScreenings = this.orderScreeningHistory(data.allScreenings);
        }
        this.sortPassports(data);
        this.initializeAdditionalInfo(data);
        return data;
      })
    );
  }

  saveAddressThenUpdateModel(
    model: ForeignVisitor,
    context?: HttpContext
  ): Observable<ForeignVisitor> {
    const newAddress = pick(model, 'address').address ?? {};

    return this.addressService
      .save(newAddress, new HttpContext().set(SUPPRESS_MESSAGE, true))
      .pipe(
        switchMap((address) => {
          const fv: ForeignVisitor = {
            ...model,
            address: { ...address },
          };
          return this.save(fv, context);
        })
      );
  }

  getFvsMetrics(): Observable<FvCollectionMetrics> {
    return this.http.get<FvCollectionMetrics>(`${this.apiUrl}/metrics`);
  }

  getOrgMetrics(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/adminMetrics`);
  }

  getFvsMetricsById(id: string): Observable<FvMetrics> {
    return this.http.get<FvMetrics>(`${this.apiUrl}/metrics/` + id);
  }

  deleteUSP(
    id: string,
    filterParams?: FilterParams
  ): Observable<ForeignVisitor> {
    return this.http
      .delete<ForeignVisitor>(`${this.apiUrl}/${id}`, {
        params: filterParams,
        context: this.context,
      })
      .pipe(
        tap(() => {
          this.changeEvent$.emit({
            type: CrudChangeType.delete,
            modelId: id,
          });
        })
      );
  }
}
