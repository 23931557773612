import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { PaginatorComponent } from '@shared/components/paginator/paginator.component';
import { FilterParams } from '@shared/models/filter-params.model';
import { PageableCollection } from '@shared/models/pageable-collection.model';
import { SystemAlert } from '@shared/models/system-alert';
import { StatusPipe } from '../status.pipe';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';

@Component({
  selector: 'app-system-alerts-collection',
  templateUrl: './system-alerts-collection.component.html',
  styleUrls: ['./system-alerts-collection.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatTableModule,
    MatToolbarModule,
    PaginatorComponent,
    StatusPipe,
    AuthEventsDialogDirective,
    AuthEventsDialogComponent,
  ],
})
export class SystemAlertsCollectionComponent implements OnInit {
  @Input() loading: boolean = false;
  @Input() position: 'fixed' | 'sticky' = 'sticky';
  @Input()
  systemAlertsCollection: PageableCollection<SystemAlert> | null = null;

  @Input() filterParams: FilterParams | null;
  @Output() filterParamsChange = new EventEmitter<FilterParams>();

  @Output() create = new EventEmitter<void>();
  @Output() edit = new EventEmitter<SystemAlert>();
  @Output() delete = new EventEmitter<SystemAlert>();

  displayedColumns: string[] = [
    'status',
    'message',
    'startDateTime',
    'endDateTime',
    'actions',
  ];

  ngOnInit(): void {
    // initialize the filterparams to the default values returned from the api
    if (this.filterParams) {
      this.filterParams.pageSize = this.systemAlertsCollection?.size;
      this.filterParams.pageNum =
        this.systemAlertsCollection?.pageable?.pageNumber;
    }
  }

  onPageChange(page: PageEvent) {
    if (this.filterParams) {
      this.filterParams.pageNum = page.pageIndex;
      this.filterParams.pageSize = page.pageSize;
      this.filterParamsChange.emit(this.filterParams);
    }
  }

  onCreate() {
    this.create.emit();
  }

  onEdit(systemAlert: SystemAlert) {
    this.edit.emit(systemAlert);
  }

  onDelete(systemAlert: SystemAlert) {
    this.delete.emit(systemAlert);
  }
}
