import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FarDetailsControlComponent } from '@app/foreign-access-requests/far-creation/far-details-control/far-details-control.component';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { FAR } from '@shared/models/far.model';
import { FarService } from '@shared/services/far.service';
@Component({
  selector: 'app-far-details-dialog',
  templateUrl: './far-details-dialog.component.html',
  styleUrls: ['./far-details-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    FarDetailsControlComponent,
    AuthEventsDialogComponent,
    AuthEventsDialogDirective,
  ],
})
export class FarDetailsDialogComponent implements OnInit {
  busy: boolean = false;
  far: FAR = {};
  hideDescription: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private farService: FarService,
    private dialogRef: MatDialogRef<FarDetailsDialogComponent>
  ) {}

  ngOnInit(): void {
    if (this.data.hideDescription || this.data.far) {
      this.far = this.data.far;
      this.hideDescription = this.data.hideDescription;
    } else this.far = this.data;
  }

  onSubmit() {
    this.busy = true;
    this.farService.save(this.far).subscribe((_far) => {
      this.dialogRef.close();
    });
  }
}
