import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { UserPrefSaved } from '@app/user-preference/model/config-view-definitions.model';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { FAR } from '@shared/models/far.model';
import { FilterParams } from '@shared/models/filter-params.model';
import { CrudService } from '@shared/services/crud.service';
import { FarService } from '@shared/services/far.service';
import { Repository } from '@shared/services/repository.service';
import { FileUploadModule } from 'ng2-file-upload';
import { tap } from 'rxjs/operators';
import { CollectionsComponent } from './collections/collections.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [Repository, { provide: CrudService, useExisting: FarService }],
  standalone: true,
  imports: [
    CommonModule,
    CollectionsComponent,
    FileUploadModule,
    AuthEventsDialogDirective,
  ],
})
export class DashboardComponent implements OnInit {
  private readonly route: ActivatedRoute = inject(ActivatedRoute);
  fars$ = this.repo.collection$.pipe(
    tap((_) => {
      this.loading = false;
    })
  );
  filterParams = this.repo.defaultFilterParams;
  loading: boolean = false;

  get queryParamsSnapshot(): Params {
    return this.repo.getQueryParamsSnapshot();
  }

  constructor(private repo: Repository<FAR>) {}

  ngOnInit(): void {
    this.loading = true;
    const savedValue: UserPrefSaved = this.route.snapshot.data
      ?.userPrefFormGroup?.value as UserPrefSaved;
    this.filterParams.currentUserCreatedBy = savedValue.far?.defaultMineOnly;
    Object.assign(this.filterParams, this.queryParamsSnapshot);
    this.onFilterParamsChange(this.filterParams);
  }

  onFilterParamsChange(filterParams: FilterParams) {
    this.loading = true;
    this.filterParams = filterParams;
    this.repo.navigateWithParams(filterParams);
  }
}
