import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppConfigService } from '@shared/services/app-config.services';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class UtilityConfigService {
  constructor(
    private _AppConfigService: AppConfigService,
    private httpClient: HttpClient
  ) {}

  getCountryData(): Observable<any> {
    return this.httpClient
      .get(this._AppConfigService.get('countries') as string)
      .pipe(
        map((responseData) => {
          try {
            return responseData;
          } catch (error) {
            console.error('JSON parse Error: ' + JSON.stringify(error));
            return throwError(error);
          }
        }),
        catchError((error) => {
          console.error('Failure Response is : ' + JSON.stringify(error));
          return throwError(error);
        })
      );
  }
}
