import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';

import { AuthService } from '@app/auth/auth.service';
import {
  ChangePasswordDialogComponent,
  ChangePasswordDirective,
} from '@app/auth/change-password-dialog/change-password-dialog.component';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { ChangePasswordComponent } from '@shared/components/change-password/change-password.component';
import { UserAvatarComponent } from '@shared/components/user-avatar/user-avatar.component';
import { UserRoleChipsComponent } from '@shared/components/user-role-chips/user-role-chips.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { EditUserContactInfoDirective } from '@shared/directives/edit-user-contact-info.directive';
import { AuthSummary } from '@shared/models/auth-event.model';
import { User } from '@shared/models/user.model';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { FullnamePipe } from '@shared/pipes/fullname.pipe';
import { GetOrgNamePipe } from '@shared/pipes/get-org-name.pipe';
import { NgPipesModule } from 'ngx-pipes';
import { Observable } from 'rxjs';

@Component({
  selector: 'useracct',
  templateUrl: 'useracct.component.html',
  styleUrls: ['useracct.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    AuthEventsDialogComponent,
    AuthEventsDialogDirective,
    ChangePasswordComponent,
    ChangePasswordDirective,
    ChangePasswordDialogComponent,
    EditUserContactInfoDirective,
    UserAvatarComponent,
    UserRoleChipsComponent,
    DefaultPipe,
    GetOrgNamePipe,
    FullnamePipe,
    NgPipesModule,
    RouterLink,
  ],
})
export class UserAcctComponent {
  systemUser$: Observable<User | null> = this.auth.user$;

  summary: AuthSummary | null = null;

  constructor(public auth: AuthService) {
    this.auth.getAuthSummary().then((summary) => (this.summary = summary));
  }
}
