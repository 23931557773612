import { FormControl } from '@angular/forms';
import { NetworkDomain } from '@app/shared/models/network-domain';
import { FVViewRoles } from '@app/shared/models/role-permissions';
import {
  ACTION_COLUMN,
  ADDITIONAL_COLUMN,
} from '../../shared/constants/standard-column.const';
import {
  BaseSavedDef,
  BaseViewDef,
  CitadelColumnDef,
  ViewType,
} from '../model/config-view-definitions.model';

export const FV_TABLE_NAME = 'fvTable';
export interface FVColumnDefs {
  owningOrgShortname: CitadelColumnDef;
  surname: CitadelColumnDef;
  citizenshipCountry: CitadelColumnDef;
  birthDate: CitadelColumnDef;
  passport: CitadelColumnDef;
  latestScreeningModifiedDate: CitadelColumnDef;
  latestScreeningStatus: CitadelColumnDef;
  latestScreeningResult: CitadelColumnDef;
  additionalData: CitadelColumnDef;
  actions: CitadelColumnDef;
}

export interface FVSortDefs {
  organization: CitadelColumnDef;
  createdDate: CitadelColumnDef;
  lastModifiedDate: CitadelColumnDef;
  foreignNationals: CitadelColumnDef;
  citizenshipCountry: CitadelColumnDef;
  residenceCountry: CitadelColumnDef;
  screeningStatus: CitadelColumnDef;
  dateOfBirth: CitadelColumnDef;
  passport: CitadelColumnDef;
  lastScreeningCompleted: CitadelColumnDef;
  lastScreeningRequested: CitadelColumnDef;
  lastScreeningModified: CitadelColumnDef;
  screeningResult: CitadelColumnDef;
  network: CitadelColumnDef;
  linkedNationalsCount: CitadelColumnDef;
}

export const FV_COLUMNS: FVColumnDefs = {
  owningOrgShortname: {
    label: 'Organization',
    hidden: false,
    isSortable: true,
    sortField: 'owningOrgShortname',
    columnIndex: 0,
  },
  surname: {
    label: 'Foreign National',
    hidden: false,
    isSortable: true,
    sortField: 'surname',
    columnIndex: 1,
  },
  citizenshipCountry: {
    label: 'Citizenship Country',
    hidden: false,
    isSortable: true,
    sortField: 'citizenshipCountry',
    columnIndex: 2,
  },
  birthDate: {
    label: 'Date of Birth',
    hidden: false,
    isSortable: true,
    sortField: 'dateOfBirth',
    columnIndex: 3,
  },
  passport: {
    label: 'Passport',
    hidden: false,
    isSortable: true,
    sortField: 'latestPassportNumber',
    columnIndex: 4,
  },
  latestScreeningModifiedDate: {
    label: 'Last Screening Modified',
    hidden: false,
    isSortable: true,
    sortField: 'latestScreening.lastModifiedDate',
    columnIndex: 6,
  },
  latestScreeningStatus: {
    label: 'Screening Status',
    hidden: false,
    isSortable: true,
    sortField: 'latestScreening.status',
    columnIndex: 7,
  },
  latestScreeningResult: {
    label: 'Screening Results',
    hidden: false,
    isSortable: true,
    sortField: 'latestScreening.result',
    columnIndex: 8,
  },
  additionalData: { ...ADDITIONAL_COLUMN },
  actions: { ...ACTION_COLUMN },
};

export const FV_SORT_FIELDS: FVSortDefs = {
  organization: { ...FV_COLUMNS.owningOrgShortname, columnIndex: 0 },
  createdDate: {
    label: 'Created Date',
    columnIndex: 1,
    hidden: false,
    isSortable: true,
    sortField: 'createdDate',
  },
  lastModifiedDate: {
    label: 'Last Modified Date',
    columnIndex: 2,
    hidden: false,
    isSortable: true,
    sortField: 'lastModifiedDate',
  },
  foreignNationals: { ...FV_COLUMNS.surname, columnIndex: 4 },
  citizenshipCountry: {
    ...FV_COLUMNS.citizenshipCountry,
    sortField: 'citizenshipCountryObject.name',
    columnIndex: 4,
  },
  residenceCountry: {
    label: 'Residence Country',
    columnIndex: 5,
    hidden: false,
    isSortable: true,
    sortField: 'residenceCountryObject.name',
  },
  screeningStatus: { ...FV_COLUMNS.latestScreeningStatus, columnIndex: 6 },
  dateOfBirth: { ...FV_COLUMNS.birthDate, columnIndex: 7 },
  passport: { ...FV_COLUMNS.passport, columnIndex: 8 },
  lastScreeningCompleted: {
    label: 'Latest Screening Completed',
    columnIndex: 9,
    hidden: false,
    isSortable: true,
    sortField: 'latestScreening.completedDate',
  },
  lastScreeningRequested: {
    label: 'Latest Screening Requested',
    columnIndex: 10,
    hidden: false,
    isSortable: true,
    sortField: 'latestScreening.requestedDate',
  },
  lastScreeningModified: {
    ...FV_COLUMNS.latestScreeningModifiedDate,
    columnIndex: 11,
  },
  screeningResult: { ...FV_COLUMNS.latestScreeningResult, columnIndex: 12 },
  linkedNationalsCount: {
    label: 'Linked Foreign Nationals',
    columnIndex: 14,
    hidden: false,
    isSortable: true,
    sortField: 'linkedVisitorCount',
  },
  network: {
    label: 'Network',
    columnIndex: 13,
    hidden: false,
    isSortable: true,
    sortField: 'networkDomain',
  },
};

export type FV_COLUMN_KEYS = keyof typeof FV_COLUMNS;
export type FV_SORT_FIELD_KEYS = keyof typeof FV_SORT_FIELDS;
export type FV_COLUMN_FORM_GROUP = { [key: string]: FormControl<boolean> };

export interface FVPrefSaved extends BaseSavedDef {
  showColumn: { [keyof in FV_COLUMN_KEYS]: boolean };
}

export interface FVTabDef extends BaseViewDef {
  defaultMineOnly: boolean;
  viewType: ViewType;
  columns: { [keyof in FV_COLUMN_KEYS]: CitadelColumnDef };
  sortFields: { [keyof in FV_SORT_FIELD_KEYS]: CitadelColumnDef };
}

export type FVTabFormDef = {
  defaultMineOnly: FormControl<boolean>;
  viewType: FormControl<ViewType>;
  columns: { [key: string]: FormControl<boolean> };
};

export const FV_TAB: FVTabDef = {
  viewDefKey: 'fv',
  defaultMineOnly: false,
  viewType: 'card',
  columns: FV_COLUMNS,
  sortFields: FV_SORT_FIELDS,
  viewName: 'Foreign Nationals (FN)',
  tableName: FV_TABLE_NAME,
  pageLink: '/fvs',
  roles: FVViewRoles,
  domains: [NetworkDomain.J, NetworkDomain.U],
};
