import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Crud } from '@shared/models/crud.decoration';
import { Education } from '@shared/models/education.model';
import { CrudService } from '@shared/services/crud.service';

@Injectable({
  providedIn: 'root',
})
@Crud({
  apiUrl: `${environment.apiUrl}/education`,
  hasIdPathUpdate: true,
  entity: 'Education',
})
export class EducationService extends CrudService<Education> {
  constructor(http: HttpClient) {
    super(http);
  }
}
