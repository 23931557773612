import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AccountDialogComponent } from '@app/admin/accounts/accounts-collection/account-dialog/account-dialog.component';
import { User } from '@shared/models/user.model';

@Directive({
  selector: '[appUserAccountDialog]',
  standalone: true,
})
export class AccountDialogDirective {
  @Input() appUserAccountDialog: string;
  @Input() readOnly: boolean = false;
  @Output() appUserUpdated = new EventEmitter<User>();

  constructor(private dialog: MatDialog) {}

  @HostListener('click', ['$event'])
  onClick() {
    const dialog = AccountDialogComponent.openDialog(
      this.dialog,
      this.appUserAccountDialog,
      this.readOnly
    );
    dialog.afterClosed().subscribe((result) => {
      if (result) return this.appUserUpdated.emit(result);
    });
  }
}
