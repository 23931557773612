import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OrgLocation } from '@shared/models/orglocation.model';
import { LocationDialogComponent } from '../../locations/collection/location-dialog/location-dialog.component';

@Directive({
  selector: '[appLocationEdit]',
  standalone: true,
})
export class LocationEditDirective {
  @Input() appLocationEdit: OrgLocation | undefined;
  @Output() appLocationDialogOpen = new EventEmitter<
    MatDialogRef<LocationDialogComponent, OrgLocation>
  >();

  @Output() appLocationEdited = new EventEmitter<OrgLocation>();

  constructor(private dialog: MatDialog) {}

  @HostListener('click', ['$event'])
  onClick(_e: Event) {
    const dialog = LocationDialogComponent.openDialog(
      this.dialog,
      this.appLocationEdit
    );
    dialog.afterClosed().subscribe((result: OrgLocation) => {
      if (result) {
        this.appLocationEdited.emit(result);
      }
    });

    this.appLocationDialogOpen.emit(dialog);
  }
}
