import { Comment } from '@shared/models/comment.model';
import { CrudService } from '@shared/services/crud.service';
import { Observable } from 'rxjs';

export abstract class CommentService extends CrudService<Comment> {
  /**
   * fires a request to find comments by parent id
   *
   * @param {string} parentId parent object ID
   *
   * @return {Observable<Comment[]>} collection of resources
   */
  public abstract findByParent(parentId: string): Observable<Comment[]>;
}
