import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { FieldComponent } from '@shared/components/field/field.component';
import { Screening } from '@shared/models/screening.model';
import {
  ScreeningClassPipe,
  ScreeningIconPipe,
  ScreeningLabelPipe,
} from '@shared/pipes/screening-display.pipe';

@Component({
  selector: 'app-fn-latest-screening',
  templateUrl: './fn-latest-screening.component.html',
  styleUrls: ['./fn-latest-screening.component.scss'],
  standalone: true,
  imports: [
    FieldComponent,
    ScreeningClassPipe,
    ScreeningIconPipe,
    ScreeningLabelPipe,
    MatIconModule,
    DatePipe,
  ],
})
export class FnLatestScreeningComponent {
  @Input() screening: Screening;

  get modifiedDate() {
    return this.screening?.lastModifiedDate;
  }
}
