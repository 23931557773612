import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ScreeningCodeDialogComponent } from '@shared/controls/screening-code-display/screening-code-dialog/screening-code-dialog.component';
import { DerogatoryInformation } from '@shared/models/derogatory-information.model';

@Directive({
  selector: '[appScreeningCode]',
  standalone: true,
})
export class ScreeningCodeDirective {
  @Input() appScreeningCode?: DerogatoryInformation = {};
  @Input() fvId: string;
  @Output() appScreeningCodeUpdated = new EventEmitter<DerogatoryInformation>();
  constructor(private dialog: MatDialog) {}

  @HostListener('click')
  onClick() {
    const dialog = ScreeningCodeDialogComponent.openDialog(
      this.dialog,
      this.appScreeningCode
    );
    dialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.appScreeningCodeUpdated.emit(result);
      }
    });
  }
}
