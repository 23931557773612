export enum FilterConfigType {
  INPUT = 'INPUT',
  DATE = 'DATE',
  DATE_RANGE = 'DATE_RANGE',
  SELECT = 'SELECT',
  TOGGLE = 'TOGGLE',
  BUTTON_TOGGLE = 'BUTTON_TOGGLE',
  COUNTRY = 'COUNTRY',
  ORG = 'ORG',
  FN = 'FN',
  USER = 'USER',
  USER_ROLE = 'USER_ROLE',
  LOCATION_ID = 'LOCATION_ID',
  GROUP_ID = 'GROUP_ID',
}
