import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AuthService } from '@app/auth/auth.service';
import { Comment } from '@shared/models/comment.model';
import { FAR } from '@shared/models/far.model';
import { AlertService } from '@shared/services/alert.service';
import { FarCommentService } from '@shared/services/far-comment.service';
import { FarService } from '@shared/services/far.service';
import { take } from 'rxjs';

@Component({
  selector: 'app-cancel-far-dialog',
  templateUrl: './cancel-far-dialog.component.html',
  styleUrls: ['./cancel-far-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
  ],
})
export class CancelFarDialogComponent {
  busy: boolean = false;
  reason: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public far: FAR,
    private farService: FarService,
    private dialogRef: MatDialogRef<CancelFarDialogComponent>,
    private alertService: AlertService,
    private commentService: FarCommentService,
    private authService: AuthService
  ) {}

  onSubmit() {
    this.busy = true;
    let state = this.far.isCanceled ? 'Uncanceled' : 'Canceled';
    this.farService
      .changeCancelState(this.far.id, !this.far.isCanceled)
      .pipe(take(1))
      .subscribe(() => {
        let comment: Comment = {
          comment: state + ' reason: ' + this.reason,
          foreignAccessRequest: this.far,
          author: this.authService.getUser() || {},
        };

        this.commentService.save(comment).subscribe(() => {
          this.alertService.successAlert('FAR ' + state);
          this.dialogRef.close();
        });
      });
  }
}
