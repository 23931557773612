import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Injector, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  FloatLabelType,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { FarEventComponent } from '@shared/components/far-card/far-event/far-event.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { FAR } from '@shared/models/far.model';
import { FilterParams } from '@shared/models/filter-params.model';
import { PageableCollection } from '@shared/models/pageable-collection.model';
import { FarService } from '@shared/services/far.service';
import { isArray } from 'lodash';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { Observable } from 'rxjs';
import { BaseMatSelectComponent } from '../base-mat-select.component';
import { FarHeaderComponent } from '@shared/components/far-card/far-header/far-header.component';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-select-far',
  templateUrl: './select-far.component.html',
  styleUrls: ['./select-far.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    MatInputModule,
    MatTooltipModule,
    NgxMatSelectSearchModule,
    FormsModule,
    ReactiveFormsModule,
    AuthEventsDialogComponent,
    AuthEventsDialogDirective,
    FarEventComponent,
    FarHeaderComponent,
  ],
})
export class SelectFarComponent
  extends BaseMatSelectComponent<FAR>
  implements AfterViewInit
{
  @Input() floatLabel: FloatLabelType = 'auto';
  @Input() filterLabel: string = 'Search';
  @Input() excludeFvId: string = '';
  @Input() multiple: boolean;
  @Input() label: string = 'Select a FAR';
  @Input() restrictCurrentOrganization: boolean = true;

  baseFilterParams: FilterParams = new FilterParams({
    currentNetworkDomain: true,
    currentOrganization: this.restrictCurrentOrganization,
    excludeFvId: this.excludeFvId,
    sort: {
      active: 'createdDate',
      direction: 'desc',
    },
  });

  constructor(injector: Injector, private farService: FarService) {
    super(injector);
  }

  ngAfterViewInit(): void {
    this.baseFilterParams['currentOrganization'] =
      this.restrictCurrentOrganization;
    this.baseFilterParams['excludeFvId'] = this.excludeFvId;
    super.ngOnInit();

    this.ngModel?.valueChanges?.subscribe((change) => {
      if (change) {
        this.selectedItems = change;
        this.matSelect.selectionChange.emit();
      }
    });
  }

  updateSelection(far: FAR) {
    this.searchCtrl.setValue(far.eventTitle);
  }

  searchItems(params: FilterParams): Observable<PageableCollection<FAR>> {
    return this.farService.find(params);
  }

  getSelectedText(value: FAR | FAR[]) {
    if (isArray(value)) {
      const values = <FAR[]>value;
      const singleItem = `${values[0]?.eventTitle}`;
      const multipleItems = `${values.length} Options Selected`;
      return values.length > 1 ? multipleItems : singleItem;
    }

    return `${value?.eventTitle}`;
  }
}
