import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { AuthFlow } from '@app/auth/cognito-auth/cognito-auth.service';
import { ChangePasswordComponent } from '@shared/components/change-password/change-password.component';
@Component({
  selector: 'app-new-password-required',
  templateUrl: './new-password-required.component.html',
  styleUrls: ['./new-password-required.component.scss'],
  standalone: true,
  imports: [CommonModule, ChangePasswordComponent],
})
export class NewPasswordRequiredComponent {
  authFlow = AuthFlow;
}
