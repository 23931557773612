import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { HttpContext } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { CollectionComponent } from '@app/foreign-visitors/dashboard/collection/collection.component';
import { ClassificationBannerComponent } from '@shared/cmt/classification-banner.component';
import { FvListItemComponent } from '@shared/components/fv-list-item/fv-list-item.component';
import { ItineraryControlComponent } from '@shared/components/itinerary-control/itinerary-control.component';
import { StepperFooterComponent } from '@shared/components/stepper/stepper-footer/stepper-footer.component';
import { SelectFvComponent } from '@shared/controls/select-fv/select-fv.component';
import { FAR, FarPurposeType } from '@shared/models/far.model';
import { FarLocation } from '@shared/models/farlocation.model';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { Organization } from '@shared/models/organization.model';
import { FarService } from '@shared/services/far.service';
import { SUPPRESS_MESSAGE } from '@shared/shared.const';
import { PhoneValidator } from '@shared/validators/phone-number.validator';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FarDetailsControlComponent } from './far-details-control/far-details-control.component';
import { FarReviewComponent } from './far-review/far-review.component';
import { SponsorComponent } from './sponsor/sponsor.component';
@Component({
  selector: 'app-far-creation',
  templateUrl: './far-creation.component.html',
  styleUrls: ['./far-creation.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
  ],
  standalone: true,
  imports: [
    CommonModule,
    MatStepperModule,
    MatCardModule,
    MatDividerModule,
    MatIconModule,
    ClassificationBannerComponent,
    FarDetailsControlComponent,
    FarReviewComponent,
    ItineraryControlComponent,
    SelectFvComponent,
    SponsorComponent,
    StepperFooterComponent,
    MatToolbarModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    MatListModule,
    FvListItemComponent,
  ],
})
export class FarCreationComponent implements OnInit, OnDestroy {
  /** Emits when the component is destroyed. */
  private readonly _destroyed = new Subject<void>();
  userOrg: Organization = {};

  far: FAR = {
    foreignAccessRequestLocations: [],
  };

  location: FarLocation = {
    startDate: '',
    endDate: '',
  };

  @ViewChild('sponsorControl')
  sponsorControl: SponsorComponent;

  @ViewChild('stepperFooter')
  stepperFooter: StepperFooterComponent;

  @ViewChild('itineraryControl')
  itineraryControl: ItineraryControlComponent;

  constructor(
    private router: Router,
    private farService: FarService,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    this.userOrg = this.auth.getOrganization() || {};
    this.far.foreignAccessRequestLocations?.push(this.location);
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  onSubmit() {
    this.farService
      .save(this.far, new HttpContext().set(SUPPRESS_MESSAGE, true))
      .pipe(takeUntil(this._destroyed))
      .subscribe(
        (result) => {
          this.router.navigate(['/far', result.id]);
        },
        (err) => (this.stepperFooter.busy = false)
      );
  }

  hasRequirements() {
    if (
      this.far.eventTitle &&
      this.far.purposeType &&
      this.location.startDate &&
      this.location.endDate &&
      this.itineraryControl.valid &&
      this.isLocationValid()
    )
      return true;
    return false;
  }

  isLocationValid() {
    if (
      this.far.purposeType === FarPurposeType.FACILITY_ACCESS ||
      this.far.purposeType === FarPurposeType.IN_PERSON_MEETING
    ) {
      if (!this.location.organizationLocation) return false;
    }

    return true;
  }

  hasValidPhoneNumber() {
    let regex = new RegExp(PhoneValidator.getPattern());
    if (this.far?.sponsor?.phone) return regex.exec(this.far?.sponsor?.phone);
    return true;
  }

  hasSponsorRequirements() {
    if (!this.far.sponsor) return true;
    if (
      this.far.sponsor &&
      this.far.sponsor.givenName &&
      this.far.sponsor.surname &&
      this.far.sponsor.email
    ) {
      if (this.far.sponsor.phone) return this.hasValidPhoneNumber();
      return true;
    }

    if (
      this.far.sponsor &&
      !this.far.sponsor.givenName &&
      !this.far.sponsor.surname &&
      !this.far.sponsor.email &&
      !this.far.sponsor.phone
    )
      return true;
    return false;
  }

  isInvalid(farForm: NgForm) {
    if (this.hasRequirements() && this.hasSponsorRequirements()) {
      farForm.form.setErrors(null);
      let sponsorForm: any = farForm.form.controls['farFV'];
      if (sponsorForm) sponsorForm.controls.sponsor.setErrors(null);
      return false;
    }
    farForm.form.setErrors({ incorrect: true });
    return true;
  }

  onRemove(fv: ForeignVisitor) {
    const filtered = this.far.foreignVisitors?.filter((x) => {
      return fv.id != x.id;
    });
    this.far.foreignVisitors = filtered;
  }
}
