import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CitadelIconModule } from '@app/citadel-icon/citadel-icon.module';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { ScreeningFabButtonComponent } from '@shared/components/screening/screening-fab-button/screening-fab-button.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { ScreeningResult } from '@shared/enums/screening-result.enum';

@Component({
  selector: 'app-far-fvs-rollup',
  templateUrl: './far-fvs-rollup.component.html',
  styleUrls: ['./far-fvs-rollup.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CitadelIconModule,
    AuthEventsDialogComponent,
    AuthEventsDialogDirective,
    ScreeningFabButtonComponent,
    MatBadgeModule,
    MatIconModule,
    MatButtonModule,
  ],
})
export class FarFvsRollupComponent implements OnInit {
  @Input() foreignAccessRequestScreeningCounts?: any;
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() hideBackgroundColor: boolean = false;
  ScreeningResult = ScreeningResult;
  reviewedRedRollup: any = {
    status: 'RED',
    total: 0,
  };

  machineRedRollup: any = {
    status: 'MACHINE_RED',
    total: 0,
  };

  greenRollup: any = {
    status: 'GREEN',
    total: 0,
  };

  otherRollup: any = {
    status: 'OTHER',
    total: 0,
  };

  ngOnInit(): void {
    if (this.foreignAccessRequestScreeningCounts) {
      this.machineRedRollup.total =
        this.foreignAccessRequestScreeningCounts.machineRedResultCount;
      this.greenRollup.total =
        this.foreignAccessRequestScreeningCounts.greenResultCount;
      this.reviewedRedRollup.total =
        this.foreignAccessRequestScreeningCounts.redResultCount;
      this.otherRollup.total =
        this.foreignAccessRequestScreeningCounts.otherResultCount;
    }
  }
}
