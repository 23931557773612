import { NgIf } from '@angular/common';
import { Directive, Input, OnInit, inject } from '@angular/core';
import { AppConfigService } from '@shared/services/app-config.services';

@Directive({
  selector: '[highEnvFeatures]',
  hostDirectives: [NgIf],
  standalone: true,
})
export class HighOnlyEnabledDirective implements OnInit {
  @Input() highEnvFeatures?: boolean = false;
  private readonly ngIfDirective = inject(NgIf);

  constructor(private config: AppConfigService) {}
  ngOnInit(): void {
    this.ngIfDirective.ngIf =
      this.config.get('highEnvFeatures', false) === this.highEnvFeatures;
  }
}
