import { AsyncPipe, CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import { FilterParams } from '@shared/models/filter-params.model';
import { Organization } from '@shared/models/organization.model';
import { PageableCollection } from '@shared/models/pageable-collection.model';
import { CrudService } from '@shared/services/crud.service';
import { OrganizationService } from '@shared/services/organization.service';
import { Repository } from '@shared/services/repository.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { OrganizationsCollectionComponent } from '../collection/collection.component';

@Component({
  selector: 'app-organizations',
  templateUrl: 'organizations.component.html',
  styleUrls: ['organizations.component.scss'],
  providers: [
    Repository,
    { provide: CrudService, useClass: OrganizationService },
  ],
  standalone: true,
  imports: [CommonModule, OrganizationsCollectionComponent, AsyncPipe],
})
export class OrganizationsComponent implements OnInit {
  loading: boolean = false;
  filterParams: FilterParams = new FilterParams({
    pageNum: 0,
    pageSize: 20,
    sortBy: 'name',
    direction: 'ASC',
  });
  organizations$: Observable<PageableCollection<Organization>> =
    this.repo.collection$.pipe(
      tap((_) => {
        this.loading = false;
      })
    );

  constructor(private repo: Repository<Organization>) {}
  ngOnInit(): void {
    Object.assign(this.filterParams, this.queryParamsSnapshot);
    this.onFilterParamsChange(this.filterParams);
  }

  get queryParamsSnapshot(): Params {
    return this.repo.getQueryParamsSnapshot();
  }

  onFilterParamsChange(filterParams: FilterParams) {
    this.loading = true;
    this.filterParams = filterParams;
    this.repo.navigateWithParams(filterParams);
  }
}
