import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { OrganizationSelectComponent } from '@shared/controls/organization-select/organization-select.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { FilterConfigType } from '@shared/enums/filter-config-type.enum';
import { BaseOptionFilter } from '@shared/models/filter-config/base-option-filter.model';
import { FilterConfig } from '@shared/models/filter-config/filter-config.type';
import { FNFilterConfig } from '@shared/models/filter-config/fn-filter-config.model';
import { GroupIDFilterConfig } from '@shared/models/filter-config/group-id-filter-config.model';
import { LocationIDFilterConfig } from '@shared/models/filter-config/location-id-filter-config.model';
import { OrgFilterConfig } from '@shared/models/filter-config/org-filter-config.model';
import { UserFilterConfig } from '@shared/models/filter-config/user-filter-config.model';
import { FilterParams } from '@shared/models/filter-params.model';
import { CountryPipe } from '@shared/pipes/country.pipe';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { FullnamePipe } from '@shared/pipes/fullname.pipe';
import { GetOrgNamePipe } from '@shared/pipes/get-org-name.pipe';
import { RolePipe } from '@shared/pipes/role.pipe';
import { FvService } from '@shared/services/fv.service';
import { GroupService } from '@shared/services/groups.service';
import { OrganizationService } from '@shared/services/organization.service';
import { OrgLocationService } from '@shared/services/orglocation.service';
import { UserService } from '@shared/services/user.service';
import { resolveProp } from '@shared/shared.functions';
import { PropResolver } from '@shared/shared.types';
import { isArray, isNil, without } from 'lodash';
import { take } from 'rxjs/operators';
import { AuthEventsDialogComponent } from '../auth-events-dialog/auth-events-dialog.component';
import { isFilterActive } from './filter-config';

@Component({
  selector: 'app-active-search-display',
  templateUrl: './active-search-display.component.html',
  styleUrls: ['./active-search-display.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    AuthEventsDialogComponent,
    AuthEventsDialogDirective,
    OrganizationSelectComponent,
    RolePipe,
    FullnamePipe,
    DefaultPipe,
    GetOrgNamePipe,
    CountryPipe,
  ],
})
export class ActiveSearchDisplayComponent implements OnInit, OnChanges {
  // Just saving filter ref objs, in case needed from special filters (FN, Org, etc)
  filterRefObjs: { [key: string]: any } = {};

  @Input() filterParams: FilterParams;
  @Input() filterFields: FilterConfig[];

  @Output() filterParamsChange = new EventEmitter<FilterParams>();

  constructor(
    private fvService: FvService,
    private orgService: OrganizationService,
    private userService: UserService,
    private orgLocationService: OrgLocationService,
    private groupService: GroupService
  ) {}

  ngOnInit() {
    this.refreshRefObjs();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.refreshRefObjs();
  }

  refreshRefObjs() {
    this.filterFields?.forEach((filter) => {
      if (!this.filterParams[filter.name]) {
        delete this.filterRefObjs[filter.name];
        return;
      }

      if (
        this.filterRefObjs[filter.name]?.id === this.filterParams[filter.name]
      )
        return;

      delete this.filterRefObjs[filter.name];

      if (filter.type === FilterConfigType.ORG) this.loadOrg(filter);
      if (filter.type === FilterConfigType.USER) this.loadUser(filter);
      if (filter.type === FilterConfigType.FN) this.loadFN(filter);
      if (filter.type === FilterConfigType.LOCATION_ID)
        this.loadLocation(filter);
      if (filter.type === FilterConfigType.GROUP_ID) this.loadGroup(filter);
    });
  }

  loadOrg(filter: OrgFilterConfig) {
    this.orgService
      .get(this.filterParams[filter.name])
      .pipe(take(1))
      .subscribe((data) => (this.filterRefObjs[filter.name] = data));
  }

  loadUser(filter: UserFilterConfig) {
    this.userService
      .get(this.filterParams[filter.name])
      .pipe(take(1))
      .subscribe((data) => (this.filterRefObjs[filter.name] = data));
  }

  loadFN(filter: FNFilterConfig) {
    this.fvService
      .get(this.filterParams[filter.name])
      .pipe(take(1))
      .subscribe((data) => (this.filterRefObjs[filter.name] = data));
  }

  loadLocation(filter: LocationIDFilterConfig) {
    this.orgLocationService
      .get(this.filterParams[filter.name])
      .pipe(take(1))
      .subscribe((data) => (this.filterRefObjs[filter.name] = data.name));
  }

  loadGroup(filter: GroupIDFilterConfig) {
    if (this.filterParams[filter.name][0]) {
      this.groupService
        .get(this.filterParams[filter.name])
        .pipe(take(1))
        .subscribe((data) => (this.filterRefObjs[filter.name] = data.name));
    }
  }

  isActive(): boolean {
    return !!this.filterFields?.find((filter) => {
      return isFilterActive(filter, this.filterParams);
    });
  }

  clearAll() {
    this.filterFields?.forEach((config) => {
      this.clearField(config, this.filterParams);
      delete this.filterRefObjs[config.name];
    });
    this.refresh(true);
  }

  clear(config: FilterConfig, value: any) {
    if (isArray(this.filterParams[config.name])) {
      this.filterParams[config.name] = without(
        this.filterParams[config.name],
        value
      );
    } else {
      this.clearField(config, this.filterParams);
    }
    delete this.filterRefObjs[config.name];
    this.refresh(true);
  }

  refresh(resetPage = false) {
    if (resetPage) this.filterParams.pageNum = 0;
    this.filterParamsChange.emit(this.filterParams);
  }

  splitFilterValues(filter: FilterConfig) {
    const value = this.filterParams[filter.name];
    return isArray(value) ? value : [value];
  }

  resolveOptValLabel(value: any, filter: BaseOptionFilter) {
    const values = isArray(value) ? value : isNil(value) ? [] : [value];

    return values.map((val) => {
      return resolveProp(
        this.resolveOpt(filter.options, val, filter.optVal),
        filter.optLabel
      );
    });
  }

  clearField(config: FilterConfig, params: FilterParams) {
    if (config.type === FilterConfigType.DATE_RANGE) {
      delete params[config.name + 'Start'];
      delete params[config.name + 'End'];
    }

    delete params[config.name];
  }

  resolveOpt(options: any[], value: any, valResolve?: PropResolver) {
    return options.find((opt) => resolveProp(opt, valResolve) === value);
  }

  protected readonly FilterConfigType = FilterConfigType;
  protected readonly isFilterActive = isFilterActive;
}
