import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { FilterParams } from '@shared/models/filter-params.model';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { FvService } from '@shared/services/fv.service';

@Component({
  selector: 'app-usp-delete-dialog',
  templateUrl: './usp-delete-dialog.component.html',
  styleUrls: ['./usp-delete-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatTooltipModule,
    MatButtonModule,
    MatSnackBarModule,
    FormsModule,
    MatCheckboxModule,
    AuthEventsDialogComponent,
    AuthEventsDialogDirective,
  ],
})
export class UspDeleteDialogComponent {
  isUsp: boolean = false;

  constructor(
    private fvService: FvService,
    private dialog: MatDialogRef<UspDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ForeignVisitor
  ) {}

  confirmDelete() {
    this.fvService
      .deleteUSP(this.data.id, new FilterParams({ isUsp: this.isUsp }))
      .subscribe(() => {
        this.dialog.close(true);
      });
  }
}
