import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContactSupportDialogComponent } from '@shared/components/contact-support-dialog/contact-support-dialog.component';

@Directive({
  selector: '[appContactSupport]',
  standalone: true,
})
export class ContactSupportDialogDirective {
  @Output() appSupportDialogClosed = new EventEmitter<void>();

  constructor(private dialog: MatDialog) {}

  @HostListener('click', ['$event'])
  onClick() {
    const dialog = ContactSupportDialogComponent.openDialog(this.dialog);
    dialog.afterClosed().subscribe(() => {
      return this.appSupportDialogClosed.emit();
    });
  }
}
