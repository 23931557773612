import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { AuthService } from '@app/auth/auth.service';
import { ClassificationBannerComponent } from '@shared/cmt/classification-banner.component';
import { ClassifyShowDirective } from '@shared/cmt/classify-show.directive';
import { ClassifyDirective } from '@shared/cmt/classify.directive';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { OrganizationSelectComponent } from '@shared/controls/organization-select/organization-select.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { DebounceClickDirective } from '@shared/directives/debounce-click.directive';
import { Organization } from '@shared/models/organization.model';
import { Role } from '@shared/models/role';
import { User } from '@shared/models/user.model';
import { GetOrgNamePipe } from '@shared/pipes/get-org-name.pipe';
import { OrganizationService } from '@shared/services/organization.service';
import { cloneDeep } from 'lodash';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { finalize, take } from 'rxjs/operators';
import { OrganizationFormComponent } from '../organization-form/organization-form.component';
@Component({
  selector: 'app-organization-dialog',
  templateUrl: './organization-dialog.component.html',
  styleUrls: ['./organization-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ClassificationBannerComponent,
    OrganizationDialogComponent,
    OrganizationFormComponent,
    OrganizationSelectComponent,
    GetOrgNamePipe,
    AuthEventsDialogDirective,
    AuthEventsDialogComponent,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    MatSnackBarModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatSortModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    ClassifyDirective,
    ClassifyShowDirective,

    DebounceClickDirective,
  ],
})
export class OrganizationDialogComponent implements OnInit {
  public Role = Role;
  organization: Organization = {};
  user: User | null = this.authService.getUser();
  @ViewChild('organizationForm') organizationForm: OrganizationFormComponent;

  /**
   * This needs to be disabled until API can be updated.
   * Errors occur in other environments because there is no access to
   * GET api/v2/systemUsers/{id} endpoint.
   *
   @ViewChild('resourceUserDetails')
   resourceUserDetails: ResourceUserDetailsComponent;
   *
   */

  constructor(
    @Inject(MAT_DIALOG_DATA) public id: string | null,
    public organizationDialog: MatDialogRef<OrganizationDialogComponent>,
    private orgService: OrganizationService,
    public authService: AuthService
  ) {}

  public disabledSubmit: boolean = false;

  ngOnInit(): void {
    this.disabledSubmit = false;
    if (this.id) {
      this.getOrganization(this.id);
    }
  }

  displayResourceDetails(): boolean {
    if (this.user?.roles) {
      if (this.user?.roles.includes(Role.sv_admin) && this.id) {
        return true;
      }
      return false;
    }
    return false;
  }

  getOrganization(id: string) {
    this.orgService
      .get(id)
      .pipe(take(1))
      .subscribe((data) => {
        this.organization = cloneDeep(data);
      });
  }

  // Handles location changes emitted from from component
  onOrganizationChange(organization: any) {
    this.organization = organization;
  }

  onSubmit() {
    this.disabledSubmit = true;
    this.orgService
      .save(this.organization)
      .pipe(
        take(1),
        finalize(() => (this.disabledSubmit = false))
      )
      .subscribe((data) => {
        this.organizationDialog.close(data);
      });
  }

  static openDialog(
    dialog: MatDialog,
    organization?: Organization
  ): MatDialogRef<OrganizationDialogComponent> {
    return dialog.open<OrganizationDialogComponent>(
      OrganizationDialogComponent,
      {
        data: organization,
        width: '750px',
      }
    );
  }
}
