import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { AvatarComponent } from '@shared/components/avatar/avatar.component';
import {
  Colors,
  FieldComponent,
} from '@shared/components/field/field.component';
import { ItineraryControlComponent } from '@shared/components/itinerary-control/itinerary-control.component';
import { ProfilePicSelectDirective } from '@shared/components/profile-pic/profile-pic-select.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { Passport } from '@shared/models/passport.model';
import { FullnamePipe } from '@shared/pipes/fullname.pipe';
import { NgPipesModule } from 'ngx-pipes';

@Component({
  selector: 'app-fn-profile',
  templateUrl: './fn-profile.component.html',
  styleUrls: ['./fn-profile.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FullnamePipe,
    AvatarComponent,
    FieldComponent,
    MatCardModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatTooltipModule,
    NgPipesModule,
  ],
})
export class FnProfileComponent {
  @Input() fn: ForeignVisitor;
  @Input() showPills: boolean = true;
  @Input() groupSelectionMode: boolean = false;
  @Input() additionalDocumentsScreeningEligibility: boolean = false;

  get passports(): Passport[] {
    return this.fn.passports ?? [];
  }

  get passport(): string | null {
    if (!this.fn.passports?.length) return null;
    const latestPassportNumber = this.fn.passports[0].number;
    const latestPassportCountry = this.fn.passports[0].issuingCountryCode;
    return `${latestPassportNumber} | ${latestPassportCountry}`;
  }

  get visa(): string | null {
    if (!this.fn.visas?.length) return null;
    const latestNumber = this.fn.visas[0].number;
    return `${latestNumber}`;
  }

  get nationalId(): string | null {
    if (!this.fn.nationalIds?.length) return null;
    const latestNumber = this.fn.nationalIds[0].nationalId;
    const latestCountry = this.fn.nationalIds[0].issuingCountryCode;
    return `${latestNumber} | ${latestCountry}`;
  }

  get dacaId(): string | null {
    if (!this.fn.dacaIds?.length) return null;
    const latestNumber = this.fn.dacaIds[0].documentId;
    return `${latestNumber}`;
  }

  get diplomaticId(): string | null {
    if (!this.fn.diplomaticIds?.length) return null;
    const latestNumber = this.fn.diplomaticIds[0].documentId;
    return `${latestNumber}`;
  }

  getLabel(fv: ForeignVisitor) {
    if (fv?.passports && fv.passports[0]) return 'passport';
    if (fv?.visas && fv.visas[0]) return 'visa';
    if (fv?.nationalIds && fv.nationalIds[0]) return 'National Id';
    if (fv?.diplomaticIds && fv?.diplomaticIds[0]) return 'Diplomatic Id';
    if (fv?.dacaIds && fv.dacaIds[0]) return 'DACA Id';
    return 'passport';
  }

  getDocumentData(fv: ForeignVisitor) {
    if (fv?.passports && fv.passports[0]) return this.passport;
    if (fv?.visas && fv.visas[0]) return this.visa;
    if (fv?.nationalIds && fv.nationalIds[0]) return this.nationalId;
    if (fv?.diplomaticIds && fv?.diplomaticIds[0]) return this.diplomaticId;
    if (fv?.dacaIds && fv.dacaIds[0]) return this.dacaId;
    return 'ISSUE';
  }

  get dateOfBirth(): string | null {
    if (!this.fn.dateOfBirth) return null;
    return `${this.fn.dateOfBirth}`;
  }

  get labelColor(): Colors {
    return this.getDocumentData(this.fn) !== 'ISSUE' ? 'primary' : 'danger';
  }
  get labelTooltip() {
    if (this.getDocumentData(this.fn) !== 'ISSUE') return '';
    return 'Passport number and passport country are required to screen a Foreign National.';
  }
  get dobColor(): Colors {
    return this.dateOfBirth ? 'primary' : 'danger';
  }
  get dobTooltip() {
    if (this.dateOfBirth) return '';
    return 'Date of Birth is required to screen a Foreign National.';
  }
  get passportTooltip() {
    if (this.passport) return '';
    return 'Passport number and passport country are required to screen a Foreign National.';
  }
  get passportColor(): Colors {
    return this.passport ? 'primary' : 'danger';
  }
}
