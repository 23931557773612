import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AddressPipe } from '@shared/pipes/address.pipe';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { FarLocationPipe } from '@shared/pipes/far-location.pipe';
import { NgPipesModule } from 'ngx-pipes';
import { FarHeaderComponent } from '../far-header/far-header.component';

@Component({
  selector: 'app-far-event',
  templateUrl: './far-event.component.html',
  styleUrls: ['./far-event.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    DefaultPipe,
    FarLocationPipe,
    AddressPipe,
    NgPipesModule,
    MatIconModule,
    MatTooltipModule,
    FarLocationPipe,
    DefaultPipe,
    NgPipesModule,
    FarHeaderComponent,
  ],
})
export class FarEventComponent {
  @Input() showLocation = true;
  @Input() contentTrimLength = 25;
  @Input() primaryLocation?: any = {};
  private pipe: FarLocationPipe = new FarLocationPipe();

  buildCityStateDisplay(city: string, state: string) {
    return this.pipe.buildCityStateDisplay({
      city: city,
      stateProvince: state,
    });
  }
}
