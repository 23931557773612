import { CommonModule, formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ScreeningService } from '@shared/services/screening.service';
import { Color, NgxChartsModule, ScaleType } from '@swimlane/ngx-charts';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UspMetricsService } from '../../usp-metrics.service';

@Component({
  selector: 'app-screening-graph',
  templateUrl: './screening-graph.component.html',
  styleUrls: ['./screening-graph.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    NgxChartsModule,
    MatTooltipModule,
  ],
})
export class ScreeningGraphComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject<void>();
  multi: any[] = [];
  originalMulti: any[];
  loading: boolean = false;
  startDate: string = '';
  endDate: string = '';
  today: Date = new Date();
  datepickerUnit: 'DAY' | 'WEEK' | 'MONTH' = 'DAY';
  datepickerUnitRange: any = {
    DAY: 31,
    WEEK: 180,
    MONTH: 365,
  };
  dateRangeExceeded: boolean = false;
  display: boolean = true;

  screeningCounts: any = {
    total: 0,
    machineRedResultCount: 0,
    greenResultCount: 0,
    redResultCount: 0,
    yellowResultCount: 0,
    USP: 0,
    machineGreenResultCount: 0,
    reviewedGreenResultCount: 0,
    humanReviewCount: 0,
  };

  screeningResultDataPoints: ScreeningResultDataPointType[] = [
    {
      label: 'Machine Red',
      value: 'machineRedResultCount',
      show: true,
    },
    {
      label: 'Green',
      value: 'greenResultCount',
      show: true,
    },
    {
      label: 'Derogatory',
      value: 'redResultCount',
      show: true,
    },
    {
      label: 'Inconclusive',
      value: 'yellowResultCount',
      show: true,
    },
    {
      label: 'USP',
      value: 'totalCount',
      show: true,
    },
    {
      label: 'Machine Green',
      value: 'machineGreenResultCount',
      show: true,
    },
    {
      label: 'Reviewed Green',
      value: 'reviewedGreenResultCount',
      show: true,
    },
  ];

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = true;
  showLegend: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Date';
  showYAxisLabel: boolean = true;
  yAxisLabel: string = 'Count';
  legendTitle: string = 'Screening';
  orgLegendTitle: string = 'Organizations';
  timeline: boolean = true;

  colorScheme: Color = {
    name: 'myScheme',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: [
      '#00acc1',
      '#4caf50',
      '#f44336',
      '#fdd835',
      '#6a1b9a',
      '#4CBB17',
      '#006400',
    ],
  };

  constructor(
    private uspService: UspMetricsService,
    private screenings: ScreeningService
  ) {}

  ngOnInit() {
    this.dateRangeExceeded = false;
    this.endDate = formatDate(new Date(), 'yyyy-MM-dd', 'en-US', 'GMT');
    let calcStart = new Date();
    calcStart.setDate(calcStart.getDate() - 7);
    this.startDate = formatDate(calcStart, 'yyyy-MM-dd', 'en-US', 'GMT');
    this.getScreeningResultsMetrics();
  }

  getScreeningResultsMetrics() {
    this.startDate = formatDate(
      new Date(this.startDate),
      'yyyy-MM-dd',
      'en-US',
      'GMT'
    );
    this.endDate = formatDate(
      new Date(this.endDate),
      'yyyy-MM-dd',
      'en-US',
      'GMT'
    );

    if (this.dateRangeValidCheck()) {
      this.loading = true;
      forkJoin([
        this.screenings.findFvsMetrics(
          this.startDate,
          this.endDate,
          this.datepickerUnit
        ),
        this.uspService.findUspMetrics(
          this.startDate,
          this.endDate,
          this.datepickerUnit
        ),
      ])
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(([metrics, usp]) => {
          this.multi = this.translatetoBarGraph(metrics, usp);
          this.originalMulti = JSON.parse(JSON.stringify(this.multi));
          this.loading = false;
        });
    }
  }

  dateRangeValidCheck() {
    this.dateRangeExceeded = false;
    let maxRange = this.datepickerUnitRange[this.datepickerUnit];
    let futureDate = new Date(this.startDate);
    futureDate.setDate(futureDate.getDate() + maxRange);

    if (new Date(this.endDate) < futureDate) return true;

    this.dateRangeExceeded = true;
    return false;
  }

  translatetoBarGraph(metrics: any, usp: any) {
    let arrayData = Object.keys(metrics);
    let uspData = Object.keys(usp);
    arrayData = arrayData.concat(uspData);
    arrayData = Array.from(new Set(arrayData));
    arrayData.sort((a, b) => (a > b ? 1 : -1));
    let formattedData: any = [];
    this.resetScreeningCount();
    for (let data of arrayData) {
      let series = [];
      for (let seriesItem of this.screeningResultDataPoints) {
        if (seriesItem.label !== 'USP') {
          if (metrics[data]) {
            series.push({
              name: seriesItem.label,
              value: metrics[data][seriesItem.value],
            });

            //Check to not double count greens
            if (seriesItem.value !== 'greenResultCount') {
              this.screeningCounts.total =
                this.screeningCounts.total + metrics[data][seriesItem.value];
            }

            this.screeningCounts[seriesItem.value] =
              this.screeningCounts[seriesItem.value] +
              metrics[data][seriesItem.value];
          } else {
            series.push({
              name: seriesItem.label,
              value: 0,
            });
          }
        } else {
          if (usp[data]) {
            series.push({
              name: seriesItem.label,
              value: usp[data][seriesItem.value],
            });
            this.screeningCounts.total =
              this.screeningCounts.total + usp[data][seriesItem.value];

            this.screeningCounts[seriesItem.label] =
              this.screeningCounts[seriesItem.label] +
              usp[data][seriesItem.value];
          } else {
            series.push({
              name: seriesItem.label,
              value: 0,
            });
          }
        }
      }
      let item = {
        name: data,
        series: series,
      };
      formattedData.push(item);
    }
    this.screeningCounts.humanReviewCount =
      this.screeningCounts.reviewedGreenResultCount +
      this.screeningCounts.redResultCount +
      this.screeningCounts.USP +
      this.screeningCounts.machineRedResultCount;
    return formattedData;
  }

  resetScreeningCount() {
    this.screeningCounts.total = 0;
    this.screeningCounts.USP = 0;
    this.screeningCounts.redResultCount = 0;
    this.screeningCounts.greenResultCount = 0;
    this.screeningCounts.machineRedResultCount = 0;
    this.screeningCounts.yellowResultCount = 0;
    this.screeningCounts.reviewedGreenResultCount = 0;
    this.screeningCounts.machineGreenResultCount = 0;
    this.screeningCounts.humanReviewCount = 0;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onSelect(data: any): void {
    if (typeof data === 'string') {
      let tempData = JSON.parse(JSON.stringify(this.multi));
      let selected = this.findDataParameter(data);
      if (selected) {
        selected.show = !selected.show;
        if (!selected.show) {
          for (let item of tempData) {
            for (let i = 0; i < item.series.length; ++i) {
              if (item.series[i].name === data) {
                item.series[i].value = 0;
              }
            }
          }
        } else {
          for (let i = 0; i < tempData.length; ++i) {
            for (let j = 0; j < tempData[i].series.length; ++j) {
              if (tempData[i].series[j].name === data) {
                tempData[i].series[j].value =
                  this.originalMulti[i].series[j].value;
                break;
              }
            }
          }
        }
      }
      this.multi = tempData;
    }
  }

  findDataParameter(event: any) {
    const selectedBar = this.screeningResultDataPoints.find((model: any) => {
      return model.label === event;
    });

    return selectedBar;
  }
}

interface ScreeningResultDataPointType {
  label: string;
  value: string;
  show: boolean;
}
