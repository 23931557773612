import { Pipe, PipeTransform } from '@angular/core';
import { BaseModel } from '@shared/models/base.model';
import { Organization } from '@shared/models/organization.model';
import { User } from '@shared/models/user.model';

@Pipe({
  name: 'auditUser',
  standalone: true,
})
export class AuditUserPipe implements PipeTransform {
  transform(
    data: BaseModel,
    format: 'createdBy' | 'modifiedBy' = 'createdBy'
  ): User | null {
    if (!data?.auditMetadata) {
      return null;
    }

    if (data?.createdBy && format === 'createdBy') {
      return {
        id: data.createdBy,
        surname: data.auditMetadata?.createdBySurname,
        givenName: data.auditMetadata?.createdByGivenName,
        email: data.auditMetadata?.createdByEmail,
        phone: data.auditMetadata?.createdByPhone,
      };
    }

    if (data?.modifiedBy && format === 'modifiedBy') {
      return {
        id: data.modifiedBy,
        surname: data.auditMetadata?.modifiedBySurname,
        givenName: data.auditMetadata?.modifiedByGivenName,
        email: data.auditMetadata?.modifiedByEmail,
      };
    }

    return null;
  }
}

@Pipe({
  name: 'auditOrg',
  standalone: true,
})
export class AuditOrgPipe implements PipeTransform {
  transform(value: BaseModel): Organization | null {
    if (!value.auditMetadata) return null;
    if (value.auditMetadata.owningOrganizationName) {
      return {
        id: value.ownerOrganizationId,
        name: value.auditMetadata.owningOrganizationName,
        shortName: value.auditMetadata.owningOrganizationShortName,
      };
    }
    return null;
  }
}
