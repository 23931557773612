import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Comment } from '@shared/models/comment.model';
import { Crud } from '@shared/models/crud.decoration';
import { Observable } from 'rxjs';
import { CommentService } from './comment.service';

@Injectable({ providedIn: 'root' })
@Crud({ apiUrl: `${environment.apiUrl}/farComments` })
export class FarCommentService extends CommentService {
  constructor(http: HttpClient) {
    super(http);
  }

  findByParent(parentId: string): Observable<Comment[]> {
    return this.http.get<Comment[]>(`${this.apiUrl}/far/${parentId}`);
  }
}
