import { AsyncPipe } from '@angular/common';
import { HttpClient, HttpContext } from '@angular/common/http';
import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { BYPASS_INTERCEPTOR } from '@shared/helpers/error.interceptor';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

const imageUrlCache: { [key: string]: Observable<SafeUrl> } = {};

@Pipe({
  name: 'secureImage',
  pure: false,
  standalone: true,
})
export class SecureImagePipe implements PipeTransform, OnDestroy {
  private asyncPipe: AsyncPipe;

  constructor(
    private cdr: ChangeDetectorRef,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {
    this.asyncPipe = new AsyncPipe(this.cdr);
  }

  getImageObs(url: string): Observable<SafeUrl> {
    if (!imageUrlCache[url]) {
      imageUrlCache[url] = this.http
        .get(url, {
          responseType: 'blob',
          context: new HttpContext().set(BYPASS_INTERCEPTOR, true),
        })
        .pipe(
          map((val) =>
            this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val))
          ),
          shareReplay()
        );
    }

    return imageUrlCache[url];
  }

  transform(url: string) {
    const imgObs$ = this.getImageObs(url);

    return this.asyncPipe.transform(imgObs$);
  }

  ngOnDestroy() {
    this.asyncPipe.ngOnDestroy();
  }
}
