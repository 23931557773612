import { CommonModule } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import {
  MonitorAlert,
  MonitorAlertType,
  MonitoringService,
  ReplicationMetrics,
} from '@app/monitoring/monitoring.service';
import { InsightCardComponent } from '@shared/components/insights-card/insight-card.component';
import { combineLatestWith, Subject, takeUntil } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatTooltipModule,
    InsightCardComponent,
    RouterLink,
  ],
})
export class DashboardComponent implements OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  alerts: MonitorAlert[];
  replicationMetrics: ReplicationMetrics;
  loading = true;

  constructor(private monitoringService: MonitoringService) {
    this.refresh();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  refresh() {
    this.loading = true;

    this.monitoringService
      .getAlerts()
      .pipe(
        takeUntil(this.ngUnsubscribe),
        combineLatestWith(this.monitoringService.getReplicationMetrics()),
        tap(() => (this.loading = false))
      )
      .subscribe(([alerts, replicationMetrics]) => {
        this.alerts = alerts;
        this.replicationMetrics = replicationMetrics;
      });
  }

  protected readonly MonitorAlertType = MonitorAlertType;
}
