import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule, Sort } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import {
  USER_ADMIN_APPROVAL_COLUMNS,
  USER_ADMIN_APPROVAL_TAB,
  UserAdminApprovalColumnDefs,
} from '@app/user-preference/config/user-admin-approval.config.const';
import { UserPrefSaved } from '@app/user-preference/model/config-view-definitions.model';
import { UserPreferenceFormService } from '@app/user-preference/service/user-preference-form.service';
import { AddApprovalDirective } from '@shared/directives/add-approval.directive';
import { User } from '@shared/models';
import { Approval } from '@shared/models/approval';
import { FilterParams } from '@shared/models/filter-params.model';
import { NetworkDomain } from '@shared/models/network-domain';
import { DaysUntilPipe } from '@shared/pipes/days-until.pipe';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { FullnamePipe } from '@shared/pipes/fullname.pipe';
import { AppConfigService } from '@shared/services/app-config.services';
import { ApprovalService } from '@shared/services/approval.service';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-approval-table',
  templateUrl: './approval-table.component.html',
  styleUrls: ['./approval-table.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    AddApprovalDirective,
    ApprovalTableComponent,
    DaysUntilPipe,
    DefaultPipe,
    FullnamePipe,
  ],
})
export class ApprovalTableComponent implements OnInit, OnDestroy {
  @Input() data: Approval[] = [];
  @Input() filterParams: FilterParams;
  @Output() filterParamsChange = new EventEmitter<FilterParams>();
  private readonly configService: AppConfigService = inject(AppConfigService);
  private readonly authService: AuthService = inject(AuthService);
  private currentUser: User = this.authService.getUser() as User;

  private ngUnsubscribe = new Subject<void>();

  @Output() sortChange = new EventEmitter<Sort>();

  currentNetWork = this.configService.get('highEnvFeatures')
    ? NetworkDomain.J
    : NetworkDomain.U;
  savedPrefs: UserPrefSaved;
  userPrefFormGroup$: Subscription;
  USER_ADMIN_APPROVAL_COLUMNS: UserAdminApprovalColumnDefs =
    USER_ADMIN_APPROVAL_COLUMNS;
  dataColumns: string[] = inject(UserPreferenceFormService).generateDataColumns(
    USER_ADMIN_APPROVAL_TAB
  );

  constructor(
    private approvalService: ApprovalService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.savedPrefs = this.route.snapshot.data.userPrefFormGroup?.value;
    const formGroup: FormGroup = this.route.snapshot.data
      .userPrefFormGroup as FormGroup;
    this.userPrefFormGroup$ = formGroup.valueChanges.subscribe(
      (savedPrefs: UserPrefSaved) => {
        this.savedPrefs = savedPrefs;
      }
    );
  }

  onSortChange(sort: Sort) {
    this.filterParams.sortBy = sort.active;
    this.filterParams.direction = sort.direction.toUpperCase();
    this.filterParamsChange.emit(this.filterParams);
  }

  extendApproval(approval: Approval) {
    this.approvalService.save(approval).subscribe();
  }

  refresh() {
    this.filterParamsChange.emit(this.filterParams);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.userPrefFormGroup$?.unsubscribe();
  }

  protected readonly parseInt = parseInt;
}
