import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddressDialogComponent } from '@shared/components/address-dialog/address-dialog.component';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';

@Directive({
  selector: '[appAddressDialog]',
  standalone: true,
})
export class AddressDialogDirective {
  @Input() appAddressDialog: ForeignVisitor;
  @Output() fvAddressUpdated = new EventEmitter<ForeignVisitor>();
  constructor(private dialog: MatDialog) {}

  @HostListener('click', ['$event'])
  onClick() {
    const dialog = AddressDialogComponent.openDialog(
      this.dialog,
      this.appAddressDialog
    );
    dialog.afterClosed().subscribe((result) => {
      if (result) return this.fvAddressUpdated.emit(result);
    });
  }
}
