import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Injector,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Organization } from '@shared/models/organization.model';
import { OrganizationService } from '@shared/services/organization.service';
import { Observable } from 'rxjs';

import {
  FloatLabelType,
  MatFormFieldAppearance,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatSelect, MatSelectModule } from '@angular/material/select';

import { AsyncPipe, CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HighlightDirective } from '@shared/directives/highlight.directive';
import { FilterParams } from '@shared/models/filter-params.model';
import { PageableCollection } from '@shared/models/pageable-collection.model';
import { GetOrgNamePipe } from '@shared/pipes/get-org-name.pipe';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { take } from 'rxjs/operators';
import { BaseMatSelectComponent } from '../base-mat-select.component';

@Component({
  selector: 'app-organization-select',
  templateUrl: './organization-select.component.html',
  styleUrls: ['./organization-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    AsyncPipe,
    FormsModule,

    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatSelectModule,
    MatTooltipModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,

    GetOrgNamePipe,
    HighlightDirective,
  ],
})
export class OrganizationSelectComponent extends BaseMatSelectComponent<Organization> {
  @Input() placeholder = 'Organization';
  @ViewChild('matSelect', { static: true })
  matSelect: MatSelect;
  @Input() floatLabel: FloatLabelType = 'auto';
  @Input() label: string = 'Organization';
  @Input() filterLabel: string = 'Search';
  @Input() parentsOnly = false;
  @Input() excludeChilds = false;
  @Input() excludeOrg: string | string[] | undefined;
  @Input()
  appearance: MatFormFieldAppearance = 'outline';
  @Input() isLoading: boolean = false;
  @Output() isLoadingChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @Output() orgIdChange: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  set orgId(_orgId: string) {
    this.loadOrgId(_orgId);
  }

  get orgId(): string {
    return this.value?.id!;
  }

  baseFilterParams: FilterParams = new FilterParams({
    sort: {
      active: 'name',
      direction: 'asc',
    },
  });

  constructor(
    public injector: Injector,
    private organizationService: OrganizationService
  ) {
    super(injector);
  }

  loadOrgId(orgId: string) {
    if ((!orgId && !this.value) || orgId === this.value?.id) return;

    if (!orgId) {
      // @ts-ignore
      this.value = null;
      super.emitChangeEvent();
      return;
    }

    this.value = { id: orgId };
    this.organizationService
      .get(orgId)
      .pipe(take(1))
      .subscribe((data) => {
        this.value = data;
        super.emitChangeEvent();
      });
  }

  emitChangeEvent() {
    super.emitChangeEvent();
    this.orgIdChange.emit(this.orgId);
  }

  searchItems(
    params: FilterParams
  ): Observable<PageableCollection<Organization>> {
    if (this.parentsOnly) params.hasSubOrgs = true;
    if (this.excludeChilds) params.hasParent = false;
    if (this.excludeOrg) params.excludeOrg = this.excludeOrg;
    return this.organizationService.find(params);
  }
}
