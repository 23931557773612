import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule, Sort } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { CitadelIconModule } from '@app/citadel-icon/citadel-icon.module';
import { FnMetadataComponent } from '@app/foreign-nationals/fn-card/fn-metadata/fn-metadata.component';
import {
  FV_COLUMNS,
  FV_TAB,
} from '@app/user-preference/config/fv.config.const';
import { UserPrefSaved } from '@app/user-preference/model/config-view-definitions.model';
import { UserPreferenceFormService } from '@app/user-preference/service/user-preference-form.service';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { ColumnChooserComponent } from '@shared/components/column-chooser/column-chooser.component';
import { ItineraryDialogComponent } from '@shared/components/itinerary-control/itinerary-dialog.component';
import { ScreeningIndicatorComponent } from '@shared/components/screening/screening-indicator/screening-indicator.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { FlagIconDirective } from '@shared/directives/flag-icon.directive';
import { FilterParams } from '@shared/models/filter-params.model';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { NetworkDomain } from '@shared/models/network-domain';
import { Role } from '@shared/models/role';
import { ScreeningDateFormat } from '@shared/models/screening.model';
import { AgePipe } from '@shared/pipes/age.pipe';
import { CountryPipe } from '@shared/pipes/country.pipe';
import { CurrentScreeningPipe } from '@shared/pipes/current-screening.pipe';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { FullnamePipe } from '@shared/pipes/fullname.pipe';
import { StatusMapperPipe } from '@shared/pipes/status-mapper.pipe';
import { getName } from 'i18n-iso-countries';
import { NgPipesModule } from 'ngx-pipes';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-fv-table',
  templateUrl: './fv-table.component.html',
  styleUrls: ['./fv-table.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    NgPipesModule,
    FullnamePipe,
    DefaultPipe,
    CountryPipe,
    FnMetadataComponent,
    ScreeningIndicatorComponent,
    FlagIconDirective,
    CitadelIconModule,
  ],
})
export class FvTableComponent implements OnInit, OnDestroy {
  @Input() fvs: ForeignVisitor[] = [];
  @Input() filterParams: FilterParams;
  @Input() farScreeningEligibility: boolean = false;
  @Input() currentNetwork: NetworkDomain = NetworkDomain.U;
  @Output() filterParamsChange = new EventEmitter<FilterParams>();
  private userPrefFormGroup$!: Subscription;
  currentOrgId: string;
  Role = Role;
  ScreeningDateFormat = ScreeningDateFormat;
  @Input() groupSelectionMode: boolean = false;

  FV_COLUMNS = FV_COLUMNS;
  savedPrefs?: UserPrefSaved;
  dataColumns: string[] = [
    'select',
    ...inject(UserPreferenceFormService).generateDataColumns(FV_TAB),
  ];

  private ngUnsubscribe = new Subject<void>();

  constructor(
    private router: Router,
    private auth: AuthService,
    private route: ActivatedRoute
  ) {
    this.currentOrgId = this.auth.getOrganization()?.id!;
  }

  viewRow(id: string) {
    this.router.navigateByUrl(`fv/${id}`);
  }

  ngOnInit(): void {
    this.savedPrefs = this.route.snapshot.data.userPrefFormGroup?.value;
    const formGroup: FormGroup = this.route.snapshot.data
      .userPrefFormGroup as FormGroup;
    this.userPrefFormGroup$ = formGroup.valueChanges.subscribe(
      (savedPrefs: UserPrefSaved) => {
        this.savedPrefs = savedPrefs;
      }
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.userPrefFormGroup$?.unsubscribe();
  }

  hasDocumentData(fv: ForeignVisitor) {
    if (fv?.passports && fv.passports[0]) return true;
    if (fv?.visas && fv.visas[0]) return true;
    if (fv?.diplomaticIds && fv?.diplomaticIds[0]) return true;
    if (fv?.dacaIds && fv.dacaIds[0]) return true;
    if (fv?.nationalIds && fv.nationalIds[0]) return true;
    return false;
  }

  onSortChange(sort: Sort) {
    this.filterParams.sortBy = sort.active;
    this.filterParams.direction = sort.direction.toUpperCase();
    this.filterParamsChange.emit(this.filterParams);
  }

  getCountryName(country: string) {
    if (country) return getName(country, 'en');
    return null;
  }

  isAllSelected() {
    const numSelected = this.getNumSelected();
    const numSelectableRows = this.fvs.filter(
      (fv) => !this.isSelectDisabled(fv)
    ).length;
    return numSelected === numSelectableRows;
  }

  isSelectDisabled(fn: ForeignVisitor) {
    return (
      fn.networkDomain !== this.currentNetwork ||
      fn.ownerOrganizationId !== this.currentOrgId
    );
  }

  getNumSelected() {
    let cnt = 0;
    for (let fn of this.fvs) if (fn.selected) ++cnt;
    return cnt;
  }

  setSelection(isSelected: boolean) {
    for (let fn of this.fvs) {
      if (!this.isSelectDisabled(fn)) fn.selected = isSelected;
    }
  }

  toggleSelection(fv: ForeignVisitor) {
    if (!this.isSelectDisabled(fv)) fv.selected = !fv.selected;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) this.setSelection(false);
    else this.setSelection(true);
  }
}
