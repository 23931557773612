import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from '@app/foreign-access-requests/dashboard/dashboard.component';
import { FarCreationComponent } from '@app/foreign-access-requests/far-creation/far-creation.component';
import { FAR_TAB } from '@app/user-preference/config/far.config.const';
import { userPreferenceFormResolver } from '@app/user-preference/resolvers/user-preference-form.resolver';
import { AuthGuard } from '@shared/helpers/auth.guard';
import { FarEditRoles, FarViewRoles } from '@shared/models/role-permissions';
import { FarViewComponent } from './farview/farview.component';

export const FAR_ROUTES: Routes = [
  {
    path: 'fars',
    title: 'FARs',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: { roles: FarViewRoles, viewDefKey: FAR_TAB.viewDefKey },
    resolve: {
      userPrefFormGroup: userPreferenceFormResolver,
    },
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'far',
    title: 'New FAR',
    component: FarCreationComponent,
    canActivate: [AuthGuard],
    data: { roles: FarEditRoles },
  },
  {
    path: 'far/:id',
    title: 'FAR',
    component: FarViewComponent,
    canActivate: [AuthGuard],
    data: {
      roles: FarViewRoles,
    },
  },
];
