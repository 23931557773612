import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipListbox, MatChipsModule } from '@angular/material/chips';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { ConfirmDialogComponent } from '@shared/components/confirm/confirmdialog.component';
import { EmptyStateComponent } from '@shared/components/empty-state/empty-state.component';
import { FvListItemComponent } from '@shared/components/fv-list-item/fv-list-item.component';
import { PaginatorComponent } from '@shared/components/paginator/paginator.component';
import { AppConfigValue } from '@shared/constants/shared.const';
import { BulkUploadDirective } from '@shared/directives/bulk-upload.directive';
import { RolesDirective } from '@shared/directives/roles.directive';
import { ScreeningResult } from '@shared/enums/screening-result.enum';
import { FilterParams } from '@shared/models/filter-params.model';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { Organization } from '@shared/models/organization.model';
import { PageableCollection } from '@shared/models/pageable-collection.model';

import { FVRescreenRoles } from '@shared/models/role-permissions';
import { ScreeningDisplayMap } from '@shared/models/screening.model';
import { AlertService } from '@shared/services/alert.service';
import { FvService } from '@shared/services/fv.service';
import { ScreeningService } from '@shared/services/screening.service';
import { FileUploadModule } from 'ng2-file-upload';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-far-view-associated-fvs',
  templateUrl: './far-view-associated-fvs.component.html',
  styleUrls: ['./far-view-associated-fvs.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FileUploadModule,
    MatButtonModule,
    MatCardModule,
    MatChipsModule,
    MatDividerModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    FvListItemComponent,
    EmptyStateComponent,
    RolesDirective,
    BulkUploadDirective,
    PaginatorComponent,
  ],
})
export class FarViewAssociatedFvsComponent implements OnInit, OnDestroy {
  @ViewChild('matChipListBox') matChipListBox: MatChipListbox;
  private ngUnsubscribe = new Subject<void>();
  baseStatusMap = ScreeningDisplayMap;
  FVRescreenRoles = FVRescreenRoles;
  ScreeningResult = ScreeningResult;
  greenResult: any;
  redResult: any;
  machineRedResult: any;

  @AppConfigValue('additionalDocumentsScreeningEligibility')
  additionalDocumentsScreeningEligibility: boolean = false;
  @Input() isDeletable: boolean = false;
  @Input() isEditable: boolean = false;
  @Input() isRescreenable: boolean = false;
  @Input() fvs: PageableCollection<ForeignVisitor>;
  @Input() farId: string = '';
  @Input() owningOrg?: string = '';
  @Output() addFv = new EventEmitter<void>();
  @Output() addGroup = new EventEmitter<void>();
  @Output() removeFv = new EventEmitter<ForeignVisitor>();
  @Output() refresh = new EventEmitter<void>();
  isSameOrg: boolean = true;
  loading: boolean = false;
  filterParams = new FilterParams({
    sortBy: 'createdDate',
    direction: 'DESC',
    pageNum: 0,
    pageSize: 10,
  });

  constructor(
    private auth: AuthService,
    private router: Router,
    private fvService: FvService,
    private screening: ScreeningService,
    private alert: AlertService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.filterParams.farId = this.farId;
    this.doSearch();
    let userOrg: Organization = this.auth.getOrganization() || {};
    if (this.owningOrg !== userOrg.id) this.isSameOrg = false;
    this.greenResult = ScreeningDisplayMap.get(ScreeningResult.Green);
    this.redResult = ScreeningDisplayMap.get(ScreeningResult.Red);
    this.machineRedResult = ScreeningDisplayMap.get(ScreeningResult.MachineRed);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  doSearch() {
    this.loading = true;
    this.fvService
      .find(this.filterParams)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        this.fvs = data;
        this.loading = false;
      });
  }

  onFilterParamChange(filterParams: FilterParams) {
    this.filterParams = filterParams;
    this.doSearch();
  }

  onAdd() {
    this.addFv.emit();
  }

  onAddGroup() {
    this.addGroup.emit();
  }

  addFilter(result: string) {
    if (this.filterParams.result === result) delete this.filterParams.result;
    else this.filterParams.result = result;
    this.filterParams.pageNum = 0;
    this.doSearch();
  }

  onBulkUpload() {
    this.doSearch();
  }

  onAddNew() {
    this.router.navigateByUrl('fv/far/' + this.farId);
  }

  onRemove(fv: ForeignVisitor) {
    this.removeFv.emit(fv);
  }

  openConfirmationDialog(): MatDialogRef<ConfirmDialogComponent> {
    let data = {
      icon: 'alert-octagon',
      color: 'warn',
      title: `ARE YOU SURE?`,
      message: `This will create a new screening request for all completed Foreign Nationals attached to this FAR.`,
      performAction: () => this.rescreen(),
      confirmText: 'Confirm',
    };

    return this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data,
    });
  }

  rescreen() {
    this.screening
      .rescreenFnByFar(this.farId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.alert.successAlert(
          res + ' Foreign National(s) have been submitted for rescreen'
        );
        this.doSearch();
      });
  }
}
