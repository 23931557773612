import {
  BooleanInput,
  NumberInput,
  coerceBooleanProperty,
  coerceNumberProperty,
} from '@angular/cdk/coercion';
import { CommonModule, DatePipe } from '@angular/common';
import {
  Component,
  ContentChild,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  TimelineBadgeDirective,
  TimelineContentTemplateDirective,
} from './timeline.directive';

export interface TimelineEvent {
  startDate?: string | number | Date;
  [key: string]: any;
}
/**
 * Timeline component will display a collection with a startDate property in ascending
 * order with a badge to the side and content of an event next to the badge
 *
 * ### Default Template
 * Each timeline entry/item is rendered in a material card by default.
 *
 * ```html
 * <app-timeline [data]="events"></app-timeline>
 * ```
 * ### Custom Template
 * To customize the  content of the timeline,
 * pass a custom template with the attribute/directive `timeline-content-tmp`
 * and declear `let-item="item"`.
 *
 * ```html
 * <app-timeline [data]="events">
 *   <ng-template timeline-content-tmp let-item="item">
 *     <div>{{item.startDate}}</div>
 *     <div>{{item.content}}</div>
 *   </ng-template>
 * </app-timeline>
 * ```
 */
@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule, MatCardModule, MatTooltipModule],
})
export class TimelineComponent implements OnChanges {
  /**
   * Position of the vertical line form the event content
   *
   * `true` - The timeline will start on the left (defualt).
   *
   * `false` - The timeline will start on the right.
   */
  @Input() left: boolean = true;

  /**
   *  Verticlal or horizontal timeline orientation
   *
   * `true` - The timeline will be horizontal.
   *
   * `false` - The timeline will be vertical (default).
   */
  @Input()
  get horizontal() {
    return this._horizontal;
  }
  set horizontal(value: BooleanInput) {
    this._horizontal = coerceBooleanProperty(value);
  }
  private _horizontal = false;

  /**
   * Alternates the position of timeline events relative to the timeline.
   *
   * `true` - Timeline events will alternate left and right positions
   * with respect to the vertical line.
   *
   * `false` - Timeline events will be on one side of the vertical line (default)
   */
  @Input() alternate: boolean = false;

  /**
   * Material icon as a string.
   *
   * use [MDI](https://pictogrammers.com/library/mdi/) for icon reference
   */
  @Input() icon: string = '';

  /**
   * Displays a triangle next to timeline content
   *
   * `true` - Displays the triangle (default)
   *
   * `false` - Hides the triangle
   */
  @Input() triangle: boolean = true;

  /**
   * Data must have a startDate property
   */
  @Input() data: TimelineEvent[] = [];

  datePipe = new DatePipe('en-US');

  @HostBinding('style.--badge-size.px')
  @Input()
  get badgeSize() {
    return this._badgeSize;
  }
  set badgeSize(value: NumberInput) {
    this._badgeSize = coerceNumberProperty(value);
  }
  private _badgeSize = 44;

  @HostBinding('style.--timeline-horizontal-width.px')
  @Input()
  public get lineWidth() {
    return this._lineWidth;
  }
  public set lineWidth(value: NumberInput) {
    this._lineWidth = coerceNumberProperty(value);
  }
  private _lineWidth = 44;

  // Custom Templates

  @ContentChild(TimelineContentTemplateDirective, { read: TemplateRef })
  contentTemplate: TemplateRef<any>;

  @ContentChild(TimelineBadgeDirective)
  badgeTemplate: TimelineBadgeDirective;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      changes.data.currentValue?.sort(
        (a: any, b: any): number => a.startDate - b.startDate
      );
    }
  }

  generateIconTooltip(item: any): string {
    if (item.startDate && !item.endDate)
      return this.datePipe.transform(item.startDate) || '';
    else if (item.startDate && item.endDate)
      return (
        this.datePipe.transform(item.startDate) +
        ' - ' +
        this.datePipe.transform(item.endDate)
      );
    return '';
  }
}
