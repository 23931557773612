import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GroupAdvancedSearch } from '@app/groups/collection/group-advanced-search';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { NoResultFoundComponent } from '@shared/components/no-result-found/no-result-found.component';
import { PaginatorComponent } from '@shared/components/paginator/paginator.component';
import { SearchToolbarComponent } from '@shared/components/search-toolbar/search-toolbar.component';
import { SortComponent } from '@shared/components/sort/sort.component';
import { OrganizationSelectComponent } from '@shared/controls/organization-select/organization-select.component';
import { RolesDirective } from '@shared/directives/roles.directive';
import { ActionButton } from '@shared/models/actionbutton.model';
import { FilterParams } from '@shared/models/filter-params.model';
import { GroupSorts } from '@shared/models/group-sort';
import { Group } from '@shared/models/group.model';
import { PageableCollection } from '@shared/models/pageable-collection.model';
import { Role } from '@shared/models/role';
import { FileUploadModule } from 'ng2-file-upload';
import { GroupCollectionTableComponent } from './group-collection-table/group-collection-table.component';

const actionButtons: ActionButton[] = [
  {
    label: 'New Group',
    menuIcon: 'group',
    groupCreate: true,
    roles: [Role.sv_admin, Role.sv_org_admin, Role.sv_org_user],
    color: 'primary',
  },
];
@Component({
  selector: 'app-groups-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    SearchToolbarComponent,
    SortComponent,
    MatSortModule,
    RolesDirective,
    OrganizationSelectComponent,
    PaginatorComponent,
    NoResultFoundComponent,
    FileUploadModule,
    GroupCollectionTableComponent,
  ],
})
export class GroupsCollectionComponent {
  @Input() loading: boolean = false;
  @Input() position: 'fixed' | 'sticky' = 'sticky';
  @Input() pageableCollection: PageableCollection<Group> =
    {} as PageableCollection<Group>;
  @Input() placeholder: string = 'Filter Criteria (e.g): Name, Desciption';
  @Input() workspaceView: boolean = false;
  @Input() filterParams: FilterParams = new FilterParams({});
  @Output() filterParamsChange = new EventEmitter<FilterParams>();

  actionButtons: ActionButton[] = actionButtons;
  GroupSorts = GroupSorts;

  onFilterParamsChange(filterParams: FilterParams) {
    this.filterParamsChange.emit(filterParams);
  }

  protected readonly GroupAdvancedSearch = GroupAdvancedSearch;
}
