import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Directive,
  DoCheck,
  EventEmitter,
  HostBinding,
  Injector,
  Input,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormGroupDirective,
  NgControl,
  NgForm,
  UntypedFormControl,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Subject } from 'rxjs';

/**
 * Reusable Base control class for creating custom controls
 */
@Directive()
export abstract class BaseControlComponent<T>
  implements ControlValueAccessor, DoCheck
{
  /** Current Value */
  value: T;

  /** Reference to default error state matcher  */
  defaultErrorStateMatcher?: ErrorStateMatcher;
  /** Parent form reference */
  parentForm: NgForm | null;
  /** Parent form group reference */
  parentFormGroup: FormGroupDirective | null;
  /** Current form control reference */
  ngControl: NgControl | null;

  /** Whether the component is in an error state (requires injector) */
  errorState: boolean = false;

  /** Emits whenever the component state changes (requires injector) */
  stateChanges = new Subject<void>();

  onChange = (value: T) => {};
  onTouched = () => {};

  @Output()
  selectionChanged = new EventEmitter<T>();

  protected _required = false;
  @Input()
  @HostBinding('class.control-required')
  set required(_required: boolean) {
    this._required = coerceBooleanProperty(_required);
  }

  get required(): boolean {
    return this._required;
  }

  protected _disabled = false;
  @Input()
  set disabled(_disabled: boolean) {
    this._disabled = coerceBooleanProperty(_disabled);
  }

  get disabled(): boolean {
    return this._disabled;
  }

  /**
   *
   * @param injector Reference to injector
   *          (if supplied, omit provide: NG_VALUE_ACCESSOR)
   *
   */
  constructor(injector?: Injector) {
    if (!injector) return;
    this.defaultErrorStateMatcher = injector.get(ErrorStateMatcher);
    this.parentForm = injector.get(NgForm, null, { optional: true });
    this.parentFormGroup = injector.get(FormGroupDirective, null, {
      optional: true,
    });
    this.ngControl = injector.get(NgControl, null, {
      self: true,
      optional: true,
    });
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  /**
   * Updates the error state based on the provided error state matcher.
   * @see https://github.com/angular/components/blob/12.2.x/src/material/core/common-behaviors/error-state.ts#L66
   */
  updateErrorState() {
    const oldState = this.errorState;
    const parent = this.parentFormGroup || this.parentForm;
    const matcher = this.defaultErrorStateMatcher;
    const control = this.ngControl
      ? (this.ngControl.control as UntypedFormControl)
      : null;
    if (!parent || !matcher || !control) return;

    const newState = matcher.isErrorState(control, parent);

    if (newState !== oldState) {
      this.errorState = newState;
      this.stateChanges.next();
    }
  }

  ngDoCheck() {
    this.updateErrorState();
  }

  /**
   * Defaluts to the current value.
   * Extend to change emitted value
   * Child class may need to emit current value or null for instance.
   * @returns
   */
  getEmitValue(): T | null {
    return this.value;
  }

  /**
   * Emit Change Event - call whenever
   * value is changed
   */
  emitChangeEvent() {
    this.onChange(this.getEmitValue() as T);
    this.selectionChanged.emit(this.getEmitValue() as T);
    this.stateChanges.next();
  }

  resetForm() {}

  /** @inheritdoc */
  writeValue(_value: T): void {
    if (_value === null) {
      this.resetForm();
    }

    this.value = _value;
  }

  /** @inheritdoc */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /** @inheritdoc */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /** @inheritdoc */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
