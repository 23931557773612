import { CommonModule } from '@angular/common';
import { Component, Inject, OnDestroy } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ThemePalette } from '@angular/material/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ConfirmDialogData } from '@shared/models/confirm-dialog-data.model';
import { isObservable, Observable, of, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirmdialog.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatProgressSpinnerModule,
  ],
})
export class ConfirmDialogComponent implements ConfirmDialogData, OnDestroy {
  title: string;
  message: string;
  icon: string;
  color: string;

  cancelText: string = 'Cancel';
  showCancel: boolean = true;
  confirmColor: ThemePalette = 'primary';
  confirmText: string = 'Ok';
  successMsg: string | null = null;
  performAction: () => Observable<any> | any = () => true;
  subscription: Subscription = Subscription.EMPTY;
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData
  ) {
    Object.assign(this, data);
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onConfirm(): void {
    this.loading = true;
    const action = this.performAction();
    const obs = isObservable(action) ? action : of(action || true);
    this.subscription = obs
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((result) => {
        this.dialogRef.close(result || true);
      });
  }
}

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class ConfirmDialogModel implements ConfirmDialogData {
  constructor(
    public title: string,
    public message: string,
    public icon: string,
    public color: string
  ) {}
}
