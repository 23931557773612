<div [ngClass]="checkboxLayout" *ngIf="formGroup">
  <ng-container *ngFor="let key of sortedColumnKeys; let i = index">
    <ng-container [formGroup]="columnsFormGroup">
      <mat-checkbox
        *ngIf="
          isColumnPermitted(key) &&
          viewDef.columns[key].hidden === false &&
          (!filterValue || filterMatches(viewDef.columns[key].label))
        "
        [formControlName]="key"
        [id]="columnComponentId + '_' + key"
        [name]="columnComponentId + '_' + key"
        (click)="$event.stopPropagation()"
      >
        {{ viewDef.columns[key].label }}
      </mat-checkbox>
    </ng-container>
  </ng-container>
</div>
