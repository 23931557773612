import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UserPrefSaved } from '@app/user-preference/model/config-view-definitions.model';
import { FilterParams } from '@shared/models/filter-params.model';
import { AccountStatus, User } from '@shared/models/user.model';
import { CrudService } from '@shared/services/crud.service';
import { Repository } from '@shared/services/repository.service';
import { UserService } from '@shared/services/user.service';
import { startWith, switchMap, tap } from 'rxjs/operators';
import { AccountsCollectionsComponent } from './accounts-collection/accounts-collection.component';

@Component({
  selector: 'app-admin-accounts',
  templateUrl: 'accounts.component.html',
  styleUrls: ['accounts.component.scss'],
  providers: [Repository, { provide: CrudService, useExisting: UserService }],
  standalone: true,
  imports: [CommonModule, AccountsCollectionsComponent],
})
export class AccountsComponent implements OnInit {
  loading: boolean = false;
  refresh$ = this.repo.refresh$.pipe(startWith({}));
  users$ = this.refresh$.pipe(
    switchMap(() => {
      return this.repo.collection$;
    }),
    tap((_) => {
      this.loading = false;
    })
  );
  queryParamsArray: Array<string> = [];
  filterParams: FilterParams = this.initParams();

  hasUserPreference: boolean = false;
  userPreference: UserPrefSaved | undefined;

  get queryParamsSnapshot() {
    return this.repo.getQueryParamsSnapshot('accountStatus', 'roles');
  }

  constructor(private repo: Repository<User>) {}

  ngOnInit(): void {
    this.queryParamsArray.length !== 0
      ? (this.filterParams = this.repo.defaultFilterParams)
      : Object.assign(this.filterParams, this.queryParamsSnapshot);
    if (!this.isFilterParams() && this.filterParams['sortBy'] !== 'lastLogin') {
      this.filterParams.accountStatus = [
        AccountStatus.Active,
        AccountStatus.PreRegistered,
      ];
    }
    this.onFilterParamsChange(this.filterParams);
  }

  // Initialize the filter Params for User Accounts with non-Inactive Users.
  initParams(): FilterParams {
    this.filterParams = this.repo.defaultFilterParams;
    return this.filterParams;
  }

  isFilterParams() {
    if (
      this.filterParams.surname ||
      this.filterParams.givenName ||
      this.filterParams.organizationId ||
      this.filterParams.emailAddress ||
      (this.filterParams.accountStatus &&
        this.filterParams.accountStatus.length > 0) ||
      (this.filterParams.roles && this.filterParams.roles.length > 0)
    ) {
      return true;
    }
    return false;
  }

  onFilterParamsChange(filterParams: FilterParams) {
    this.loading = true;
    if (this.hasUserPreference) {
      this.filterParams = this.repo.defaultFilterParams;
      this.hasUserPreference = false;
    } else {
      this.filterParams = filterParams;
    }
    this.repo.navigateWithParams(this.filterParams);
  }
}
