import { Injectable } from '@angular/core';
import { AttachmentService } from './attachment.service';
import { environment } from '@environments/environment';
import { Crud } from '@shared/models/crud.decoration';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
@Crud({ apiUrl: `${environment.apiUrl}/attachment/fars` })
export class FarAttachmentService extends AttachmentService {
  constructor(http: HttpClient) {
    super(http);
  }
}
