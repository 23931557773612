import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { EmptyStateComponent } from '@shared/components/empty-state/empty-state.component';
import { CreateFarDirective } from '@shared/components/far-from-fv-dialog/create-far.directive';
import { PaginatorComponent } from '@shared/components/paginator/paginator.component';
import { FAR } from '@shared/models/far.model';
import { FilterParams } from '@shared/models/filter-params.model';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { PageableCollection } from '@shared/models/pageable-collection.model';
import { AppConfigService } from '@shared/services/app-config.services';
import { FarService } from '@shared/services/far.service';
import { FvService } from '@shared/services/fv.service';
import { take } from 'rxjs';
import { FarListComponent } from '../far-list/far-list.component';
import { AddFarDialogComponent } from './add-far-dialog/add-far-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
@Component({
  selector: 'fvview-activity',
  templateUrl: 'fvactivity.component.html',
  styleUrls: ['fvactivity.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatChipsModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    MatTooltipModule,
    FarListComponent,
    PaginatorComponent,
    AuthEventsDialogComponent,
    AuthEventsDialogComponent,
    EmptyStateComponent,
    CreateFarDirective,
    MatProgressSpinnerModule,
  ],
})
export class FvActivityComponent implements OnInit {
  loading = false;
  activeFilter: string = 'all';
  @Input() fv: ForeignVisitor;
  @Input() fars: PageableCollection<FAR>;
  @Input() linkedById: string | null = '';
  upcomingFarMode: boolean = false;
  isDecending: boolean = true;
  farScreeningEligibility: boolean = false;
  filterParams = new FilterParams({
    sortBy: 'primaryFar.startDate',
    direction: 'DESC',
    pageNum: 0,
    pageSize: 10,
  });

  constructor(
    private farService: FarService,
    private config: AppConfigService,
    private dialog: MatDialog,
    private fvService: FvService
  ) {}

  ngOnInit(): void {
    this.farScreeningEligibility =
      this.config.get('farScreeningEligibility') || false;
    this.filterParams.fvId = this.fv.id;
    this.doSearch('all');
  }

  doSearch(type?: string) {
    this.loading = true;
    if (type === 'all') {
      this.filterParams.pageNum = 0;
      delete this.filterParams.isActiveFar;
      this.upcomingFarMode = false;
    } else if (type === 'upcoming') {
      this.upcomingFarMode = true;
      this.filterParams.pageNum = 0;
      this.filterParams.isActiveFar = true;
    }
    this.farService
      .find(this.filterParams)
      .pipe(take(1))
      .subscribe((data) => {
        this.fars = data;
        this.loading = false;
      });

    const fvGet = this.linkedById?.length
      ? this.fvService.getLinked(this.linkedById, this.fv.id)
      : this.fvService.get(this.fv.id);

    fvGet.pipe(take(1)).subscribe((data) => {
      this.fv.activeFarCount = data.activeFarCount;
      this.fv.farCount = data.farCount;
    });
  }

  onAdd() {
    let dialogRef = this.dialog.open(AddFarDialogComponent, {
      data: this.fv,
      width: '600px',
    });

    dialogRef
      .beforeClosed()
      .pipe(take(1))
      .subscribe(() => {
        let ok = dialogRef.componentInstance.ok;
        if (ok) {
          this.doSearch();
        }
      });
  }

  onFilterParamChange(filterParams: FilterParams) {
    this.filterParams = filterParams;
    this.doSearch();
  }

  changeSort(isDecending: boolean) {
    this.isDecending = isDecending;
    if (this.isDecending) this.filterParams.direction = 'DESC';
    else this.filterParams.direction = 'ASC';
    this.doSearch();
  }
}
