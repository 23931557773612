import { CommonModule } from '@angular/common';
import {
  Component,
  Directive,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule, NgModelGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { PhoneNumberControlComponent } from '@shared/controls/phone-number-component/phone-number-control.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { ContactPhoneNumber } from '@shared/models/contact-phone-number.model';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { ForeignVisitorPhoneService } from '@shared/services/foreign-visitor-phone.service';
import { cloneDeep } from 'lodash';
import { AuthEventsDialogComponent } from '../auth-events-dialog/auth-events-dialog.component';
import { DialogCloseMessage } from '@shared/shared.types';

interface PhoneDialogData {
  phone: ContactPhoneNumber;
  fvId: string;
  lockPrimary: boolean;
}

@Directive({
  selector: '[appPhoneDialog]',
  standalone: true,
})
export class PhoneDialogDirective {
  @Input() appPhoneDialog?: ContactPhoneNumber;
  @Input() fvId: string;
  @Input() lockPrimary: boolean;
  @Output() fvPhoneUpdated = new EventEmitter<ContactPhoneNumber>();

  constructor(private dialog: MatDialog) {}

  @HostListener('click', ['$event'])
  onClick() {
    if (!this.appPhoneDialog) this.appPhoneDialog = {};
    const dialogData: PhoneDialogData = {
      phone: cloneDeep(this.appPhoneDialog),
      fvId: this.fvId,
      lockPrimary: this.lockPrimary,
    };
    const dialog = PhoneDialogComponent.openDialog(this.dialog, dialogData);
    dialog.afterClosed().subscribe((result) => {
      if (result) return this.fvPhoneUpdated.emit(result);
    });
  }
}

@Component({
  selector: 'app-phone-dialog',
  templateUrl: './phone-dialog.component.html',
  styleUrls: ['./phone-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatSnackBarModule,
    FormsModule,
    ReactiveFormsModule,
    AuthEventsDialogComponent,
    AuthEventsDialogDirective,
    PhoneNumberControlComponent,
  ],
})
export class PhoneDialogComponent {
  busy: boolean = false;
  phone: ContactPhoneNumber;
  @ViewChild('phoneNumberGroup') phoneNumberGroup: NgModelGroup;
  constructor(
    public phoneDialog: MatDialogRef<PhoneDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fvPhoneService: ForeignVisitorPhoneService
  ) {}

  static openDialog(
    dialog: MatDialog,
    item: PhoneDialogData
  ): MatDialogRef<PhoneDialogComponent> {
    return dialog.open<PhoneDialogComponent>(PhoneDialogComponent, {
      data: item,
      width: '1000px',
    });
  }

  save(phone: ContactPhoneNumber) {
    this.busy = true;
    const update = {
      ...phone,
      ...{
        foreignVisitor: {
          id: this.data.fvId,
        },
      },
    };
    this.fvPhoneService.save(update).subscribe((result: ForeignVisitor) => {
      this.onClose({
        update: result,
        message: `Updated Phone Information for ${result.givenName} ${result.surname} `,
      });
    });
  }
  onClose(message: DialogCloseMessage<ForeignVisitor>) {
    this.phoneDialog.close(message);
  }
}
