import { Pipe, PipeTransform } from '@angular/core';
import { ContactEmail } from '@shared/models/contact-email.model';
import { ForeignVisitorEmailService } from '@shared/services/foreign-visitor-email.service';

@Pipe({
  name: 'sortEmail',
  standalone: true,
})
export class SortEmailPipe implements PipeTransform {
  constructor(private emailService: ForeignVisitorEmailService) {}
  transform(emails: ContactEmail[]) {
    return this.emailService.organizeEmails(emails);
  }
}
