import { CommonModule } from '@angular/common';
import { Component, HostListener, Input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { NetworkDomain } from '@app/shared/models/network-domain';
import { ScreeningResult } from '@shared/enums/screening-result.enum';
import { ScreeningStatus } from '@shared/enums/screening-status.enum';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { Screening } from '@shared/models/screening.model';
import { PipesModule } from '@shared/pipes/pipes.module';
import { FnLatestScreeningComponent } from './fn-latest-screening/fn-latest-screening.component';
import { FnMetadataComponent } from './fn-metadata/fn-metadata.component';
import { FnProfileComponent } from './fn-profile/fn-profile.component';

const mockScreening: Screening = {
  status: ScreeningStatus.Completed,
  result: ScreeningResult.Green,
  completedDate: new Date(),
};
const mockFv: ForeignVisitor = {
  givenName: 'John',
  surname: 'Doe',
  gender: 'female',
  passports: [
    {
      issuingCountryCode: 'dz',
    },
  ],
  latestScreening: mockScreening,
};

@Component({
  selector: 'app-fn-card',
  templateUrl: './fn-card.component.html',
  styleUrls: ['./fn-card.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatTooltipModule,
    FnLatestScreeningComponent,
    FnProfileComponent,
    FnMetadataComponent,
  ],
})
export class FnCardComponent {
  @Input() fn: ForeignVisitor = mockFv;
  @Input() showPills: boolean = true;
  @Input() hideMetadataFooter: boolean = false;
  @Input() isLinkedNationals: boolean = false;
  @Input() originalFnId: string;
  @Input() farScreeningEligibility: boolean = false;
  @Input() additionalDocumentsScreeningEligibility: boolean = false;
  @Input() groupSelectionMode: boolean = false;
  @Input() currentNetwork: NetworkDomain = NetworkDomain.U;
  currentOrgId: string;

  date = new Date();

  @HostListener('click')
  onClick() {
    if (this.groupSelectionMode) {
      if (this.fn.networkDomain === this.currentNetwork) {
        this.fn.selected = !this.fn.selected;
      }
      return;
    }
    if (this.isLinkedNationals) {
      let url = this.router.serializeUrl(
        this.router.createUrlTree([
          `/fv/${this.originalFnId}/linked/${this.fn.id}`,
        ])
      );
      window.open(url, '_blank');
    } else this.router.navigate([`/fv/${this.fn.id}`]);
  }

  constructor(private router: Router, private auth: AuthService) {
    this.currentOrgId = this.auth.getOrganization()?.id!;
  }

  isSelectDisabled(fn: ForeignVisitor) {
    return (
      fn.networkDomain !== this.currentNetwork ||
      fn.ownerOrganizationId !== this.currentOrgId
    );
  }
}
