import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Crud } from '@shared/models/crud.decoration';
import { Visa } from '@shared/models/visa.model';
import { CrudService } from '@shared/services/crud.service';

@Injectable({ providedIn: 'root' })
@Crud({ apiUrl: `${environment.apiUrl}/visas`, entity: 'Visa' })
export class VisaService extends CrudService<Visa> {
  constructor(http: HttpClient) {
    super(http);
  }
}
