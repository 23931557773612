import { NumberInput, coerceNumberProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { TimelineComponent } from '@shared/components/timeline/timeline.component';
import { TimelineModule } from '@shared/components/timeline/timeline.module';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { Screening } from '@shared/models/screening.model';
import { ScreeningService } from '@shared/services/screening.service';
import { NgPipesModule } from 'ngx-pipes';
import { Observable } from 'rxjs';
import { TransitionPipe } from './transition.pipe';

@Component({
  selector: 'screening-transition',
  templateUrl: './screening-transition.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    AuthEventsDialogComponent,
    AuthEventsDialogDirective,
    TimelineComponent,
    TimelineModule,
    TransitionPipe,
    NgPipesModule,
  ],
})
export class ScreeningTransitionComponent {
  @Input()
  public get screeningId(): string {
    return this._screeningId;
  }
  public set screeningId(value: string) {
    this._screeningId = value;
    this.screening$ = this.screeningService.findHistoryByScreeningId(
      this._screeningId
    );
  }
  private _screeningId: string;

  _loading: boolean = false;

  public get transitions() {
    return this._transitions;
  }
  public set transitions(value) {
    this._transitions = value;
  }
  private _transitions: Screening[];

  @Input()
  public get iconSize() {
    return this._iconSize;
  }
  public set iconSize(value: NumberInput) {
    this._iconSize = coerceNumberProperty(value);
  }
  private _iconSize: number;

  screening$: Observable<Screening[]>;

  constructor(private screeningService: ScreeningService) {}
}
