import { Component, forwardRef, Input, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgForm,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { hasValues } from '@shared/helpers/has-values';
import {
  ContactPhoneNumber,
  PhoneType,
} from '@shared/models/contact-phone-number.model';
import { PhoneValidator } from '@shared/validators/phone-number.validator';
import { BaseControlComponent } from '../base-control.component';
import { CommonModule } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { NgPipesModule } from 'ngx-pipes';

@Component({
  selector: 'app-phone-number-control',
  templateUrl: './phone-number-control.component.html',
  styleUrls: ['./phone-number-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PhoneNumberControlComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PhoneNumberControlComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    NgPipesModule,
  ],
})
export class PhoneNumberControlComponent
  extends BaseControlComponent<ContactPhoneNumber>
  implements Validator
{
  @Input() numberRequired: boolean = true;
  @Input() showPrimaryToggle: boolean = true;
  @Input() lockPrimary: boolean = true;
  @ViewChild('phoneNumberForm') phoneNumberForm: NgForm;
  onValidationChange: any = () => {};
  phoneTypes = PhoneType;
  PhoneValidation = PhoneValidator.getPattern();

  constructor() {
    super();
  }

  hasData(): boolean {
    return hasValues(this.value);
  }

  validate(
    control: AbstractControl<ContactPhoneNumber>
  ): ValidationErrors | null {
    if (this.numberRequired || this.hasData()) {
      if (!control.value) return null;
      if (!control.value.number) return { phone: true };
    } else {
      return null;
    }
    return null;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }
}
