import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatFormFieldAppearance,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { BaseControlComponent } from '@shared/controls/base-control.component';
import { OrganizationSelectComponent } from '@shared/controls/organization-select/organization-select.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { RolesDirective } from '@shared/directives/roles.directive';
import { Group } from '@shared/models/group.model';
import { Role } from '@shared/models/role';
@Component({
  selector: 'app-group-form',
  templateUrl: './group-form.component.html',
  styleUrls: ['./group-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: GroupFormComponent,
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    AuthEventsDialogComponent,
    AuthEventsDialogDirective,
    OrganizationSelectComponent,
    RolesDirective,
  ],
})
export class GroupFormComponent extends BaseControlComponent<Group> {
  @Input() appearance: MatFormFieldAppearance = 'outline';
  Role = Role;
  invalid: boolean = true;

  constructor() {
    super();
  }

  writeValue(value: Group): void {
    this.value = value || {};
  }

  hasValidValues() {
    if (this.value.name && this.value.ownerOrganizationId) {
      return true;
    }
    return false;
  }
}
