import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Organization } from '@shared/models/organization.model';
import { OrganizationDialogComponent } from '../../organizations/collection/organization-dialog/organization-dialog.component';

@Directive({
  selector: '[appOrganizationEdit]',
  standalone: true,
})
export class OrganizationEditDirective {
  @Input() appOrganizationEdit: Organization | undefined;
  @Output() appOrganizationDialogOpen = new EventEmitter<
    MatDialogRef<OrganizationDialogComponent, Organization>
  >();

  @Output() appOrganizationEdited = new EventEmitter<Organization>();

  constructor(private dialog: MatDialog) {}

  @HostListener('click', ['$event'])
  onClick(_e: Event) {
    const dialog = OrganizationDialogComponent.openDialog(
      this.dialog,
      this.appOrganizationEdit
    );
    dialog.afterClosed().subscribe((result: Organization) => {
      if (result) {
        this.appOrganizationEdited.emit(result);
      }
    });

    this.appOrganizationDialogOpen.emit(dialog);
  }
}
