import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeIconDirective } from '@shared/directives/mat-badge-icon.directive';
import { StopPropagationDirective } from '@shared/directives/stop-propagation.directive';
import { FilterParams } from '@shared/models/filter-params.model';
import { SortSelect } from '@shared/models/sort-select.model';

@Component({
  selector: 'app-sort',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatSelectModule,
    MatSortModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    StopPropagationDirective,
    MatBadgeIconDirective,
  ],
})
export class SortComponent {
  @Input() sortFields: SortSelect[];
  @Input() filterParams: FilterParams = new FilterParams();
  @Output() filterParamsChange: EventEmitter<FilterParams> = new EventEmitter();

  toggleDirection() {
    this.filterParams.direction = this.isAsc ? 'DESC' : 'ASC';
    this.emitChangeEvent();
  }

  get isAsc(): boolean {
    return this.filterParams?.direction !== 'DESC';
  }

  get activeSort(): string {
    return this.filterParams?.sortBy as string;
  }

  set activeSort(_sort: string) {
    this.filterParams.sortBy = _sort;
    this.emitChangeEvent();
  }

  emitChangeEvent() {
    this.filterParamsChange.emit(this.filterParams);
  }
}
