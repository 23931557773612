import { Address } from '@shared/models/address.model';
import { BaseModel } from '@shared/models/base.model';

export interface FvWork extends BaseModel {
  id?: string;
  employerName?: string; // (60)
  employerType?: string; // (40)
  employerPhone?: EmployerType; // (20)
  organizationalOwnership?: OrganizationalOwnership; // (40)
  address?: Address;
  employeePhone?: string; // (20)
  employeeEmail?: string; // (60)
  occupationTitle?: string; // (60)
}

// enum to match api enum
export enum OrganizationalOwnership {
  GOVERNMENT_ORGANIZATION = 'Government Organization',
  GOVERNMENT_OWNED_ORGANIZATION = 'Government-owned Organization',
  PRIVATE_ORGANIZATION = 'Private Organization',
}
// enum to match api enum
export enum EmployerType {
  FOR_PROFIT = 'For-Profit',
  NON_PROFIT = 'Non-Profit',
  CONTRACTOR = 'Contractor',
  GOVERNMENT = 'Government',
  COMMERCIAL = 'Commercial',
  ACADEMIA = 'Academia',
  INDIVIDUAL = 'Individual',
  FOREIGN_GOVERNMENT = 'Foreign Government',
  FOREIGN_GOVERNMENT_AFFILIATED = 'Foreign Government Affiliated',
}
/**
 * Adds an associated FV to the Work Record.
 * This is necessary when saving an Employment Record
 */
export interface EmploymentRecord extends FvWork {
  foreignVisitor: {
    id: string;
  };
}
