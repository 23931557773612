import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-no-result-found',
  template: `
    <div class="text-center">
      <div class="mat-headline-3">No Results Found</div>
      <mat-icon svgIcon="table-search" class="md-64"></mat-icon>
    </div>
  `,
  standalone: true,
  imports: [CommonModule, MatIconModule],
})
export class NoResultFoundComponent {}
