import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { CrudChangeType } from '@shared/models/crud-change-type.enum';
import { Crud } from '@shared/models/crud.decoration';
import { CrudService } from '@shared/services/crud.service';
import { Observable, tap } from 'rxjs';
import { FilterParams } from '../models';
import { UserFeedback } from '../models/user-feedback.model';

@Injectable({
  providedIn: 'root',
})
@Crud({
  apiUrl: `${environment.apiUrl}/userfeedback`,
  entity: 'User Feedback',
})
export class UserFeedbackService extends CrudService<UserFeedback> {
  constructor(http: HttpClient) {
    super(http);
  }

  deleteFeedback(
    id: string,
    filterParams?: FilterParams
  ): Observable<UserFeedback> {
    return this.http
      .delete<UserFeedback>(`${this.apiUrl}/${id}`, {
        params: filterParams,
        context: this.context,
      })
      .pipe(
        tap(() => {
          this.changeEvent$.emit({
            type: CrudChangeType.delete,
            modelId: id,
          });
        })
      );
  }
}
