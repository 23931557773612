import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BaseControlComponent } from '@shared/controls/base-control.component';
import { FAR } from '@shared/models/far.model';
import { AddressPipe } from '@shared/pipes/address.pipe';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { FullnamePipe } from '@shared/pipes/fullname.pipe';
import { NgPipesModule } from 'ngx-pipes';

@Component({
  selector: 'app-far-review',
  templateUrl: './far-review.component.html',
  styleUrls: ['./far-review.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    NgPipesModule,
    AddressPipe,
    FullnamePipe,
    DefaultPipe,
  ],
})
export class FarReviewComponent extends BaseControlComponent<FAR> {
  @Input() reviewFAR: FAR = {};

  @Input() formInvalid?: boolean = true;

  constructor() {
    super();
  }
}
