import { CommonModule } from '@angular/common';
import {
  Component,
  Directive,
  EventEmitter,
  HostListener,
  Inject,
  Injector,
  Input,
  Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ClassificationBannerComponent } from '@shared/cmt/classification-banner.component';
import { ClassifyShowDirective } from '@shared/cmt/classify-show.directive';
import { ClassifyDirective } from '@shared/cmt/classify.directive';
import { Comment, CommentType } from '@shared/models/comment.model';
import { AlertService } from '@shared/services/alert.service';
import { CommentService } from '@shared/services/comment.service';
import { FarCommentService } from '@shared/services/far-comment.service';
import { FVCommentService } from '@shared/services/fv-comment.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-comment-dialog',
  templateUrl: './comment-dialog.component.html',
  styleUrls: ['./comment-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatSnackBarModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ClassificationBannerComponent,
    ClassifyShowDirective,
    ClassifyDirective,
  ],
})
export class CommentDialogComponent {
  commentService: CommentService;

  comment: Comment;

  saving = false;

  constructor(
    private injector: Injector,
    private alert: AlertService,
    private dialogRef: MatDialogRef<CommentDialogComponent, Comment>,
    @Inject(MAT_DIALOG_DATA) public data: CommentDialogData
  ) {
    this.commentService =
      this.data.type === 'FAR'
        ? this.injector.get<FarCommentService>(FarCommentService)
        : this.injector.get<FVCommentService>(FVCommentService);

    this.comment = {
      ...this.data?.comment,
    } as Comment;

    if (this.data.parentId && this.data.type === 'FAR') {
      this.comment.foreignAccessRequest = { id: this.data.parentId };
    } else if (this.data.parentId) {
      this.comment.foreignVisitor = { id: this.data.parentId };
    }
  }

  save() {
    this.saving = true;
    this.commentService
      .save(this.comment)
      .pipe(finalize(() => (this.saving = false)))
      .subscribe((result) => {
        this.alert.successAlert('Comment successfully saved.');
        this.dialogRef.close(result);
      });
  }

  static openDialog(
    dialog: MatDialog,
    data?: CommentDialogData
  ): MatDialogRef<CommentDialogComponent, Comment> {
    return dialog.open<CommentDialogComponent, CommentDialogData, Comment>(
      CommentDialogComponent,
      {
        data,
        width: '600px',
      }
    );
  }
}

interface CommentDialogData {
  type?: CommentType;
  parentId?: string;
  comment?: Comment;
}
