import { ClassificationModel } from '@shared/models/classification.model';
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-classification-banner',
  templateUrl: 'classification-banner.component.html',
  standalone: true,
  imports: [CommonModule],
})
export class ClassificationBannerComponent {
  @Input() classificationModel: ClassificationModel;
}
