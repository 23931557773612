import { A11yModule } from '@angular/cdk/a11y';
import { AsyncPipe, CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
  MatDialogState,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { CitadelIconModule } from '@app/citadel-icon/citadel-icon.module';
import { CitadelLogoDirective } from '@shared/directives/citadel-logo.directive';
import { Observable, timer } from 'rxjs';
import { finalize, scan, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'timeout-dialog',
  templateUrl: './timeoutdialog.component.html',
  standalone: true,
  imports: [
    CommonModule,
    A11yModule,
    MatButtonModule,
    CitadelLogoDirective,
    CitadelIconModule,
    MatDialogModule,
    MatIconModule,
    AsyncPipe,
  ],
})
export class TimeoutDialogComponent {
  timeout$: Observable<number>;

  constructor(
    public dialogRef: MatDialogRef<TimeoutDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TimeoutDialogData
  ) {
    this.timeout$ = timer(0, 1000).pipe(
      scan((current) => --current, data.timeout + 1),
      takeWhile((counter) => counter > 0),
      finalize(() => this.onFinalize())
    );
  }

  logout(): void {
    this.dialogRef.close(true);
  }

  onFinalize() {
    // if the dialog is currently open then logout
    if (this.dialogRef.getState() === MatDialogState.OPEN) this.logout();
  }

  static openDialog(
    dialog: MatDialog,
    data: TimeoutDialogData
  ): MatDialogRef<TimeoutDialogComponent> {
    return dialog.open<TimeoutDialogComponent>(TimeoutDialogComponent, {
      maxWidth: '400px',
      data,
      disableClose: true,
    });
  }
}

//data model for dialog
interface TimeoutDialogData {
  timeout: number;
}
