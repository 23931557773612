import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { FieldComponent } from '@shared/components/field/field.component';
import { ItineraryControlComponent } from '@shared/components/itinerary-control/itinerary-control.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { DaysUntilPipe } from '@shared/pipes/days-until.pipe';
import { DefaultPipe } from '@shared/pipes/default.pipe';

@Component({
  selector: 'app-fn-metadata',
  templateUrl: './fn-metadata.component.html',
  styleUrls: ['./fn-metadata.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    AuthEventsDialogComponent,
    AuthEventsDialogDirective,
    ItineraryControlComponent,
    FieldComponent,
    MatCardModule,
    MatTooltipModule,
    MatIconModule,
    MatBadgeModule,
    DaysUntilPipe,
    DefaultPipe,
  ],
})
export class FnMetadataComponent {
  @Input() fn: ForeignVisitor;
  @Input() tableDisplay: boolean = false;
  @Input() hideMetadataFooter: boolean = false;
  @Input() farScreeningEligibility: boolean = false;

  get createdByFullName() {
    const givenName = this.fn.auditMetadata?.createdByGivenName;
    const surName = this.fn.auditMetadata?.createdBySurname;
    return `${givenName} ${surName}`;
  }

  get owningOrgShortName() {
    return this.fn.auditMetadata?.owningOrganizationShortName!;
  }

  get owningOrgName() {
    return this.fn.auditMetadata?.owningOrganizationName!;
  }

  get networkDoimain() {
    return this.fn.networkDomain!;
  }

  get comments() {
    return this.fn.comments?.length ?? 0;
  }

  get linkedVisitors() {
    return this.fn.linkedVisitorCount!;
  }

  get activeFarCount() {
    return this.fn.activeFarCount!;
  }

  get farCount() {
    return this.fn.farCount!;
  }

  get attachmentCount() {
    return this.fn.attachmentCount!;
  }

  get deletionDate() {
    return this.fn.deletionDate!;
  }
}
