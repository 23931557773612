import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { CrudService } from '@shared/services/crud.service';
import { NationalId } from '../models/national-id';
import { Crud } from '@shared/models/crud.decoration';

@Injectable({ providedIn: 'root' })
@Crud({
  apiUrl: `${environment.apiUrl}/nationalIds`,
  entity: 'National ID',
  hasIdPathUpdate: true,
})
export class NationalIdService extends CrudService<NationalId> {
  constructor(http: HttpClient) {
    super(http);
  }
}
