import { HttpContextToken } from '@angular/common/http';

export const ENTITY = new HttpContextToken<string | null>(() => null);
export const SUPPRESS_MESSAGE = new HttpContextToken<boolean>(() => false);

export const HTTPMethodMap = new Map([
  ['POST', 'created'],
  ['PUT', 'updated'],
  ['DELETE', 'deleted'],
]);
