import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { User } from '@shared/models/user.model';
@Directive({
  selector: '[appAuthEventsDialog]',
  standalone: true,
})
export class AuthEventsDialogDirective {
  @Input() authEventUser: User;
  @Output() appAuthEventsClosed = new EventEmitter<void>();

  constructor(private dialog: MatDialog) {}

  @HostListener('click', ['$event'])
  onClick() {
    const dialog = AuthEventsDialogComponent.openDialog(this.dialog, {
      user: this.authEventUser,
    });
    dialog.afterClosed().subscribe(() => {
      return this.appAuthEventsClosed.emit();
    });
  }
}
