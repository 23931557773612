import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { cloneDeep } from 'lodash';
import { PhysicalIdVehicleDialogComponent } from '../fvidentification/physical-id-dialog/physical-id-vehicle-dialog.component';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';

@Component({
  selector: 'app-fvview-identifying-vehicle',
  templateUrl: './fvview-identifying-vehicle.component.html',
  styleUrls: ['./fvview-identifying-vehicle.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatExpansionModule,
    MatDialogModule,
    MatDividerModule,
    MatSnackBarModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatTooltipModule,
    MatMenuModule,
    DefaultPipe,
    AuthEventsDialogComponent,
    AuthEventsDialogDirective,
  ],
})
export class FvviewIdentifyingVehicleComponent {
  @Input() deletable?: boolean = false;
  @Input() editable?: boolean = true;
  @Input() fv: ForeignVisitor;

  constructor(public dialog: MatDialog) {}

  openPhysicalDialog() {
    const dialogConfig: MatDialogConfig = {
      data: cloneDeep(this.fv),
      width: '800px',
    };
    this.dialog.open(PhysicalIdVehicleDialogComponent, dialogConfig);
  }

  featuresIsEmpty() {
    if (!this.fv.hairColor && !this.fv.eyeColor && !this.fv.identifyingMarks)
      return true;
    return false;
  }

  vehicleIsEmpty() {
    if (
      !this.fv.driversLicenseId &&
      !this.fv.vehicleLicensePlate &&
      !this.fv.vehicleMake &&
      !this.fv.vehicleModel
    )
      return true;
    return false;
  }

  isEmpty() {
    return this.vehicleIsEmpty() && this.featuresIsEmpty();
  }
}
