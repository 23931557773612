import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FvViewInfoBannerComponent } from '@app/foreign-visitors/fvview/fv-view-info-banner/fv-view-info-banner.component';
import { FarCardComponent } from '@shared/components/far-card/far-card.component';
import {
  Address,
  ContactEmail,
  ContactPhoneNumber,
  ForeignVisitor,
  Passport,
  Visa,
} from '@shared/models';
import { DocumentId } from '@shared/models/document-id.model';
import { CountryPipe } from '@shared/pipes/country.pipe';
import { PhoneNumberPipe } from '@shared/pipes/phone-number.pipe';
@Component({
  selector: 'app-fv-summary',
  templateUrl: './fv-summary.component.html',
  styleUrls: ['./fv-summary.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,

    FarCardComponent,
    FvViewInfoBannerComponent,
    PhoneNumberPipe,
    CountryPipe,
  ],
})
export class FvSummaryComponent {
  @Input() reviewFV?: ForeignVisitor;
  @Input() fvEmail: ContactEmail;
  @Input() fvPhone: ContactPhoneNumber;
  @Input() passport?: Passport = {};
  @Input() visa?: Visa = {};
  @Input() diplomaticId?: DocumentId = {};
  @Input() address?: Address = {};
  @Input() formInvalid = true;
}
