import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormsModule,
  NgForm,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { AuthFlow } from '@app/auth/cognito-auth/cognito-auth.service';
import { AlertService } from '@shared/services/alert.service';
import { confirmMatchValidator } from '@shared/validators/confirm-match.validator';
import { CustomValidators } from '@shared/validators/custom.validators';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AuthEventsDialogComponent } from '../auth-events-dialog/auth-events-dialog.component';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    AuthEventsDialogComponent,
  ],
})
export class ChangePasswordComponent implements OnInit {
  @Input() authFlow: string;
  @ViewChild('formDirective') form: NgForm;

  AuthFlow = AuthFlow;
  requiredAttributes: string[] = [];
  subscription: Subscription;

  passwordForm: UntypedFormGroup;
  updateAttributesForm: UntypedFormGroup;

  email: string;
  result: string;
  hide = {
    oldPassword: true,
    newPassword: true,
    confirmPassword: true,
  };
  success: Promise<any> | string;

  public busy_ = new BehaviorSubject(false);
  public busy$ = this.busy_.asObservable();

  private cognitoUser: CognitoUser;

  constructor(
    private fb: UntypedFormBuilder,
    private auth: AuthService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private alert: AlertService
  ) {}

  ngOnInit(): void {
    this.passwordForm = this.fb.group(
      {
        newPassword: [
          null,
          Validators.compose([
            // 1. Password Field is required
            Validators.required,
            // 2. Check for number
            CustomValidators.patternValidator(/\d/, { hasNumber: true }),
            // 3. check whether the entered password has upper case letter
            CustomValidators.patternValidator(/[A-Z]/, {
              hasCapitalCase: true,
            }),
            // 4. check whether the entered password has a lower-case letter
            CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
            // 5. check whether the entered password has a special character
            CustomValidators.patternValidator(/[!@#*(_\-+{}[\].\/?:']/, {
              hasSpecialCharacter: true,
            }),
            // 6. Has a minimum length of 12 characters
            Validators.minLength(12),
          ]),
        ],

        confirmNewPassword: [null, Validators.required],
      },
      {
        validators: confirmMatchValidator('newPassword', 'confirmNewPassword'),
      }
    );

    if (this.authFlow === AuthFlow.TEMPORARY_PASSWORD) {
      // this.passwordForm.setValidators([])
    }

    if (this.authFlow === AuthFlow.CHANGE_PASSWORD) {
      this.passwordForm.addControl(
        'oldPassword',
        new UntypedFormControl(null, Validators.required)
      );
    }

    this.requiredAttributes = this.auth.getRequiredAttributes();
    this.updateAttributesForm = new UntypedFormGroup({});
    this.requiredAttributes.forEach((attr) => {
      this.updateAttributesForm.addControl(
        attr,
        new UntypedFormControl(null, Validators.required)
      );
    });
    this.passwordForm.addControl(
      'requiredAttributes',
      this.updateAttributesForm
    );

    this.route.queryParams.subscribe((params) => {
      if (params.email) {
        this.email = params['email'];
      }
    });
  }

  get f() {
    return this.passwordForm.controls;
  }

  onSubmit() {
    if (this.passwordForm.invalid) {
      return;
    }

    this.authFlow === AuthFlow.CHANGE_PASSWORD
      ? this.submitChangePassword()
      : this.submitNewPasswordRequired();
  }

  /**
   * **Flow** - Change Password
   */
  public async submitChangePassword() {
    this.busy_.next(true);
    const oldPassword = this.passwordForm.get('oldPassword')?.value;
    const newPassword = this.passwordForm.get('newPassword')?.value;

    try {
      this.success = (await this.auth.changePassword(
        oldPassword,
        newPassword
      )) as string;
      this.onSuccess(this.success);
    } catch (error) {
      const msg = error.message || error;
      this.alert.errorAlert(
        `${msg}, please check your old password and try again`
      );
    } finally {
      this.busy_.next(false);
    }
  }

  /**
   * **Flow** - Initial Login, Change temp Password
   */

  public async submitNewPasswordRequired() {
    this.busy_.next(true);
    const updateAttributes = this.getUpdatedAttributes();
    const newPassword = this.passwordForm.get('newPassword')?.value;

    try {
      this.cognitoUser = await this.auth.answerNewPassword(
        newPassword,
        updateAttributes
      );
    } catch (error) {
      const msg = error.message || error;
      this.alert.errorAlert(msg);
    } finally {
      this.busy_.next(false);
    }
  }

  getUpdatedAttributes() {
    // returns an object with updated Attributes.
    // if no updated attributes are required, then returns {}
    return Object.keys(this.updateAttributesForm.controls).reduce(
      (obj: Object, attr: string) => {
        obj[attr as keyof typeof obj] =
          this.updateAttributesForm.get(attr)?.value;
        return obj || null;
      },
      {}
    );
  }

  onSuccess(success: string) {
    const message = 'Password has been updated';
    this.alert.successAlert(message);
    this.form.resetForm();
  }

  onFailure(err: string) {
    const message = err;
    this.alert.errorAlert(message);
  }
}
