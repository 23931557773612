import { CommonModule } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import {
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgForm,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatFormFieldAppearance,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { AddressComponent } from '@shared/controls/address/address.component';
import { BaseControlComponent } from '@shared/controls/base-control.component';
import { CountrySelectComponent } from '@shared/controls/country-select/country-select.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { FlagIconDirective } from '@shared/directives/flag-icon.directive';
import { ContactType } from '@shared/enums/contact-type.enum';
import { Contact } from '@shared/models/contact.model';
import { Role } from '@shared/models/role';
import { CountryPipe } from '@shared/pipes/country.pipe';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { PhoneValidator } from '@shared/validators/phone-number.validator';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ContactFormComponent,
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    NgxMatSelectSearchModule,
    FormsModule,
    ReactiveFormsModule,
    CountryPipe,
    DefaultPipe,
    AddressComponent,
    AuthEventsDialogComponent,
    AuthEventsDialogDirective,
    CountrySelectComponent,
    FlagIconDirective,
  ],
})
export class ContactFormComponent extends BaseControlComponent<Contact> {
  @ViewChild('contactForm') contactForm: NgForm;
  @Input() appearance: MatFormFieldAppearance = 'outline';
  Role = Role;
  invalid: boolean = true;
  ContactType = ContactType;
  PhoneValidation = PhoneValidator.getPattern();

  constructor() {
    super();
  }

  clearNameFields() {
    this.value.givenName = '';
    this.value.surname = '';
    this.value.groupName = '';
  }

  writeValue(value: Contact): void {
    this.value = value || {};
  }

  get valid(): boolean {
    return this.contactForm?.valid ?? true;
  }
}
