import {
  ChangeDetectorRef,
  Directive,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@app/auth/auth.service';
import { SwitchRolesDialogComponent } from '@app/auth/switch-roles-dialog/switch-roles-dialog.component';
import { AppConfigService } from '@shared/services/app-config.services';
import { UserService } from '@shared/services/user.service';
/**
 */
@Directive({
  selector: '[appSwitchRoles]',
  standalone: true,
})
export class SwitchRolesDirective {
  constructor(
    private dialog: MatDialog,
    private auth: AuthService,
    private user: UserService,
    private config: AppConfigService,
    private viewContainer: ViewContainerRef,
    private changeDetector: ChangeDetectorRef,
    private templateRef: TemplateRef<any>,
    private renderer: Renderer2
  ) {
    this.viewContainer?.clear();
    const show = this.config.get('showRoleSelector');

    // Only show when config enabled, and roles are filtered
    // or has more then one role
    if (show && (this.user.filteredRoles?.length || auth.getRoles()?.length)) {
      const el =
        this.viewContainer.createEmbeddedView(templateRef).rootNodes[0];
      this.changeDetector.markForCheck();
      this.renderer.listen(el, 'click', this.onClick.bind(this));
    }
  }

  onClick() {
    const dialog = SwitchRolesDialogComponent.openDialog(this.dialog);
    dialog.afterClosed().subscribe((result) => {
      if (result) window.location.reload();
    });
  }
}
