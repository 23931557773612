import { NetworkDomain } from '@app/shared/models/network-domain';
import { FarViewRoles } from '@app/shared/models/role-permissions';
import {
  ACTION_COLUMN,
  ADDITIONAL_COLUMN,
} from '../../shared/constants/standard-column.const';
import {
  BaseSavedDef,
  BaseViewDef,
  CitadelColumnDef,
  ViewType,
} from '../model/config-view-definitions.model';

export const FAR_TABLE_NAME = 'farTable';
export interface FARColumnDefs {
  organization: CitadelColumnDef;
  eventTitle: CitadelColumnDef;
  purposeType: CitadelColumnDef;
  createdDate: CitadelColumnDef;
  sponsorSurname: CitadelColumnDef;
  visits: CitadelColumnDef;
  assocFVStatus: CitadelColumnDef;
  additionalData: CitadelColumnDef;
  actions: CitadelColumnDef;
}

export interface FARSortDefs {
  eventTitle: CitadelColumnDef;
  createdDate: CitadelColumnDef;
  lastModifiedDate: CitadelColumnDef;
  sponsorSurname: CitadelColumnDef;
  visits: CitadelColumnDef;
  purposeType: CitadelColumnDef;
  network: CitadelColumnDef;
  organization: CitadelColumnDef;
}

export const FAR_COLUMNS: FARColumnDefs = {
  organization: {
    label: 'Organization',
    hidden: false,
    isSortable: true,
    sortField: 'orgName',
    columnIndex: 0,
  },
  eventTitle: {
    label: 'Event Title',
    hidden: false,
    isSortable: true,
    sortField: 'eventTitle',
    columnIndex: 1,
  },
  purposeType: {
    label: 'Purpose Type',
    hidden: false,
    isSortable: true,
    sortField: 'purposeType',
    columnIndex: 2,
  },
  createdDate: {
    label: 'Created Date',
    hidden: false,
    isSortable: true,
    sortField: 'createdDate',
    columnIndex: 3,
  },
  sponsorSurname: {
    label: 'Sponsor Surname',
    hidden: false,
    isSortable: true,
    sortField: 'sponsorSurname',
    columnIndex: 4,
  },
  visits: {
    label: 'Visits',
    hidden: false,
    isSortable: true,
    sortField: 'locationCount',
    columnIndex: 5,
  },
  assocFVStatus: {
    label: 'Foreign National State',
    hidden: false,
    isSortable: true,
    sortField: 'status',
    columnIndex: 6,
  },
  additionalData: ADDITIONAL_COLUMN,
  actions: ACTION_COLUMN,
};

export const FAR_SORT_FIELDS: FARSortDefs = {
  eventTitle: { ...FAR_COLUMNS.eventTitle, columnIndex: 0 },
  createdDate: { ...FAR_COLUMNS.createdDate, columnIndex: 1 },
  lastModifiedDate: {
    label: 'Last Modified Date',
    columnIndex: 2,
    hidden: false,
    isSortable: true,
    sortField: 'lastModifiedDate',
  },
  sponsorSurname: { ...FAR_COLUMNS.sponsorSurname, columnIndex: 3 },
  visits: { ...FAR_COLUMNS.visits, columnIndex: 3 },
  purposeType: { ...FAR_COLUMNS.purposeType, columnIndex: 4 },
  network: {
    label: 'Network',
    columnIndex: 5,
    hidden: false,
    isSortable: true,
    sortField: 'networkDomain',
  },
  organization: { ...FAR_COLUMNS.organization, columnIndex: 6 },
};

export type FAR_COLUMN_KEYS = keyof typeof FAR_COLUMNS;
export type FAR_SORT_FIELD_KEYS = keyof typeof FAR_SORT_FIELDS;

export interface FARPrefSaved extends BaseSavedDef {
  showColumn: { [key in FAR_COLUMN_KEYS]: boolean };
}

export interface FARTabDef extends BaseViewDef {
  defaultMineOnly: boolean;
  viewType: ViewType;
  columns: { [key in FAR_COLUMN_KEYS]: CitadelColumnDef };
  sortFields: { [key in FAR_SORT_FIELD_KEYS]: CitadelColumnDef };
}

export const FAR_TAB: FARTabDef = {
  viewDefKey: 'far',
  defaultMineOnly: false,
  viewType: 'card',
  columns: FAR_COLUMNS,
  sortFields: FAR_SORT_FIELDS,
  viewName: 'Foreign Access Request (FAR)',
  pageLink: '/fars',
  roles: FarViewRoles,
  tableName: FAR_TABLE_NAME,
  domains: [NetworkDomain.J, NetworkDomain.U],
};
