import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CitadelIconModule } from '@app/citadel-icon/citadel-icon.module';

@Component({
  selector: 'app-passport-help',
  templateUrl: './passport-help.component.html',
  styleUrls: ['./passport-help.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    CitadelIconModule,
    MatButtonModule,
    MatTooltipModule,
  ],
})
export class PassportHelpComponent {}
