import { CommonModule } from '@angular/common';
import { Component, Injector, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BaseControlComponent } from '@shared/controls/base-control.component';
import {
  FAR,
  FarPurposeType,
  FarPurposeTypeTooltip,
  FARTitlePurpose,
} from '@shared/models/far.model';
import { ItineraryControlObservable } from '@shared/services/itinerary-control-observable.service';
@Component({
  selector: 'app-far-details-control',
  templateUrl: './far-details-control.component.html',
  styleUrls: ['./far-details-control.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatTooltipModule,
  ],
})
export class FarDetailsControlComponent extends BaseControlComponent<FARTitlePurpose> {
  constructor(
    injector: Injector,
    private itineraryControl: ItineraryControlObservable
  ) {
    super(injector);
  }

  @Input() hideDescription: boolean = false;
  @Input() isFarCreation: boolean = false;
  FarPurposeType = FarPurposeType;
  FarPurposeTypeTooltip = FarPurposeTypeTooltip;
  value: FAR = {};

  writeValue(_value: FARTitlePurpose): void {
    super.writeValue(_value);
    this.value = _value || {};
  }

  checkLocationRequirement() {
    if (
      this.value.purposeType === FarPurposeType.FACILITY_ACCESS ||
      this.value.purposeType === FarPurposeType.IN_PERSON_MEETING
    ) {
      this.itineraryControl.locationRequired();
    } else if (
      this.value.purposeType === FarPurposeType.OTHER ||
      this.value.purposeType === FarPurposeType.SYSTEM_ACCESS ||
      this.value.purposeType === FarPurposeType.VIRTUAL_MEETING
    ) {
      this.itineraryControl.locationOptional();
    }
  }

  disablePurposeType() {
    if (this.isFarCreation) return false;

    if (
      this.value.foreignAccessRequestLocations &&
      this.value.foreignAccessRequestLocations.length > 0
    ) {
      for (let loc of this.value.foreignAccessRequestLocations)
        if (!loc.organizationLocation) return true;
    }

    return false;
  }

  generateTooltip(): string {
    if (this.disablePurposeType())
      return 'Add Locations to all Itinerary items first to change to this purpose type.';
    return '';
  }
}
