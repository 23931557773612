import { FilterConfigType } from '@shared/enums/filter-config-type.enum';
import { FilterConfig } from '@shared/models/filter-config/filter-config.type';
import { Role } from '@shared/models/role';

export const OrgAdvancedSearch: FilterConfig[] = [
  {
    type: FilterConfigType.INPUT,
    label: 'Name',
    name: 'name',
  },
  {
    type: FilterConfigType.INPUT,
    label: 'Short Name',
    name: 'shortName',
  },
  {
    type: FilterConfigType.TOGGLE,
    label: 'Orgs with FN Records',
    name: 'hasFNRecs',
  },
  {
    type: FilterConfigType.ORG,
    label: 'Parent Organization',
    name: 'parentOrganizationId',
    col: 12,
    ifRole: [
      Role.sv_admin,
      Role.sv_help_desk,
      Role.sv_vetter,
      Role.sv_org_admin,
    ],
    parentsOnly: true,
  },
];
