import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ActivatedRoute } from '@angular/router';
import {
  VIEW_DEF_DEFINITIONS,
  VIEW_DEF_KEYS,
} from '@app/user-preference/constants/view-properties.const';
import {
  BaseViewDef,
  CitadelColumnDef,
} from '@app/user-preference/model/config-view-definitions.model';
import { UserPrefColumnSortType } from '@app/user-preference/model/user-preference.model';
import { UserPreferenceFormService } from '@app/user-preference/service/user-preference-form.service';
import { uniqueId } from 'lodash';

@Component({
  selector: 'app-user-preference-column-checkboxes',
  standalone: true,
  imports: [CommonModule, MatCheckboxModule, ReactiveFormsModule],
  templateUrl: './user-preference-column-checkboxes.component.html',
  styleUrl: './user-preference-column-checkboxes.component.scss',
})
export class UserPreferenceColumnCheckboxesComponent implements OnInit {
  @Input() viewDefKey: VIEW_DEF_KEYS;
  @Input() checkboxLayout?: 'checkbox-columns' | 'checkbox-rows' =
    'checkbox-rows';
  @Input() sortType: UserPrefColumnSortType = 'ColumnOrderProperty';
  filterValue: string; // Filter the columns displayed
  permittedColumns: string[] = [];

  columnComponentId: string = uniqueId(
    UserPreferenceColumnCheckboxesComponent.name
  );
  viewDef: BaseViewDef;
  formGroup: FormGroup;

  private readonly userPrefFormService: UserPreferenceFormService = inject(
    UserPreferenceFormService
  );
  private readonly route: ActivatedRoute = inject(ActivatedRoute);

  toggleAll(select: boolean) {
    Object.keys(this.columnsFormGroup.controls).forEach((key: string) => {
      this.columnsFormGroup.get(key)?.patchValue(select);
    });
  }

  ngOnInit(): void {
    // Allow the tabViewDef to be passed or use the resolver's data
    this.viewDefKey = this.route.snapshot?.data?.viewDefKey
      ? this.route.snapshot.data.viewDefKey
      : this.viewDefKey;
    if (!this.viewDefKey)
      throw `The viewDefKey is not defined in ${UserPreferenceColumnCheckboxesComponent.name}. 
    Either specify it as an input or as a data property of the route.`;
    this.viewDef = VIEW_DEF_DEFINITIONS[this.viewDefKey];
    this.permittedColumns = this.userPrefFormService.generateDataColumns(
      this.viewDef
    );
    this.formGroup = this.route.snapshot?.data?.userPrefFormGroup?.get(
      this.viewDefKey
    ) as FormGroup;
  }

  private sortColumnsAlphabetically(): string[] {
    return Object.entries(this.viewDef.columns)
      .sort(
        (
          entry1: [key: string, column: CitadelColumnDef],
          entry2: [key: string, column: CitadelColumnDef]
        ) => {
          if (entry1[1].label > entry2[1].label) return 1;
          if (entry1[1].label < entry2[1].label) return -1;
          return 0;
        }
      )
      .map((sortedEntry: [string, CitadelColumnDef]) => sortedEntry[0]);
  }

  private sortColumnsByColumnIndex(): string[] {
    return Object.entries(this.viewDef.columns)
      .sort(
        (
          entry1: [key: string, column: CitadelColumnDef],
          entry2: [key: string, column: CitadelColumnDef]
        ) => {
          if (entry1[1].columnIndex > entry2[1].columnIndex) return 1;
          if (entry1[1].columnIndex < entry2[1].columnIndex) return -1;
          return 0;
        }
      )
      .map((sortedEntry: [string, CitadelColumnDef]) => sortedEntry[0]);
  }

  isColumnPermitted(key: string): boolean {
    return this.permittedColumns.includes(this.viewDef.columns[key].sortField);
  }

  get columnsFormGroup(): FormGroup {
    return this.formGroup?.get('showColumn') as FormGroup;
  }

  get sortedColumnKeys(): string[] {
    if (this.sortType === 'Alphabetical') {
      return this.sortColumnsAlphabetically();
    }

    return this.sortColumnsByColumnIndex();
  }

  changeDisplayOrder(sortType: UserPrefColumnSortType = 'ColumnOrderProperty') {
    this.sortType = sortType;
  }

  filterMatches(label: string) {
    if (!this.filterValue) return true;
    const regex = new RegExp(this.filterValue, 'gi');
    return regex.test(label);
  }
}
