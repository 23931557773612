import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { FvListItemComponent } from '@shared/components/fv-list-item/fv-list-item.component';
import { ItineraryControlComponent } from '@shared/components/itinerary-control/itinerary-control.component';
import { SelectFvComponent } from '@shared/controls/select-fv/select-fv.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { FAR } from '@shared/models/far.model';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { Group } from '@shared/models/group.model';
import { FullnamePipe } from '@shared/pipes/fullname.pipe';
import { FarService } from '@shared/services/far.service';
import { GroupService } from '@shared/services/groups.service';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { take } from 'rxjs';

@Component({
  selector: 'app-add-fvs-dialog',
  templateUrl: './add-fvs-dialog.component.html',
  styleUrls: ['./add-fvs-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatDividerModule,
    MatSnackBarModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    AuthEventsDialogComponent,
    AuthEventsDialogDirective,
    ItineraryControlComponent,
    SelectFvComponent,
    FvListItemComponent,
    FullnamePipe,
  ],
})
export class AddFvsDialogComponent implements OnInit {
  fvsToAssociate: ForeignVisitor[] = [];
  id: string = '';
  organizationId: string = '';
  busy: boolean = false;
  ok: boolean = false;
  type: 'Group' | 'FAR';
  foreignVisitorGroupId: string = '';
  operation: 'Adding' | 'Removing' = 'Adding';
  appropriatePreposition: 'to' | 'from' = 'to';

  constructor(
    private farService: FarService,
    private groupService: GroupService,
    @Inject(MAT_DIALOG_DATA) private data: FAR | Group | any,
    private dialog: MatDialogRef<AddFvsDialogComponent>
  ) {}

  ngOnInit(): void {
    this.ok = false;
    this.id = this.data.id;

    if (this.data.remove) {
      this.operation = 'Removing';
      this.appropriatePreposition = 'from';
      this.foreignVisitorGroupId = this.id;
      delete this.data.remove;
    }
    if ((this.data as Group).foreignVisitorGroupType) this.type = 'Group';
    else this.type = 'FAR';

    if (this.data && this.data.ownerOrganizationId)
      this.organizationId = this.data.ownerOrganizationId;
  }

  onSubmit() {
    this.ok = true;
    this.busy = true;
    if (this.type === 'FAR') {
      this.farService
        .addFvToFar(this.data.id, this.fvsToAssociate)
        .pipe(take(1))
        .subscribe((far) => {
          this.onClose();
        });
    } else if (this.type === 'Group') {
      let idArray = [];
      for (let fv of this.fvsToAssociate) idArray.push(fv.id);

      if (this.operation === 'Adding') {
        this.groupService
          .addFvToGroup(this.data.id, idArray)
          .pipe(take(1))
          .subscribe((group) => {
            this.onClose();
          });
      } else if (this.operation === 'Removing') {
        this.groupService
          .removeFvFromGroup(this.data.id, idArray)
          .pipe(take(1))
          .subscribe((group) => {
            this.onClose();
          });
      }
    }
  }
  onClose() {
    this.dialog.close();
  }
  onRemove(fv: ForeignVisitor) {
    const filtered = this.fvsToAssociate.filter((x) => {
      return fv.id != x.id;
    });
    this.fvsToAssociate = filtered;
  }
}
