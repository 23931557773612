import { CommonModule } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  MatFormFieldAppearance,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { DateTimeComponent } from '@shared/controls/date-time/date-time.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { MinDateDirective } from '@shared/directives/min-date-time.directive';
import { IDialogData } from '@shared/models/dialog-data.model';
import { SystemAlert } from '@shared/models/system-alert';
import { SystemAlertsCrudService } from '../system-alerts-crud.service';

@Component({
  selector: 'app-system-alert-dialog',
  templateUrl: './system-alert-dialog.component.html',
  styleUrls: ['./system-alert-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatProgressSpinnerModule,
    AuthEventsDialogDirective,
    AuthEventsDialogComponent,
    DateTimeComponent,
    MinDateDirective,
  ],
})
export class SystemAlertDialogComponent implements OnInit {
  appearance: MatFormFieldAppearance = 'outline';
  systemAlert: SystemAlert;
  busy: boolean = false;

  get minEndDate() {
    const now = new Date();
    let min = now;

    if (this.systemAlert.startDate) {
      const startDate = new Date(this.systemAlert.startDate);
      min = startDate > now ? addMinutes(startDate, 5) : now;
    }
    return min;
  }

  constructor(
    private systemAlertService: SystemAlertsCrudService,
    public dialogRef: MatDialogRef<SystemAlertDialogComponent>,
    @Inject(LOCALE_ID) private locale: string,
    @Inject(MAT_DIALOG_DATA) public dialogData: IDialogData<SystemAlert>
  ) {}

  ngOnInit(): void {
    this.systemAlert = { ...this.dialogData.model };
  }

  save() {
    this.busy = true;
    this.systemAlertService.save(this.systemAlert).subscribe(
      (systemAlert) => {
        this.dialogRef.close();
      },
      (err) => (this.busy = false)
    );
  }
}

function addMinutes(date: Date, minutes: number) {
  const minutesInMiliseconds = minutes * 60 * 1000;
  return new Date(+date + minutesInMiliseconds);
}
