import { Routes } from '@angular/router';
import { AuthGuard } from '@shared/helpers/auth.guard';
import { Roles } from '@shared/models';
import { UserPreferenceComponent } from './components/user-preference/user-preference.component';
import { userPrefViewDefResolver } from './resolvers/user-pref-view-def.resolver';
import { userPreferenceFormResolver } from './resolvers/user-preference-form.resolver';
import { userPreferenceResolver } from './resolvers/user-preference.resolver';

export const USER_PREFERENCE_ROUTES: Routes = [
  {
    path: 'user-preferences',
    canActivate: [AuthGuard],
    canDeactivate: [
      (component: UserPreferenceComponent) =>
        component.canDeactivateWithUnsavedChanges(),
    ],
    loadComponent: () =>
      import('./components/user-preference/user-preference.component').then(
        (c) => c.UserPreferenceComponent
      ),
    resolve: {
      userPreference: userPreferenceResolver,
      viewDefs: userPrefViewDefResolver,
      userPrefFormGroup: userPreferenceFormResolver,
    },
    data: { roles: Roles },
  },
];
