import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { CitadelIconModule } from '@app/citadel-icon/citadel-icon.module';
import { ScreeningUpdateDirective } from '@shared/components/screening/screening-dialog/screening-update.directive';
import { ScreeningResult } from '@shared/enums/screening-result.enum';
import { ScreeningStatus } from '@shared/enums/screening-status.enum';
import { Screening, ScreeningDateFormat } from '@shared/models/screening.model';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import {
  ScreeningClassPipe,
  ScreeningLabelPipe,
  ScreeningDatePipe,
} from '@shared/pipes/screening-display.pipe';

@Component({
  selector: 'screening-insight-card',
  templateUrl: 'screening-insight-card.component.html',
  styleUrls: ['screening-insight-card.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    CitadelIconModule,
    ScreeningClassPipe,
    DefaultPipe,
    ScreeningLabelPipe,
    ScreeningDatePipe,
    ScreeningUpdateDirective,
  ],
})
export class ScreeningInsightCardComponent {
  @Input() public value: Screening;
  ScreeningDateFormat = ScreeningDateFormat;
  ScreeningResult = ScreeningResult;

  getDateType(status?: string) {
    if (status === ScreeningStatus.New) return 'Created: ';
    else if (
      status === ScreeningStatus.InProcess ||
      status === ScreeningStatus.Error
    )
      return 'Requested: ';
    else if (
      status === ScreeningStatus.Completed ||
      status === ScreeningStatus.Historical
    )
      return 'Completed: ';
    return '';
  }

  isBlackTextColor(screening: Screening) {
    if (screening.result === ScreeningResult.Yellow) return true;
    return false;
  }
}
