import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { AuthService } from '@app/auth/auth.service';
import { AuthEventResponse } from '@shared/models/auth-event-response.enum';
import { AuthEvent, AuthSummary } from '@shared/models/auth-event.model';
import { User } from '@shared/models/user.model';
import { FullnamePipe } from '@shared/pipes/fullname.pipe';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'app-auth-events-dialog',
  templateUrl: './auth-events-dialog.component.html',
  styleUrls: ['./auth-events-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatTableModule,
    MatProgressSpinnerModule,
    FullnamePipe,
  ],
})
export class AuthEventsDialogComponent {
  AuthEventResponse = AuthEventResponse;

  user: User;

  summary: AuthSummary | null;

  events: AuthEvent[];

  displayedColumns = ['icon', 'type', 'createdDate', 'response', 'location'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AuthEventsDialogData,
    private auth: AuthService,
    private userService: UserService
  ) {
    this.user = data?.user as User;
    if (this.user?.id) {
      this.userService
        .getUsersAuthEvents(this.user.id)
        .subscribe((events) => (this.events = events));
    } else {
      this.auth.getAuthSummary().then((summary) => (this.summary = summary));

      this.userService
        .getAuthEvents()
        .subscribe((events) => (this.events = events));
    }
  }

  static openDialog(
    dialog: MatDialog,
    data: AuthEventsDialogData = {}
  ): MatDialogRef<AuthEventsDialogComponent> {
    return dialog.open<AuthEventsDialogComponent>(AuthEventsDialogComponent, {
      minWidth: '955px',
      data,
    });
  }
}

interface AuthEventsDialogData {
  user?: User;
}
