import { CommonModule } from '@angular/common';
import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  NgForm,
  ReactiveFormsModule,
  ValidationErrors,
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRippleModule } from '@angular/material/core';
import {
  MatFormFieldAppearance,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AuthService } from '@app/auth/auth.service';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { AppConfigValue } from '@shared/constants/shared.const';
import { BaseControlComponent } from '@shared/controls/base-control.component';
import { OrganizationSelectComponent } from '@shared/controls/organization-select/organization-select.component';
import { RolesSelectControlComponent } from '@shared/controls/roles-select-control/roles-select-control.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { RolesDirective } from '@shared/directives/roles.directive';
import { Role, Roles } from '@shared/models/role';
import {
  RoleAssignableBy,
  UserCrossOrgEditRoles,
} from '@shared/models/role-permissions';
import { User } from '@shared/models/user.model';
import { isEqual } from 'lodash';
import { NgPipesModule } from 'ngx-pipes';
@Component({
  selector: 'app-user-form',
  templateUrl: './accounts-user-form.component.html',
  styleUrls: ['./accounts-user-form.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgPipesModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatSelectModule,
    OrganizationSelectComponent,
    RolesSelectControlComponent,
    RolesDirective,
    AuthEventsDialogComponent,
    AuthEventsDialogDirective,
  ],
})
export class AccountsUserFormComponent
  extends BaseControlComponent<User>
  implements OnInit
{
  @AppConfigValue('limitedUserAdmin')
  limitedUserAdmin: boolean = false;
  @ViewChild('userForm') userForm: NgForm;
  @ViewChild('roleChipList') roleChipList: RolesSelectControlComponent;
  @Input() value: User;
  @Input() readOnly: boolean = false;
  @Input() currentUser: User | null;
  @AppConfigValue('roles')
  roles: Role[] = Roles;
  UserCrossOrgEditRoles = UserCrossOrgEditRoles;
  appearance: MatFormFieldAppearance = 'outline';
  Role = Role;

  hideRoleSelect = false;
  assignableRoles: Role[];

  constructor(private injector: Injector, private authService: AuthService) {
    super(injector);
  }

  ngOnInit(): void {
    if (!this.value.id && this.currentUser && this.currentUser.organization) {
      this.value.organization = this.currentUser.organization;
    }

    this.assignableRoles = (Object.keys(RoleAssignableBy) as Role[]).filter(
      (r) => this.authService.hasAnyRole(...RoleAssignableBy[r])
    );

    //  If new user and only 1 assignable role, auto add it
    if (!this.value.id && this.assignableRoles.length === 1) {
      this.value.roles = [...this.assignableRoles];
    }

    //  If only 1 assignable role, and user has them, then hide role select
    if (
      this.assignableRoles.length === 1 &&
      isEqual(this.value.roles, this.assignableRoles)
    ) {
      this.hideRoleSelect = true;
    }
  }

  validate(_control: AbstractControl): ValidationErrors | null {
    return _control.value ? null : { roles: true };
  }
}
