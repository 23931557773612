import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInput, MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  AuthFlow,
  CognitoAuthService,
} from '@app/auth/cognito-auth/cognito-auth.service';
import { PasswordRulesComponent } from '@shared/components/password-rules/password-rules.component';
import { AlertService } from '@shared/services/alert.service';
import { ValidPasswordDirective } from '@shared/validators/password.validator';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-login-forgotaccount',
  templateUrl: 'forgotaccount.component.html',
  styleUrls: ['forgotaccount.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    ValidPasswordDirective,
    PasswordRulesComponent,
  ],
})
export class ForgotaccountComponent {
  AuthFlow = AuthFlow;

  public email = new UntypedFormControl('', [
    Validators.email,
    Validators.required,
  ]);
  newPassword: string;
  confirmPassword: string;
  public code = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern(/\d/),
  ]);
  hide = {
    newPassword: true,
    confirmPassword: true,
  };

  @ViewChild('newPasswordInput') newPasswordInput: MatInput;

  hasNumber = /\d/;
  hasCapitalCase = /[A-Z]/;
  hasSmallCase = /[a-z]/;
  hasSpecialCharacter = /[!@#*(_\-+{}[\].\/?:']/;

  codeRequested = false;
  success = false;

  private busy_ = new BehaviorSubject(false);
  public busy$ = this.busy_.asObservable();

  @Output() viewChange = new EventEmitter<AuthFlow>();

  constructor(private auth: CognitoAuthService, private alert: AlertService) {}

  async requestCode(email: string) {
    this.busy_.next(true);
    try {
      await this.auth.forgotPassword(email);
      this.codeRequested = true;
    } catch (error) {
      this.onError(error);
    } finally {
      this.busy_.next(false);
    }
  }

  async updatePassword(email: string, code: string, newPassword: string) {
    this.busy_.next(true);
    try {
      await this.auth.answerForgotPassword(email, code, newPassword);
      this.success = true;
      this.alert.successAlert('Password Has Been Reset Successfully!');
      let self = this;
      setTimeout(function () {
        self.triggerViewChange(AuthFlow.LOGIN);
      }, 3000);
    } catch (error) {
      this.onError(error);
    } finally {
      this.busy_.next(false);
    }
  }

  get isEmailValid(): boolean {
    return this.email.valid;
  }

  triggerViewChange(view: AuthFlow) {
    this.viewChange.emit(view);
  }

  onError(error: { type: string; message: string }) {
    const msg = error.message || JSON.stringify(error);
    this.alert.errorAlert(msg);
  }
}
