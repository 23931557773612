import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FAR } from '@shared/models/far.model';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { FarFromFvDialogComponent } from './far-from-fv-dialog.component';

@Directive({
  selector: '[appCreateFar]',
  standalone: true,
})
export class CreateFarDirective {
  @Input() fv: Partial<ForeignVisitor> = {};
  @Output() farCreated = new EventEmitter<FAR>();

  constructor(private dialog: MatDialog) {}

  @HostListener('click') openDialog() {
    const dialog = this.dialog.open(FarFromFvDialogComponent, {
      data: this.fv,
      maxWidth: '80vw',
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.farCreated.emit(result);
      }
    });
  }
}
