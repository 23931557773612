import { CommonModule } from '@angular/common';
import { Component, HostListener, Input } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { FAR } from '@shared/models/far.model';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { FieldComponent } from '../field/field.component';
import { FarEventComponent } from './far-event/far-event.component';
import { FarFvsRollupComponent } from './far-fvs-rollup/far-fvs-rollup.component';
import { FarHeaderComponent } from './far-header/far-header.component';
import { FarMetadataComponent } from './far-metadata/far-metadata.component';

@Component({
  selector: 'app-new-far-card',
  templateUrl: './far-card.component.html',
  styleUrls: ['./far-card.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    DefaultPipe,
    FarEventComponent,
    FarMetadataComponent,
    FarFvsRollupComponent,
    FarHeaderComponent,
    FieldComponent,
    MatIconModule,
    MatTooltipModule,
    MatBadgeModule,
    MatCardModule,
    MatIconModule,
    MatBadgeModule,
  ],
})
export class FarCardComponent {
  @Input() far: FAR;
  @Input() showRollups = true;

  @HostListener('click')
  onClick() {
    this.router.navigate([`/far/${this.far.id}`]);
  }

  constructor(private router: Router) {}
}
