import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Crud } from '@shared/models/crud.decoration';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { Group } from '@shared/models/group.model';
import { CrudService } from '@shared/services/crud.service';
import { Observable, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
@Crud({
  apiUrl: `${environment.apiUrl}/foreignVisitorGroup`,
  entity: 'Foreign National Group',
  hasIdPathUpdate: true,
})
export class GroupService extends CrudService<Group> {
  constructor(http: HttpClient) {
    super(http);
  }

  /**
   * @param groupId
   * @param model
   * @returns
   */
  public addFvToGroup(
    groupId: string,
    fvs: ForeignVisitor[]
  ): Observable<Group> {
    return this.http
      .put<Group>(
        `${this.apiUrl}/${groupId}/addfvs`,
        { ids: fvs },
        { context: this.context }
      )
      .pipe(
        tap((m) =>
          this.changeEvent$.emit({
            type: 'update',
            model: m,
            modelId: groupId,
          })
        )
      );
  }

  public removeFvFromGroup(
    groupId: string,
    fvs: ForeignVisitor[]
  ): Observable<Group> {
    return this.http
      .put<Group>(
        `${this.apiUrl}/${groupId}/deletefvs`,
        { ids: fvs },
        { context: this.context }
      )
      .pipe(
        tap((m) =>
          this.changeEvent$.emit({
            type: 'update',
            model: m,
            modelId: groupId,
          })
        )
      );
  }
}
