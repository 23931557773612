import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommentDialogComponent } from '@shared/components/comments/comment-dialog.component';
import { Comment, CommentType } from '@shared/models/comment.model';

@Directive({
  selector: '[appCommentDialog]',
  standalone: true,
})
export class CommentDialogDirective {
  @Input()
  comment: Comment;

  @Input()
  parentId: string;

  @Input()
  appCommentDialog: CommentType;

  @Output() commentSaved = new EventEmitter<Comment>();

  constructor(private dialog: MatDialog) {}

  @HostListener('click', ['$event'])
  onClick() {
    if (!this.appCommentDialog) {
      throw new Error('Missing CommentDialog object type');
    }

    const dialog = CommentDialogComponent.openDialog(this.dialog, {
      type: this.appCommentDialog,
      parentId: this.parentId,
      comment: this.comment,
    });
    dialog.afterClosed().subscribe((comment) => {
      if (comment) this.commentSaved.emit(comment);
    });
  }
}
