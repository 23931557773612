import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { StepperFooterComponent } from '@shared/components/stepper/stepper-footer/stepper-footer.component';
import { ContactEditDirective } from '@shared/directives/contact-edit.directive';
import { Contact } from '@shared/models/contact.model';
import { AddressPipe } from '@shared/pipes/address.pipe';
import { CountryPipe } from '@shared/pipes/country.pipe';
import { DefaultPipe } from '@shared/pipes/default.pipe';

@Component({
  selector: 'app-contact-table',
  templateUrl: './contact-table.component.html',
  styleUrls: ['./contact-table.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatTableModule,
    DefaultPipe,
    CountryPipe,
    StepperFooterComponent,
    ContactEditDirective,
    AddressPipe,
  ],
})
export class ContactTableComponent implements OnInit {
  @Input() contacts: Contact[] = [];
  @Input() showActions: boolean = false;
  @Output() appContactEdited = new EventEmitter<Contact>();

  innerDisplayedColumns: string[] = [
    'givenName',
    'surname',
    'groupName',
    'type',
    'email',
    'phone',
    'address',
  ];

  constructor() {}

  ngOnInit(): void {
    if (this.showActions) this.innerDisplayedColumns.push('contactActions');
  }

  refresh() {
    this.appContactEdited.emit();
  }

  isContactAddress(contact: Contact): boolean {
    if (
      contact.address &&
      (contact.address.city ||
        contact.address.line1 ||
        contact.address.line2 ||
        contact.address.stateProvince ||
        contact.address.countryCode)
    )
      return true;
    return false;
  }
}
