import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AddressDialogDirective } from '@shared/directives/address-dialog.directive';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { EmailDialogDirective } from '@shared/directives/email-dialog.directive';
import { hasValues } from '@shared/helpers/has-values';
import { Address } from '@shared/models/address.model';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { AddressPipe } from '@shared/pipes/address.pipe';
import { CountryPipe } from '@shared/pipes/country.pipe';
import { DaysUntilPipe } from '@shared/pipes/days-until.pipe';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { SortEmailPipe } from '@shared/pipes/sort-emails.pipe';
import { SortPhoneNumbersPipe } from '@shared/pipes/sort-phone-numbers.pipe';
import { NgPipesModule } from 'ngx-pipes';
import { AuthEventsDialogComponent } from '../auth-events-dialog/auth-events-dialog.component';
import { EmailDialogComponent } from '../email-dialog/email-dialog.component';
import { FvEmailDisplayComponent } from '../fv-email-display/fv-email-display.component';
import { FvPhoneDisplayComponent } from '../fv-phone-display/fv-phone-display.component';
import { PhoneDialogDirective } from '../phone-dialog/phone-dialog.component';

@Component({
  selector: 'app-fv-details',
  templateUrl: './fv-details.component.html',
  styleUrls: ['./fv-details.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    AuthEventsDialogComponent,
    AuthEventsDialogDirective,
    FvPhoneDisplayComponent,
    NgPipesModule,
    CountryPipe,
    DefaultPipe,
    CountryPipe,
    AddressPipe,
    SortEmailPipe,
    SortPhoneNumbersPipe,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatSnackBarModule,
    MatDividerModule,
    EmailDialogComponent,
    EmailDialogDirective,
    FvEmailDisplayComponent,
    PhoneDialogDirective,
    AddressDialogDirective,
  ],
})
export class FvDetailsComponent {
  @Input() fv: ForeignVisitor;
  @Input() summary: boolean;
  @Input() card: boolean;
  @Input() compact = false;

  constructor() {}

  //Address Pipe Check: if it's an empty string as opposed to null, will display nothing as opposed to N/A
  hasAddress(address: Address | null): boolean {
    if (address) {
      let addressFields: Address = {
        line1: address.line1,
        line2: address.line2,
        city: address.city,
        stateProvince: address.stateProvince,
        postalCode: address.postalCode,
      };
      return this.hasData(addressFields);
    }
    return false;
  }

  //checks for values with the hasValues method
  hasData(item: any) {
    return hasValues(item);
  }

  checkDeletionDate(date: string) {
    let numDate = new DaysUntilPipe().transform(date);
    return parseInt(numDate) <= 7;
  }
}
