import { CommonModule } from '@angular/common';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  Directive,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FAR } from '@shared/models/far.model';
import { SponsorDialogData } from '@shared/models/sponsor-dialog-data.model';
import { Sponsor } from '@shared/models/sponsor.model';
import { SponsorService } from '@shared/services/sponsor.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SponsorComponent } from './sponsor.component';

@Directive({
  selector: '[appSponsorEdit]',
  standalone: true,
})
export class SponsorEditDirective {
  @Input() appSponsorEdit: FAR;
  @Output() appSponsorDialogOpen = new EventEmitter<
    MatDialogRef<SponsorDialogComponent, Sponsor>
  >();

  @Output() sponsorEdited = new EventEmitter<Sponsor>();

  constructor(private dialog: MatDialog) {}

  @HostListener('click', ['$event'])
  onClick() {
    const far = this.appSponsorEdit;
    const dialog = SponsorDialogComponent.openDialog(this.dialog, {
      farId: far.id,
      sponsor: far.sponsor as Sponsor,
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.sponsorEdited.emit(result);
      }
    });

    this.appSponsorDialogOpen.emit(dialog);
  }
}

@Component({
  selector: 'app-sponsor-dialog',
  templateUrl: './sponsor-dialog.component.html',
  styleUrls: ['./sponsor.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    SponsorComponent,
  ],
})
export class SponsorDialogComponent
  implements OnInit, AfterViewChecked, OnDestroy
{
  busy: boolean = false;

  farId: string;
  sponsor: Sponsor;
  private ngUnsubscribe = new Subject<void>();
  @ViewChild('sponsorControl') sponsorControl: SponsorComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SponsorDialogData,
    private sponsorService: SponsorService,
    private dialogRef: MatDialogRef<SponsorDialogComponent>,
    private changeDetect: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.farId = this.data.farId;
    this.sponsor = { ...this.data.sponsor };
  }

  ngAfterViewChecked(): void {
    this.changeDetect.detectChanges();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onSubmit() {
    this.busy = true;
    this.sponsorService
      .saveForFar(this.farId, this.sponsor)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (sponsor) => {
          this.dialogRef.close(sponsor);
        },
        (err) => {
          this.busy = false;
        }
      );
  }

  static openDialog(
    dialog: MatDialog,
    data: SponsorDialogData
  ): MatDialogRef<SponsorDialogComponent, Sponsor> {
    return dialog.open(SponsorDialogComponent, {
      data,
    });
  }
}
