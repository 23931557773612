import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'age',
  standalone: true,
})
export class AgePipe implements PipeTransform {
  transform(inputDate: string | number | Date): string {
    //NOTE: use 365.25 days per year as an approximation to take into account leap years
    if (!inputDate || inputDate === '') {
      return '';
    }
    let today: Date = this.getToday();
    const startDate: Date =
      typeof inputDate == 'string' || typeof inputDate == 'number'
        ? new Date(inputDate)
        : inputDate;
    let age = today.getFullYear() - startDate.getFullYear();
    const monthDiff = today.getMonth() - startDate.getMonth();
    const dayDiff = today.getDate() - startDate.getDate();
    const isPastMonth = monthDiff > 0;
    const isCurrentMonthPastDay = monthDiff === 0 && dayDiff >= 0;
    const isPastAnniversary = isPastMonth || isCurrentMonthPastDay;
    if (!isPastAnniversary) age--;

    return age.toLocaleString();
  }

  getToday() {
    return new Date();
  }
}
