<div class="grid-collection">
  <app-search-toolbar
    class="search-toolbar"
    viewDefKey="far"
    [filterFields]="FarAdvancedSearch"
    [filterParams]="filterParams"
    [filterPlaceholder]="'Event Title | Foreign National Name | Sponsor Name'"
    (filterParamsChange)="onFilterParamChange($event)"
    [compactCard]="false"
    [actionButtons]="headerActionButtons"
    [(view)]="view"
    [loading]="loading"
    >Foreign Access Requests
  </app-search-toolbar>
  <ng-container *ngIf="pageableCollection">
    <ng-container *ngIf="pageableCollection.content.length; else noData">
      <div class="grid-main">
        <div [class]="workspaceView ? '' : 'container'">
          <app-far-table
            *ngIf="view === 'table'; else card"
            [fars]="pageableCollection.content"
            [filterParams]="filterParams"
            (filterParamsChange)="onFilterParamChange($event)"
          ></app-far-table>
          <ng-template #card>
            <div class="my-3 d-flex flex-wrap gap justify-content-center">
              <ng-container *ngFor="let far of pageableCollection.content">
                <app-new-far-card [far]="far"></app-new-far-card>
              </ng-container>
            </div>
          </ng-template>
        </div>
      </div>
    </ng-container>
    <ng-template #noData>
      <app-no-result-found></app-no-result-found>
    </ng-template>
    <app-paginator
      [filterParams]="filterParams"
      (filterParamsChange)="onFilterParamChange($event)"
      [pageIndex]="pageableCollection.number"
      [pageSize]="pageableCollection.size"
      [totalElements]="pageableCollection.totalElements"
    ></app-paginator>
  </ng-container>
</div>
