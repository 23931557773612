import { Platform } from '@angular/cdk/platform';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AuthFlow } from '@app/auth/cognito-auth/cognito-auth.service';
import { ForgotaccountComponent } from '../login/forgot-account/forgotaccount.component';
import { LoginComponent } from '../login/login/login.component';
import { MultiFactorAuthenticationComponent } from '../login/multi-factor-authentication/multi-factor-authentication.component';
import { NewPasswordRequiredComponent } from '../login/new-password-required/new-password-required.component';

@Component({
  selector: 'app-cognito-auth-login',
  templateUrl: './cognito-auth-login.component.html',
  styleUrls: ['./cognito-auth-login.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    LoginComponent,
    ForgotaccountComponent,
    MultiFactorAuthenticationComponent,
    NewPasswordRequiredComponent,
  ],
})
export class CognitoAuthLoginComponent implements OnInit {
  public view: AuthFlow;
  constructor(private platform: Platform) {
    //redirect to blocked if mobile device
    if (this.platform.ANDROID || this.platform.IOS) {
      console.log('Using Mobile Device');
      this.view = AuthFlow.BLOCKED;
    }
  }

  ngOnInit() {
    if (this.view !== AuthFlow.BLOCKED) {
      this.view = AuthFlow.LOGIN;
    }
  }

  updateView(event: AuthFlow) {
    this.view = event;
  }
}
