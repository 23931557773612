import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlMatchResult } from '@angular/router';
import { FV_TAB } from '@app/user-preference/config/fv.config.const';
import { userPreferenceFormResolver } from '@app/user-preference/resolvers/user-preference-form.resolver';
import { AuthGuard } from '@shared/helpers/auth.guard';
import { FVEditRoles, FVViewRoles } from '@shared/models/role-permissions';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FVComponent } from './fv/creation.component';
import { FvViewComponent } from './fvview/fvview.component';

export const FV_ROUTES: Routes = [
  {
    path: 'fvs',
    title: 'Foreign Nationals',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      roles: FVViewRoles,
      viewDefKey: FV_TAB.viewDefKey,
    },
    resolve: {
      userPrefFormGroup: userPreferenceFormResolver,
    },
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'fv',
    title: 'New Foreign National',
    component: FVComponent,
    canActivate: [AuthGuard],
    data: { roles: FVEditRoles },
  },
  {
    path: 'fv/far/:id',
    title: 'New Foreign National',
    component: FVComponent,
    canActivate: [AuthGuard],
    data: {
      roles: FVEditRoles,
    },
  },
  {
    matcher: (url) => {
      // Using a matcher, so I can have view FV and view Linked FV
      // with the same route, to avoid refreshing when toggling between
      let result: UrlMatchResult | null = null;
      if (
        url.length === 4 &&
        url[0].path === 'fv' &&
        url[2].path === 'linked'
      ) {
        // route /fv/:linkedById/linked/:id
        result = {
          consumed: url,
          posParams: {
            linkedById: url[1],
            id: url[3],
          },
        };
      } else if (url.length === 2 && url[0].path === 'fv') {
        // route /fv/:id
        result = {
          consumed: url,
          posParams: {
            id: url[1],
          },
        };
      }
      return result;
    },
    component: FvViewComponent,
    title: 'Foreign National',
    canActivate: [AuthGuard],
    data: { roles: FVViewRoles },
  },
];
