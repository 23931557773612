import { CommonModule } from '@angular/common';
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AddApprovalComponent } from '@app/admin/approvals/dialogs/add-approval.component';
import { AuthService } from '@app/auth/auth.service';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { ResourceUserDetailsComponent } from '@shared/components/resource-user-details/resource-user-details.component';
import { AppConfigValue } from '@shared/constants/shared.const';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { Approval } from '@shared/models/approval';
import { Role } from '@shared/models/role';
import { User } from '@shared/models/user.model';
import { ApprovalService } from '@shared/services/approval.service';
import { UserService } from '@shared/services/user.service';
import { cloneDeep } from 'lodash';
import { Observable, of, switchMap, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountsUserFormComponent } from '../accounts-user-form/accounts-user-form.component';

@Component({
  selector: 'app-account-dialog',
  templateUrl: './account-dialog.component.html',
  styleUrls: ['./account-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    AccountsUserFormComponent,
    AuthEventsDialogDirective,
    AuthEventsDialogComponent,
    ResourceUserDetailsComponent,
  ],
})
export class AccountDialogComponent implements OnInit, AfterContentChecked {
  @ViewChild('appUserForm') appUserForm: AccountsUserFormComponent;
  readOnly: boolean = false;
  user$: Observable<User>;
  currentUser: User | null = this.authService.getUser(); //Logged In User
  busy: boolean = false;
  approvalRequired = false;
  userSaveFlow: Observable<User | null>;

  origUser: User;

  @AppConfigValue('orgAdminApprovalsEnabled')
  orgAdminApprovalsEnabled: boolean = false;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AccountDialogComponent>,
    private approvalDialogRef: MatDialogRef<AddApprovalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    public authService: AuthService,
    private approvalService: ApprovalService,
    private changeDetect: ChangeDetectorRef
  ) {}

  ngOnInit() {
    /*
      If there is a userId passed to the dialog,
      then the dialog is in edit mode and the user$ observable is set to a http call to get that specific user.
      If there is not a userId then the observable,
      then the form is not in edit mode the user$ observable is set to an empty object.
    */
    if (this.data?.userId) {
      this.user$ = this.userService
        .get(this.data.userId)
        .pipe(tap((u) => (this.origUser = cloneDeep(u))));
    } else {
      this.user$ = of({} as User);
    }
    this.readOnly = this.data?.readOnly;
  }

  ngAfterContentChecked(): void {
    this.changeDetect.detectChanges();
  }

  onSubmit(user: User) {
    this.approvalRequired =
      !this.origUser?.roles?.includes(Role.sv_org_admin) &&
      !!user.roles?.includes(Role.sv_org_admin);
    this.userSaveFlow = user.id
      ? this.updateAccount(user)
      : this.createAccount(user);

    this.userSaveFlow.pipe().subscribe({
      next: (user) => {
        if (user) {
          this.dialogRef.close(user);
        }
      },
      error: (err) => {
        this.busy = false;
      },
    });
  }

  saveAccount(user: User) {
    this.busy = true;
    return this.userService.save(user).pipe(
      tap(() => {
        this.busy = false;
      })
    );
  }

  userOrNull = (user: User | null) => {
    if (user) {
      return this.saveAccount(user);
    }
    return of(null);
  };

  createAccount(user: User) {
    if (this.approvalRequired) {
      return this.promptApproval(user).pipe(switchMap(this.userOrNull));
    }
    return this.saveAccount(user);
  }

  updateAccount(user: User) {
    if (this.approvalRequired) {
      return this.getApprovalbyUserId(user.id ?? '').pipe(
        switchMap((orgAdminApproval: Approval) => {
          if (orgAdminApproval && orgAdminApproval.approvalDate) {
            return of({ ...user, orgAdminApproval });
          }
          return this.promptApproval(user);
        }),
        switchMap(this.userOrNull)
      );
    }
    return this.saveAccount(user);
  }

  getApprovalbyUserId(id: string) {
    return this.approvalService.getBySystemUserId(id);
  }

  promptApproval(user: User) {
    // If org admin approval not enabled yet, just ignore
    if (!this.orgAdminApprovalsEnabled) {
      return of(user);
    }

    this.approvalDialogRef = AddApprovalComponent.openDialog(this.dialog, {
      user,
    });
    return this.approvalDialogRef.afterClosed().pipe(
      map((orgAdminApproval) => {
        if (!orgAdminApproval) return null;
        user.orgAdminApproval = orgAdminApproval;
        return user;
      })
    );
  }

  static openDialog(
    dialog: MatDialog,
    userId?: string,
    readOnly?: boolean
  ): MatDialogRef<AccountDialogComponent> {
    return dialog.open<AccountDialogComponent>(AccountDialogComponent, {
      data: { userId: userId, readOnly: readOnly },
      width: '600px',
    });
  }
}
