import { Pipe, PipeTransform } from '@angular/core';
import { Role, RoleLabels } from '@shared/models/role';

@Pipe({
  name: 'roleLabel',
  standalone: true,
})
export class RolePipe implements PipeTransform {
  transform(data: Role): string {
    return RoleLabels[data];
  }
}
