import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Directive,
  HostBinding,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, NgModel } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { BaseControlComponent } from '@shared/controls/base-control.component';
import { BaseModel } from '@shared/models/base.model';
import { FilterParams } from '@shared/models/filter-params.model';
import { PageableCollection } from '@shared/models/pageable-collection.model';
import {
  BehaviorSubject,
  from,
  fromEvent,
  Observable,
  ObservableInput,
  of,
  Subject,
  Subscription,
} from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  mergeMap,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

/**
 * Base class for creating auto complete select controls
 */
@Directive()
export abstract class BaseMatSelectComponent<T extends BaseModel>
  extends BaseControlComponent<T>
  implements OnInit, OnDestroy
{
  @HostBinding('class') class = 'auto-select-control';

  @ViewChild(NgModel)
  ngModel: NgModel;

  @Input()
  appearance: MatFormFieldAppearance = 'outline';

  @Input()
  placeholder = 'Select Item';

  @Input()
  clearable = true;

  @Input() selectedItems: T[];

  @Input()
  hideSelected = false;

  @ViewChild('matSelect', { static: true })
  matSelect: MatSelect;

  @HostBinding('class.auto-select-invalid')
  errorState: boolean = false;

  @Input()
  @HostBinding('class.hide-select-value')
  hideInputDisplay: boolean = false;

  pageNum: number = 0;

  baseFilterParams: FilterParams;

  /** control for the search input value */
  searchCtrl: FormControl = new FormControl();

  onChange = (value: T) => {};
  onTouched = () => {};

  _required = false;
  @Input()
  set required(_required: boolean) {
    this._required = coerceBooleanProperty(_required);
  }

  get required(): boolean {
    return this._required;
  }

  _disabled = false;
  @Input()
  set disabled(_disabled: boolean) {
    this._disabled = coerceBooleanProperty(_disabled);
  }

  get disabled(): boolean {
    return this._disabled;
  }

  searchInput$ = new BehaviorSubject<string>('');

  filteredValues = new Subject<T[]>();

  searchedNext: boolean = false;

  public searching = false;

  private destroy$: Subject<void> = new Subject<void>();

  pageNumber: number = 0;
  totalPages?: number = 0;

  pagedItems?: PageableCollection<T>;
  checkScrollSub$ = Subscription.EMPTY;
  fetchMoreSub$ = Subscription.EMPTY;
  searchSub$ = Subscription.EMPTY;

  protected constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.search();
  }

  search() {
    this.searching = true;
    this.searchSub$ = this.searchCtrl.valueChanges
      .pipe(
        startWith(''),
        takeUntil(this.destroy$),
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => {
          this.searching = true;
          this.pageNumber = 0;
          if (this.searchedNext) {
            this.fetchMoreSub$?.unsubscribe();
            this.searchedNext = false;
          }
        }),
        switchMap(() => this.doSearch() as ObservableInput<any>)
      )
      .subscribe((_result) => {
        this.pageNumber = 0;
        this.totalPages = _result.totalPages;
        this.pagedItems = _result;
        if (this.pagedItems && this.pagedItems.content) {
          this.filteredValues.next(this.pagedItems.content);
        }
        this.searching = false;
        this.matSelect.compareWith = (a: T, b: T) => a?.id === b?.id;
      });
  }

  private doSearch(): Observable<PageableCollection<T>> | {} {
    return this.searchItems(this.getFilterParams()).pipe(
      catchError(() => of({})) // empty list on error
    );
  }

  getFilterParams(): FilterParams {
    this.baseFilterParams.search = this.searchCtrl?.value;
    this.baseFilterParams.pageNum = this.pageNumber;
    return this.baseFilterParams;
  }

  refresh() {
    this.doSearch();
  }

  getNextPageOfData() {
    if (this.searching) {
      return;
    }
    if (this.totalPages) {
      if (this.pageNumber >= this.totalPages - 1) {
        this.pageNumber++;
        this.fetchMoreSub$?.unsubscribe();
        return;
      }
    }

    this.pageNumber++;
    this.searching = true;
    this.searchedNext = true;
    this.fetchMoreSub$ = this.searchItems(this.getFilterParams()).subscribe(
      (_result) => {
        this.searching = false;
        if (_result.content && _result.content.length > 0) {
          if (this.pagedItems) {
            this.pagedItems.content = this.pagedItems.content.concat(
              _result.content
            );
            this.filteredValues.next(this.pagedItems.content);
          }
        }
      }
    );
  }

  monitorScroll(event: boolean) {
    if (!event) {
      return;
    }

    this.checkScrollSub$.unsubscribe();
    this.checkScrollSub$ = from(['scroll', 'mousewheel'])
      .pipe(
        mergeMap((event) =>
          fromEvent(this.matSelect.panel.nativeElement, event)
        )
      )
      .subscribe((event) => {
        if (this.isNearBottomOfScrollPanel()) {
          this.getNextPageOfData();
        }
      });
  }

  private isNearBottomOfScrollPanel(): boolean {
    if (!this.matSelect.panel) return false;

    if (this.matSelect.panel) {
      return (
        this.matSelect.panel.nativeElement.offsetHeight +
          this.matSelect.panel.nativeElement.scrollTop >=
        this.matSelect.panel.nativeElement.scrollHeight * 0.85
      );
    } else {
      return false;
    }
  }

  abstract searchItems(params: FilterParams): Observable<PageableCollection<T>>;

  resetForm() {
    super.resetForm();
    if (this.ngModel?.control) {
      this.ngModel.control.reset();
    }
  }

  ngOnDestroy(): void {
    this.checkScrollSub$.unsubscribe();
    this.searchSub$.unsubscribe();
    this.fetchMoreSub$.unsubscribe();
  }

  clearValue(evt?: MouseEvent) {
    evt?.stopPropagation();
    this.pageNumber = 0;
    this.totalPages = 99;
    if (this.pagedItems) {
      this.pagedItems.content = [];
    }
    this.value = null as any;
    this.searchCtrl.reset();
    this.refresh();
    this.emitChangeEvent();
  }
}
