import { AsyncPipe, CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  Role,
  RoleColors,
  RoleDescriptions,
  RoleIcons,
  RoleLabels,
} from '@shared/models/role';

@Component({
  selector: 'app-user-role-chips',
  templateUrl: './user-role-chips.component.html',
  styleUrls: ['./user-role-chips.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatListModule,
    MatIconModule,
    MatChipsModule,
    MatTooltipModule,
    AsyncPipe,
  ],
})
export class UserRoleChipsComponent {
  RoleLabels = RoleLabels;
  RoleColors = RoleColors;
  RoleDescriptions = RoleDescriptions;
  RoleIcons = RoleIcons;
  @Input() selectable: boolean = true;

  @Input() stacked = false;

  @Input() roles?: Role[];
}
