import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseModel } from '@shared/models/base.model';
import { FilterParams } from '@shared/models/filter-params.model';
import { PageableCollection } from '@shared/models/pageable-collection.model';
import { isArray } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CrudService } from './crud.service';

@Injectable()
export class Repository<T extends BaseModel> {
  defaultFilterParams = new FilterParams({
    sortBy: 'createdDate',
    direction: 'DESC',
    pageNum: 0,
    pageSize: 20,
  });

  refresh$ = this.crudService.changeEvent$;

  private filterParams_ = new BehaviorSubject<FilterParams>(
    this.defaultFilterParams
  );
  filterParams$ = this.filterParams_.asObservable();

  collection$: Observable<PageableCollection<T>> = this.filterParams$.pipe(
    switchMap((filterParams) => {
      return this.crudService.find(filterParams);
    })
  );

  constructor(
    private crudService: CrudService<T>,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  onFilterParamChange(filterParams: FilterParams) {
    this.filterParams_.next(filterParams);
  }

  /**
   * Updates the url to reflect the Filter Params
   *
   * @param filterParams filterParams to update the router with
   */
  navigate(filterParams: FilterParams) {
    this.router.navigate([], {
      queryParams: filterParams.asParams(),
      replaceUrl: true,
    });
  }

  /**
   * Combines the `navigate()` and `FilterParamChange()`.
   * These 2 were used in all of the non-workspace collections.
   *
   * @param filterParams filterParams that are used in navigate
   */
  navigateWithParams(filterParams: FilterParams) {
    this.navigate(filterParams);
    this.onFilterParamChange(filterParams);
  }

  getQueryParamsSnapshot(...arrayKeys: string[]) {
    const params = { ...this.route.snapshot.queryParams };

    arrayKeys.forEach((key) => {
      if (params[key] && !isArray(params[key])) params[key] = [params[key]];
    });
    return params;
  }
}
