import { CommonModule } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AddressComponent } from '@shared/controls/address/address.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { hasValue } from '@shared/helpers/has-values';
import { Education } from '@shared/models/education.model';
import { AuthEventsDialogComponent } from '../auth-events-dialog/auth-events-dialog.component';

@Component({
  selector: 'app-fv-education-form',
  templateUrl: './fv-education.component.html',
  styleUrls: ['./fv-education.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatSelectModule,
    AddressComponent,
    AuthEventsDialogComponent,
    AuthEventsDialogDirective,
  ],
})
export class FvEducationFormComponent {
  @ViewChild('fvEducationForm') fvEducationForm: NgForm;
  @Input() education: Education;

  constructor() {}

  get valid(): boolean | null {
    return this.fvEducationForm?.valid && this.hasValues();
  }

  hasValues(): boolean {
    return (
      hasValue(this.education.educationDates[0].startDate) ||
      hasValue(this.education.educationDates[0].endDate) ||
      hasValue(this.education.schoolName) ||
      hasValue(this.education.degree) ||
      hasValue(this.education.campusLocation)
    );
  }
}
