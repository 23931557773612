import { Pipe, PipeTransform } from '@angular/core';
import {
  CommunicationContact,
  CommunicationContactDescriptions,
  CommunicationContacts,
  DerogatoryInformation,
  OffenseCode,
  OffenseCodeDescriptions,
  OffenseCodeMap,
  OffenseCodePrograms,
  OffenseCodes,
  RelationalCode,
  RelationalCodeDescriptions,
  RelationalCodes,
  TimeFrame,
  TimeFrameDescriptions,
  TimeFrames,
  Verification,
  VerificationDescriptions,
  Verifications,
} from '@shared/models/derogatory-information.model';

@Pipe({
  name: 'derogDescription',
  standalone: true,
})
export class DerogDescriptionPipe implements PipeTransform {
  offenseCode = OffenseCode;
  offenseCodeMap = OffenseCodeMap;
  offenseCodes = OffenseCodes;
  offenseCodeDescriptions = OffenseCodeDescriptions;
  offenseCodePrograms = OffenseCodePrograms;
  relationalCodes = RelationalCodes;
  relationalCodeDescriptions = RelationalCodeDescriptions;
  communicationContacts = CommunicationContacts;
  communicationContactDescriptions = CommunicationContactDescriptions;
  timeFrames = TimeFrames;
  timeFrameDescriptions = TimeFrameDescriptions;
  verifications = Verifications;
  verificationDescriptions = VerificationDescriptions;

  transform(
    code: string,
    attr:
      | 'program'
      | 'offense'
      | 'relations'
      | 'comms'
      | 'timeframe'
      | 'verified'
  ): string {
    switch (attr) {
      case 'program': {
        return this.offenseCodePrograms[code as OffenseCode];
      }
      case 'offense': {
        return this.offenseCodeDescriptions[code as OffenseCode];
      }
      case 'relations': {
        return this.relationalCodeDescriptions[code as RelationalCode];
      }
      case 'comms': {
        return this.communicationContactDescriptions[
          code as CommunicationContact
        ];
      }
      case 'timeframe': {
        return this.timeFrameDescriptions[code as TimeFrame];
      }
      case 'verified': {
        return this.verificationDescriptions[code as Verification];
      }
    }
  }
}

@Pipe({
  name: 'offenseDescription',
  standalone: true,
})
export class OffenseCodeDescriptionPipe implements PipeTransform {
  transform(value: DerogatoryInformation): string {
    if (!value.offenseCode) return '';
    return OffenseCodeDescriptions[value.offenseCode];
  }
}

@Pipe({
  name: 'relationalDescription',
  standalone: true,
})
export class RelationalCodeDescriptionPipe implements PipeTransform {
  transform(value: DerogatoryInformation): string {
    if (!value.relationalCode) return '';
    return RelationalCodeDescriptions[value.relationalCode];
  }
}

@Pipe({
  name: 'communicaitonDescription',
  standalone: true,
})
export class CommunicationCodeDescriptionPipe implements PipeTransform {
  transform(value: DerogatoryInformation): string {
    if (!value.numberOfCommunicationContacts) return '';
    return CommunicationContactDescriptions[
      value.numberOfCommunicationContacts
    ];
  }
}

@Pipe({
  name: 'timeframeDescription',
  standalone: true,
})
export class TimeframeCodeDescriptionPipe implements PipeTransform {
  transform(value: DerogatoryInformation): string {
    if (!value.timeFrameOfCommunicationContacts) return '';
    return TimeFrameDescriptions[value.timeFrameOfCommunicationContacts];
  }
}

@Pipe({
  name: 'verificationDescription',
  standalone: true,
})
export class VerificationCodeDescriptionPipe implements PipeTransform {
  transform(value: DerogatoryInformation): string {
    if (!value.verificationOfCommunicationContent) return '';
    return VerificationDescriptions[value.verificationOfCommunicationContent];
  }
}
