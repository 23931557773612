import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TimelineComponent } from './timeline.component';
import {
  TimelineBadgeDirective,
  TimelineContentTemplateDirective,
} from './timeline.directive';

const SHARED_COMPONENTS = [
  TimelineBadgeDirective,
  TimelineComponent,
  TimelineContentTemplateDirective,
];
@NgModule({
  imports: [CommonModule, FormsModule, ...SHARED_COMPONENTS],
  exports: SHARED_COMPONENTS,
})
export class TimelineModule {}
