import { CommonModule } from '@angular/common';
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { UserPrefSaved } from '@app/user-preference/model/config-view-definitions.model';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { FilterParams } from '@shared/models/filter-params.model';
import { OrgLocation } from '@shared/models/orglocation.model';
import { PageableCollection } from '@shared/models/pageable-collection.model';
import { CrudService } from '@shared/services/crud.service';
import { OrgLocationService } from '@shared/services/orglocation.service';
import { Repository } from '@shared/services/repository.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LocationsCollectionComponent } from '../collection/collection.component';

@Component({
  selector: 'app-locations',
  templateUrl: 'locations.component.html',
  styleUrls: ['locations.component.scss'],
  providers: [
    Repository,
    { provide: CrudService, useClass: OrgLocationService },
  ],
  standalone: true,
  imports: [
    CommonModule,
    LocationsCollectionComponent,
    AuthEventsDialogDirective,
    AuthEventsDialogComponent,
  ],
})
export class LocationsComponent implements OnInit, AfterContentChecked {
  private readonly route: ActivatedRoute = inject(ActivatedRoute);
  loading: boolean = false;
  filterParams: FilterParams = new FilterParams({
    pageNum: 0,
    pageSize: 20,
    sortBy: 'name',
    direction: 'ASC',
  });
  locations$: Observable<PageableCollection<OrgLocation>> =
    this.repo.collection$.pipe(
      tap((_) => {
        this.loading = false;
      })
    );

  constructor(
    private repo: Repository<OrgLocation>,
    private changeDetect: ChangeDetectorRef
  ) {}
  ngOnInit(): void {
    const savedValue: UserPrefSaved = this.route.snapshot.data
      ?.userPrefFormGroup?.value as UserPrefSaved;
    this.filterParams.currentUserCreatedBy =
      savedValue.location?.defaultMineOnly;
    Object.assign(this.filterParams, this.queryParamsSnapshot);
    this.onFilterParamsChange(this.filterParams);
  }

  ngAfterContentChecked(): void {
    this.changeDetect.detectChanges();
  }

  get queryParamsSnapshot(): Params {
    return this.repo.getQueryParamsSnapshot();
  }

  onFilterParamsChange(filterParams: FilterParams) {
    this.loading = true;
    this.filterParams = filterParams;
    this.repo.navigateWithParams(filterParams);
  }
}
