import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AdvancedSearchDirective } from '@shared/directives/advanced-search.directive';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { FilterConfig } from '@shared/models/filter-config/filter-config.type';
import { FilterParams } from '@shared/models/filter-params.model';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatToolbarModule,
    MatDialogModule,
    MatSnackBarModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    AdvancedSearchDirective,
    AuthEventsDialogDirective,
  ],
})
export class FilterComponent implements OnInit, OnDestroy, OnChanges {
  @Input() label?: string = 'Quick Search' || undefined;
  @Input() filterFields: FilterConfig[] = [];
  @Input() placeholder: string = 'Filter Criteria (e.g): Name, Email';
  @Input() filterParams: FilterParams;
  @Output() filterParamsChange: EventEmitter<FilterParams> = new EventEmitter();
  filter: UntypedFormControl = new UntypedFormControl();
  hasValue: boolean;
  filterSubscription: Subscription;

  constructor() {}

  /**
   * When the component initilizes, a subscription will be created that
   * listens to the changes of the formControl as an observable.
   * Then a debounce of 500ms will be added to limit the values emitted
   * downstream to the observable. The hasValue property will be true
   * if there is a term and false if there is no term.
   */
  ngOnInit() {
    this.filterSubscription = this.filter.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((term) => {
        this.hasValue = !!term;
        if (term) term = term.replaceAll(',', ' ');
        this.onFilterChange(term);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filterParams && this.filterParams.search) {
      this.filter.setValue(this.filterParams.search);
      if (this.filterParams.search.length > 0) {
        this.hasValue = true;
      }
    }

    if (!this.filterParams.search && !!this.filter.value)
      this.filter.setValue(null);
  }

  ngOnDestroy() {
    this.filterSubscription?.unsubscribe();
  }

  /**
   * Will update the filterParams property and emit it as a value for the
   * parent listening to the output
   * @param value : is a string that will be set to the filterParams.search property
   */
  onFilterChange(value: string): void {
    this.filterParams.search = value;
    this.filterParams.pageNum = 0;
    this.filterParamsChange.emit(this.filterParams);
  }

  /**
   * Will set the hasValue to false removing the Button in the template.
   * Then is will set the value of the filter:FormControl to an empty string
   */
  clearSearchField() {
    this.hasValue = false;
    this.filterParams.search = '';
    this.filter.setValue('');
  }

  onAdvancedSearchChange(filterParams: FilterParams) {
    this.filterParams = filterParams;
    this.filterParams.pageNum = 0;
    this.filterParamsChange.emit(filterParams);
  }
}
