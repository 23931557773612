import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AuthService } from '@app/auth/auth.service';
import { AppConfigService } from '@app/shared/services/app-config.services';
import { RolesDirective } from '@shared/directives/roles.directive';
import { Role } from '@shared/models/role';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { take } from 'rxjs';
import { GraphContainerComponent } from '../graph-container/graph-container.component';
import { MetricsInsightsComponent } from '../insights/insights.component';

@Component({
  selector: 'app-metrics-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    GraphContainerComponent,
    MetricsInsightsComponent,
    RolesDirective,
    DefaultPipe,
    MatToolbarModule,
    FormsModule,
    MatMenuModule,
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule,
  ],
})
export class DashboardComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private config: AppConfigService
  ) {}

  @ViewChild('graphContainer') graphContainer: GraphContainerComponent;
  showMetrics: boolean = true;
  Role = Role;
  displayDataList: any = [
    { label: 'Insights', checked: true },
    { label: 'Screening Results', checked: true },
  ];
  isHighSide: boolean = false;
  ngOnInit() {
    this.isHighSide = this.config.get('highEnvFeatures') ?? false;
    this.authService.user$?.pipe(take(1)).subscribe((user) => {
      if (user && user.roles) {
        if (user.roles.length > 1 || user.roles[0] !== Role.sv_org_user) {
          this.displayDataList.push({
            label: 'Screening Results by Organization',
            checked: true,
          });
        }
      }

      this.displayDataList.push({ label: 'Screening Status', checked: true });
      if (this.isHighSide) {
        this.displayDataList.push({
          label: 'Derogatory Metrics',
          checked: true,
        });
      }
    });
  }

  toggleAll(state: boolean) {
    for (let item of this.displayDataList) {
      item.checked = state;
    }
    this.graphContainer.showScreeningGraph = state;
    this.graphContainer.showOrgGraph = state;
    this.graphContainer.showStatusGraph = state;
    if (this.isHighSide) {
      this.graphContainer.showDerogGraph = state;
    }
  }

  toggleComponent(component: any) {
    if (component.label !== 'Insights') {
      if (component.label === 'Screening Results') {
        this.graphContainer.showScreeningGraph = component.checked;
      }
      if (component.label === 'Screening Results by Organization') {
        this.graphContainer.showOrgGraph = component.checked;
      }
      if (component.label === 'Screening Status') {
        this.graphContainer.showStatusGraph = component.checked;
      }
      if (component.label === 'Derogatory Metrics' && this.isHighSide) {
        this.graphContainer.showDerogGraph = component.checked;
      }
    }
  }
}
