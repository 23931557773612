import { without } from 'lodash';
import { SortSelect } from './sort-select.model';

export enum GroupSortField {
  NAME = 'name',
  DESCRIPTION = 'description',
  CREATED_DATE = 'createdDate',
  TYPE = 'foreignVisitorGroupType',
  FN_COUNT = 'foreignVisitorCount',
  NETWORK = 'networkDomain',
}

export const GroupSortLabels: { [key in GroupSortField]: string } = {
  [GroupSortField.NAME]: 'Name',
  [GroupSortField.DESCRIPTION]: 'Description',
  [GroupSortField.CREATED_DATE]: 'Created Date',
  [GroupSortField.TYPE]: 'Group Type',
  [GroupSortField.FN_COUNT]: 'Foreign National Count',
  [GroupSortField.NETWORK]: 'Network',
};

export const GroupSorts: SortSelect[] = without(
  Object.values(GroupSortField),
  // Non-sortable fields
  GroupSortField.NETWORK
).map((value) => {
  return { label: GroupSortLabels[value], value };
});
